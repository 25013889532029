import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * DATA_CHECKPOINT DATA
 */


//FIXME: downloading input_data everytime may be excessive
    //input_data
export const data_checkpoint_fields = gql`
  fragment DataCheckpointFields on data_checkpoint {
    id
    person_id
    update_id
    target_date
    input_data
    values
    projection_id
    notes
   }`

export const data_checkpoint_info_query = gql`
  query data_checkpoint_info($DataCheckpointId: uuid!){
    data_checkpoint( where: {id: {_eq: $DataCheckpointId}} ){
         ...DataCheckpointFields
    }
  }
  ${data_checkpoint_fields}
`

export class DataCheckpointInfo extends StaticQuery {
  get required_impl() {
    return ['DataCheckpointId'];
  }
  get gql_impl() {
    return data_checkpoint_info_query;
  }
  data_impl({ data: { data_checkpoint: [d] } }) {
    return d;
  }
}

export const data_checkpoint_for_person_query = gql`
  query data_checkpoint_info($PersonId: uuid!) {
    data_checkpoint (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...DataCheckpointFields
    }
  }
  ${data_checkpoint_fields}
  `

export class PersonDataCheckpoint extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return data_checkpoint_for_person_query;
  }
  data_impl({ data: { data_checkpoint: checkpoint } }) {
    return checkpoint;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_data_checkpoint_query = gql`
  mutation update_data_checkpoint($checkpointId: uuid!, $changeMap: data_checkpoint_set_input!) {
    update_data_checkpoint (
      where: {
          id: {_eq: $data_checkpointId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...DataCheckpointFields
      }
    }
  }
  ${data_checkpoint_fields}
`
export const insert_data_checkpoint_query = gql`
  mutation insert_data_checkpoint($changeMap: data_checkpoint_insert_input!) {
    insert_data_checkpoint (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...DataCheckpointFields
      }
    }
  }
  ${data_checkpoint_fields}
`

export const upsert_data_checkpoint_query = gql`
  mutation upsert_data_checkpoint($changeMap: data_checkpoint_insert_input!, $changeCols: [data_checkpoint_update_column!]!) {
    data_checkpoint:insert_data_checkpoint (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: data_checkpoint_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...DataCheckpointFields
      }
    }
  }
  ${data_checkpoint_fields}
`

export const delete_data_checkpoint_query = gql`
  mutation delete_data_checkpoint($deleteId: uuid!) {
    delete_data_checkpoint (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditDataCheckpointInfo extends SaveableQuery {
  get friendly_name() { return "Calculation checkpoint" }
  get required_keys_impl() {
    return ["id", "person_id", "update_id", "target_date", "input_data", "values"];
  }
  data_impl({ data: { data_checkpoint: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_data_checkpoint_query;
  }
  get delete_gql_impl() {
    return delete_data_checkpoint_query;
  }
  get save_queries_impl() {
    return [(new PersonDataCheckpoint(null, null))];
  }
}

