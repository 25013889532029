import { LitElement, html, css } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-icon-button';
import '@material/mwc-fab';

import { KaleForm, KaleComponent } from '../shared-components/form.js';
import '../shared-components/form.js';
import { EditNotesInfo } from '../queries/queries.js';
// TODO: replace with debounce-decorator
import { debounce } from '../shared-components/utilities/debounce.js';

const note_style = css`
        :host {
          min-width: 40%;
          min-height: 200px;
          height: 100%;
          box-sizing: border-box;
          margin: 12px;
          flex: 1 1;
          margin-bottom: 12px;
          background-color: var(--paper-yellow-600);
          box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
          position: relative;
          cursor: text;
          border-radius: 8px;
        }
        .note {
          box-sizing: border-box;
          position: relative;
          padding: 20px;
        }
        .note_content {
          padding: 4px;
          box-sizing: border-box;
          color: black;
          height: 100%;
          font-familY: monospace;
        }

        .note_content:focus, .note_content[focused] {
          outline: var(--paper-purple-400) auto 5px;
        }
  
        .tools  {
          visibility: hidden;
          margin-top: -32px;
          margin-bottom: 32px;
          margin-left: 32px;
          transform: scale(0.75);
        }
        .tools > * {
          margin-left: -16px;
        }
        .tools[focused]{
          visibility: visible;
        }
        .buttons {
          /*background-color: white;*/
          visibility: hidden;
          position: absolute;
          bottom: 0;
          margin: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row; 
          margin-bottom: 0;
        }
        :host(:hover) > .buttons, .buttons[focused] {
          visibility: visible;
        }
`

class Note extends KaleForm {
  static styles = note_style
  get form_name() { return "Note" }
  static get properties() {
    return {
      note: { type: Object },
      personid: { type: Number },
      editing: { type: Boolean },
      ...(super.properties)
    }
  }

  get focused() {
    return this._focused;
  }
  set focused(focus) {
    let old = this._focused;
    if (focus) {
      this.blur_debounce.clear();
      this._focused = true;
      if (focus != old) { this.requestUpdate("focused", old); }
    } else {
      this.blur_debounce();
    }
  }

  blur_actual() {
    let old = this._focused;
    this._focused = false;
    let text = this.editor.innerHTML;
    this.note.text = text;
    if (old != this._focused) {
      this.requestUpdate("focused", old);
    }
  }

  constructor() {
    super();
    this.unclicked = true;
    this.blur_debounce = debounce(this.blur_actual.bind(this), 150);
    this.note_mutation = new EditNotesInfo(
      note => {
        if (this.note && this.note.id === note.id) {
          this.note = note;
        }
      },  // data update function
      { changeMap: null },  //initial variables
      note => { // finalizing function
        this.dispatchEvent(new CustomEvent('note-detail-saved', { detail: { form: this, name: this.form_name, new: !this.note.id, note: note } }));
      });

  }

  save_impl(data) {
    this.note_mutation.save(this.note.id ? data : Object.assign(this.note, data), this.note);
  }
  delete() {
    if (this.note.id) {
      this.note_mutation.delete(this.note);
    } else {
      this.dispatchEvent(new CustomEvent('note-detail-saved', { detail: { form: this, name: this.form_name, new: !this.note.id, note: this.note } }));
    }
  }
  render() {
    return html`
        ${ this.note ?
        html`
              <div class="note">
                <div class="note_content" id="editor" ?focused=${this.focused} @focus=${e => this.focused = true} @blur=${e => this.focused = false} contenteditable @input=${e => this.input_change()} .innerHTML=${this.note.text !== "" ? this.note.text : this._focused ? "" : "Make a note..."}></div>
              </div>
              <div class="tools" ?focused=${this.focused}>
                <mwc-icon-button title="Bold" icon="format_bold" @click=${e => this.set_format('bold')}></mwc-icon-button>
                <mwc-icon-button title="Italic" icon="format_italic" @click=${e => this.set_format('italic')}></mwc-icon-button>
                <mwc-icon-button title="Underline" icon="format_underline" @click=${e => this.set_format('underline')}></mwc-icon-button>
                <mwc-icon-button title="Strikethrough" icon="format_strikethrough" @click=${e => this.set_format('strikethrough')}></mwc-icon-button>
              </div>
              <div class="buttons" ?focused=${this.focused}>
                <mwc-icon-button title="Delete" icon="delete"  @click=${e => this.delete()}></mwc-icon-button>
                <div style="flex: 1"></div>
                <mwc-button title="Save" icon="save" ?disabled=${!this.dirty && this.note.id} @click=${e => this.save()}>${this.dirty || !this.note.id ? "save" : "saved"}</mwc-icon-button>
              </div> `
        : html``}
      `;
  }

  firstUpdated() {
    this.editor = this.renderRoot.getElementById('editor');
    this.addEventListener("click", e => this.editor.focus());
  }

  input_change() {
    let text = this.editor.innerHTML;
    this.handleComponentDirty({ elem: this.editor, dirty: text !== this.note.text, field: 'text', value: text });
    //this.note.text = text;
  }

  set_format(cmd) {
    this.focused = true;
    document.execCommand(cmd, false);
    this.focused = true;
    this.editor.focus();
  }
}
window.customElements.define('person-note', Note);

class PersonNotes extends KaleForm {
  get form_name() { return "PersonNotes" }
  static get properties() {
    return {
      personid: { type: Number },
      focused_id: { type: String },
      focused_elem: { type: Object },
      new_notes: { type: Array },
      ...(super.properties)
    }
  }

  get notes() {
    return this._notes;
  }
  set notes(val) {
    let old = this._notes;
    if (old !== val) {
      this._notes = val.map(v => ({ date: new Date(v.inserted_at), data: v })).sort((b, a) => a.date - b.date);
      this.requestUpdate('notes', old);
    }
  }

  constructor() {
    super();
    this.new_notes = [];
  }
  render() {

    return html`
      <style>
        :host {
          margin-bottom: 24px;
          position: relative;
        }
        .container {
          box-sizing: border-box;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
          flex-direction: row; 
          margin: 0px -12px -12px;
          flex-wrap: wrap;
        }
        .add_button {
          margin-top: 24px;
        }
      </style>

      <mwc-button class="add_button" raised dense title="Add Note" icon="note_add" @click=${e => this.new_note()}>Note</mwc-button>
      <div class="container">
      ${this.new_notes ?
        repeat(this.new_notes, n => n.date, note =>
          html`<person-note .personid=${this.personid} .note=${note.data} @note-detail-saved=${e => this.new_notes = this.new_notes.filter(n => n.date !== note.date)}></person-note>`)
        : html``}
      ${this.notes ?
        repeat(this.notes, n => n.data.id, note =>
          html`
              <person-note .personid=${this.personid} .note=${note.data}></person-note>
            `)
        : html``}
      </div>
        
    `
  }

  new_note() {
    //console.log("INSERTING BLANK NOTE");
    this.new_notes = [...this.new_notes, { date: new Date(), data: { person_id: this.personid, text: "" } }];
  }

}

window.customElements.define('person-notes', PersonNotes);
export { PersonNotes }
