import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

export const person_tag_fields = gql`
  fragment PersonTagFields on person_tag {
    person_id
    person_tag_type_code
    tag_type {
      code
      name
      is_system
      priority
    }
   }`

export const person_tag_info_query = gql`
  query person_tag_info($PersonId: Int!){
    person_tag( where: {person_id: {_eq: $PersonId}} ){
         ...PersonTagFields
    }
  }
  ${person_tag_fields}
`

export class PersonTagInfo extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return person_tag_info_query;
  }
  data_impl({ data: { person_tag: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const person_tags_for_person_query = gql`
  query person_tag_info($PersonId: Int!) {
    person_tag (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...PersonTagFields
    }
  }
  ${person_tag_fields}
  `

export class PersonPersonTags extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return person_tags_for_person_query;
  }
  data_impl({ data: { person_tag: person_tags } }) {
    return person_tags;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_person_tag_query = gql`
  mutation update_person_tag($person_id: uid!, $TagCode: person_tag_type_code!, $changeMap: person_tag_set_input!) {
    update_person_tag (
      where: {
      _and: [
      {person_id: {_eq: $PersonId}},
      {person_tag_type_code: {_eq: $TagCode}}
    ]
    },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...PersonTagFields
      }
    }
  }
  ${person_tag_fields}
`
export const insert_person_tag_query = gql`
  mutation insert_person_tag($changeMap: person_tag_insert_input!) {
    insert_person_tag (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: person_tag_pkey,
        update_columns: []
      }
    ) {
      returning {
        ...PersonTagFields
      }
    }
  }
  ${person_tag_fields}
`

export const upsert_person_tag_query = gql`
  mutation upsert_person_tag($changeMap: person_tag_insert_input!, $changeCols: [person_tag_update_column!]!) {
    person_tag:insert_person_tag (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: person_tag_pkey,
        update_columns: []
      }
    ) {
      returning {
        ...PersonTagFields
      }
    }
  }
  ${person_tag_fields}
`

/*

query person_tag_update($PersonId: uuid!, $Remove: [String]){
    person_tag( where: {
      _and: [
      {person_id: {_eq: $PersonId}},
      {person_tag_type_code: {_in: $Remove}}
    ]
    }){
         ...PersonTags
    }
  }


mutation person_tag_update($PersonId: uuid!, $Add: [person_tag_insert_input!], $Remove: [String]) {
  delete_person_tag(where: {_and: [{person_id: {_eq: $PersonId}}, {person_tag_type_code: {_in: $Remove}}]}) {
    affected_rows
  }
  insert_person_tag(objects: $Add) {
    affected_rows
  }
}
delete_person_tag(where: {_and: [{person_id: {_eq: $PersonId}}, {person_tag_type_code: {_in: $Remove}}]}) {
    affected_rows
  }

*/
export const delete_person_tag_query = gql`
  mutation delete_person_tag($deleteId: Int!) {
    delete_person_tag (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`

export const clear_tags = gql`
  mutation clear_tags($PersonId: uuid!, $Tags: [String!]) {
    delete_person_tag (
       where: {_and: [{person_id: {_eq: $PersonId}}, {person_tag_type_code: {_in: $Tags}}]}
      )
      {
      affected_rows
      }
}
`

export const insert_tags = gql`
mutation person_tag_update($Tags: [person_tag_insert_input!]!) {
  insert_person_tag(
    objects: $Tags,
    on_conflict: {
        constraint: person_tag_pkey,
        update_columns: []
      }
    ) {
    affected_rows
  }
}`




export class EditPersonTagInfo extends SaveableQuery {
  get friendly_name() { return "Tag" }
  get required_keys_impl() {
    return ["id", "person_id", "tag_type_id"];
  }
  data_impl({ data: { person_tag: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_person_tag_query;
  }
  get delete_gql_impl() {
    return delete_person_tag_query;
  }
  get save_queries_impl() {
    return [(new PersonPersonTags(null, null))];
  }
}

