//
// Component to render the timeline intervals from a timeline model
// 
//
const debug = false;

import { LitElement, html, css, svg, nothing } from 'lit';
import { until } from 'lit/directives/until.js';


import { timeline_colors, timeline_classes, transitions } from './colors.js';
import { editor_type_info } from './editor_types.js';
import { editors, series_editors } from './editors.js';

import './tag-list.js';
import './timeline-fab.js';
import { wait, trueAfter } from '../shared-components/utilities/anim.js';
import { EventDate } from '../benefits-app/eventdate.js';
import { context_builders } from './context.js';


const class_info = new Map();
editor_type_info.forEach(e => {
  [e.class, e.icon]
  if (class_info.has(e.class)) {
    class_info.set(e.class, [...class_info.get(e.class), e])
  } else {
    class_info.set(e.class, [e])
  }
});
const editor_data_map = new Map(editor_type_info.map(e => [e.class, e]));

const get_editor_icon = ((c, d) => {
  let info = class_info.get(c);
  if (info) {
    let ret = info.find(i => !i.matches || i.matches(d));
    return ret ? ret.icon : null;
  }
});

/*
let label_path_points_old = (x1, y1, x2, y2) => {
  let w = x2 - x1;
  let h = y2 - y1;
  let absw = Math.abs(w);
  let absh = Math.abs(h);
  let signh = h >= 0 ? 1 : -1
  let cx1 = w * 1.2 - (absh); //201.75
  cx1 = cx1 > 0 ? cx1 : 0;
  let cy1 = h * 0.2 - (signh) * 30;
  let cx2 = -w * 0.2 + (0.7 * absh); //-67.25
  cx2 = cx2 > 0.7 * w * absw ? 0.7 * w * absw : cx2;
  let cy2 = h * 0.8 + (signh) * 30;
  return [[x1, y1], [cx1 + x1, cy1 + y1], [cx2 + x1, cy2 + y1], [w + x1, h + y1]];
}*/
/*
let label_path_points = (i, n, x1, y1, x2, y2) => {
  let w = x2 - x1;
  let h = y2 - y1;
  let absh = Math.abs(h);
  absh = absh > 0 ? absh : 1;
  let frac = 1 - (0.25 + (1.0 * i / n) * 0.5);
  //let theta = Math.atan(h / w);
  let denom = (absh / (0.2 * w));
  let x_off = frac * w + denom;
  let y_off = -h * (1 - frac) / denom;
  let y_off2 = (h * frac) / denom;
  //let x1fac = frac * w;// absh < 1.2 * absw ? absh : absw * 1.2;
  return [[x1, y1], [x1 + x_off, y1 - y_off2], [x1 + x_off, y2 - y_off], [x2, y2]]
}

let make_label_path = (i, n, x1, y1, x2, y2) => {
  let [[p1x, p1y], [p2x, p2y], [p3x, p3y], [p4x, p4y]] = label_path_points(i, n, x1, y1, x2, y2);
  return `M ${p1x},${p1y} C ${p2x},${p2y} ${p3x},${p3y} ${p4x},${p4y}`;
  //return `M ${x1},${y1} c ${cx1},${cy1} ${cx2},${cy2} ${w},${h}`;
  //`M 0,0 c 150,-50 -50,150 100,100`
}

let make_label_points = (c, i, n, x1, y1, x2, y2) => {
  let points = label_path_points(i, n, x1, y1, x2, y2);
  let [[p1x, p1y], [p2x, p2y], [p3x, p3y], [p4x, p4y]] = points;

  return svg`
  ${points.map(([x, y]) => svg`<circle class=${c} r="2" cx=${x} cy=${y}></circle>`)}
  <path class=${c} d=${`M ${p1x},${p1y} L ${p2x},${p2y} L ${p3x},${p3y} L ${p4x},${p4y} L ${p1x},${p1y}`}></path>
  `
}*/

Math.easeInOutCubic = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return c / 2 * t * t * t + b;
  t -= 2;
  return c / 2 * (t * t * t + 2) + b;
};

Math.easeOutQuint = function (t, b, c, d) {
  t /= d;
  t--;
  return c * (t * t * t * t * t + 1) + b;
};
Math.easeOutNonic = function (t, b, c, d) {
  t /= d;
  t--;
  return c * (t * t * t * t * t * t * t * t * t + 1) + b;
};

const label_path_points = (i, n, x_start, y_start, x_end, y_end) => {
  let w = x_end - x_start;
  let h = y_end - y_start;
  let absh = Math.abs(h);
  let up = y_end < y_start;
  let b = w > absh ? w : absh;
  let a = w > absh ? absh : w;

  // shared point in middle
  let A_x = x_start + w / 2;
  let A_y = y_start + h / 2;

  let ratio = (absh * 2) / w;
  let clamp_ratio = (ratio >= 3 ? 3 : ratio < 1 ? 1 : ratio) - 1;
  let fac = Math.easeOutNonic(clamp_ratio, 0, 5, 2);
  fac = fac * fac;
  fac = fac > 5 ? 5 : fac;

  //let scaled_clamp = Math.log(Math.pow(10, clamp_ratio + 1));
  let div = (4 + fac);
  let horiz_offset = w / div;//- (w / ((absh / w)));
  //horiz_offset = horiz_offset < w / 10 ? w / 10 : horiz_offset;
  // first control point, horizontal from start, 1/4w over
  //console.log(`y: ${y_start}, h:${absh}, w:${w}, h/w:${ratio}, clamp:${clamp_ratio}, fac: ${fac}, div:${div} off:${horiz_offset}, w/3: ${w / 3}`);
  let cp_A1_x = x_start + horiz_offset;
  let cp_A1_y = y_start;// + a / 10;

  // second control point (third point is mirror)
  //let side = b / 8; // Math.sqrt((b * b) / 36); // pythagoras
  let cp_A2_x = A_x - (w / 12) - (fac * (w / 20)); //A_x - side / 3;
  let cp_A2_y = A_y + ((b / 5) + (fac * a / 15)) * (up ? 1 : -1);

  // last control point, horizontal to end
  let cp_B2_x = x_end - horiz_offset;
  let cp_B2_y = y_end;// - a / 10;


  return [[x_start, y_start], [cp_A1_x, cp_A1_y], [cp_A2_x, cp_A2_y], [A_x, A_y], [cp_B2_x, cp_B2_y], [x_end, y_end]];
}

const make_label_path = (i, n, x_start, y_start, x_end, y_end) => {
  let [[p1x, p1y], [p2x, p2y], [p3x, p3y], [p4x, p4y], [p5x, p5y], [p6x, p6y]] = label_path_points(i, n, x_start, y_start, x_end, y_end);
  return `M ${p1x},${p1y} C ${p2x},${p2y} ${p3x},${p3y} ${p4x},${p4y} S ${p5x},${p5y} ${p6x},${p6y}`;
}

const make_label_points = (c, i, n, x_start, y_start, x_end, y_end) => {
  let points = label_path_points(i, n, x_start, y_start, x_end, y_end);
  let [[s_x, s_y], [cp1x, cp1y], [cp2x, cp2y], [m_x, m_y], [cp3x, cp3y], [e_x, e_y]] = points;

  return svg`
  ${points.map(([x, y]) => svg`<circle class=${c} r="2" cx=${x} cy=${y}></circle>`)}
  <path class=${c} d=${`M ${s_x},${s_y} L ${cp1x},${cp1y} M ${m_x},${m_y} L ${cp2x},${cp2y} M ${e_x},${e_y} L ${cp3x},${cp3y}`}></path>
  `
}


class TimelinePoint {
  constructor(date, index, lists) {
    this._date = date;// ? new EventDate(date) : null;
    this._index = index;
    this._lists = lists;
  }
  get date() { return this._date };
  get normalized() { return this._lists.normalized[this._index]; }
  get n() { return this._lists.normalized[this._index]; }
  get squished() { return this._lists.squished[this._index]; }
  get s() { return this._lists.squished[this._index]; }
  get screen_y() { return this._lists.screen_y[this._index]; }
  get sy() { return this._lists.screen_y[this._index]; }
}


let style = css`
        ${timeline_classes}
        :host {
         font-family: "Roboto", "Sans";
         --timeline-pix-ratio: 1;

          --infosheet-person-color: var(--paper-grey-600);
          --infosheet-person-text: white;
          --infosheet-line-color: var(--paper-grey-600);
          /*
         --shadow-level-one: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
         --shadow-level-onepointone: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4); 
         --shadow-level-two: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4); */
         ${transitions}

          }
        #container {
          transition: var(--opacity-transition);
        }
        #container[inprogress] {
          opacity: 0.5;
        }

        #timeline-canvas {
          transition: var(--transform-transition);
          transform: translateY(-100%);
          transform-origin: top;
        }
        #timeline-canvas[loaded] {
          transform: translateY(0);
        }
        foreignObject {
          overflow: visible;
          /*
          border: 1px dotted pink;
          */
          
        }
        foreignObject > * {
          transform: scale(var(--timeline-pix-ratio));
          transform-origin: 0 0;
          position: fixed;
          /*
          border: 1px solid green;
          */
        }

        .service_hours { --graph-color: var(--adjustment-color); }
        .service_years { --graph-color: var(--employment-color); }
        .credit_years { --graph-color: var(--benefit-color); }
        .balance { --graph-color: var(--contribution-color); }

        path.graphs {
          fill: var(--graph-color);
          fill-opacity: 0.2;
          stroke-width: 2px;
          stroke: var(--graph-color);
        }


        line {
          //stroke: purple;
          stroke-linecap: round;
        }


        div.infosheet { color: var(--cursor-color); }

        div.side_infosheet { 
          z-index: 1;
          position: fixed;
          right: 0;
          top: 0;
          width: 0;
          height: 0;
          overflow: visible;
        }
        .infosheet_content { 
          opacity: 0;
          position: fixed;
          top: var(--app-bar-offset, 64px);
          right: 0;
          height: calc(100vh - 64px);
          width: 400px;
          box-shadow: var(--shadow-elevated);
          transform: translateX(440px);
          transition: var(--transform-transition), opacity 1s step-end;

          padding: 18px;
          background-color: white;
          /*
          background-color: var(--paper-grey-700);
          border: 1px var(--paper-grey-700);
          color: white;*/
          display: grid;
          grid-template-columns: 120px auto;
          grid-template-rows: auto auto;
          grid-template-areas:
            "title title"
            "cal lines"
            "info info";
          align-content: start;
          grid-gap: 8px;
        }   
        div.side_infosheet[open] > .infosheet_content {
          transform: translateX(0);
          opacity: 1;
          /*transition: opacity 0.5s step-end;*/
          transition: var(--transform-transition), opacity 0s step-end;
        }
        .infotitle { 
          grid-area: title;
          display: flex;
          flex-direction: row;
          font-size: 90%;
        }
        .infotitle h1 {
          text-transform: uppercase;
          color: var(--paper-grey-600);
          flex: 1;
          margin: 0;
          margin-bottom: 12px;
        }
        .infotitle mwc-icon-button {
          flex: 0;
          position: relative;
          top: -3px;
        }

/*
        .infosheet_content::before { 
          content: '';
          height: 20px;
          position: absolute;
          top: -20px;
          left: 0px;
          width: 500px;
          box-shadow: var(--shadow-elevated);
        }
        */

        div.infosheet_personinfo_border {
          border-radius: 8px;
          border-top: 3px solid var(--infosheet-person-color);
          padding: 6px;
          grid-area: info;
          margin-top: 24px;
        }

        div.infosheet_personinfo {
          color: white;
          padding: 18px;
          border-radius: 8px;
          transition: var(--bg-transition);
        }

        .cal_connector {
          display: block;
          border-left: 3px solid var(--infosheet-person-color);
          height: 100%;
          width: 1px;
          grid-area: cal;
          position: relative;
          top: 32px;
          left: 60px;
          z-index: -1;
        }


        div.infosheet_personinfo > div {
          margin-bottom: 12px;
        }

        div.infosheet_content .header { 
          font-size: 1.2em;
        }

        .infosheet { --cursor-color: var(--paper-grey-500); }
        line.infosheet { stroke: var(--cursor-color); }
        circle.infosheet {
          fill: var(--infosheet-line-color);
          fill: none;
          stroke-width: 1px; 
          stroke: var(--infosheet-line-color);
          r: 9px;
          transition: r 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }
        circle.infosheet.snapped {
          r: 10px;
        }
        circle.infosheet.snapped[anim_done] {
          r: 6px;
        }
        /*
        circle.infosheet:not([exists]) {
          r: 10px;
        }
        circle.infosheet.snapped[animate] {
          animation: radius 1s cubic-bezier(0.4, 0, 0.2, 1);
        }
        @keyframes radius {
          from { r: 9px; }
          to { r: 6px; }
        }*/
        path.infosheet_leader {
          stroke-width: 1px;
          stroke: var(--infosheet-line-color);
          fill: none;

          stroke-dasharray: 1;
          stroke-dashoffset: 0;
        }
        path.infosheet_leader[animate] {
          stroke-dasharray: 1;
          stroke-dashoffset: 1;
          animation: dash 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }

        @keyframes dash {
          from { stroke-dashoffset: 1; }
          to { stroke-dashoffset: 0; }
        }
     

        .infosheet_date { 
          font-size: 3em;
          font-weight: 200;
        }
        .calendar {
          font-size: 18px;
          width: 120px;
          text-align: center;
          border: 2px solid var(--infosheet-person-color);
          border-radius: 8px;
          grid-area: cal;
          height: fit-content;
          overflow: hidden;
          background-color: white;
        }
        .calendar > .cal_month {
          transition: var(--bg-transition);
          background-color: var(--infosheet-person-color);
          color: var(--infosheet-person-text);
          padding: 8px;
        }
        .calendar > .cal_date {
          font-size: 48px;
          font-weight: 100;
          padding: 12px;
          padding-bottom: 0;
          padding-top: 0;
        }
        .calendar > .cal_year {
          font-size: 18px;
          color: var(--paper-grey-700);
          font-weight: 400;
          padding-bottom: 12px;
        }

        .infosheet_lines {
          grid-area: lines;
          width: auto;
          margin: 8px;
          margin-right: 18px;
        }
        .infosheet_line {
          border-bottom: 1px solid var(--paper-grey-400);
          padding: 8px;
          padding-bottom: 3px;
        }
        .infosheet_node {
          font-size: 80%;
          display: inline-block;
          padding: 4px 8px;
          width: fit-content;
          color: var(--timeline-primary-color);
          border-radius: 8px;
          border: 1px solid var(--timeline-primary-color);
        }
        .infosheet_node.editor {
          background-color: var(--timeline-primary-color);
          color: var(--timeline-text-color);
          border: none;
        }

        .deceased_person {
          --infosheet-person-color: var(--deceased-person-color);
          --infosheet-line-color: var(--deceased-person-alt-color);
        }
        .working_retiree_person {
          --infosheet-person-color: var(--working-retiree-person-color);
          --infosheet-line-color: var(--working-retiree-person-alt-color);
        }
        .employee_person {
          --infosheet-person-color: var(--employee-person-color);
          --infosheet-line-color: var(--employee-person-alt-color);
        }
        .ex-employee_person {
          --infosheet-person-color: var(--ex-employee-person-color);
          --infosheet-line-color: var(--ex-employee-person-alt-color);
        }
        .retiree_person {
          --infosheet-person-color: var(--retiree-person-color);
          --infosheet-line-color: var(--retiree-person-alt-color);
          --infosheet-person-text: black;
        }
        .disability_person {
          --infosheet-person-color: var(--disability-person-color);
          --infosheet-line-color: var(--disability-person-alt-color);
          --infosheet-person-text: black;
        }
        .ineligible_person {
          --infosheet-person-color: var(--ineligible-person-color);
          --infosheet-line-color: var(--ineligible-person-alt-color);
        }
        .errors_person {
          --infosheet-person-color: var(--error-person-color);
          --infosheet-line-color: var(--error-person-alt-color);
        }

        .infosheet_content  .infosheet_personinfo{
          background-color: var(--infosheet-person-color);
          color: var(--infosheet-person-text);
        }





        line.gap { }
        line.gap_clear {
          stroke: #ddd;
          stroke: #aaa;
        }

        .base {
          --span-color: var(--paper-grey-500);
          --span-text-color: white;
          stroke: var(--paper-grey-500);
          stroke-dasharray: 4;
          stroke-width: 2;
          opacity: 0.8;
          }
 
        .projected_tl {
          --span-color: var(--paper-teal-500);
          --span-text-color: white;
          stroke: var(--paper-teal-500);
          stroke-dasharray: 4;
          stroke-width: 12;
          opacity: 0.4;
          }
 
        
        line.subspan {
          transition: var(--stroke-width-transition);
          stroke: var(--timeline-primary-color);
        }

        line.subspan[tag_highlight] {
          stroke-width: 5px;
        }
        line.subspan[error] {
          stroke: var(--error-color);
        }


        path[error] {
          fill: var(--error-color);
        }
        path[migration] {
          fill: var(--migration-color);
        }

        .editor_chip[error], line[error] {
          --timeline-primary-color: var(--error-color);
          --timeline-text-color: white;
          stroke: var(--error-color);
          }

        .editor_chip[migration], line[migration] {
          --timeline-primary-color: var(--migration-color);
          --timeline-text-color: white;
          stroke: var(--migration-color);
          }

        circle {
          fill: var(--paper-grey-800);
          stroke-width: 3;
        }

        path.label_line {
          stroke: var(--timeline-primary-color);
          fill: none;
          transition: var(--stroke-width-transition);
        }

        path.label_line_point, circle.label_line_point {
          stroke: var(--timeline-primary-color);
          fill: none;
          opacity: 0.2;
        }

        path.label_line[tag_highlight] {
          stroke-width: 2px;
        }
        .label_marker.shown {
          stroke-width: 3px;
        }

        circle.series_node, circle.label_marker {
          stroke: #ddd;
          stroke-width: 1.5px;
          fill: var(--timeline-primary-color);
          transform: scale(1,1);
          transition: var(--transform-transition);
        }

        circle.series_node[empty], circle.label_marker[empty] {
          transform: scale(0.7,0.7);
        }
        
        circle.label_marker[tag_highlight] {
          transform: scale(1.3);
        }
     
         circle.series_highlight_node, circle.label_highlight_marker {
          fill: var(--timeline-primary-color);
          opacity: 0;
          transition: var(--opacity-transition);
        }
        
        circle.label_outer_marker {
          stroke: none;
          opacity: 0.25;
          fill: var(--timeline-primary-color);
          transform: scale(1,1);
          transition: var(--transform-transition);
        }

        circle.label_outer_marker[tag_highlight] {
          transform: scale(1.5);
        }
       
        circle.series_highlight_node[series_highlight], circle.label_highlight_marker[tag_highlight] {
          fill: var(--timeline-primary-color);
          opacity: 0.35;
        }



        .label {
          /*font-size: 12px;*/
          box-sizing: border-box;
          border: 2px solid rgba(0,0,0,0);
          border-radius: 8px;
          font-size: 75%;
          color: var(--timeline-primary-color);
          padding: 7px 12px 7px 12px;
          cursor: default;
          overflow: visible;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: left;
          transition: var(--border-transition);
        }
        .label[calculating] {
          cursor: wait !important;
        }

        path.label_line[cancelled] {
          stroke-dasharray: 2 3;
        }
      
        .label[cancelled] .lbl_body {
          font-weight: 100;
          text-decoration: line-through;
        }

        .label.error, .label.migration, .label.alert {
          font-weight: bold;
          overflow: visible;
        }

        .label > .lbl_body {
          box-sizing: border-box;
          font-style: italic;
          display: flex;
          flex-direction: column; 
          margin-bottom: -3px;
          text-align: right;
        }

        /*
        .label > span.indicator {
          font-weight: 900;
          text-transform: uppercase;
          font-size: 80%;
          font-stretch: condensed;
          color: white;
          border-radius: 10px;
          padding: 4px 8px 4px 8px;
          display: inline-block;
          position: absolute;
          margin: 0;
          right: 100%;
          top: 4px;
        }*/
        .label > span.indicator {
          display: block;
          font-weight: 100;
          text-transform: uppercase;
          font-size: 80%;
          font-stretch: condensed;
          font-style: normal;
        }
        /*

        .label > span.error-label-indicator {
          background-color: var(--error-color);
        }

        .label > span.migration-label-indicator {
          background-color: var(--migration-color);
        }

        .label > span.alert-label-indicator {
          background-color: var(--alert-color);
        }*/


        .lbl_body > span {
          display: inline-block;
          white-space: nowrap;
          /*
          
          */
          text-align: left;
          font-size: 110%;
        }

        .lbl_body span+span { 
          font-stretch: condensed;
          font-weight: 200;
          font-size: 90%;
        }

        .label:not([editor]) .lbl_body span+span {
          transition: var(--opacity-transition);
          opacity: 0;
          max-width: 100px;
          overflow: visible;
        }

        .label:not([editor]) {
          transition: var(--font-transition), var(--transform-transition);
        }

          .label[tag_highlight]:not([editor]) {
            /*transform: scale(1.1);
            font-weight: 800;
            border-radius: 100%;
            border: 2px solid var(--timeline-primary-color);
            */
            border-color: var(--timeline-primary-color);
          }

        .label[tag_highlight]:not([editor]) .lbl_body span+span { 
          opacity: 1;
        }


          .label-container {
            box-sizing: border-box;
          /*height: 64px;
          width: 200px;*/
          height: calc(100% / var(--timeline-pix-ratio));
          width: calc(100% / var(--timeline-pix-ratio));
          font-style: italic;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row; 
          margin-bottom: 0;
        }
        .label-container.left {
           color: var(--paper-grey-600);
           
           flex-direction: column;
           justify-content: flex-start;
           align-items: flex-start;
           height: fit-content;
           width: fit-content;
           /*
           background-color: purple;
           */
           font-style: normal;
        }

          .label_isolator {
            position: fixed;
          }
        .label.infosheet {
          /*font-size: 15px;*/
          font-size: 100%;
          font-weight: bold;
          width: 100px;
          cursor: pointer;
         text-align: right;
         position: relative;
         left: -18px;
        }
        .label.infosheet_item {
          white-space: nowrap;
          overflow: visible;

        } 
        .label.infosheet_item > .item_label {
          font-weight: 400;
          text-transform: uppercase;
          /*font-size: 8px;*/
          font-size: 50%;
          position: relative;
          top: -2px;
          margin-right: 4px;
          width: 46px;
          display: inline-block;
          }

          .infosheet_extra {
            font-size: 80%;
          font-stretch: condensed;
          font-weight: 100;
          }
   
        
        .label[editor] {
          font-weight: 800;
          infosheet: pointer;
          font-style: normal;
          cursor: pointer;

          background-color: var(--timeline-primary-color);
          color:white;
          
          /*border: 1px solid var(--timeline-primary-color);*/
          border-bottom-left-radius: 16px ;
          border-bottom-right-radius: 16px ;
          border-top-left-radius: 16px ;
          border-top-right-radius: 16px ;
          transition: var(--shadow-transition);
          box-shadow: var(--shadow-scenario); /* var(--shadow-level-one);*/
        }

        .label[is_scenario], .editor_chip[is_scenario] {
          --shadow-scenario: 0 0 0 2px var(--paper-grey-200), 0 0 0 8px var(--paper-teal-200);
        }

        .label[highlighted], .label[editor]:hover, .editor_chip:hover, .editor_chip[tag_highlight], .label[editor][tag_highlight] {
          box-shadow: var(--shadow-elevated), var(--shadow-scenario);
          /*filter: brightness(1.05);*/
        }


        .edit_container {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 8px;
        }

        .editor_parent_path {
          fill: var(--timeline-primary-color);
          fill-opacity: 0.2;
          stroke: none;
        }

        .editor_chip {
          box-sizing: border-box;
          overflow: hidden;
          width: 100%;
          min-height: 100%;
          background-color: var(--timeline-primary-color);
          color: var(--timeline-text-color);
          /*font-size: 14px;*/
          font-size: 75%;
          font-weight: bold;
          text-align: left;

          padding: 12px 12px 8px 12px;
          border-radius: 8px ;
          cursor: pointer;

          transition: var(--shadow-transition);
          /*box-shadow: none; */
          box-shadow: var(--shadow-scenario); /* var(--shadow-level-one);*/
        }
        .editor_chip[calculating] {
          cursor: wait;
        }


        .editor_chip > .chip_header {
          display: inline-block;
          font-weight: 700;
          text-transform: uppercase;
          font-stretch: condensed;
        }

        .editor_chip > .chip_detail {
          /*font-size: 13px;*/
          font-size: 90%;

          font-weight: 100;
          font-stretch: ultra-condensed;
        }
        .editor_chip > .chip_tags {
          margin-top: -4px;
          
        }
        .chip_tags > .chip_tag {
          /*font-size: 9px;*/
          font-size: 60%;
          font-weight: 800;
          font-stretch: ultra-condensed;
          display: inline-block;
          padding: 2px 7px 2px 7px;
          margin-right: 2px;
          border-radius: 8px;
          color: var(--timeline-primary-color);
          background-color: var(--timeline-text-color);
        }

        .editor_chip mwc-icon {
          position: relative;
          top: 8px;
          margin-right: 4px;
        }

        div.popup {
          border-radius: 8px ;
          width: fit-content;
          z-index: 50;
          transition: var(--shadow-transition);
          box-shadow: none; /* var(--shadow-level-one);*/
          box-shadow: var(--shadow-level-one);
          overflow: hidden;
        }

        div.popup > .item {
          display: block;
          padding: 8px 16px 8px 16px;
          cursor: pointer;
        }
        div.popup > .item:first-child {
          padding-top: 8px;
        }
        div.popup > .item:last-child {
          padding-bottom: 8px;
        }

        div.popup > .item:hover {
          background-color: var(--paper-grey-300);
        }
        @media screen and (max-width: 1500px) { 
          .infosheet_content { 
            width: 300px;
            transform: translateX(340px);
          }
          path.infosheet_leader {
            stroke: none;
          }
        }

        @media screen and (max-width: 1200px) { 
          .infosheet_content { 
            position: fixed;
            top: 64px;
            left: 0;
            transform: translateX(-520px);
            height: calc(100vh - 64px);
            width: 480px;
          }
        }

        timeline-fab[projection] {
          --timeline-fab-color: var(--paper-teal-600);
        }
  


      @keyframes spin-cw {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
      }
      @keyframes spin-ccw {
        from {
            transform:rotate(360deg);
        }
        to {
            transform:rotate(0deg);
        }
      }

      .calculation-indicator {
        opacity: 0;
        transition: var(--opacity-transition);
      }
      .calculation-indicator[calculating] {
        opacity: 0.5;
      }

      .calculation-indicator[calculating] > .calculation-gear {
          animation:spin-cw normal 1s infinite ease-in-out;
      }
      .calculation-indicator[calculating] > .calculation-gear[ccw] {
          animation:spin-ccw normal 1s infinite ease-in-out;
      }
`


class EditorContext {
  constructor(timeline, args) {
    this.extra_args = args;
    this.model = timeline.model;
  }
  getStateAt(date, phase = 'pre') {
    let result = this.model.dates.find(d => d.date && d.date <= date && (!d.next_date || d.next_date > date) && d.phase === phase);
    console.warn("GET STATE AT", date, '=', result)
    console.log(this.model.dates);
    return result ? result.state : null;
  }
}
const svg_shrink = 128;

class NGTimeline extends LitElement {
  constructor() {
    super();
    this.rect = { width: 0, height: 0, scale: window.devicePixelRatio };

    //this.min = 0;
    //this.max = 0;
    //this.range = 0;

    this.lists = {
      dates: [],
      normalized: [],
      squished: [],
      screen_y: [],
      point_map: new Map(),
      first_date: null,
      last_date: null,
      first_date_p: { date: null, sy: 0, n: 0, s: 0 },
      last_date_p: { date: null, sy: 0, n: 0, s: 0 },
      date_range: 1,
      squish_zones: []
    };

    this.squish_fac = 0.6;
    this.zoom_fac = 0;

    this.editor = null;

    this.spans = [];
    this.labels = [];
    this.editors = [];
    this.span_nlevels = 0;

    this.children_above = false;
    this.debug_dates = false;
    this.highlight_set = new Set();
    this.label_highlight_set = new Set();
  }


  updateListNormalized() {
    this.lists.normalized = this.lists.dates.map(d => this.normalizeDate(d));
  }

  updateListSquished() {
    this.lists.squished = this.lists.normalized.map(n => {
      /*let z = this.lists.squish_zones.find(z => z.n1 >= n && z.n2 <= n);
      let f = (n - z.n1)/z.dist;
      let squish = z.squish_end + (z.squish_end - z.squish_start)*f;
      let s = this.squishNormalCoord(n);
      console.log(`${n} => ${s} = ${s-n}   ${z.dist} [${z.n1}-${z.n2}]  [${z.squish_start}-${z.squish_end}] ${f} ${squish}`); 
      return s; */
      return this.squishNormalCoord(n);
    });
    //console.log(this.lists.squished.map((s,i) => `n:${this.lists.normalized[i]}\t\ts:${s}`).join("\n"));
  }

  updateListScreenYs() {
    this.lists.screen_y = this.lists.squished.map(s => this.screenYFromSquished(s));
  }

  normalizeDate(date) {
    if (date === null || !this.lists.date_range) { return 0; }
    return 1 - ((date - this.lists.first_date) / this.lists.date_range);
  }
  squishNormalCoord(n) {
    if (this.squish_fac === 0) return n;
    let z = this.lists.squish_zones.find(z => z.n1 >= n && z.n2 <= n);
    if (!z) return n;
    let f = (z.n1 - n) / z.ndist;
    let squish = (z.squish_start * (1 - f)) + (z.squish_end * f);

    /*
    if (n-squish !== n) {
      console.warn("squishing", `${z.squish_start}@${z.n1}  ${n}   ${z.squish_end}@${z.n2}   =>   f=${f}    squish=${squish}   out=${n-squish}`)
    }*/
    return n - squish;
  }
  screenYFromSquished(s) {
    if (s <= 0) { return 0; }
    return s * this.rect.height;
  }

  getDateFromScreenY(y) {
    let s = y / this.rect.height;
    if (s > 1) { return this.lists.first_date }
    else if (s < 0) { return this.lists.last_date }
    let z = this.lists.squish_zones.find(z => z.s2 <= s && z.s1 >= s);
    let d;
    if (z) {
      let rel = (s - z.s1) / (z.s2 - z.s1);
      d = new EventDate(z.d1 - 0 + rel * (z.d2 - z.d1))
    }
    //console.log(`y=${y} s=${s} rel=${rel} d=${d} z=`, z);
    return d;
  }
  pointIndexFromDate(date) {
    if (this.lists.point_map.has(date ? date.str : "null")) { return this.lists.point_map.get(date ? date.str : "null"); }
    let i = this.lists.dates.length;
    let n = this.normalizeDate(date);
    let s = this.squishNormalCoord(n);
    let sy = this.screenYFromSquished(s);
    this.lists.dates.push(date);
    this.lists.normalized.push(n);
    this.lists.squished.push(s);
    this.lists.screen_y.push(sy);
    this.lists.point_map.set(date ? date.str : "null", i);
    return i;
  }
  newPoint(date) {
    let i = this.pointIndexFromDate(date);
    return new TimelinePoint(date, i, this.lists);
  }
  async showEditorFor(typename, data, series_data, extra_args) {
    console.warn("showing editor for ", typename, data, series_data, extra_args);
    let context = new EditorContext(this, extra_args);

    //let timing = [];
    //timing.push({ label: 'start', time: performance.now() });
    let elem = series_data ? series_editors[typename] : editors[typename];
    let elem_instance = null;
    //timing.push({ label: 'elem', time: performance.now() });
    console.log("got element", elem);
    if (elem) {
      elem_instance = new elem();
      //timing.push({ label: 'instance', time: performance.now() });
      elem_instance.personid = this.model && this.model.person_id;
      elem_instance.data = data;
      if (series_data) {
        elem_instance.series_data = series_data;
      }
      if (this.mutation_redirect) {
        console.warn("adding redirect");
        elem_instance.mutation_redirect = this.mutation_redirect;
      }
      elem_instance.state = this.model;
      elem_instance.opened = true;
      elem_instance.addEventListener("close", e => {
         this.editor = null;
         elem_instance.opened = false;
         // wait a bit for any animations before totally removing the element from the DOM:
         setTimeout(() => {
          window.router.unregisterDialog(elem_instance);
         }, 5000);
      });
      //timing.push({ label: 'instance set', time: performance.now() });
      this.editor = { data: data, element: elem_instance, series: series_data, typename: typename, context: context };
      //timing.push({ label: 'editor', time: performance.now() });
      window.router.registerDialog(elem_instance);
      await this.updateComplete;
      console.log("editor is showing", this.editor)
      elem_instance.context = context;
      //timing.push({ label: 'render', time: performance.now() });
    } else {
      console.warn("NO EDITOR FOR", typename, series_data ? '[SERIES]' : '');
      this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `no editor for ${typename}${series_data ? `[${series_data.length}]` : ''}` } }));
      this.editor = null;
    }
    //let start = timing[0].time;
    //console.log("SHOW TIMING:\n\t", timing.slice(1).map(({ label, time }) => { let ret = `${label}: ${time - start}`; start = time; return ret; }).join('\n\t'));
  }


  static get properties() {
    return {
      debug_dates: { type: Boolean },
      debug_paths: { type: Boolean },
      debug_layout: { type: Boolean },
      children_above: { type: Boolean },
      calculating: { type: Boolean },
      rect: { type: Object },
      model: { type: Object },
      labels: { type: Array },
      spans: { type: Array },
      infosheet: { type: Object },
      editor: { type: Object },
      popup: { type: Object },
      squish_fac: { type: Number },
      zoom_fac: { type: Number },
      mutation_redirect: { type: Object }
    };
  }
  updated(props) {
    if (props.has("model") && this.model && this.model.state && this.model.nodes && this.model.nodes.length > 0) { //} && this.model && this.model) {
      //console.log("MODEL", this.model);
      //console.log("STATE", this.model.state);
      if (this.model && this.model.nodes) {
        //console.log("BUILD USES")
        //console.log("RAW NODES:", this.model.raw_nodes);
        // console.log("NODES:", this.model.nodes);
        // console.log("SERIES_NODES:", this.model.series_nodes);
        this.now = this.model.now;
        this.build(this.model); //.nodes, this.model.series_nodes, this.model.contexts);
      }
    }
    if (props.has("squish_fac")) {

      this.applySquishToZones(this.lists.squish_zones);
      this.updateListSquished();
      this.updateListScreenYs();
      this.layoutLabels();
      this.layoutSpans();

      this.requestUpdate("lists");
    }
  }

  getElemsForEvt(ids) {
    console.log("get elems for", ids);
    let elems = Array.from(this.renderRoot.querySelectorAll(Array.from(ids).map(id => `[event_id='${id}']`).join(', '))).filter(a => a.jump_y).sort((a, b) => b.jump_y - a.jump_y);
    console.log("got", [...elems])
    return elems[0];
  }

  async setZoom(fac) {
    let old = this.zoom_fac;
    let old_h = old * this.rect.height;
    this.zoom_fac = fac;
    await this.updateComplete;
    let new_h = fac * this.rect.height;
    let incr = (new_h - old_h) / 2;

    this.max_y = 0;
    this.layoutLabels();

    this.dispatchEvent(new CustomEvent("zoomscroll", { detail: { incr: incr } }));
  }

  async setSquish(fac) {
    this.squish_fac = fac;
    this.max_y = 0;
    this.layoutLabels();
  }

  async flash(ids) {
    let ms = 1000;
    this.highlight(ids);
    await wait(ms);
    this.unhighlight(ids);
    /*
    await wait(ms);
    this.highlight(ids);
    await wait(ms);
    this.unhighlight(ids);
    await wait(ms);
    this.highlight(ids);
    await wait(ms);
    this.unhighlight(ids);*/
  }
  warning_highlight(warning_uuid) {

  }

  label_highlight(label) {
    this.label_highlight_set.add(label);
    this.requestUpdate("label_highlight_set");
  }
  label_unhighlight() {
    this.label_highlight_set.clear();
    this.requestUpdate("label_highlight_set");
  }

  highlight(ids) {
    //console.log("high", ids);
    if (!ids || !ids.constructor || ids.constructor.name !== 'Set') { return }
    let old = this.highlight_set;
    try {
      this.highlight_set = new Set([...this.highlight_set, ...ids]);
    } catch (error) {
      console.error("whut?", error, ids);
    }
    this.requestUpdate("label_highlight_set", old);
  }
  unhighlight(ids) {
    //console.log("unhigh", ids);
    if (!ids) { return }
    let old = this.highlight_set;
    this.highlight_set = new Set([...this.highlight_set].filter(id => !ids.has(id)));
    this.requestUpdate("highlight_set", old);
  }
  jumpTo(ids) {
    if (!ids) { return }
    let elem = this.getElemsForEvt(ids);
    if (elem) elem.scrollIntoViewIfNeeded(false);
    //if (elem) elem.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    this.flash(ids);
  }
  getcoord_default(d) {
    let ret = 1 - ((d - this.min) / this.range)
    return ret;
  }
  invertcoord_default(y) {
    let frac = (this.rect.height - y) / this.rect.height;
    let ms = this.lists.date_range * frac + (this.lists.first_date - 0);
    let date = new Date(ms);
    return date;
  }

  getcoord_gaps(d) {
    let gap = this.gaps.find(g => d > g.d2);
    let offset = gap ? gap.cumulative : 0;
    let ingap = this.gaps.find(g => d <= g.d2 && d >= g.d1);
    let remapped = ingap ? ingap.d1 : d;
    let ret = 1 - ((remapped - offset - this.min) / this.range)
    return ret;
  }
  invertcoord_gaps(y) {
    let scaled = y / this.rect.height;
    let igap = this.gaps ? this.gaps.findIndex(g => scaled < g.y) : -1;

    let d1 = null;
    let d2 = null;
    if (igap === -1) {
      // somewhere between start and first gap in line
      d1 = this.first_date;
      d2 = this.gaps && this.gaps.length > 0 ? this.gaps[this.gaps.length - 1].d1 : this.last_date;
    } else if (igap === 0) {
      d1 = this.gaps[igap].d2;
      d2 = this.last_date;
    } else {
      d1 = this.gaps[igap].d2;
      d2 = this.gaps[igap - 1].d1;
    }

    let y1 = this.getcoord(d1);
    let y2 = this.getcoord(d2);
    let dist = y1 - y2;
    let offset = y1 - scaled;
    let frac = offset / dist;
    let date_frac = Math.round((d2 - d1) * frac);
    let date = new Date((d1 - 0) + date_frac);
    return date;
  }


  mouseToTimeline(e) {
    let bound = this.svg.getBoundingClientRect();
    let event_x = e.clientX - bound.left - this.svg.clientLeft;
    let event_y = e.clientY - bound.top - this.svg.clientTop;
    let date = this.getDateFromScreenY(event_y);
    return { x: event_x, y: event_y, date: date };
  }
  /*
  short_report(tl_state, nodes) {
    const { status_info,
      service_hours = 0,
      ytd_service_hours = 0,
      vesting_years = 0,
      ytd_vesting_years = 0,
      credited_years = 0,
      weighted_credited_years = 0,
      ytd_credited_years = 0,
      contribution_balance,
      contribution_interest,
      ytd_contribution_interest,
      participating,
      contributing,
      vested,
      early_retirement,
      full_early_retirement,
      full_retirement,
      deceased,
      tag_list,
      in_service,
      active_events } = tl_state;
    const retirement = active_events.retirement;
    const suspension = active_events.suspension;
    const employment = active_events.employment;
    let seen_nodes = new Set();
    nodes = nodes.filter(n => {
      let seen = seen_nodes.has(n.id);
      seen_nodes.add(n.id);
      return !seen;
    });
    return [
      nodes.length > 0 ? nodes.map(e => `[${e.brief_text}]`).join(', ') : null,
      status_info.short_messages.join(', ') + (in_service ? ' [in service]' : ' [not in service]'),
      `hours: ${(service_hours - ytd_service_hours).toLocaleString()} +${ytd_service_hours.toLocaleString()}`,
      `vest yrs: ${(vesting_years).toLocaleString()} +${ytd_vesting_years.toLocaleString()}`,
      `cred. yrs: ${(credited_years).toLocaleString()} +${ytd_credited_years.toLocaleString()}`,
      credited_years ? `mult: ${(weighted_credited_years).toLocaleString()}` : null,
      contributing ? `ctrb: ${(contribution_balance).toLocaleString([], { style: 'currency', currency: 'USD' })} +${ytd_contribution_interest.toLocaleString([], { style: 'currency', currency: 'USD' })}` : null,
      !contributing && participating ? `participant ${participating.nb_str}` : null,
      !vested && contributing ? `contributor ${contributing.nb_str}` : null,
      vested ? `vested ${vested.nb_str}` : null,
      !retirement && !full_early_retirement && early_retirement ? `early elig. ${early_retirement.nb_str}` : null,
      !retirement && !full_retirement && full_early_retirement ? `unreduced elig. ${full_early_retirement.nb_str}` : null,
      !retirement && full_retirement ? `full elig. ${full_retirement.nb_str}` : null,
      deceased ? `deceased ${deceased.nb_str}` : null,
      employment ? `${employment.employer.code} since ${employment.span_begins.nb_str}` : null,
      suspension ? `suspended since ${suspension.span_begins.nb_str}` : null,
      retirement ? `retired since ${retirement.span_begins.nb_str}` : null,
      tag_list.length > 0 ? tag_list.join(', ') : null
    ].filter(n => n);
  }*/

  nodeInfo(nodes) {
    return nodes && nodes.length > 0 ? nodes.map(e => `${e.brief_text}`) : [];
  }

  mouseover(evt, span) {
    //if (this.popup || (this.infosheet && this.infosheet.locked)) return;

    let { x: event_x, y: event_y, date } = this.mouseToTimeline(evt);
    let y = event_y;
    let y_scroll_amt = evt.clientY - y;

    //let label = this.labels ? this.labels.reduce((closest, next) => Math.abs(next.date-date) < Math.abs(closest.date - date) ? next : closest, this.labels[0]) : null;
    let highlighted = null;
    let snapped = null;
    let extra = null;
    let label = this.labels ? this.labels.reduce((closest, next) => Math.abs(next.marker_p.sy - y) < Math.abs(closest.marker_p.sy - y) ? next : closest, this.labels[0]) : null;

    //console.log(this.series_nodes);
    const snap_dist = 7;
    if (label && Math.abs(label.marker_p.sy - y) < snap_dist) {
      snapped = label.label;
      extra = label.extra ? label.extra : null;
      //console.log("snapped to marker", label);
      y = label.marker_p.sy;
      date = label.date;
      if (label.edit_data) {
        highlighted = label.edit_data;
      }
    } else {
      let spans = this.spans ? this.spans.reduce((acc, nxt) => [...acc, { type: 'start', date: nxt.start, point: nxt.start_p, span: nxt }, { type: 'end', date: nxt.end, point: nxt.end_p, span: nxt }], []) : [];
      let span_snap = this.spans ? spans.reduce((closest, next) => Math.abs(next.point.sy - y) < Math.abs(closest.point.sy - y) ? next : closest, spans[0]) : null;
      if (span_snap && Math.abs(span_snap.point.sy - y) < snap_dist) {
        snapped = `${span_snap.span.span_class} ${span_snap.type}`;
        //console.log("snapped to span", span_snap.type, span_snap.span);
        y = span_snap.point.sy;
        date = span_snap.date;
        highlighted = span_snap.span.edit_data;
      } else {
        let series_node = this.series_nodes ? this.series_nodes.reduce((closest, next) => Math.abs(next.point.sy - y) < Math.abs(closest.point.sy - y) ? next : closest, this.series_nodes[0]) : null;
        if (series_node && Math.abs(series_node.point.sy - y) < snap_dist / 2) {
          switch (series_node.label_class) {
            case "contribution":
              let contrib = series_node.edit_series_data.find(d => d.contribution_date === series_node.date.str);
              snapped = `contribution: $${contrib ? contrib.ee_pension_contribution : '???'}`;
              break;
            default:
              snapped = `${series_node.label_class}: ${series_node.label}`;
              break;
          }
          //console.log("snapped to series node", series_node);

          y = series_node.point.sy;
          date = series_node.date;
          highlighted = series_node.edit_data;
        }
      }
    }



    let spans = this.spans.filter(s => s.start <= date && (s.end === null || s.end >= date));
    let state;

    let nodes = [
      ...spans.filter(s => s.end && s.end.equals(date)).map(s => ({ ...s, brief_text: `end ${s.brief_text}` })),
      ...spans.filter(s => s.start.equals(date)).map(s => ({ ...s, brief_text: `begin ${s.brief_text}` })),
      ...this.series_nodes.filter(s => s.date.equals(date)),
      ...this.labels.filter(s => s.date.equals(date)),
    ];


    /*
  
    if (date >= this.lists.first_date && date <= this.lists.last_date) {
      state = this.model.dates.find(d => d.date >= date);
    }*/
    //console.log(this.model.dates);
    let dates = this.model.dates ? this.model.dates.filter(d => d.phase === 'post' || d.phase === 'annual' || d.phase === 'present' || d.phase === 'next') : null;
    if (date >= this.lists.last_date && dates && dates[dates.length - 1].date <= date) {
      state = dates[dates.length - 1];
    } else if (y > this.lists.first_date_p.sy) {
      state = null;
    } else if (dates) {
      state = dates.find(d => (d.date <= date || date.equals(d.date)) && d.next_date > date);
    }



    //console.log("date:", date, "state:", state);

    //if (y <= this.lists.first_date_p.sy && y >= this.lists.last_date_p.sy) {
    //let states = this.model.dates.filter(d => Math.abs(d.point.sy - y) < 10);
    //state = this.model.dates.filter(d => d.point.sy <= y).reduce((c, n) => (Math.abs(n.point.sy - y) < Math.abs(c.point.sy-y)) ? n : c, this.model.dates[0]);
    /*
    console.log("states for", y, states);
    if (states.length > 0) {
      states.sort((a,b) => a.point.sy - b.point.sy);
      state = states[0];
    }*/
    //}

    /*
    console.log('hover', date);
    console.log("hoverstate", state);
    if (state) {
      console.log(date, state.state);
    }
    console.log("hovernodies", nodes, snapped);
    */

    let node_map = new Map(nodes.map(n => [n.id + n.brief_text, n]));
    /*
    if (node_map.size < nodes.length) {
      console.warn("removed nodes", nodes, node_map);
    }*/

    if (this.popup || (this.infosheet && this.infosheet.locked)) {
      this.infosheet.alt_y = y;
      this.infosheet.alt_snapped = nodes && nodes.length > 0;
      this.requestUpdate('infosheet');
    } else {
      this.infosheet = {
        nodes: Array.from(node_map.values()),
        //nodeinfo: this.nodeInfo(nodes),
        report: ['nothing here'], //state && state.state ? this.short_report(state.state, nodes) : ['nothing here'],
        y: y,
        yoff: y_scroll_amt,
        snapped: nodes && nodes.length > 0,
        extra: extra,
        evt_x: event_x,
        evt_y: event_y,
        d: date,
        spans: spans,
        highlighted: highlighted,
        state: state
      };
    }
  }
  mouseleave(id) {
    if (this.popup || (this.infosheet && this.infosheet.locked)) return;
    this.infosheet = null;
  }
  getContext(date, id) {
    return [];
    /*
    console.log(this.model.state.contexts.map(c => [c.start, c.end, date]))
    console.log(this.model.state.contexts.map(c => [c.start, c.end, date]))
    return this.model.state.contexts.filter(c => c.start <= date && (!c.end || c.end >= date));
    */
    console.log(this.contexts);
    if (id) return this.contexts.filter(c => c.id === id);
    return this.contexts.filter(c => c.start <= date && (!c.end || c.end >= date));
  }
  timelineClick(e, id, date) {
    e.preventDefault();
    e.stopPropagation();
    if (e.button === 0 && this.infosheet) {
      this.popup = null;
      if (this.infosheet.locked) {
        if (this.infosheet.alt_y) {
          this.infosheet = null;
          this.mouseover(e);
        } else {
          this.infosheet = null;
        }
      } else {
        this.infosheet.locked = true;
      }
    }
    if (e.button === 2) {
      console.log("CONTEXT MENU", id, date, e);
      //let { x, y, date } = this.mouseToTimeline(e);
      let ctx = {
        ...this.mouseToTimeline(e),
        showEditorFor: (typename, data, series_data, context) => this.showEditorFor(typename, data, series_data, context),
      };
      if (date) { ctx.date = date }
      let items = this.getContext(ctx.date, id).map(c => ({ label: c.label, action: () => c.action(ctx) }));//.map(c => ({ label: c.label, action: () => console.log("CONTEXT", c) }))
      //console.log("tl click", x, y, date, e, contexts);
      //let items = [1, 2, 3].map(i => ({ label: `item ${i}`, action: () => console.log("item", i, 'clicked') })).concat(contexts);
      this.popup = { x: ctx.x, y: ctx.y, items: items }
      console.log("POPUP", this.popup);
    }
  }

  hideMenu(e) {
    this.popup = null;
  }

  extract_dates(nodes) {
    let dates = new Set();
    nodes.filter(n => !n.is_label || n.show_label).forEach(n => {
      [n.date, n.start, n.end].filter(d => d != null).forEach(d => { d = new EventDate(d); dates.add(d) });
    })
    return Array.from(dates.values()).sort((a, b) => a - b);
  }

  build_squish_zones(dates) {
    let zones = [];
    let zone_accum = 0;
    let dset = new Map(dates.map(d => [d.str, d]));
    let unique_dates = [...dset.values(), ...dset.values()];
    unique_dates.sort((a, b) => a - b);
    unique_dates = unique_dates.slice(1, unique_dates.length - 1);
    let ndist_sum = 0;
    zones = unique_dates.reduce((result, value, index, array) => {
      if (index % 2 === 0) {
        let [p1, p2] = array.slice(index, index + 2);
        //let time = p2-p1;
        let n1 = this.normalizeDate(p1);
        let n2 = this.normalizeDate(p2);
        let ndist = n1 - n2;
        //time_sum += time;
        ndist_sum += ndist;
        //result.push({d1: p1, d2: p2, time: time, n1: n1, n2: n2, dist: dist});
        result.push({ n1: n1, n2: n2, ndist: ndist, d1: p1, d2: p2 });
      }
      return result;
    }, []);
    let ndist_avg = ndist_sum / zones.length;
    this.lists.zone_ndist_avg = ndist_avg;
    this.applySquishToZones(zones);
    return zones;
  }

  applySquishToZones(zones) {
    let accumulated_squish = 0;
    zones.forEach(z => {

      // get variance of this zones size in normal space from the average
      let diff = this.lists.zone_ndist_avg - z.ndist;

      // that variance times the global squish fac is the amount squish space differs from norm space - the squish
      let squish = diff * (this.squish_fac);

      // record the accumulated squish over the zone
      z.squish_start = accumulated_squish;
      accumulated_squish += squish;
      z.squish_end = accumulated_squish;

      // apply the squish to the end points to get the end points in squish space
      z.s1 = z.n1 - z.squish_start;
      z.s2 = z.n2 - z.squish_end;
      //z.sdist = z.s1 - z.s2;
    });
  }

  // called only for new data from compute
  build({nodes, series_nodes, contexts, now, endpoint}) {
    if (!this.model) return;
    nodes.forEach(n => { if (n.event) n.id = n.event.id });
    this.master_series_node_ids = new Map(nodes.filter(n => n.is_series && n.event).map(n => [n.series_class, n.event.id]));
    series_nodes.forEach(n => { n.master_id = this.master_series_node_ids.get(n.series_class); if (n.event) n.id = n.event.id });

    let dates = this.extract_dates(nodes);
    let min = dates[0];
    let max = dates[dates.length - 1];

    // Reset state
    this.lists = {
      dates: [],
      normalized: [],
      squished: [],
      screen_y: [],
      point_map: new Map(),
      first_date: null,
      last_date: null,
      first_date_p: { date: null, sy: 0, n: 0, s: 0 },
      last_date_p: { date: null, sy: 0, n: 0, s: 0 },
      date_range: 1,
      squish_zones: [],
    };
    this.lists.first_date = min;
    this.lists.last_date = max;
    this.lists.date_range = max - min;
    this.lists.squish_zones = this.build_squish_zones(dates);
    this.lists.first_date_p = this.newPoint(min);
    this.lists.last_date_p = this.newPoint(max);

    this.labels = nodes.filter(n => n.is_label || n.is_series);
    this.spans = nodes.filter(n => n.is_span);

    this.editors = nodes.filter(n => n.edit_data !== undefined && n.edit_data !== null);
    this.span_editors = this.spans.filter(n => n.edit_data !== undefined && n.edit_data !== null);
    this.series_nodes = series_nodes;

    this.present = this.newPoint(new EventDate(now));
    this.end = this.newPoint(new EventDate(endpoint, 100));

    // Compute indent levels for editor spans
    let span_dates = [];
    this.spans.forEach(s => {
      s.start = s.start ? new EventDate(s.start) : null;
      s.end = s.end ? new EventDate(s.end) : null;
      span_dates.push({ type: 1, date: s.start, span: s });
      span_dates.push({ type: -1, date: s.end ? s.end : max, span: s });
    });
    span_dates.sort((a, b) => a.date - b.date);
    let level = 0;
    this.span_nlevels = 0;
    let parents = [];
    span_dates.forEach(s => {
      if (s.type === 1) { s.span.level = level; s.span.parent = parents.length > 0 ? parents[0].span : null }
      level += s.type;
      switch (s.type) {
        case -1:
          parents.shift();
          break;
        case 1:
          parents.unshift(s);
          break;
      }
      if (level > this.span_nlevels) { this.span_nlevels = level }
    });

    // sort indented spans above or below per setting
    if (this.children_above) {
      this.spans.sort((b, a) => b.level - a.level);
    } else {
      this.spans.sort((a, b) => b.level - a.level);
    }

    // attach points
    this.labels.forEach(l => { l.date = l.date ? new EventDate(l.date) : null; l.marker_p = this.newPoint(l.date); });

    this.spans.forEach(s => {
      s.start_p = this.newPoint(s.start);
      s.end_p = this.newPoint(s.end);
    });

    this.series_nodes.forEach(s => { s.date = s.date ? new EventDate(s.date) : null; s.point = this.newPoint(s.date) });

    // break up spans with subspans
    this.spans.forEach(s => {
      s.contained_spans = this.spans.filter(o => s.id != o.id && s.start_p.n >= o.start_p.n && s.end_p.n <= o.end_p.n);
      s.spans = [
        { p: s.start_p, t: 0 },
        ...s.contained_spans.map(o => ({ p: o.start_p, t: 0 })),
        ...s.contained_spans.map(o => ({ p: o.end_p, t: 3 })),
        { p: s.end_p, t: 3 }
      ];
      if (this.projection) console.log(s.start_p, this.present, s.end_p)
      if (this.projection && this.present && s.start_p.sy > this.present.sy && s.end_p.sy < this.present.sy) {
        s.spans = [
          ...s.spans, 
          { p: this.present, t: 0 },
          { p: this.end, t: 3 }
        ]
        if (debug) console.log("inserted present", s.spans);
      }
      s.spans.sort((a, b) => a.p.n - b.p.n);
      s.spans = s.spans.reduce((result, value, index, array) => {
        if (index % 2 === 0) {
          let [p1, p2] = array.slice(index, index + 2);
          result.push({ p1: p1, p2: p2 });
        }
        return result;
      }, []);
    });
    if (debug) console.log("SPANS", this.spans);

    if (contexts) this.buildContextItems(contexts);

    this.layoutLabels();
    this.layoutSpans();
    this.loaded = true;
  }


  buildContextItems(contexts) {
    //this.contexts = contexts.filter(c => context_builders.has(c.template)).map(c => context_builders.get(c.template)(c.args));
    //console.log("CTXS:", this.contexts);
  }
  // called when rect changes or normalized rebuilt
  layoutLabels() {
    if (this.labels.length < 1) return;
    const label_height = 64;
    this.labels.sort((a, b) => a.marker_p.s - b.marker_p.s);

    let dist = (p1, p2) => Math.abs(p1 - p2) - label_height;
    let shown = this.labels.filter(l => l.show_label);
    let end = shown.length - 1;


    // set up initial coords and data
    shown = shown.sort((a, b) => a.marker_p.sy !== b.marker_p.sy ? a.marker_p.sy - b.marker_p.sy : ((a.edit_data ? 1 : 0) - (b.edit_data ? 1 : 0)));
    shown.forEach((l, i) => {
      l.last_y = l.label_y ? l.label_y : l.marker_p.sy;// * this.rect.height;
      l.label_y = l.marker_p.sy;// * this.rect.height;
      l.column = 0;
      l.neighbors = [i > 0 ? shown[i - 1] : null, i < end ? shown[i + 1] : null];
      l.rand = l.rand ? l.rand : Math.random();
    });

    shown[0].label_y += (shown[0].rand) * label_height;
    let start = shown[0].label_y;
    shown[end].label_y -= (shown[end].rand) * label_height;
    let avg_dist = (shown[end].label_y - shown[0].label_y) / (shown.length - 1);
    //let middle = (shown[end].label_y + shown[0].label_y) / 2;
    avg_dist = avg_dist > label_height ? avg_dist : label_height;

    shown.forEach((l, i) => { if (i > 0) l.label_y = start + avg_dist * i });
    // attempt to adjust everything a little closer to marker points
    if (avg_dist > label_height) {
      let core = shown;
      core.forEach(c => c.orig_y = c.label_y);
      let adjusting = core.length > 0;
      let loops = 0;

      // loop until overlaps have been removed
      while (adjusting && loops < 100) {
        adjusting = false;
        loops += 1;
        core.forEach(l => {
          let [d1, d2] = l.neighbors.map(n => n ? dist(n.label_y, l.label_y) : 1000);
          l.d1 = d1 !== null ? d1 : 1000;
          l.d2 = d2 !== null ? d2 : 1000;
          l.offset = (l.marker_p.sy - l.label_y);
          l.potential = l.offset < 0 ? d1 : l.offset > 0 ? d2 : 0;
        });
        let l = core.reduce((cur, nxt) => cur.potential > nxt.potential ? cur : nxt, core[0]);

        let { d1, d2, offset } = l;
        let abs = Math.abs(offset);
        let fac = abs - label_height / 2
        if (abs > label_height / 2 || true) {
          let delta = 0;
          if (offset < 0) {
            delta = (abs < d1 ? offset : -d1);
          } else if (offset > 0) {
            delta = (abs < d2 ? offset : d2);
          }
          if (delta !== 0) {
            l.label_y += delta;
            adjusting = true;
          }
        }

      }
    }


    // Skip rendering of points that are too close (<5px) to predecessors or other points
    if (this.series_nodes) {
      let last_series_points = {}
      let last_mark = null;

      this.series_nodes.forEach(p => {
        let y = p.point.screen_y;
        let l = last_series_points[p.series_class];
        p.render_point = p.marker_p || last_mark === null || (last_mark && Math.abs(y - last_mark) > 5) || Math.abs(y - l) > 5;

        if (p.render_point) last_series_points[p.series_class] = y;
        if (p.render_point) last_mark = y;
      });
    }

  }

  layoutGraphs() {
    return;
    //FIXME: may or may not work anymore
    if (!this.graphs) { return }
    let w = this.rect.width;
    let h = this.rect.height;
    let level_w = w * 0.1;

    Object.keys(this.graphs).forEach(k => {
      this.graphs[k].coords.forEach(c => {
        c.screen_x = Math.round(level_w * c.norm_x * 10) / 10;
        c.screen_y = Math.round(h * c.norm_y * 10) / 10;
      });
      this.graphs[k].data = this.graphs[k].coords.length > 0 ? `M 0,${this.graphs[k].coords[this.graphs[k].coords.length - 1].screen_y} L0,${h} ${this.graphs[k].coords.map(c => `${c.screen_x},${c.screen_y}`).join(" ")}` : "M 0,0";
    });
  }

  layoutSpans() {
    if (this.spans.length < 1) return;
    const max_column_width = 200;
    let edit_column_width = ((this.rect.width - this.xpos_frac * this.rect.width) / this.span_nlevels);
    edit_column_width = edit_column_width > max_column_width ? max_column_width : edit_column_width;
    this.edit_column_width = edit_column_width;

    const x_offset = 8; // padding in editor container
    const y_offset = 16; // corner radii
    const scale_into_parent = ((cs, ps, py) => {
      let parent_range = Math.abs(ps[1] - ps[0]);
      let n1 = (cs[0] - ps[0]) / parent_range;
      let n2 = (cs[1] - ps[0]) / parent_range;
      let parent_y_range = Math.abs(py[0] - py[1]);
      return [py[0] + parent_y_range * n1, py[0] + parent_y_range * n2];
    });

    const scale_y_to_parent_range = ((cs, p) => {
      let psy1 = p.start_p.sy - y_offset;
      let psy2 = p.end_p.sy + y_offset;

      let parent_range = Math.abs(p.end_p.s - p.start_p.s);
      let n1 = (cs - p.start_p.s) / parent_range;
      let parent_y_range = Math.abs(psy2 - psy1);
      return psy1 + parent_y_range * n1;
    });

    const screen_coords = span => {
      //console.log("laying out", span);
      span.display_level = span.level;
      span.ydist = Math.abs(span.start_p.sy - span.end_p.sy); // y distance from start to end of visual span
      span.editor_h = span.ydist < 100 ? 100 : span.ydist; // minimum 100px 
      span.height_adjustability = (span.editor_h - 100) / 2; // half the excess over minimum
      span.editor_cy = (span.start_p.sy + span.end_p.sy) / 2; // center point of editor box
      span.editor_y_top = span.editor_cy - span.editor_h / 2;  // y coord of editor box
      span.editor_y_bottom = span.editor_cy + span.editor_h / 2;  // y coord of editor box
      span.editor_x1 = edit_column_width * span.display_level; // left x coord of editor container
      span.editor_w = edit_column_width;
      span.editor_x2 = span.editor_x1 + span.editor_w; // right x coord of editor box
      //console.log(`${span.id}:  d: ${span.ydist}  h: ${span.editor_h}  adj: ${span.height_adjustability}  cy: ${span.editor_cy}`)
    }

    const nudge = (span, amt) => {
      span.editor_y_top += amt;
      span.editor_y_bottom += amt;
      span.editor_cy += amt;
      return amt;
    }

    const nudge_height = (span, amt) => {
      amt = amt < -span.height_adjustability ? -span.height_adjustability : amt;
      span.editor_y_bottom += amt;
      span.editor_cy += amt;
      span.editor_h += amt;
      span.height_adjustability += amt;
      return amt;
    }

    this.span_editors.forEach(span => screen_coords(span));

    for (let i = 0; i < this.span_nlevels; i++) {
      let total_overlap = 0;
      let adjustments = [];
      let spans = this.span_editors.filter(s => s.level === i);


      let joints = [];
      for (let a = 0; a < spans.length - 1; a++) {
        let [bottom, top] = spans.slice(a, a + 2);
        let overlap = top.editor_y_bottom - bottom.editor_y_top;
        //console.log(`${i}: ${a}/${spans.length - 1}: bottom: ${bottom.editor_y_bottom}-${bottom.editor_y_top}, top: ${top.editor_y_bottom}-${top.editor_y_top}, overlap: ${overlap}`)
        if (bottom.height_adjustability > 0) { adjustments.push({ type: 'height', span: bottom, func: nudge_height, max: bottom.height_adjustability, dir: 1 }); }
        if (top.height_adjustability > 0) { adjustments.push({ type: 'height', span: top, func: nudge_height, max: top.height_adjustability, dir: -1 }); }
        if (a === 0) { adjustments.push({ type: 'y', span: bottom, func: nudge, max: 50, dir: 1 }); }
        if (a === spans.length - 2) { adjustments.push({ type: 'y', span: top, func: nudge, max: 50, dir: -1 }); }
        bottom.gap_above = -overlap;
        top.gap_below = -overlap;
        joints.push({ bottom: bottom, top: top, overlap: (overlap > 0 ? overlap : 0) });
      }
      total_overlap = joints.reduce((tot, cur) => tot += cur.overlap, 0)
      let total_adjustment = adjustments.reduce((tot, cur) => tot += cur.max, 0)
      let adj_frac = total_overlap / total_adjustment;
      if (adj_frac > 1) {
        console.warn("not enough slop");
      }
      if (adj_frac > 0) {
        adjustments.forEach(a => a.responsibility = a.max * adj_frac);
        let total_responsiblity = adjustments.reduce((tot, cur) => tot += cur.responsibility, 0)
        adjustments.forEach(a => {
          let delta = a.func(a.span, a.responsibility * a.dir);
        });
        joints.filter(j => j.overlap > 0).sort((a, b) => a.top.end_p.sy - b.top.end_p.sy).forEach(j => {
          let amt = j.top.editor_y_bottom - j.bottom.editor_y_top;
          j.bottom.editor_y_top += amt;
          j.bottom.editor_y_bottom += amt;
          j.bottom.editor_cy += amt;
        });
      }
    }

    this.span_editors.forEach(span => {
      let top_points = [];
      let bottom_points = [];
      //span.join_paths = [];
      let last_top = span.editor_y_bottom - y_offset;
      let last_bottom = span.editor_y_top + y_offset;
      let last_x = span.editor_x1 + x_offset;


      top_points.push([last_x, last_top]);
      bottom_points.push([last_x, last_bottom]);

      let p = span.parent;
      while (p) {
        if (span.start_p.s <= p.start_p.s) {
          last_top = scale_y_to_parent_range(span.start_p.s, p);
          top_points.push([p.editor_x2 - x_offset - 2, last_top]);
          top_points.push([p.editor_x1 + x_offset - 2, last_top]);
        }
        if (span.end_p.s >= p.end_p.s) {
          last_bottom = scale_y_to_parent_range(span.end_p.s, p);
          bottom_points.push([p.editor_x2 - x_offset - 2, last_bottom]);
          bottom_points.push([p.editor_x1 + x_offset - 2, last_bottom]);
        }
        p = p.parent;
      }

      last_top = span.start_p.sy;
      last_bottom = span.end_p.sy;
      //last_x = //this.xpos_frac * this.rect.width;

      top_points = top_points.map(([x, y]) => (xoff => [x, y]))
      bottom_points = bottom_points.map(([x, y]) => (xoff => [x, y]))
      //top_points.push([0, last_top]);
      // bottom_points.push([0, last_bottom]);
      top_points.push(xoff => [-xoff, last_top]);
      bottom_points.push(xoff => [-xoff, last_bottom]);

      top_points.reverse();
      span.path = (xpos, xoff) => {
        const points = [...top_points, ...bottom_points].map(p => p(xoff)).map(([x, y]) => [x + xpos, y]);
        return `M ${points[0].join(",")} L${points.slice(1).map(p => p.join(",")).join(" ")}`;
      }
      //console.log("join path", span.id, points, span.join_path);
      //span.join_paths.push(`M ${right_x},${right_ys[0]} L${right_x},${right_ys[1]} ${left_x},${left_ys[0]} ${left_x},${left_ys[1]}`);
      //console.log("joinpaths", span.join_paths);
    });
  }



  firstUpdated() {
    // this.canvas = this.renderRoot.getElementById('timeline-canvas');
    // console.log("first updated:", this.canvas);
    // this.ctx = this.canvas.getContext('2d');
    // this.drawCanvas(this.ctx);

    this.svg = this.renderRoot.getElementById('timeline-canvas');

    // window.addEventListener('resize', e => { console.log('resizzled', e, window.width, window.innerWidth) });

    if (typeof window.ResizeObserver !== 'undefined') {
      this.resizer = new ResizeObserver(entries => {
        for (let entry of entries) {
          //console.log("RESIZZLE OBS", entry, window.devicePixelRatio);
          //entry.target.style.borderRadius = Math.max(0, 250 - entry.contentRect.width) + 'px';
          if (this.rect.height != entry.contentRect.height || this.rect.width != entry.contentRect.width) {
            //console.log("actual resize observed", `w = ${this.rect.width}=>${entry.contentRect.width} w = ${this.rect.height}=>${entry.contentRect.height}`);
            this.rect = entry.contentRect;//{ ...(entry.contentRect), scale: window.devicePixelRatio };
            this.rect.scale = window.devicePixelRatio;
            // console.log("SET RECT", this.rect, entry.contentRect);

            //this.applySquishToZones(this.lists.squish_zones);
            //this.updateListSquished();
            this.updateListScreenYs();
            this.layoutLabels();
            if (this.show_graphs) {
              this.layoutGraphs();
            }
            this.layoutSpans();
          }
        }
      });
      this.resizer.observe(this.renderRoot.getElementById('size-box'));
    } else {
      console.warn("No ResizeObserver support");
      const box = this.renderRoot.getElementById('size-box');
      const resize = () => {
        this.rect = { width: box.scrollWidth, height: box.scrollHeight };
        this.updateListScreenYs();
        this.layoutLabels();
        if (this.show_graphs) {
          this.layoutGraphs();
        }
        this.layoutSpans();
      }
      resize();
      window.addEventListener('resize', resize);
    }
  }
  get xpos() {
    let min = this.rect.width - (200 * this.span_nlevels) - 100;
    let mid = this.rect.width / 2;
    return min < mid ? min : mid;
  }
  get xpos_frac() { return this.xpos / (this.rect.width > 0 ? this.rect.width : 1) }

  render() {
    const gap_size = 4;
    const timeline_stroke = 4;
    const gmfy = 8 / timeline_stroke;
    const gmfx = 8 / timeline_stroke;
    const xpos = this.xpos; //_frac * this.rect.width;
    const xpos_frac = this.xpos_frac;
    const xpos_pct = xpos;//`${Math.round(this.xpos_frac * 100)}%`;
    const label_base_x = xpos - 100 * this.rect.scale;
    const labels_x = label_base_x - 200 + 10 * this.rect.scale;
    const spans_spacing = 16;
    const spans_x = xpos + spans_spacing;
    //const timeline_event_block_width = 24 * this.rect.scale;
    const timeline_event_block_width = 80 * this.rect.scale;
    const timeline_event_block_x = xpos - (timeline_event_block_width - 64); //+ timeline_event_block_width;

    const labels = this.labels ? this.labels.filter(l => l.label_y !== undefined) : [];
    let max_label = labels.length > 0 ? labels[labels.length - 1].label_y : 0;
    const new_max_y = [
      ...(this.span_editors ? this.span_editors.map(e => e.editor_y_bottom) : []),
      ...(this.series_nodes ? this.series_nodes.map(n => n.point.sy) : [])
    ].reduce((prev, cur) => cur > prev ? cur : prev, max_label);
    this.max_y = this.max_y && this.max_y > new_max_y ? this.max_y : new_max_y;
    const max_y = this.max_y;
    //console.warn("SCALE", this.rect.scale);
        //<svg id="timeline-canvas" ?loaded=${this.loaded} viewBox="0 0 ${this.rect.width} ${this.rect.height}" style=" --timeline-pix-ratio: ${this.rect.scale}; position: absolute; top: 0; left: 0; margin-bottom: 64px; margin-top: 64px; overflow: visible;" @click=${e => this.hideMenu(e)}>

    let min_label = labels.length > 0 ? labels[0].label_y : 0;
    const min_y = [
      ...(this.span_editors ? this.span_editors.map(e => e.editor_y_top) : []),
      ...(this.series_nodes ? this.series_nodes.map(n => n.point.sy) : [])
    ].reduce((prev, cur) => cur < prev ? cur : prev, min_label);
    if (this.span_editors) this.span_editors.forEach(span => {
      span.error = (span.edit_data && span.edit_data.errors) || (span.event.span_tags && span.event.span_tags.find(t => t === 'ERROR'))
    });

    return html`
      <div id="container" style="position: relative; height: 100%; top: ${min_y < 0 ? -min_y : 0}px" ?inprogress=${!this.model || this.model.in_progress}>
        <div class="calculation-indicator" ?calculating=${this.calculating}  style="position: absolute; height: 0px; width: 0px; top: 20px; left: 8px; --mdc-icon-size: 40px;">
        <mwc-icon class="calculation-gear" style="--mdc-icon-size: 40px;">settings</mwc-icon>
        <mwc-icon class="calculation-gear" ccw style="--mdc-icon-size: 24px; opacity: 0.3; position: relative; left: 24px; top: -20px;">settings</mwc-icon>
        </div>
        <div id="size-box" style="height: calc(100vh - 200px + 300% * ${this.zoom_fac}); width: 100%;"></div>
        <div id="expander_box" style="height: ${max_y - this.rect.height + 200}px"></div>
        <svg id="timeline-canvas" ?loaded=${this.loaded} viewBox="0 0 ${this.rect.width} ${this.rect.height}" style=" --timeline-pix-ratio: ${1}; position: absolute; top: 0; left: 0; margin-bottom: 64px; margin-top: 64px; overflow: visible;" @click=${e => this.hideMenu(e)}>
            <defs>
            </defs>
             

            ${ this.show_graphs && this.graphs ? svg`
              <path d=${this.graphs.bal.data} class="graphs balance"></path>
              <path d=${this.graphs.syr.data} class="graphs service_years"></path>
              <path d=${this.graphs.cyr.data} class="graphs credit_years"></path>
              <path d=${this.graphs.hrs.data} class="graphs service_hours"></path>
            ` : svg``}

            <-- ### TL BASE -->
            <line
                    x1=${xpos_pct}
                    x2=${xpos_pct}
                    y1=${ - 200 + min_y}
                    y2=${ this.rect.height > max_y ? this.rect.height : max_y}
                    stroke-width=${timeline_stroke} class="base"
             />

            <-- ### PROJECTION PART -->
            <line
                    x1=${xpos_pct}
                    x2=${xpos_pct}
                    y1=${  - 200 + min_y }
                    y2=${ this?.present?.sy || 0 }
                    stroke-width=${timeline_stroke} class="projected_tl"
             />

 


          ${ // ####### TL SPANS
              // Render the colored-coded timeline segments for span (begin/end) events over the base (grey) timeline
              this.spans.map(span =>
                svg`${span.spans.map(subspan =>
                  svg`<line
                            ?error=${span.error} 
                            event_id=${span.id}
                            .jump_y=${(subspan.p1.p.sy > subspan.p2.p.sy ? subspan.p1.p.sy : subspan.p2.p.sy)}
                            ?tag_highlight=${this.highlight_set.has(span.id)}
                            x1=${xpos_pct}
                            x2=${xpos_pct}
                            y2=${ subspan.p2.p.sy}
                            y1=${ subspan.p1.p.date === null ? -100 : subspan.p1.p.sy}
                            stroke-width=${timeline_stroke} class=${`subspan ${span.span_class}`}  />`
                  )}`)
              // END TL SPANS
            }

          ${ // ###### EDITOR BLOCKS
              // Render the large editor blocks for span (begin/end) events to the right of the timeline
            this.spans.filter(s => s.edit_data).map(span =>
              svg`
                    <path ?error=${span.error} d=${span.path(spans_x, spans_spacing)} class=${`editor_parent_path ${span.span_class}`}></path> 
                    <foreignObject
                      x=${span.editor_x1 + spans_x}
                      y=${span.editor_y_top}
                      width=${span.editor_w}
                      height=${span.editor_h}
                    >
                      <div class="edit_container" style="position: fixed">
                        <div 
                          class=${`editor_chip ${span.span_class}`} 
                          event_id=${span.id}
                          ?calculating=${this.calculating} 
                          ?is_scenario=${span.scenario}
                          ?error=${span.error}
                          ?tag_highlight=${this.highlight_set.has(span.id) || (span.labels && span.labels.length > 0 && this.label_highlight_set.has(span.labels[0]))}
                          .jump_y=${span.editor_y_top}
                          @mouseover=${e => this.highlight(new Set([span.event.id]))}
                          @mouseleave=${e => this.unhighlight(new Set([span.event.id]))}
                          @click=${e => !this.calculating && span?.edit_data?.__typename && this.showEditorFor(span.edit_data.__typename, span.edit_data, null, null)}
                          @contextmenu=${e => this.timelineClick(e, span.id)}
                        >
                          <mwc-ripple></mwc-ripple>
                          <div class="chip_header">${span.labels.map(l => html`<span>${l}</span>`)}</div>
                          <div class="chip_detail">${span.start > this.now && !span.end ? `begins ${span.start.nb_str}` : html`${span.start.nb_str}&mdash;${span.end ? span.end.nb_str : 'ongoing'}`}</div>
      ￼
                          <div class="chip_tags">
                            <mwc-icon>${ (() => { const ico = get_editor_icon(span.edit_data.__typename, span.edit_data); return ico ? ico : 'refresh' })()}</mwc-icon>
                            ${span.tags ? span.tags.map(t => html`<span class="chip_tag">${t}</span>`) : ''}
                          </div>
                        </div>
                      </div>
                    </foreignObject>
                  ` 
                  // END EDITOR BLOCKS
                  )}
  ${
      //##### 
      // render the timeline points that have been marked as renderable
      // each point gets an inner and outer circle

      this.series_nodes ? this.series_nodes.filter(node => node.render_point).map(node =>
        svg`<circle event_id=${node.id}
                series=${node.series_class}
                ?series_highlight=${this.highlight_set.has(node.id) || this.highlight_set.has(node.master_id) || (node.labels && node.labels.length > 0 && this.label_highlight_set.has(node.labels[0]))} 
                cx=${xpos_pct}
                cy=${node.point.sy}
                .jump_y=${node.point.sy}
                r="3"
                class=${`series_node ${node.series_class}`}
                />
             <circle event_id=${node.id}
                series=${node.series_class}
                ?series_highlight=${this.highlight_set.has(node.id) || this.highlight_set.has(node.master_id) || (node.labels && node.labels.length > 0 && this.label_highlight_set.has(node.labels[0]))} 
                cx=${xpos_pct}
                cy=${node.point.sy}
                r="8"
                class=${`series_highlight_node ${node.series_class}`}
              />`
      ) : svg``
      }


      ${
      // ######
      // render original position of labels, for position code debugging
      this.debug_layout ?

        this.labels.filter(l => l.orig_y && l.show_label).map((lbl, i, lbls) => svg`
              <path d=${make_label_path(i, lbls.length, label_base_x + 10, lbl.orig_y, xpos, lbl.marker_p.sy)} class=${`label_line ${lbl.label_class}`} style="opacity: 0.2"/>
              <foreignObject x=${labels_x} y=${lbl.orig_y - 32} width="200" height="64" style="opacity: 0.2">
                <div class="label-container">
                  <div event_id=${lbl.id} .jump_y=${lbl.orig_y} class="label ${lbl.label_class}">
                    ${lbl.tag ? html`<span class="indicator ${lbl.tag}-label-indicator">${lbl.tag}</span>` : html``}
                    <span class= "lbl_body" >
                      ${lbl.labels.map(l => html`<span>${l}</span>`)}
                    </span >
                  </div >
                </div >
              </foreignObject >
          `) : ``
      }

      ${
          // #####  LABELS  #####
          // render the labels to the left of the timeline points
          // each label gets a leader line, a foreignobject block
          // for the contents, and a couple of circles if highlighted

        this.labels.map((lbl, i, lbls) =>
          svg`${
            // show leader control points: 
            lbl.show_label ? svg`
              ${this.debug_paths ? make_label_points(`label_line_point ${lbl.label_class}`, i, lbls.length, label_base_x + 10, lbl.label_y, xpos, lbl.marker_p.sy) : ``}
              <path ?cancelled=${lbl.cancelled} ?tag_highlight=${this.highlight_set.has(lbl.id)}  d=${make_label_path(i, lbls.length, label_base_x + 10, lbl.label_y, xpos, lbl.marker_p.sy)} class=${`label_line ${lbl.label_class}`} />` : svg``}
              <circle ?tag_highlight=${this.highlight_set.has(lbl.id) || (lbl.labels && lbl.labels.length > 0 && this.label_highlight_set.has(lbl.labels[0]))} .jump_y=${lbl.marker_y} event_id=${lbl.id} cx=${xpos_pct} cy=${lbl.marker_p.sy} r="3" class=${`label_marker ${lbl.label_class} ${lbl.shown ? 'shown' : ''}`} style="transform-origin: ${xpos}px ${lbl.marker_p.sy}px"/>
              <circle ?tag_highlight=${this.highlight_set.has(lbl.id) || (lbl.labels && lbl.labels.length > 0 && this.label_highlight_set.has(lbl.labels[0]))} .jump_y=${lbl.marker_y} event_id=${lbl.id} cx=${xpos_pct} cy=${lbl.marker_p.sy} r="8" class=${`label_highlight_marker ${lbl.label_class} ${lbl.shown ? 'shown' : ''}`} style="transform-origin: ${xpos}px ${lbl.marker_p.sy}px"/>
              ${lbl.show_label ? svg`
              <circle ?tag_highlight=${this.highlight_set.has(lbl.id) || (lbl.labels && lbl.labels.length > 0 && this.label_highlight_set.has(lbl.labels[0]))} event_id=${lbl.id} cx=${xpos_pct} cy=${lbl.marker_p.sy} r="8" class=${`label_outer_marker ${lbl.label_class}`} style="transform-origin: ${xpos}px ${lbl.marker_p.sy}px" />
              <foreignObject x=${labels_x} y=${lbl.label_y - 32} width="200" height="64">
                <div class="label-container">
                  <div
                      class="label ${lbl.label_class}"
                      ?calculating=${this.calculating}
                      ?tag_highlight=${this.highlight_set.has(lbl.id) || (lbl.labels && lbl.labels.length > 0 && this.label_highlight_set.has(lbl.labels[0]))}  
                      event_id=${lbl.id} .jump_y=${lbl.label_y}
                      ?highlighted=${lbl.edit_data && this.infosheet && this.infosheet.highlighted && this.infosheet.highlighted.id === lbl.edit_data.id}
                      ?editor=${lbl.edit_data}
                      ?cancelled=${lbl.cancelled}
                      @mouseover=${e => { this.highlight(new Set((lbl.event ? [lbl.event.id] : []))); if (lbl.labels && lbl.labels.length > 0) this.label_highlight(lbl.labels[0]) }}
                      @mouseleave=${e => { this.unhighlight(new Set((lbl.event ? [lbl.event.id] : []))); if (lbl.labels && lbl.labels.length > 0) this.label_unhighlight() }}
                      @contextmenu=${e => this.timelineClick(e, lbl.id, lbl.marker_p.date)}
                      @click=${e => {
                          if (!this.calculating && lbl.edit_data?.__typename) {
                            console.log("showing a label editor", lbl);
                            this.showEditorFor(lbl.edit_data.__typename, lbl.edit_data, lbl.edit_series_data, null);
                          }}}
                    >
                    ${lbl.tag ? html`<span class="indicator ${lbl.tag}-label-indicator">${lbl.tag}</span>` : html``}
                    <span class= "lbl_body" >
                      ${lbl.labels.map(l => html`<span>${l}</span>`)}
                    </span >
                  </div >
                </div >
              </foreignObject>` : nothing}
                `) 
              /// ### END LABELS
      }

       ${ // #### INDICATOR for hovered point on TL ####  FIXME: line 2343 might have to remove animate
         this.infosheet ?
            svg` 
            <path
              pathLength="1"
              ?animate=${true}
              d=${make_label_path(null, null, xpos_pct + 7, this.infosheet.y, this.rect.width - 400, (112) - this.infosheet.yoff)}
              class=${`infosheet_leader ${this.infosheet && this.infosheet.state && this.infosheet.state.state ? this.infosheet.state.state.status_info.person_type : ''}_person`}
              @animationend=${e => { console.log(e); e?.path?.[0]?.removeAttribute?.('animate'); }}
            /> 
          <circle
              cx=${xpos_pct}
              ?anim_done=${until(trueAfter(50), false)}
              class=${`infosheet ${this.infosheet.snapped ? 'snapped' : ''} ${this.infosheet && this.infosheet.state && this.infosheet.state.state ? this.infosheet.state.state.status_info.person_type : ''}_person`}
                cy=${this.infosheet.y}
            /> 
            
            ${ this.infosheet && this.infosheet.alt_y ? svg`
            <circle
              cx=${xpos_pct}
              ?anim_done=${until(trueAfter(50), false)}
              class=${`infosheet ${this.infosheet.alt_snapped ? 'snapped' : ''}`}
                cy=${this.infosheet.alt_y}
            />` : nothing}`: nothing
        // ### END INDICATOR 
        }
          <line
              @mouseover=${ e => this.mouseover(e)}
              @mousemove=${ e => this.mouseover(e)}
              @mouseleave=${ e => this.mouseleave()}
              @click=${e => this.timelineClick(e)}
              @contextmenu=${e => this.timelineClick(e)}
              x1=${ timeline_event_block_x}
              x2=${ timeline_event_block_x}
              y1="-50"
              y2=${ this.rect.height}
              stroke="purple"
              stroke-width=${ timeline_event_block_width}
              stroke-opacity="0"
              stroke-linecap="square"
              style = "stroke-linecap: square;"
            />
          ${ this.popup ? svg`
                <foreignObject x=${this.popup.x} y=${this.popup.y} width="300" height="300">
                  <div class="popup" style="background-color: white; color: black;">
                  ${this.popup.items.map(({ label, action }) => html`
                    <div class="item" @click=${e => { action(this.popup.context); this.popup = null; this.infosheet = null; }}><mwc-ripple></mwc-ripple>${label}</div>
                  `)}
                  </div>
                </foreignObject>
                ` : ``} 
        </svg >
      </div >
    <timeline-fab
        ?projection=${this.projection}
        label=${this.projection ? "refine scenario" : "new event"}
        @new-event=${ e => this.showEditorFor(e.detail.event_type, null, null, e.detail.extra_args)}
      >
    </timeline-fab >
    <div id="sliders" style="position: fixed; right: 24px; top: var(--app-bar-offset);">
      <vertical-slider title="Zoom" .top_icon=${"zoom_in"} .bottom_icon=${"zoom_out"}  .value=${this.zoom_fac} @value-changed=${e => this.setZoom(e.detail.value)}></vertical-slider>
      <vertical-slider title="Stretch" .top_icon=${"format_line_spacing"} .value=${this.squish_fac} @value-changed=${e => this.setSquish(e.detail.value)}></vertical-slider >

      ${
      html``

      /*
      
    <div style="position: fixed; right: 24px; bottom: 12px; opacity: 0.15">
      <div style="margin-top: 12px; color: grey;">
      paths
        <mwc-switch @checked-changed=${(e) => this.debug_paths = !this.debug_paths}></mwc-switch>
      </div>
      <div style="margin-top: 12px; color: grey;">
      layout
        <mwc-switch @checked-changed=${(e) => this.debug_layout = !this.debug_layout}></mwc-switch>
      </div>
      <div style="margin-top: 12px; color: grey;">
      dates
        <mwc-switch @checked-changed=${(e) => this.debug_dates = !this.debug_dates}></mwc-switch>
      </div>
      <div style="margin-top: 12px; color: grey;">
      sort
        <mwc-switch
    @checked-changed=${
    (e) => {
    this.children_above = !this.children_above
    if (this.children_above) {
      this.spans.sort((b, a) => b.level - a.level);
    } else {
      this.spans.sort((a, b) => b.level - a.level);
    }
    }
    }></mwc-switch>
    </div>
    </div>
  <div style="position: absolute; top: 100px; right: 100px;">${this.popup ? this.popup.y : 'nope'}::${this.pl_coord}</div>

      ${this.infosheet ? html`
      <div class="label infosheet">${this.infosheet.d ? this.infosheet.d.str : html``}</div>
      ${this.infosheet ? this.infosheet.report.map(rl => html`<div class="label infosheet_item">${rl}</div>`) : html``}
      ` : ``}
            ${this.infosheet && this.infosheet.nodeinfo ? this.infosheet.nodeinfo.map(n => html`<div>${n}</div>`) : ``}



    let nodes = [
      ...this.labels.filter(s => s.date.equals(date)),
      ...this.series_nodes.filter(s => s.date.equals(date)),
      ...spans.filter(s => s.start.equals(date)).map(s => ({ ...s, brief_text: `begin ${s.brief_text}` })),
      ...spans.filter(s => s.end && s.end.equals(date)).map(s => ({ ...s, brief_text: `end ${s.brief_text}` })),
    ];


        <div class='infosheet_date'>${this.infosheet && this.infosheet.d ? this.infosheet.d.str : html``}</div>

        <div class="infotitle"><h1>Date Detail</h1> 
        </div>

    */
      }

      </div >

  <div class="side_infosheet" ?open=${this.infosheet}>
      ${this.infosheet && this.infosheet.locked ? html`<div id="infoscrim" @click=${e => this.infosheet = null} style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; background-color: black; opacity: 0.5;"></div>` : ``}
      <div class=${`infosheet_content ${this.infosheet && this.infosheet.state && this.infosheet.state.state ? this.infosheet.state.state.status_info.person_type : ''}_person`}>

        ${this.infosheet && this.infosheet.locked ? html`<mwc-icon-button style="position: fixed; top: 0; right: 0;" title="Close" icon="cancel" @click=${e => this.infosheet = null} ></mwc-icon-button>` : ``} 

        ${this.infosheet && this.infosheet.d ? html`
          <div class="calendar">
            <div class="cal_month">${this.infosheet.d.toLocaleString('default', { month: 'long' }).toUpperCase()}</div>
            <div class="cal_date">${this.infosheet.d.getDate()}</div>
            <div class="cal_year">${this.infosheet.d.getFullYear()}</div>
          </div>
          <div class="cal_connector"></div>

        ` : html``}

        <div class="infosheet_lines">
          ${this.infosheet && this.infosheet.nodes ? this.infosheet.nodes.map(n => html`
          <div class="infosheet_line">
            <div class="infosheet_node ${n.edit_data ? 'editor' : ''} ${['span_class', 'series_class', 'label_class'].map(c => n[c]).find(c => c)}">
              ${n.brief_text}
            </div>
          </div>
          `) : ``}
        </div>

        ${this.infosheet && this.infosheet.state ? html`
          <div class="infosheet_personinfo_border">
          <div class="infosheet_personinfo">
              ${this.infosheet.state.state.report.map(({is_future, pri, sec}, i) => html`
                <div class=${i === 0 ? 'header' : ''}>${is_future? html`<mwc-icon>trending_up</mwc-icon>` : nothing}<b>${pri}</b> ${sec}</div>
              `)}
            <person-tags .tags=${this.infosheet && this.infosheet.state && this.infosheet.state.state.tag_list}></person-tags>
        </div>
        </div>

        `: html``}


      </div>
  </div>


`;
  }


}

NGTimeline.styles = style;

window.customElements.define('new-timeline', NGTimeline);
export { NGTimeline }
