import { LitElement, html, css } from 'lit';


const vertical_slider_style = css`
        :host {
         font-family: "Roboto", "Sans";
         --slider-color: var(--paper-cyan-800);
         --transform-transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }

        #container {
          cursor: pointer;
          position: relative;
        }
        #slider {
          background-color: var(--slider-color);
          width: 2px;
          height: 128px;
          position: relative;
          top: 18px;
        }
        #click_target {
          width: 50px;
          height: 128px;
          position: absolute;
          top: 46px;
          left: -24px;
        }

        #thumb_container {
          width: 18px;
          height: 18px;
          position: relative;
          right: 8px;
        }
        #thumb {
          background-color: var(--slider-color);
          width: 18px;
          height: 18px;
          border-radius: 50%;
          transform: scale(0.66);
          transition: var(--transform-transition);

        }

        #thumb[dragging] {
          transform: scale(1);
        }

        mwc-icon {
          color: var(--slider-color);
          position: relative;
          right: 33px;
        }

        #top {
          top: 36px;
        }
        #bottom {
          bottom: 12px;
        }
`;


class VerticalSlider extends LitElement {
  static styles = vertical_slider_style
  constructor() {
    super();
    this._value = 0;
    this.pixel_range = 128 - 18;
    this.dragging = false;
    this.icon = "";
  }
  static get properties() {
    return {
      dragging: { type: Boolean },
      icon: { type: String },
    };
  }
  set value(val) {
    if (val === this._value) return;
    this._value = val;
    this.requestUpdate("value");
  }
  get value() {
    return this._value;
  }


  mouseDrag(e) {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
    if (e.movementY == 0) return;
    //console.log("pointer move", e.movementY, e.movementY/this.pixel_range, this._value, this._value - (e.movementY/this.pixel_range));
    this._value = this._value - (e.movementY / this.pixel_range);
    this._value = this._value > 1 ? 1 : this._value < 0 ? 0 : this._value;
    this.dispatchEvent(new CustomEvent("value-changed", { bubbles: true, composed: true, detail: { value: this._value } }));
    this.requestUpdate("value");
  }

  beginDrag(e) {
    this.addEventListener("pointermove", this.mouseDrag);
    this.addEventListener("pointerup", this.endDrag);
    this.setPointerCapture(e.pointerId);
    this.dragging = true;
  }
  endDrag(e) {
    this.dragging = false;
    this.releasePointerCapture(e.pointerId);
    this.removeEventListener("pointermove", this.mouseDrag);
  }
  setPos(e) {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    let rect = this.renderRoot.getElementById('click_target').getBoundingClientRect();
    let event_y = e.clientY;
    //console.log(event_y, rect.y);
    let rel_y = event_y - rect.y - 9;
    this._value = 1 - ((rel_y > this.pixel_range ? this.pixel_range : rel_y) / this.pixel_range);
    this._value = this._value > 1 ? 1 : this._value < 0 ? 0 : this._value;
    console.log(rel_y, this._value, this.value * -this.pixel_range);
    this.dispatchEvent(new CustomEvent("value-changed", { bubbles: true, composed: true, detail: { value: this._value } }));
    this.requestUpdate("value");
  }
  render() {
    return html`
    <div id="container">
      ${this.top_icon !== "" ? html`<mwc-icon id="top">${this.top_icon}</mwc-icon>` : html``}
      <div id="slider" @click=${e => this.setPos(e)}></div>
      <div id="click_target" @click=${e => this.setPos(e)}>
      </div>
      <div id="thumb_container"
?dragging=${this.dragging}
@pointerdown=${e => this.beginDrag(e)}
@pointerup=${e => this.endDrag(e)}
style=${`transform: translateY(${this.value * -this.pixel_range}px);`}>
        <div id="thumb" ?dragging=${this.dragging}></div>
      </div>
      ${this.bottom_icon !== "" ? html`<mwc-icon id="bottom">${this.bottom_icon}</mwc-icon>` : html``}
    </div>

    `
  }
}

window.customElements.define('vertical-slider', VerticalSlider);
export { VerticalSlider }
