import { LitElement, html, css, svg, nothing } from 'lit';
import { KalePage } from '../shared-components/page.js';
import { repeat } from 'lit/directives/repeat.js';
import { Icon } from '@material/mwc-icon';
import { Button } from '@material/mwc-button';
import { Dialog } from '@material/mwc-dialog';
import { TextField } from '@material/mwc-textfield';
import { Checkbox } from '@material/mwc-checkbox';


import { ProgressCircle } from '../shared-components/progress-circle.js';

import { client, person_match_fragment, EditContributionInfo } from '../queries/queries.js';
import gql from 'graphql-tag';

import { EventDate } from '../benefits-app/eventdate.js';

import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import { ContributionDateCell, ContributionAmtCell, ContributionTextCell, ContributionSSNCell, ContributionBoolCell } from '../components/editors.js';
import '../components/edit-person.js';
import { debounce } from '../shared-components/utilities/debounce.js';
import * as Comlink from 'comlink';
import FuzzySet from 'fuzzyset.js';

const jsdate = (d) => {
  return d ? new EventDate(new Date(d.y, d.m - 1, d.d)) : null;
}

const xlsxdate = (data) => {
  return jsdate(XLSX.SSF.parse_date_code(data));
}


const standard_name = n => {
  let s = String(n ?? "");
  return s?.toLowerCase?.()?.replace?.(/[^\w]+/g, " ")?.split?.(" ")?.sort?.()?.join?.(" ")
}

const standard_ssn = ssn => {
  let s = String(ssn ?? "");
  return s?.replace?.(/[^\d]/g, '');
}

function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0)
        costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

const arcDraw = (radius, cx, cy, pct) => {
  pct = pct === undefined ? 0 : pct;
  pct = pct === 1 ? 0.999999 : pct;
  let phi = (Math.PI / 2) * 3 - pct * Math.PI * 2;
  return `M ${cx},${cy + radius} A ${radius} ${radius} 0 ${pct <= 0.5 ? 0 : 1},1 ${cx + radius * Math.cos(phi)} ${cy - radius * Math.sin(phi)}`;
}
const alpha_sort = (a, b) => {
  a = a && a.trim ? a.trim() : a;
  b = b && b.trim ? b.trim() : b;
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

const numeric_sort = (a, b) => a - b;

const sorts = {
  'match': numeric_sort,
  'type': alpha_sort,
  'selected': numeric_sort, // works for bools
  'section': alpha_sort,
  'status': alpha_sort,
  'name': alpha_sort,
  'ssn': alpha_sort,
  'dob': numeric_sort,
  'hire_date': numeric_sort,
  'term_date': numeric_sort,
  'eoy_base_salary': numeric_sort,
  'w2_wages': numeric_sort,
  'w2_exclusions': numeric_sort,
  'contrib_401k': numeric_sort,
  'ytd_base_pay': numeric_sort,
  'pension_participant': alpha_sort,
  'contrib_pension_employee': numeric_sort,
  'contrib_pension_employer': numeric_sort,
  'other_pension_plan': alpha_sort,
  'pension_salary': numeric_sort,
  'union': alpha_sort,
  'intloff': alpha_sort,
  'affoff': alpha_sort,
  'part_time_hours': alpha_sort,
  'notes': alpha_sort
}

const header = [
  { short: "name", long: "(1) NAME", type: 'text' },
  { short: "ssn", long: "(2) SSN", type: 'ssn' },
  { short: "dob", long: "(3) DOB ", type: 'date' },
  { short: "hire_date", long: "(4) HIRE DATE", type: 'date' },
  { short: "term_date", long: "(5) TERMINATION DATE", type: 'date' },
  { short: "eoy_base_salary", long: "(6) BASE SALARY", long_func: (data) => `(6) BASE SALARY as of ${data.date.nb_str}`, type: 'amt' },
  { short: "w2_wages", long: "(7) W-2 WAGES", type: 'amt', ignore: true },
  { short: "w2_exclusions", long: "(8) W-2 EXCLUSIONS", type: 'amt', ignore: true },
  { short: "w2_nonwages", long: "(9) W-2 NONWAGES", type: 'amt', ignore: true },
  { short: "contrib_401k", long: "(10) 401(K) CONTRIBUTIONS", type: 'amt', ignore: true },
  { short: "ytd_base_pay", long: "(11) YTD BASE PAY", type: 'amt', ignore: true },
  { short: "pension_participant", long: "(12) PENSION PARTICIPANT", type: 'bool', ignore: true },
  { short: "contrib_pension_employee", long: "(13) EE PENSION CONTRIBUTION", type: 'amt' },
  { short: "contrib_pension_employer", long: "(14) ER PENSION CONTRIBUTION", type: 'amt', ignore: true },
  { short: "other_pension_plan", long: "(15) OTHER PENSION PLAN", type: 'bool', ignore: true },
  { short: "pension_salary", long: "(16) PENSION SALARY", type: 'amt' },
  { short: "union", long: "(17) UNION", type: 'bool', ignore: true },
  { short: "intloff", long: "(18) INTLOFF", type: 'bool', ignore: true },
  { short: "affoff", long: "(19) AFFOFF", type: 'bool', ignore: true },
  { part_time: false, short: "notes", long: "NOTE", type: 'text', ignore: true },
  { part_time: true, short: "part_time_hours", long: "(20) PT HOURS", type: 'number' }
];

const section_names = { full_time: 'FT', part_time: 'PT', lost_time: 'OTHER' };
const status_names = { ignore: 'IGN', imported: 'IMP' };



const ssn_filter = /^\d{3}\-?\d{2}\-?\d{4}$/;

let material_decel = [0, 0, .2, 1];
let material_std = [.4, 0, .2, 1];
let material_accel = [.4, 0, 1, 1];
let material_sharp = [.4, 0, .6, 1];


const import_export_page_styles = css`
       
      .pink {
        --mdc-theme-on-primary: white;
        --mdc-theme-primary: var(--paper-pink-a200);
        --mdc-theme-on-secondary: white;
        --mdc-theme-secondary: var(--paper-pink-a200);

        }

      .column {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column; 
        width: 100%;
        padding: 24px;
        padding-top: 0;
        height: 100%;
      }
      .column > * {
        margin-top: 24px;
      }
      .load_message {
          padding-bottom: 18px;

        }

      .drag_placeholder {
          text-align: center;
          opacity: 0.4;
          box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; 
        width: 100%;
        height: 100%;

        }

        .scroller {
          overflow: overlay;
          height: calc(100vh - 64px);
          width: 100%;
        }

        .content-area {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;

          height: calc(100vh - 64px);
        }

        mwc-fab {
          /*
          position: fixed;
          right: 50px;
          bottom: 50px;
          */
        }
        #fab-holder {
          width: 100%;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: flex-end;
          flex-direction: row;
        }

        .primary {
          font-weight: 500;
          font-size: 20px;
          }
        .secondary {
          font-weight: 200;
          font-size: 16px;
          margin-top: 8px;
          }


        mwc-top-app-bar {
          --mdc-theme-primary: var(--paper-green-700);
          background-color: var(--paper-green-700);
        }

        .card {
          box-sizing: border-box;
          background-color: white;
          border: 1px solid var(--paper-grey-400);
          border-radius: 8px;
          transition: var(--shadow-transition);
          height: 100%;
          width: 100%;
          font-size: 12px;
          overflow: hidden;

          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column; 

        }
        .card > h2 {
          margin: 24px;
        }

        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }

        .card[height_computed] {
          height: fit-content;
        }

        .table-scroller[height_computed] {
          height: fit-content;
        }
              
        .top-app-bar-adjust {
          margin-top: 64px;
          }


        /* TABLE STYLES */

        .table-container { flex: 1 1; box-sizing: border-box; width: 100%; position: relative; /*FIXME: this isn't optimal... */ overflow-y: hidden; overflow-x: overlay}
        .table-scroller { overflow-y: hidden; height: 100%; width: 100%; min-width: fit-content; }
        table { box-sizing: border-box; border-collapse: collapse; width: 100%;}
        td,th { border: none; padding: 10px 28px; max-width: calc(100% / 14)}
        th {
          text-align: left;
          text-transform: uppercase;
          border: none;
          font-size: 90%;
          opacity: 0.5;
          max-width: calc(100vw / 20);
        }
        th[type="number"], th[type="amt"] {
          text-align: right;
        }
        th[type="match"] {
          text-align: center;
        }
        th > div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row; 
          position: relative;
        }
        
        th[type="number"] > div, th[type="amt"] > div {
          justify-content: flex-end;
        }
        th[type="number"] span, th[type="amt"] span {
          order: 99;
        }


        th mwc-icon {
          visibility: hidden;
          margin-right: 4px;
        }
        th mwc-icon[sort]{
          visibility: visible;
        }
        th:hover mwc-icon:not([sort]) {
          visibility: visible;
          opacity: 0.45;
        }
        th span {
        }

        th:hover {
          cursor: pointer;
        }

        th.control {
          opacity: 1;
        }



        /*
        tr { border: none}
        tr:nth-child(even) { background: #CCC;}
        */

        tr { border: none; border-bottom: 1px solid var(--paper-grey-400);}
        tbody > tr:hover {background-color: var(--paper-yellow-50); cursor: pointer;}
        tr[selected] {background-color: var(--paper-teal-50); cursor: pointer;}
        tr[imported] {opacity: 0.5}


        td.amtcell, td.datecell, td.textcell, td.numbercell > *
        {
          cursor: auto;
        }

        td.remove { padding: 10px 0px;}
        td.type { 
          text-transform: uppercase;
          font-size: 75%;
          font-weight: bold;
        }

        td.amtcell, td.numbercell {
          text-align: right;
        }
        td.boolcell { 
          text-align: center;
        }
        
        td[match="good"] {
          color: var(--paper-green-900);
        }
        td[match="poor"] {
          color: var(--paper-yellow-900);
          font-weight: 500;
        }
        td[match="bad"] {
          color: var(--paper-red-900);
          font-weight: 800;
        }

        td.match {
          font-weight: 900;
        }


        span.currency {
          float: left;
        }
        contrib-datecell[changed], contrib-amtcell[changed] {
        font-style: italic;
        color: var(--paper-green-600); 
        }

        .table_controls {
          box-sizing: border-box;

          font-size: 16px;
          padding: 18px 36px 0px 36px;
          width: 100%;
          height: 96px;

          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;

          --mdc-theme-primary: var(--paper-teal-700);
        }
        .table_controls[selection] {
          background-color: var(--paper-teal-100);
          color: var(--paper-teal-900);
        }

        .table_controls > * {
        }

        .table_controls > .info {
          margin-left: 64px;
        }
        div.info > span {
        }
        span.info-label {
          font-weight: 100;
          font-size: 90%;
        }
        span.info-data { 
          margin-left: 0.3em;
          font-weight: 900;
          color: var(--paper-purple-700);
          font-size: 130%;
        }

        .table_controls .buttons {
          flex: 1 1;

          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
        }
        .buttons > * {
          margin-right: 12px;
          margin-left: 12px;
        }

        div.gauge {
          position: relative;
          width: 48px;
          height: 48px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          font-size: 120%;
          font-weight: bold;
        }

        div.gauge > span {
          white-space: nowrap;
          text-transform: uppercase;
        }
       
        #navigation {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          padding: 12px;
        }

        #navigation-info {
          margin-right: 12px;
        }
        #navigation:last-child { margin-right : 12px }

        mwc-checkbox[disabled] {
          opacity: 0.5;
        }

 
        svg { overflow: visible; }
        circle { opacity: 0.25 }
        path { fill: none; stroke-width: 10px;}
        circle { fill: none; stroke-width: 5px; opacity: 0.3}
        svg[match="good"] { stroke: var(--paper-green-900)}
        svg[match="poor"] { stroke: var(--paper-yellow-900)}
        svg[match="bad"] { stroke: var(--paper-red-900)}
`;



class ImportExportPage extends KalePage {
  static styles = [super.styles, import_export_page_styles]
  static icon = "cloud_upload"
  static default_title = "Import Census Data"

  get form_name() { return "Import" }
  constructor() {
    super();
    this.title = "Import";
    this.rect = { width: 0, height: 0 };
    this.build_cache = debounce(this.build_cache_debounced.bind(this), 100);
    this.files = [];
    this.data = null;
    this.selected = new Set();
    this.select_all = false;
    this.filter = { ignored: true, imported: true, good_match: false, poor_match: false };
    this.sort = [];
    this.match_threshold = 0.7;
    this.threshold_good = 0.85;
    this.threshold_poor = 0.5;
    this._display_limit = null;
    this.render_count = 1;
    this.XLSXworker = Comlink.wrap(new Worker(new URL('/src/components/census-worker.js', import.meta.url), {type: 'module'}));
  }
  renderPage() {
    this.render_count += 1;

    let top_item = this.display_item + 1;
    let last_item = this.data && this.data.filtered ? this.data.filtered_length : 'unknown';
    let bottom_item = Math.min(this.display_item + this.display_limit, last_item);

    return html`
            <div class="column" ?dragging=${this.dragging} ?data=${this.data && !this.dragging} id="drop-target" @drop=${e => { e.preventDefault(); this.dragDrop(e) }} @dragenter=${e => this.dragEnter(e)} @dragover=${e => this.dragEnter(e)} @dragleave=${e => this.dragLeave(e)} >


            ${true && this.data && this.data.type === 'census' ? html`
              <div class="card">
                <div class="table_controls" ?selection=${this.selected.size > 0}>
                ${this.selected.size > 0 ? html`<div class="selection_info">${this.selected.size} item${this.selected.size === 1 ? '' : 's'} selected</div>` : html`<h2>Census Data</h2>`}
                ${this.data && this.data.employer && this.data.employer.consensus ? html`<div class="info"> ${this.data.employer.names.get(this.data.employer.consensus)}</div>` : html``}
                ${this.data && this.data.year ? html`<div class="info"><span class="info-label">YEAR</span><span class="info-data">${this.data.year}</span></div>` : html``}
                ${this.data && this.data.employer ? html`<div class="info"><span class="info-label">GROUP</span><span class="info-data">${this.data.employer.canonical ? this.data.employer.canonical : this.data.employer.consensus}</span></div>` : html``}
                  ${this.selected.size > 0 ?
          // <mwc-button>${this.data.filtered.find((r) => r.status === 'ignore' && this.selected.has(r.row_id)) ? 'unignore' : 'ignore'} selected</mwc-button>
          html`
                  <div class="buttons">
                    <mwc-button @click=${e => this.importSelected()}>import selected</mwc-button>
                </div>` : html``}
                </div>
                  ${this.data && this.data.data ? html`
                    <div class="table-container" id="tablecontainer">
                        <div class="table-scroller" >
                          <table>
                            <thead>
                              <tr id="file_header">
                                <th class="control" @click=${e => this.toggle_sort('selected')}>
                                
                                  <div>
                                    <mwc-checkbox @click=${e => { e.stopPropagation(); this.toggleAll() }} .checked=${this.select_all}></mwc-checkbox>
                                    <mwc-icon style="position:relative; bottom:4px; opacity: 0.5;" ?sort=${this.sort.find(s => s.col === 'selected')}>${this.sort.find(s => s.col === 'selected' && s.dir < 0) ? 'arrow_downward' : 'arrow_upward'}</mwc-icon>
                                  </div>
                                </th>
                                <th  type="match" @click=${e => this.toggle_sort('match')}>
                                  <div>
                                    <span>MATCH</span>
                                    <mwc-icon ?sort=${this.sort.find(s => s.col === 'match')}>${this.sort.find(s => s.col === 'match' && s.dir < 0) ? 'arrow_upward' : 'arrow_downward'}</mwc-icon>
                                  </div>
                                </th>
                                <th  type="text" @click=${e => this.toggle_sort('section')}>
                                  <div>
                                    <span>TYPE</span>
                                    <mwc-icon ?sort=${this.sort.find(s => s.col === 'section')}>${this.sort.find(s => s.col === 'section' && s.dir < 0) ? 'arrow_upward' : 'arrow_downward'}</mwc-icon>
                                  </div>
                                </th>
                                ${header.filter(h => !h.ignore).map(h => html`
                                <th type=${h.type} @click=${e => this.toggle_sort(h.short)}>
                                  <div>
                                    <span>${h.long_func ? h.long_func(this.data) : h.long}</span>
                                    <mwc-icon ?sort=${this.sort.find(s => s.col === h.short)}>${this.sort.find(s => s.col === h.short && s.dir < 0) ? 'arrow_upward' : 'arrow_downward'}</mwc-icon>
                                  </div>
                                </th>
                                `)}
                              </tr>
                            </thead>
                            <tbody id="file_data">
                            ${(this.data && this.data.filtered ? this.data.filtered : []).map((row) => html`
                            <tr ?imported=${row.status === 'imported' || (row.matching_contribs && row.matching_contribs.length > 0)} ?selected=${this.selected.has(row.row_id)} @click=${e => { this.toggleSelected(row.row_id, row) }}>
                                <td><mwc-checkbox ?disabled=${!this.rowSelectable(row)} @click=${e => { e.stopPropagation(); if (!this.rowSelectable(row)) { e.preventDefault() } else { this.toggleSelected(row.row_id, row) } }} .checked=${this.selected.has(row.row_id)}></mwc-checkbox></td>
                                <td class="match numbercell" match=${row.match > this.threshold_good ? "good" : row.match > this.threshold_poor ? "poor" : "bad"}>
                                <div class="gauge" >
                                <span>${
            row.matching_contribs && row.matching_contribs.length > 0 ? "IMPORTED" :
              //row.match_data && row.match_data.e && row.match_data.e.employer.code !== this.data.employer.consensus ? 'EMPLOYER MISMATCH' :
              row.match ? Math.round(row.match * 100) + '%' :
                row.match_failed ? 'no match' : ''

            }</span>
                                
                                <svg 
                                match=${row.match > this.threshold_good ? "good" : row.match > this.threshold_poor ? "poor" : "bad"}
                                style="position: absolute; top: 0px; left: 0px; opacity: 0.8"
                                width="48" height="48" viewBox="0 0 100 100"> 
                                  <path d=${arcDraw(50, 50, 50, row.match)} />
                                  <circle cx="50" cy="50" r="50"/>
                                </svg> 
                                </div>
                                </td>
                                <td class="type">${section_names[row.section]}${row.status ? ' | ' + status_names[row.status] : ''}</td>
                                ${header.filter(h => !h.ignore).map(header => {
              switch (header.type) {
                case "text":
                  return html`
                                      <td class="textcell"
                                      match=${row.match_fields && row.match_fields[header.short] && row.match_fields[header.short].match}
                                      title=${row.match_fields && row.match_fields[header.short] ? `"${row.match_fields[header.short].comparing[1]}": ${Math.round(row.match_fields[header.short].val * 100)}%` : 'no match'}
                                      >
                                      <contrib-textcell
                                      .value=${row[header.short]}
                                      .field=${header.short}
                                      .contrib_id=${null} ></contrib-datecell>
                                      </td>`;
                  break;
                case "date":
                  return html`
                                      <td class="datecell"
                                      match=${row.match_fields && row.match_fields[header.short] && row.match_fields[header.short].match}
                                      >
                                      <contrib-datecell
                                      .value=${row[header.short]}
                                      .field=${header.short}
                                      .contrib_id=${null} ></contrib-datecell>
                                      </td>`;
                  break;
                case "number":
                  return html`
                                      <td class="numbercell"
                                      match=${row.match_fields && row.match_fields[header.short] && row.match_fields[header.short].match}
                                      >
                                      <contrib-numbercell
                                      .value=${row[header.short]}
                                      .field=${header.short}
                                      .contrib_id=${null} ></contrib-datecell>
                                      </td>`;
                  break;
                case "amt":
                  return html`
                                      <td class="amtcell"
                                      match=${row.match_fields && row.match_fields[header.short] && row.match_fields[header.short].match}
                                      >
                                      <contrib-amtcell
                                      .value=${row[header.short]}
                                      .field=${header.short}
                                      .contrib_id=${null} ></contrib-datecell>
                                      </td>`;
                  break;
                case "bool":
                  return html`
                                      <td class="boolcell"
                                      match=${row.match_fields && row.match_fields[header.short] && row.match_fields[header.short].match}
                                      >
                                      <contrib-boolcell
                                      .value=${row[header.short]}
                                      .field=${header.short}
                                      .contrib_id=${null} ></contrib-datecell>
                                      </td>`;
                  break;
                case "ssn":
                  return html`
                                      <td class="ssncell"
                                      match=${row.match_fields && row.match_fields[header.short] && row.match_fields[header.short].match}
                                      >
                                      <contrib-ssncell
                                      .value=${row[header.short]}
                                      .field=${header.short}
                                      .contrib_id=${null} ></contrib-datecell>
                                      </td>`;
                  break;
                default:
                  return html`<td>unknow type: ${header.type}</td>`
                  break;
              }
            })}
                              </tr>
                            `)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div id="navigation">
                        <div id="navigation-info">${top_item}-${bottom_item} of ${last_item}</div>
                        <mwc-icon-button ?disabled=${this.display_item <= 0} icon="chevron_left" @click=${e => this.display_item = this.display_item - this.display_limit}></mwc-icon-button>
                        <mwc-icon-button ?disabled=${this.display_item >= (this.data.filtered_length - (this.data.filtered_length % this.display_limit))} icon="chevron_right" @click=${e => this.display_item = this.display_item + this.display_limit}></mwc-icon-button>
                      </div>
                  ` : html`` /*FULLTIME
                               <td class="remove"><mwc-icon-button @click=${e => this.deleteContrib(c)} icon="delete"></mwc-icon-button></td>
                  */} 

              </div>
            ` : html`

              <div class="drag_placeholder">
            ${(this.data && this.data.type === 'loading') ? html`
              <progress-circle style="--progress-color: white; --progress-bg: var(--paper-grey-700); --progress-size: 128;" .status=${'animate'} .icon_incomplete=${""} .icon_complete=${""}></progress-circle>
              ` : html`
            ${ this.data && (this.data.type === 'unknown' || this.data.type === 'error') ? html`
            <div class="load_message">${this.data.message}</div>
            ` : ``}
              <div>DROP FILES</div>`}
            </div>

            `} 


                  <div id="fab-holder">
              <mwc-fab class="pink" id="upload_button" icon="file_upload" extended label="Open File" @click=${e => { this.renderRoot.getElementById('file_chooser').click(); this.renderRoot.getElementById('upload_button').blur(); }}></mwc-fab>
              </div>


            </div>


    <input
        id="file_chooser"
        type="file"
        multiple="false"
        accept=".xlsx"
        @change=${e => { for (const f of e.target.files) { this.addFile(f) } }}
        hidden>
        `;
  }

  async fetchMatches() {

    this.data.data.filter((r) => this.visible(r)).forEach(async r => {
      r.match = 0;
      this.doSearch(r);
    });
  }


  doSearch(row) {
    let q = gql`
      query search_people($useremail: String!, $recs: Int!, $rec_offset: Int!, $search_string: String!, $bookmarked: String!,  $employer: String!, $include_tags: _text!, $exclude_tags: _text!) {
        results:people_search_all(
        args: {
            useremail: $useremail,
            recs: $recs,
            rec_offset: $rec_offset,
            search_string: $search_string,
            bookmarked: $bookmarked,
            employer: $employer,
            include_tags: $include_tags,
            exclude_tags: $exclude_tags
            }
          ){
          ...PersonMatchFields
        }
      }
      ${person_match_fragment}
    `;

    let args = {
      useremail: this.active_user,
      recs: 5,
      rec_offset: 0,
      search_string: `${row.name} ${row.ssn}`,
      bookmarked: "",
      employer: "",
      include_tags: `{}`,
      exclude_tags: `{}`
    };

    client.query({
      fetchPolicy: 'network-only',
      query: q,
      variables: args
    }
    ).then(data => {
      if (data.data && data.data.results && data.data.results.length > 0) this.newData(row, data.data.results)
    })
      .catch(error => console.error(error));
  }

  async newData(row, data) {
    this.loadMatchInfo(row, data);
    this.build_cache();
  }

  rateMatch(row, match) {
    // FIXME: get most relevant employment (e.g., closest to census date)
    let employment;
    //  console.log("rateMatch", row, match, this.data);
    if (match.employments && match.employments.length > 0) {
      match.employments.forEach(e => e.d = new EventDate(e.start_date));
      let emps = match.employments.filter(e => e.d <= this.data.date);
      emps.sort((a, b) => a.d - b.d);
      employment = emps[0];
    } else {
      employment = null; //{code: null, name: null};
    }
    match.e = employment;

    let m = {
      name: `${match.last_name},${match.first_name ? ' ' + match.first_name : ''}${match.middle_name ? ' ' + match.middle_name[0] : ''}`,
      ssn: match.ssn ? match.ssn : "",
      dob: match.birth_date ? new EventDate(match.birth_date) : "",
      hire_date: employment && employment.start_date ? new EventDate(employment.start_date) : "",
      term_date: employment && employment.end_date ? new EventDate(employment.end_date) : ""
    };
    m.name = standard_name(m.name);
    m.ssn = standard_ssn(m.ssn);
    let total = 0;
    let match_fields = {};
    const matched_fields = ['name', 'ssn', 'dob'];//, 'hire_date', 'term_date'];
    const import_values = {
      name: standard_name(row.name),
      ssn: standard_ssn(row.ssn),
      dob: String(row.dob ?? ""),
    }
    const fuzzys = {
      "name": FuzzySet([import_values.name]),
      "ssn": FuzzySet([import_values.ssn]),
      "dob": FuzzySet([import_values.dob]),
    }
    matched_fields.forEach(f => {
      const import_val = import_values[f];
      const compare_val = String(m[f]);
      let val = similarity(import_val, compare_val);
      let fuzzy = fuzzys[f].get(compare_val);
      let fuzzyval = fuzzy?.[0]?.[0];
      match_fields[f] = {
        val: fuzzyval ?? val,
        fuzzy,
        fuzzyval: fuzzy?.[0],
        comparing: [import_val, compare_val],
        match: val > this.threshold_good ? "good" : val > this.threshold_poor ? "poor" : "bad"
      };
      total += fuzzyval ?? val;
    });
    return { overall: total / matched_fields.length, fields: match_fields };
  }

  integrateEmployment(employment) {
    let code = employment.employer.code;
    let name = employment.employer.name;
    if (this.data.employer.all.has(code)) {
      let existing = this.data.employer.all.get(code);
      this.data.employer.all.set(code, existing + 1)
    } else {
      this.data.employer.all.set(code, 1);
    }
    this.data.employer.names.set(code, name);
    if (!this.data.employer.canonical && !this.data.employer.consensus) {
      let threshold = (this.data.filtered.length - (this.data.employer.all.has('UNK') ? this.data.employer.all.get('UNK') : 0)) / 2;
      if (Array.from(this.data.employer.all.entries()).some(e => e[1] >= threshold)) {
        let old_consensus = this.data.employer.consensus;
        this.data.employer.consensus = Array.from(this.data.employer.all.entries()).reduce((max, next) => next[1] > max[1] ? next : max, [null, 0])[0];
        if (this.data.employer.consensus !== old_consensus) {
          this.changeConsensus();
        }

      }
    }
  }

  changeConsensus() {
    this.data.filtered.forEach(d => {
        //console.log('changing', d, d.match_data && d.match_data.contributions ? [...d.match_data.contributions] : 'no data' );
        //console.log(`IN <=== (${d.consensus_emp}) e: ${d.contrib ? d.contrib.employer_code : null}, d: ${d.match_data ? d.match_data.contributions: null}, m: ${d.matching_contribs ? d.matching_contribs.length : null}`)
        d.consensus_emp = this.data.employer.consensus;
        if (d.contrib) {
          d.contrib.employer_code = this.data.employer.consensus;
        } else {
          this.buildRowContrib(d);
        }
        if (d.match_data && d.match_data.contributions) {
          d.matching_contribs = this.hasContribution(this.data.date, d, d.match_data.contributions);
        }
        /*if (d.matching_contribs) {
          d.matching_contribs = d.matching_contribs.filter(m => m.employer_code === this.data.employer.consensus);
        }*/
        //console.log(`OUT ===> (${d.consensus_emp}) e: ${d.contrib ? d.contrib.employer_code : null}, d: ${d.match_data ? d.match_data.contributions: null}, m: ${d.matching_contribs ? d.matching_contribs.length : null}`)
    });
  }

  hasContribution(date, row, contribs) {
    const comp = (l, r) => {
      l = l === undefined || l === null ? 0 : Number(l);
      r = r === undefined || r === null ? 0 : Number(r);
      return Math.round(l*100) === Math.round(r*100);
    }
    let match_fields = [
      "eoy_base_salary",
      "ee_pension_contribution",
      "pension_salary",
    ]
    //let fuzziest = contribs.filter(c => c.contribution_date.getFullYear() === row.contrib.contribution_date.getFullYear());
    //let fuzzy = contribs.filter(c => new EventDate(c.contribution_date).equals(row.contrib.contribution_date));
    let matches = contribs.filter(c =>
      new EventDate(c.contribution_date).equals(row.contrib.contribution_date)
        && match_fields.every(f => comp(c[f], row.contrib[f]))
        && (!this.data.employer.consensus || !c.employer_code || c.employer_code === row.contrib.employer_code)
      );
    return matches;
  }

  buildRowContrib(row) {
    row.contrib = {
      contribution_date: this.data.date,
      eoy_base_salary: row.eoy_base_salary,
      ytd_base_pay: row.ytd_base_pay,
      ee_pension_contribution: row.contrib_pension_employee,
      er_pension_contribution: row.contrib_pension_employer,
      pension_salary: row.pension_salary,
      made_up: null,
      employer_code: this.data.employer.consensus ? this.data.employer.consensus : null
    }
  }

  buildRowMatch(row, match) {
    row.match_data = match.data;
    row.matching_contribs = this.hasContribution(this.data.date, row, match.data.contributions);
    row.match = match.match.overall;
    row.match_fields = match.match.fields;
    row.contrib.person_id = match.data.id;

    //console.log(`inserted match: ${row.name}`, 'matching:', row.matching_contribs, match, match && match.data && match.data.contributions ? match.data.contributions : null);
    //console.log(row);
  }

  loadMatchInfo(row, data) {
    console.log("LOADMATCH", row, data);
    /*
    let rated_matches = data.map(d => ({ data: d, match: this.rateMatch(row, d) })).filter(m => m.match.overall > this.match_threshold);
    rated_matches.sort((a, b) => b.match.overall - a.match.overall);
    let top_match = rated_matches[0];
    */
    for (let field in row) {
      if (row[field] && row[field].constructor.name === 'Date') row[field] = new EventDate(row[field]);
    }
    this.buildRowContrib(row);

    let top_match = null;
    for (let m of data) {
      let rate = this.rateMatch(row, m);
      console.log("RATE", rate, m);
      if (rate.overall > this.match_threshold) {
        top_match = { data: m, match: rate };
        break;
      }
    }
    if (top_match && top_match.data.e) this.integrateEmployment(top_match.data.e);
    if (top_match) {
      this.buildRowMatch(row, top_match);
    } else {
      row.match_failed = true;
    }
  }

  get filter() {
    return this._filter;
  }
  set filter(filter) {
    this._filter = filter;
    this.build_cache();
  }

  get display_limit() {
    return this._display_limit;
  }

  set display_limit(limit) {
    if (limit != this._display_limit) {
      this._display_limit = limit;
      this.build_cache();
    }
  }

  get display_item() {
    return this._display_item;
  }

  set display_item(item) {
    let max_item = this.data.filtered_length - (this.data.filtered_length % this.display_limit);
    let change_item = item < 0 ? 0 : (item > max_item ? max_item : item);
    if (change_item != this._display_item) {
      this._display_item = change_item;
      this.build_cache();
    }
  }

  get sort() {
    return this._sort;
  }

  set sort(sort) {
    this._sort = sort;
    this.build_cache();
  }

  toggle_sort(column) {
    let previous = this.sort.find(s => s.col === column);
    let prev_dir = previous ? previous.dir : 0;
    let next_dir = ((prev_dir + 2) % 3) - 1;
    this.sort = [{ col: column, dir: next_dir }, ...this.sort.filter(s => s.col !== column)].filter(s => s.dir !== 0);
  }
  async build_cache_debounced() {
    if (this.data) {
      this.data.filtered = this.data.data.filter((r) => this.visible(r));
      this.data.filtered_length = this.data.filtered.length;
      this.data.filtered.sort((a, b) => {
        for (let sort of this.sort) {
          let res;
          if (sort.col === 'selected') {
            res = sort.dir > 0 ? sorts[sort.col](this.selected.has(b.row_id), this.selected.has(a.row_id)) : sorts[sort.col](this.selected.has(a.row_id), this.selected.has(b.row_id));
          } else {
            res = sort.dir > 0 ? sorts[sort.col](a[sort.col], b[sort.col]) : sorts[sort.col](b[sort.col], a[sort.col]);
          }
          if (res !== 0) { return res };
        }
        return 0;
      });
      this.data.filtered = this.data.filtered.slice(this.display_item, this.display_item + this.display_limit);
    } else {
    }
    this.requestUpdate("data");
    await this.updateComplete;
    this.computeDisplayLimit();
  }

  visible(row) {
    return !row.ignore && row.status !== 'ignore';
  }
  rowSelectable(row) {
    return row.matching_contribs && row.matching_contribs.length === 0 && row.match_data; //&& row.match_data.e && row.match_data.e.employer.code === this.data.employer.consensus;
  }



  toggleSelected(id, row) {
    console.log("toggling", id, row, this.rowSelectable(row));
    if (!this.rowSelectable(row)) {
      return;
    }
    if (this.selected.has(id)) {
      this.selected.delete(id);
    } else {
      this.selected.add(id);
    }
    this.requestUpdate('selected');
  }
  toggleAll() {
    this.select_all = !this.select_all;
    if (this.select_all) {
      this.selected = new Set([...this.selected.keys(), ...this.data.data.filter((r) => this.visible(r) && this.rowSelectable(r)).map((r) => r.row_id)]);
    } else {
      this.selected = new Set();
    }
    this.requestUpdate('selected');
  }

  importSelected() {
    this.data.data.filter((r) => this.selected.has(r.row_id)).forEach(import_row => {
      console.log("importing", import_row);
      let import_data = import_row.contrib;
      import_data.employer_code = this.data.employer.consensus;


      /*
      {
        person_id: import_row.match_data.id,
          contribution_date: this.data.date,
            salary: import_row.eoy_base_salary,
              amount: import_row.contrib_pension_employee,
                interest: 0,
                  balance: 0
      };*/
      console.log("trying to import", import_data);
      let importer = new EditContributionInfo(
        p => console.log("saved data", p),  // data update function
        { changeMap: null },  //initial variables
        p => { // finalizing function
          console.log("finalized data", p);
          import_row.matching_contribs = [p];
          this.selected.delete(import_row.row_id);
          this.requestUpdate("data");
        },
        (e, msgs) => { // error handler
          this.error = { data: this.data_property, error: e, msgs: msgs };
          console.error(this.error);
          this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
        });
      importer.save(import_data, import_data);
    });
  }

  firstUpdated() {
    super.firstUpdated();


    this.resizer = new ResizeObserver(async entries => {
      for (let entry of entries) {
        if (this.rect.height != entry.contentRect.height || this.rect.width != entry.contentRect.width) {
          this.rect = entry.contentRect;
          this.computeDisplayLimit();
        }
      }
    });
    //this.resizer.observe(this.renderRoot.getElementById('tablecontainer'));

    /*
      let options = {
        root: document.querySelector('.table-scroller'),
        rootMargin: '0px',
        threshold: 1
      }
   
      this.observer = new IntersectionObserver((entries,obs) => {
        entries.forEach(entry => {
          //console.log("intersection observed:", entry.isIntersecting, entry.intersectionRatio);
          this.__result_trigger_exposed = entry.isIntersecting;
          if (!entry.isIntersecting) {
            console.log('intersectionObserver');
            
          }
        })
      }, options);
   
      this.trigger = this.renderRoot.getElementById('watcher');
   
      this.observer.observe(this.trigger)
  */
  }


  activateRoute() {

  }


  dragEnter(e) {
    e.preventDefault();
    this.dragging = true;
  }
  dragLeave(e) {
    e.preventDefault();
    this.dragging = false;
  }

  dragDrop(e) {
    e.preventDefault();
    this.dragging = false;

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          var file = e.dataTransfer.items[i].getAsFile();

          window.requestIdleCallback(() => this.addFile(file));

        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        window.requestIdleCallback(() => this.addFile(e.dataTransfer.files[i]));
      }
    }
    // Pass event to removeDragData for cleanup
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      e.dataTransfer.items.clear();
    } else {
      // Use DataTransfer interface to remove the drag data
      e.dataTransfer.clearData();
    }
  }


  async handleCensusData({ year, employer_code, ft_data, pt_data, lt_data }) {
    //console.log("raw ft\n", ft_data.map(({ name, dob, hire_date, term_date }) => JSON.stringify({ name, dob, hire_date, term_date })));

    let date = new EventDate(new Date(parseInt(year), 11, 31));
    this.data = { type: 'census', year: year, date: date, employer: { consensus: employer_code, canonical: employer_code, all: new Map(), names: new Map() }, data: [...ft_data, ...pt_data, ...lt_data] };
    console.log("CENSUS BACK FROM WORKR");
    console.log({ ...this.data })
    this.filter = { ignored: true, imported: true, good_match: false, poor_match: false };
    this.display_limit = 8;///this.data.data.length;
    this.display_item = 0;
    this.fetchMatches();
    await this.updateComplete;
    this.resizer.disconnect();
    this.resizer.observe(this.renderRoot.getElementById('tablecontainer'));
  }


  async bgOpenXLSX(f) {
    this.data = { type: 'loading' };
    await this.updateComplete;

    const data_handlers = {
      'census': data => this.handleCensusData(data),
      'error': error => this.data = { 'type': 'error', error, message: `${error}. Try again.` },
      'unknown': message => this.data = { 'type': 'unknown', message: `Unable to recognize sheet layout (${message}). Check file and try again.` }
    }
    new this.XLSXworker(f,
      Comlink.proxy(async (type, data) => {
        if (data_handlers[type]) {
          data_handlers[type](data);
        } else {
          this.data = { type: 'unknown', message: "Unable to load file. Try Again." };
        }
      }));
  }

  addFile(f) {
    this.data = { type: 'loading' };
    //  this.files.push(f);
    this.files = [f];
    this.files.forEach(f => {
      switch (f.type) {
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          this.bgOpenXLSX(f);
          break;
        case "text/tab-separated-values":
        case "text/csv":
          this.data = { 'type': 'error', message: `Only .xlsx files are currently recognized` };
          //this.openCSV(f);
          break;
        default:
          console.error("invalid file type:", f.type);
          this.data = { 'type': 'error', message: `Found [${f.type}] file, but only .xlsx files are currently recognized.` };
      }
    });
  }
  idleLoadProcess(deadline, processed, remaining, report) {
    let time = deadline.timeRemaining();
    let start = performance.now();
    let chunk = 50;//Math.round(time / (report.time_per_row ? report.time_per_row : 4));
    /*
    while ((deadline.timeRemaining() || deadline.didTimeout) && remaining.length > 0) {
      processed = [...processed, ...remaining.slice(0, chunk).map(r => report.row_func(r))];
      remaining = remaining.slice(chunk);
      //processed.push(report.row_func(remaining.shift()));
    }*/
  }

  // DEPRECATED
  /*
  async loadCensus(ws, year, ft_row, pt_row, lt_row) {
    console.log("loading a census report with sections on", ft_row, pt_row, lt_row);
    let start = performance.now();
    let { c: last_col, r: last_row } = XLSX.utils.decode_range(ws["!ref"]).e;
    last_row = Number(Array.from(Object.keys(ws)).filter(k => k[0] === 'A').reverse()[0].replace(/[^\d]/, ''))
    let ft_data = ft_row && pt_row ? XLSX.utils.sheet_to_json(ws, { header: header.filter(f => !f.part_time).map(f => f.short), range: XLSX.utils.encode_range({ c: 0, r: ft_row }, { c: last_col, r: pt_row - 1 }) }) : null;
    let pt_data = pt_row && pt_row ? XLSX.utils.sheet_to_json(ws, { header: header.filter(f => f.part_time !== false).map(f => f.short), range: XLSX.utils.encode_range({ c: 0, r: pt_row }, { c: last_col, r: lt_row - 1 }) }) : null;
    let lt_data = lt_row && pt_row ? XLSX.utils.sheet_to_json(ws, { header: header.filter(f => !f.part_time).map(f => f.short), range: XLSX.utils.encode_range({ c: 0, r: lt_row }, { c: last_col, r: last_row }) }) : null;
    const ignore = (r) => !(r.name && ssn_filter.test(r.ssn)) || !(r.pension_participant === 'Y' || r.pension_participant === 'NE') || (r.contrib_pension_employee == 0 && !(r.part_time_hours > 0));
    console.log("decoded", performance.now() - start)
    ft_data.forEach((r, i) => {
      r.ssn = r.ssn ? String(r.ssn).replace(/[^0-9]/g, '') : r.ssn;
      r.section = 'full_time';
      r.status = ignore(r) ? 'ignore' : null;
      r.row_id = `${r.section}[${i}]::${r.name}::${r.ssn}`;
      r.dob = xlsxdate(r.dob);
      r.hire_date = xlsxdate(r.hire_date);
      r.term_date = xlsxdate(r.term_date);
    });
    console.log("ft", performance.now() - start)
    pt_data.forEach((r, i) => {
      r.section = 'part_time';
      r.status = ignore(r) ? 'ignore' : null;
      r.row_id = `${r.section}[${i}]::${r.name}::${r.ssn}`;
      r.dob = xlsxdate(r.dob);
      r.hire_date = xlsxdate(r.hire_date);
      r.term_date = xlsxdate(r.term_date);
    });
    console.log("pt", performance.now() - start)
    lt_data.forEach((r, i) => {
      r.section = 'lost_time';
      r.status = ignore(r) ? 'ignore' : null;
      r.row_id = `${r.section}[${i}]::${r.name}::${r.ssn}`;
      r.dob = xlsxdate(r.dob);
      r.hire_date = xlsxdate(r.hire_date);
      r.term_date = xlsxdate(r.term_date);
    });
    console.log("lt", performance.now() - start)
    let date = new EventDate(new Date(parseInt(year) - 1, 11, 31));
    this.data = { type: 'census', year: year, date: date, employer: { consensus: null, all: new Map(), names: new Map() }, data: [...ft_data, ...pt_data, ...lt_data] };
    this.filter = { ignored: true, imported: true, good_match: false, poor_match: false };
   
    this.display_limit = 8;///this.data.data.length;
    this.display_item = 0;
    this.fetchMatches();
    await (this.updateComplete);
    this.resizer.disconnect();
    this.resizer.observe(this.renderRoot.getElementById('tablecontainer'));
  }
  */



  computeDisplayLimit() {
    let scroller = this.renderRoot.querySelector('.table-scroller');
    let header = this.renderRoot.querySelector('#file_header');
    let first = this.renderRoot.querySelector('#file_data > tr');
    let container_height = scroller && header ? scroller.clientHeight - header.offsetHeight : null;
    let row_height = first ? first.offsetHeight : null;
    this.display_limit = container_height && row_height ? Math.floor(container_height / row_height) : 8;
  }
  /*
  async openXLSX(f) {
    this.data = { type: 'loading' };
    console.log("attempting to load", f.name);
    await this.updateComplete;
    let reader = new FileReader();
    reader.onprogress = e => {
      console.log(`loading... ${e.loaded}/${e.total}`);
    }
    reader.onload = e => {
      let workbook = XLSX.read(e.target.result, { type: 'array' });
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      //console.log(worksheet);
      let ft_cell = Object.entries(worksheet).find(([cell, content]) => content.v && content.v === "SCHEDULE 1 - FULL-TIME EMPLOYEES");
      if (ft_cell) { // Looks like a census report
        let year = worksheet.E1.v.trim().split(" ")[0];
        console.log("CENSUS REPORT, YEAR", year);
        let pt_cell = Object.entries(worksheet).find(([cell, content]) => content.v && content.v === "SCHEDULE 2 - PART-TIME EMPLOYEES");
        let lt_cell = Object.entries(worksheet).find(([cell, content]) => content.v && content.v === "SCHEDULE 3 - OTHER EMPLOYEES (LOST TIMERS)");

        this.loadCensus(worksheet, year, ft_cell ? XLSX.utils.decode_cell(ft_cell[0]).r : null, pt_cell ? XLSX.utils.decode_cell(pt_cell[0]).r : null, lt_cell ? XLSX.utils.decode_cell(lt_cell[0]).r : null);
        //this.loadCensus(worksheet, year, ft_cell ? XLSX.utils.decode_cell(ft_cell[0]).r : null, pt_cell ? XLSX.utils.decode_cell(pt_cell[0]).r : null, lt_cell ? XLSX.utils.decode_cell(lt_cell[0]).r : null);
      } else {
        // Not a census report
        this.data = { error: "unrecognized file layout" };
      }
    }

    reader.readAsArrayBuffer(f);
  }

  async bgLoad(ws, year, ft_row, pt_row, lt_row) {
    this.worker = Comlink.wrap(new Worker('/src/components/census-worker.js'));
    let work = await new this.worker();
    work.loadCensus(ws, year, ft_row, pt_row, lt_row);
  }
  */

  static get properties() {
    return {
      search_results: { type: Array },
      files: { type: Array },
      selected: { type: Object },
      select_all: { type: Boolean },
      filter: { type: Object },
      sort: { type: Object },
      data: { type: Object },
      dragging: { type: Boolean },
      active_user: { type: String },
      ...(super.properties)
    };
  }
}

window.customElements.define('import-page', ImportExportPage);
export { ImportExportPage }
