import { LitElement, html, css } from 'lit';
import { KalePage } from '../shared-components/page.js';
import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import '../components/edit-person.js';

const user_pref_styles = css`
  
      .column {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column; 
        width: 100%;
      }
      .column > * {
        margin-top: 24px;
        width: 100%;

        max-width: 700px;
      }


          .card {
        box-sizing: border-box;
          background-color: white;
          border: 1px solid var(--paper-grey-400);
          padding: 12px;
         padding-bottom: 16px;
         border-radius: 8px;
          transition: var(--shadow-transition);
          cursor: text;

        }

        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }
        #search_box {
         font-size: 22px;
        }

        #search_box > #search_text_editable {
          display: inline-block;
          margin-left: 8px;
          min-width: 10px;
        }

        #search_text_editable,#search_text_editable:active,#search_text_editable:focus   {
            border:none;
            outline:none;
         }
         
         #search_text_editable[empty]::before {
          content: "Search";
          color: var(--paper-grey-500);
         }

        #search_text_editable:active::before,#search_text_editable:focus::before {
          content: "";
        }

        #results {
          padding: 0;
        }
        
        .result {
          padding: 4px;
          color: var(--paper-grey-800);
               display: flex;
                 flex-direction: row;
          justify-content: space-between;
          align-content: center;
          align-items: center;

        }
        .result > div {
          width: fit-content;
          margin: 8px;
        }

        .graphic {
          width: 64px;
        }
        .info {
          flex: 3;
          
        }
        .name {
          
        }
        .subinfo {
          font-size: 80%;
          color: var(--paper-grey-600);
          
        }
        .actions {
          text-align: right;
          
        }
      
        .top-app-bar-adjust {
          margin-top: 64px;
        }
`;

class UserPrefsPage extends KalePage {
  static styles = user_pref_styles

  static styles = [ super.styles, user_pref_styles]
  static icon = "settings_applications"
  static default_title = "Preferences"
  get form_name() { return 'App Settings' }

  renderPage() {
    console.log("USER PREF RENDERPAGE")
    return html`
            <div class="column">
              <div class="card">
              prefs
              </div>
            </div>
        `;
  }

  activateRoute() {
    //Poke the search query whenever the page is reshown:
    //console.log("people-page activated");
    //this.updateSearchResults();
  }

  static get properties() {
    return {
      tags: { type: Array },
      search_results: { type: Array },
      add_person: { type: Boolean },
      ...(super.properties)
    };
  }
}

window.customElements.define('prefs-page', UserPrefsPage);
export { UserPrefsPage }
