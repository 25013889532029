import gql from 'graphql-tag';

export const enum_spec = {
  'person_marital_status_code': {
    nullable: true,
    query: gql`query marital_status_values { values:person_marital_status_codes { code name deprecated }}`
  },
  'person_gender_code': {
    nullable: true,
    query: gql`query gender_values { values:person_gender_codes { code name deprecated }}`
  },
  'person_suffix_code': {
    nullable: true,
    query: gql`query suffix_values { values:person_suffix_codes { code name deprecated }}`
  },
  'person_title_code': {
    nullable: true,
    query: gql`query title_values { values:person_title_codes { code name deprecated }}`
  },
  'employer_code': {
    query: gql` query employer { values:employer_codes { code name deprecated is_employer }}`
  },
  'employment_type_code': {
    query: gql` query employment_type { values:employment_type_codes { code name deprecated }}`
  },
  'suspension_type_code': {
    query: gql` query suspension_type { values:suspension_type_codes { code name deprecated }}`
  },
  'beneficiary_relationship_code': {
    query: gql` query relationship { values:beneficiary_relationship_codes { code name deprecated }}`
  },
  'beneficiary_type_code': {
    query: gql` query beneficiary_type { values:beneficiary_type_codes { code name deprecated }}`
  },
  'benefit_type_code': {
    query: gql` query benefit_type { values:benefit_type_codes { code name deprecated }}`
  },
  'benefit_annuity_type_code': {
    query: gql` query annuity_type { values:benefit_annuity_type_codes { code name deprecated }}`
  },
  'adjustment_type_code': {
    query: gql` query adjustment_type { values:service_adjustment_type_codes { code name deprecated }}`
  },
  'participation_change_type_code': {
    query: gql` query change_type { values:participation_change_type_codes { code name deprecated }}`
  },
  'address_state_code': {
    query: gql` query state_values { values:address_state_codes { code name deprecated }}`
  },
  'address_status_code': {
    query: gql` query address_status_values { values:address_status_codes { code name deprecated }}`
  },
  'email_status_code': {
    query: gql` query email_status_values { values:email_status_codes { code name deprecated }}`
  },
  'phone_status_code': {
    query: gql` query phone_status_values { values:phone_status_codes { code name deprecated }}`
  },
  'phone_type_code': {
    query: gql` query phone_type_values { values:phone_type_codes { code name deprecated }}`
  },
  'withdrawal_type_code': {
    query: gql` query withdrawal_type_values  { values:withdrawal_type_codes { code name deprecated  }}`
  }
}
/*
export class EnumInfo extends StaticQuery {

  get required_impl() {
    return ['NotesId'];
  }
  get gql_impl() {
    return person_note_info_query;
  }
  data_impl({ data: { person_note: [d] } }) {
    return d;
  }
}
export class EditEnumInfo extends SaveableQuery {
  get friendly_name() { return "Note" }
  get required_keys_impl() {
    return [
      "id",
      "person_id"
    ];
  }
  data_impl({ data: { person_note: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_person_note_query;
  }
  get delete_gql_impl() {
    return delete_person_note_query;
  }
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }
}

*/