//
// Timeline editor components for employment, retirement, suspension, etc.
// Inherit from a generic base class

import { html, css, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-checkbox';
import '../shared-components/form.js';
import { EventDate } from '../benefits-app/eventdate.js';
import { KaleComponent, KaleForm } from '../shared-components/form.js';
import {find_person_type} from '../pages/people.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);

import { timeline_classes } from './colors.js';
import gql from 'graphql-tag';
// TODO: remove direct calls to client
import {
  client,
  simple_person_fields,
  EmploymentInfo,
  EditEmploymentInfo,
  BenefitInfo,
  BenefitPaymentInfo,
  EditBenefitInfo,
  SuspensionInfo,
  EditSuspensionInfo,
  BeneficiaryInfo,
  EditBeneficiaryInfo,
  ContributionInfo,
  EditContributionInfo,
  ServiceAdjustmentInfo,
  EditServiceAdjustmentInfo,
  WithdrawalInfo,
  EditWithdrawalInfo,
  WithdrawalRepaymentInfo,
  EditWithdrawalRepaymentInfo,
  EditBenefitPaymentInfo,
  ParticipationChangeInfo,
  EditParticipationChangeInfo,
  SalaryHistoryInfo,
  EditSalaryHistoryInfo,
} from '../queries/queries.js';

const popup_style = css`
  :host {
    box-sizing: border-box;
  } 
  ${timeline_classes}
  .header {
    box-sizing: border-box;
    margin-top: -40px;
    margin-right: -24px;
    margin-left: -24px;
    padding: 20px;
    padding-bottom: 4px;
    color: var(--timeline-text-color);
    background-color: var(--timeline-primary-color);
  }


  .header[errors] {
    --header-color: var(--error-color);
    --header-text-color: white;
  }


  .errors {
    color: var(--error-color);
    margin-top: 12px;
  }

  .save-error {
    color: var(--error-color);
    padding: 12px;
  }


  .confirm-message {
    padding-right: 12px;
    padding-left: 12px;
    display: inline-block;
    color: #6200ee;
    font-weight: 800;
  }

  mwc-dialog {
    overflow: visible;
    --mdc-dialog-max-width: calc(99vw - 100px);
  }
  mwc-select{
    height: auto; /* Set the initial height */
    max-height: 100px; /* Set the maximum height to allow scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  .group {

  }

  .group > * {
  }

  span.scenario_notice {
    display: inline-block;
    background-color: var(--paper-teal-200);
    color: var(--paper-grey-900);
    float: right;
    border-radius: 10px;
    padding: 3px 6px;
    font-size: 80%;
    font-weight: 100;
  }

  div.content {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: var(--popup-max-height, 80vh);
    max-width: var(--popup-max-width, 80vw);
    padding: 12px;
    margin-right: -24px;
    margin-left: -24px;
  }
`

    //this.mutation.save(data, this.data_property, this.save_message);
    //this.mutation.delete(this.data_property);


export class GenericPopupEditor extends KaleForm {
  static styles = [ popup_style ]
  get form_name() { return "GenericEditor" }
  get title() { return "EDITOR" }
  get subscription_type() { return null; }
  get mutation_type() { return null; }
  get data_property() { return null; }
  set data_property(p) { }
  get default() { return {} }
  get id_property() { return null; }
  get id_property_name() { return null }
  set id_property(id) { }
  get subscription_args() { return {} }

  set data(d) {
    this.data_property = d;
    this.id_property = d ? d.id : null;
  }

  get show_delete() { return true; }
  get show_new() { return false; }
  required(field) { return true; }

  constructor() {
    super();
    //this.subscription = new this.subscription_type(p => { /*console.log(this.id_property, "got data", p);*/ this.data_property = p; }, this.subscription_args);

    this.mutation = new this.mutation_type(
      p => this.data_property = p,  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
        this.saving = false;
        this.dispatchEvent(new CustomEvent('saved', { detail: this }));
        this.dispatchEvent(new CustomEvent('close', { detail: this }));
        this.setToDefault();
      },
      (e, msgs) => { // error handler
        this.error = { data: this.data_property, error: e, msgs: msgs };
        this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
      }, this);
    this.dirty_data = new Map();
    this.opened = false;
  }

  static get properties() {
    return Object.assign({
      personid: { type: String },
      opened: { type: Boolean },
      deleting: { type: Boolean },
      errors: { type: Array },
      mutation_redirect: { type: Object },
      ...(super.properties)
    });
  }

  set mutation(m) {
    this._mutation = m;
  }
  get mutation() {
    return this.mutation_redirect ? this.mutation_redirect.make(
      this?._mutation,
      p => this.data_property = p,  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
        this.saving = false;
        this.dispatchEvent(new CustomEvent('saved', { detail: this }));
        this.dispatchEvent(new CustomEvent('close', { detail: this }));
        this.setToDefault();
      },
      (e, msgs) => { // error handler
        this.error = { data: this.data_property, error: e, msgs: msgs };
        this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
      },
      this) : this._mutation;
  }

  get context() {
    return this._context;
  }

  set context(ctx) {
    this._context = ctx;
    if (ctx && ctx.data) {
      Object.keys(ctx.data).forEach(k => {
        // inject altered data into form
        if (this.data_property) this.data_property[k] = ctx.data[k];
        this.handleComponentDirty({ elem: true, dirty: true, valid: true, field: k, value: ctx.data[k] });
      });
    }
  }

  close() {}

  setToDefault() {
    super.setToDefault();
    this.data_property = null;
    this.id_property = null;
  }
  //style=${`--header-color: ${this.header_class}; --header-text-color: ${this.header_text_color ? this.header_text_color : "white"};`}


  render() {
    return html`
      
      <mwc-dialog id="dialog"
                    suppressDefaults
                    ?open=${this.opened} 
                    @closed=${e => { this.error = null; this.close(); }}
                    class=${this.header_class}
                    >
          <div class="header" ?errors=${(this.data_property && this.data_property.errors) || (this.context && this.context.errors)}>
            <h2>${this.title} ${this?.data_property?.projected ? html`<span class="SCENARIO_notice">SCENARIO</span>` : ''}</h2>
            ${this.header_extra ?? nothing}
          </div>
          ${this.error ? html`<div class="save-error"><h4>SAVE FAILED</h4>
          ${this.error.msgs.map(m => html`<div>${m}</div>`)}
          </div>` : html``}
          <div
            slot="primaryAction"
            class="footer_buttons" 
            style="width: 100%; display: flex; align-items: center; justify-content: flex-end; flex-direction: row;position: relative;left: -16px;">
              <div style="width: 100%">
                ${this.show_new ? html`<mwc-button icon="playlist_add" @click=${e => this.insertNew()}>new</mwc-button>` : html``}
                  ${this.deleting ? html`
                    <div class="confirm-message">Delete: Are you sure?</div>
                    <mwc-button icon="cancel" raised @click=${e => this.deleting = null}>no</mwc-button>
                    <mwc-button icon="check" @click=${e => this.delete(e)}>yes</mwc-button>
                  ` : this.show_delete ? html`
                  <mwc-button icon="delete" ?disabled=${!this.id_property || this.data_property?.projected } @click=${e => this.deleting = true}>delete</mwc-button>
                ` : html``}
                </div>
                <mwc-button @click=${e => { this.error = null; this.cancel(e) }} >${this.dirty ? "cancel" : "close"}</mwc-button>
                <mwc-button icony="content_create" ?unelevated=${this.dirty} ?disabled=${!this.dirty || !this.valid }  @click=${(e) => { this.error = null; this.save(); }} >
                  ${this.dirty || !this.id_property ? (
                      this.data_property?.projected ? (this.saving ? "saving..." : "modify scenario") : (this.saving ? "saving..." : "save")
                    ) : "saved"}
                </mwc-button>
          </div>
        </div>
          ${this.data_property && this.data_property.errors ? html`
            <div class="errors">${this.data_property.errors.join(", ")}</div>
          ` : html``}
          <div class="content">
          ${this.getForm()}
          </div>
        </mwc-dialog>
    `
  } 
  //slot="contentSlot"

  getForm() { return html`<div>NO FORM</div>` }

  updated(props) {
    if (props.has(this.id_property_name) && !this.folded) {
      //this.subscription.query(this.subscription_args);
    }
  }
  async runQuery() { this.subscription.query(this.subscription_args); }

  save_impl(data) {
    //data = Object.assign(this.default, data);
    this.deleting = null;
    console.log(`${this.form_name}.save_impl()...`, data, this.personid)
    if (!data.person_id) data.person_id = this.personid;
    this.mutation.save(data, this.data_property, this.save_message, this.defaults);
  }

  async cancel(e) {
    this.dispatchEvent(new CustomEvent('cancel', { detail: this }));
    this.dispatchEvent(new CustomEvent('close', { detail: this }));
    this.data_property = null;
    this.deleting = null;
    //this.setToDefault();
  }

  async delete(e) {
    console.log(this.name, "delete", this.id_property, this.id_property_name, this.data_property);
    console.log("this.mutation", this.mutation);
    this.mutation.delete(this.data_property);
    this.dispatchEvent(new CustomEvent('delete', { detail: this }));
    this.dispatchEvent(new CustomEvent('close', { detail: this }));
    this.deleting = null;
    //this.setToDefault();
  }
}


export class EmploymentEditor extends GenericPopupEditor {
  get form_name() { return "EmploymentEditor" }
  get title() { return "Employment" }
  get subscription_type() { return EmploymentInfo; }
  get mutation_type() { return EditEmploymentInfo; }
  get data_property() { return this.employment; }
  get default() { return { employment_type_code: 'FT' } }
  set data_property(p) { this.employment = p; }
  get id_property() { return this.employmentid; }
  get id_property_name() { return "employmentid" }
  set id_property(id) { this.employmentid = id; }
  get subscription_args() { return { EmploymentId: this.employmentid ? this.employmentid : null } }
  get header_class() { return "employment"; }

  required(field) { return field !== 'end_date' }

  static get properties() {
    return {
      employment: { type: Object },
      employmentid: { type: String },
      ...(super.properties)
    }
  }

  getForm() {
    return html`
        <div class="group">
              <kale-filtered-enum
                .label=${"Employer"}
                .field=${'employer_code'}
                ?required=${this.required('employer_code')}
                .value=${this.employment ? this.employment.employer.code : null}
                .default=${this.employment ? this.employment.employer.code : null}>
                </kale-filtered-enum>
              <kale-enum
                .label=${"Employment Type"}
                .field=${'employment_type_code'}
                ?required=${this.required('employment_type_code')}
                .default=${this.employment ? this.employment.employment_type.code : this.default.employment_type_code}
                .value=${this.employment ? this.employment.employment_type.code : null}
                >
                </kale-enum>
            </div>
            <div class="group">
              <kale-date .label=${"Start"} .field=${'start_date'} ?required=${this.required('start_date')}  .value=${this.employment ? this.employment.start_date : null}></kale-date>
              <kale-date .label=${"End"} .field=${'end_date'} ?required=${this.required('end_date')}  .value=${this.employment ? this.employment.end_date : null}></kale-date>
            </div>
    `
  }
}
window.customElements.define('edit-employment', EmploymentEditor);

const ben_titles = {
  'DEATH': 'Death Benefit',
  'RET': 'Retirement Benefit',
  'DIS': 'Disability Benefit',
  'DEF': 'Retirement/Disability/Death Benefit'
}

/*

        */


export class BenefitEditor extends GenericPopupEditor {
  get form_name() { return "BenefitEditor" }
  get title() { return ben_titles[this.benefit && this.benefit.benefit_type && this.benefit.benefit_type.code ? this.benefit.benefit_type.code : this.extra_args && this.extra_args.benefit_type ? this.extra_args.benefit_type : 'DEF'] }
  get subscription_type() { return BenefitInfo; }
  get mutation_type() { return EditBenefitInfo; }
  get data_property() { return this.benefit; }
  set data_property(p) { this.benefit = p; }
  get id_property() { return this.benefitid; }
  get id_property_name() { return "benefitid" }
  set id_property(id) { this.benefitid = id; }
  get subscription_args() { return { BenefitId: this.benefitid ? this.benefitid : null } }
  get header_class() { return "benefit"; }

  required(field) {
    if (this.is_lump()) {
      return [
        'benefit_type_code',
        'start_date',
        'benefit_annuity_type_code',
        'lump_sum'
      ].includes(field);
    } else {
      return [
        'benefit_type_code',
        'start_date',
        'benefit_annuity_type_code',
        'annual_salary_at_start',
        'final_average_salary',
        'monthly_annuity_amount',
        'monthly_annuity_amount_taxable',
      ].includes(field);
    }
  }

  triggerUpdate(field) {
    //trigger full redraw if annuity type changes
    return field === "benefit_annuity_type_code";
  }
  is_lump() {
    if (this.dirty_map.has("benefit_annuity_type_code")) {
      let dirty = this.dirty_map.get("benefit_annuity_type_code").value;
      return dirty === 'LUMP';
    }
    return this.benefit && this.benefit && this.benefit.annuity_type && this.benefit.annuity_type.code === 'LUMP';
  }


  static get properties() {
    return {
      benefit: { type: Object },
      benefitid: { type: String },
      ...(super.properties)
    }
  }
  /*
                .value=${this.benefit ? this.benefit.benefit_type.code : this.extra_args && this.extra_args.benefit_type ? this.extra_args.benefit_type.code : null}
                .default=${this.benefit ? this.benefit.benefit_type.code : this.extra_args && this.extra_args.benefit_type ? this.extra_args.benefit_type.code : null}
                */
  getForm() {
    return html`
            <div class="group">
              <kale-enum
                .label=${"Benefit Type"}
                .field=${'benefit_type_code'}
                ?required=${this.required('benefit_type_code')}
                .value=${this.benefit ? this.benefit.benefit_type.code : null}
                .default=${this.benefit ? this.benefit.benefit_type.code : null}
              ></kale-enum>
              <kale-enum
                .label=${"Annuity Type"} 
                .field=${'benefit_annuity_type_code'}
                ?required=${this.required('benefit_annuity_type_code')}
                .value=${this.benefit ? this.benefit.annuity_type.code : null}
                .default=${this.benefit ? this.benefit.annuity_type.code : null}
              ></kale-enum>
            </div>
             ${ this.is_lump() ? html`
            <div class="group">
              <kale-date .label=${"Date"} .field=${'start_date'} ?required=${this.required('start_date')}  .value=${this.benefit ? this.benefit.start_date : null}></kale-date>
              <kale-textfield .label=${"Amount"} .field=${'lump_sum'} ?required=${this.required('lump_sum')}  .value=${this.benefit ? this.benefit.lump_sum : null}></kale-textfield>
            </div>
            ` : html`
            <div class="group">
              <kale-date .label=${"Start"} .field=${'start_date'} ?required=${this.required('start_date')}  .value=${this.benefit ? this.benefit.start_date : null}></kale-date>
              <kale-date .label=${"End"} .field=${'end_date'} ?required=${this.required('end_date')}  .value=${this.benefit ? this.benefit.end_date : null}></kale-date>
            </div>

            <div class="group">
              <kale-textfield  .label=${"Annual Salary at Start"} .field=${'annual_salary_at_start'} ?required=${this.required('annual_salary_at_start')}  .value=${this.benefit ? this.benefit.annual_salary_at_start : null}></kale-textfield>
              <kale-textfield  .label=${"Final Average Salary"} .field=${'final_average_salary'} ?required=${this.required('final_average_salary')}  .value=${this.benefit ? this.benefit.final_average_salary : null}></kale-textfield>
            </div>
            <div class="group">
              <kale-textfield    .label=${"Monthly Annuity Amt"} .field=${'monthly_annuity_amount'} ?required=${this.required('monthly_annuity_amount')}  .value=${this.benefit ? this.benefit.monthly_annuity_amount : null}></kale-textfield>
              <kale-textfield .label=${"Taxable"} .field=${'monthly_annuity_amount_taxable'} ?required=${this.required('monthly_annuity_amount_taxable')}  .value=${this.benefit ? this.benefit.monthly_annuity_amount_taxable : null}></kale-textfield>
              <kale-date .label=${"Fully Taxable"} .field=${'full_taxable_date'} ?required=${this.required('full_taxable_date')}  .value=${this.benefit ? this.benefit.full_taxable_date : null}></kale-date>
            </div>`}
            `
  }
}
window.customElements.define('edit-benefit', BenefitEditor);

export class SuspensionEditor extends GenericPopupEditor {
  get form_name() { return "SuspensionEditor" }
  get title() { return "Suspension" }
  get subscription_type() { return SuspensionInfo; }
  get mutation_type() { return EditSuspensionInfo; }
  get data_property() { return this.suspension; }
  set data_property(p) { this.suspension = p; }
  get id_property() { return this.suspensionid; }
  get id_property_name() { return "suspensionid" }
  set id_property(id) { this.suspensionid = id; }

  get subscription_args() { return { SuspensionId: this.suspensionid ? this.suspensionid : null } }
  get header_class() { return "suspension"; }

  required(field) { return field !== 'end_date' }
  static get properties() {
    return {
      suspension: { type: Object },
      suspensionid: { type: String },
      ...(super.properties)
    }
  }
  getForm() {
    return html`
            <div class="group">
              <kale-enum
                .label=${"Suspension Type"}
                .field=${'suspension_type_code'}
                ?required=${this.required('suspension_type_code')}
                .value=${this.suspension ? this.suspension.suspension_type.code : null}
              ></kale-enum>
            </div>
            <div class="group">
              <kale-date .label=${"Start"} .field=${'start_date'} ?required=${this.required('start_date')}  .value=${this.suspension ? this.suspension.start_date : null}></kale-date>
              <kale-date .label=${"End"} .field=${'end_date'} ?required=${this.required('end_date')}  .value=${this.suspension ? this.suspension.end_date : null}></kale-date>
            </div>
    `
  }
}
window.customElements.define('edit-suspension', SuspensionEditor);


class NewBenPicker extends KaleForm {

}

class BeneficiaryPicker extends KaleComponent {

  firstUpdated() {
    this.add_form = this.renderRoot.getElementById("add_person_form");
    this.select_dialog = this.renderRoot.getElementById("select_beneficiary_dialog");
    this.create_dialog = this.renderRoot.getElementById("create_beneficiary_dialog");
    //this.search_form = this.renderRoot.getElementById('search_form');
    //this.searchChange();
    this.first_name = null;
    this.last_name = null;
    this.ssn = null;

  }

  showSelectDialog() { console.log("SHOW BEN SELECT"); this.hideCreateDialog(); this.select_dialog.open = true; this.searchChange() }
  hideSelectDialog() { this.select_dialog.open = false; }
  showCreateDialog() { this.hideSelectDialog(); this.create_dialog.open = true; }
  hideCreateDialog() { this.create_dialog.open = false; }

  set beneficiary(benefic) {
    let oldBen = this._origBen;
    if (benefic !== oldBen && (this._beneficiary === undefined || this._beneficiary === null)) {
      this._beneficiary = benefic;
      this._origBen = benefic;
      this.requestUpdate('beneficiary', oldBen);
    } else {
      this._origBen = benefic;
    }
  }
  get beneficiary() {
    return this._beneficiary;
  }
  searchChange(e) {
    if (e && e?.stopPropagation) {
      e.stopPropagation();
      this[e.detail.field] = e.detail.value;
    } else if (e) {
      this[e.field] = e.value;
    }
    /*
    let vals = ['first_name', 'last_name', 'ssn']
      .filter(k => this[k] !== null)
      .map(k => [k, this[k]]);
      */


    let q = gql`
      query search_people($useremail: String!, $recs: Int!, $rec_offset: Int!, $search_string: String!, $bookmarked: String!,  $employer: String!, $include_tags: _text!, $exclude_tags: _text!) {
        results:people_search_all(
        args: {
            useremail: $useremail,
            recs: $recs,
            rec_offset: $rec_offset,
            search_string: $search_string,
            bookmarked: $bookmarked,
            employer: $employer,
            include_tags: $include_tags,
            exclude_tags: $exclude_tags
            }
          ){
          ...SimplePersonFields
          tags {
            person_tag_type_code
            tag_type {
              deprecated
            }

          }
        }
      }
      ${simple_person_fields}
    `;



    this.__limit = 50;
    this.__offset = 0;

    let args = {
      useremail: "",
      recs: this.__limit,
      rec_offset: this.__offset,
      search_string: this.search_text ? this.search_text : "",
      bookmarked: "",
      employer: "",
      include_tags: `{}`,
      exclude_tags: `{}`
    };
    console.warn("SEARCHING, ARGS=", {...args});


    /*let vals = Array.from(this.search_form.children)
      .filter((c) => c.hasAttribute('field') && c.value && c.value !== '')
      .map((c) => [c.getAttribute('field'), c.value]);
      */
    //let where = vals.map((v) => `${v[0]}: {_ilike: "%${v[1]}%"}`).join('\n');

    /*
    client.query({
      fetchPolicy: 'network-only',
      query: gql`
    query search_people {
      person(
        where: {${where}}
        limit: 50
      ){
        ...SimplePersonFields
      }
    }
    ${simple_person_fields}
    `})*/
    client.query({
      fetchPolicy: 'network-only',
      query: q,
      variables: args
    })
      .then(data => {console.log("SEARCH RES:", data.data.results); this.newData(data)})
      .catch(error => console.error(error));
  }

  newData(d) {
    if (d.data.results) {
      this.search_results = d.data.results;
    } else {
      this.search_results = [];
    }
  }
  /*

                    <mwc-textfield label="First Name" field="first_name" id='first_name'></mwc-textfield>
                    <mwc-textfield label="Last Name" field="last_name" id='last_name'></mwc-textfield>
                    <mwc-textfield label="SSN" field="ssn" id='ssn'></mwc-textfield>
                  <div class="secondary" id="search_form" @component-dirty=${e => this.searchChange(e)} @paste=${e => this.searchChange(e)} @cut=${e => this.searchChange(e)}>


        <div style="cursor: pointer; position: absolute; top: 0; left: -10px; width: 100%; height: 100%;" @click=${e => this.showSelectDialog()}></div>





                    <kale-textfield .label=${"First Name"} .field=${"first_name"} id='first_name'></kale-textfield>
                    <kale-textfield .label=${"Last Name"} .field=${"last_name"} id='last_name'></kale-textfield>
                    <kale-textfield .label=${"SSN"} .field=${"ssn"} id='ssn'></kale-textfield>


                    <kale-textfield fullwidth outlined .label=${"Search"} .field=${"search_text"} id='search'></kale-textfield>



        <mwc-textfield
            id="input_elem"
            label=${`${this.label}*`} 
            .value=${this._beneficiary ? ['title.name', 'first_name', 'middle_name', 'last_name', 'suffix.name'].map(f => f.split(".").reduce((val, f) => val && val[f] ? val[f] : null, this._beneficiary)).filter(n => n !== null).join(" ") : null}
            @click=${e => this.showSelectDialog()}
            ></mwc-textfield>
        <mwc-button @click=${e => this.showSelectDialog()}>Pick Existing Person</mwc-button>
  */

  render() {
    console.log("RENDERING BENEF", this._beneficiary);
    let ben = this._beneficiary ? {
      name: ['title.name', 'first_name', 'middle_name', 'last_name', 'suffix.name'].map(f => f.split(".").reduce((val, f) => val && val[f] ? val[f] : null, this._beneficiary)).filter(n => n !== null).join(" "),
      dob: dayjs(this._beneficiary.dob).format('YYYY-MM-DD'),
      person_type: find_person_type(this._beneficiary)
    } : null;
    return html`
      <style>
      
        table { border-collapse: collapse;}
        td,th { padding: 0.75em; border: none; }
        th { text-align: left; text-transform: uppercase;}
        tr { border: none}
        tr:nth-child(even) { background: #CCC;}
        td.button {
          text-align: right;
        }
        #no_results {
          width: 100%;
          text-align: center;
          color: var(--paper-grey-700);
          font-weight: 100;
        }

        .column {
          display: flex;
          align-items: right;
          justify-content: flex-end;
          flex-direction: column; 
        }
        .column > * {
          margin-top: 24px;
          min-width: 500px;
          max-width: 840px;
        }

        #search_form_ {
          box-sizing: border-box;
          border: 1px solid var(--mdc-primary, #6200ee);
          border-radius: 20px;
          padding: 2px 20px;
          margin: 12px;
          margin-top: 14px;
          margin-bottom: 14px;
          margin-left: 1px;
          margin-right: 25px;
          background-color: var(--mdc-text-field-fill-color, whitesmoke);
        }

        #search_form_:hover {
          border: 2px solid var(--mdc-primary, #6200ee);
          margin: 12px;
          margin-left: 0;
          margin-right: 24px;
        }


        .results {
          width: min(90vw, 600px);
          height: min(80vh, 300px);
          overflow-y: overlay;
          overflow-x: hidden;
          

        }
        .ben_chip {
          background-color: var(--paper-grey-200);
          color: white;
          display: inline-block;
          padding: 4px 8px;
          padding-top: 6px;
          font-size: 10px;
          line-height: 12px;
          border-radius: 10px;
          margin: 4px;
          cursor: pointer;
          font-weight: 900;
        }
        .ben_chip  .sub {
          font-size: 80%;
          font-weight: 100;
        }

        .ben_chip[person-type='working_retiree']{
          background-color: var(--working-retiree-person-color);
        }
        .ben_chip[person-type='employee']{
          background-color: var(--employee-person-color);
        }
        .ben_chip[person-type='ex-employee']{
          background-color: var(--ex-employee-person-color);
          }
        .ben_chip[person-type='retiree']{
          background-color: var(--retiree-person-color);
          color: var(--paper-grey-700);
        }
        .ben_chip[person-type='deceased']{
          background-color: var(--deceased-person-color);
        }
        .ben_chip[person-type='ineligible']{
          background-color: var(--ineligible-person-color);
        }
        .ben_chip[person-type='errors']{
          background-color: var(--error-person-color);
        }
        .ben_chip[person-type='undefined']{
          background-color: var(--ineligible-person-color);
        }

        .beneficiary-info {
          background-color: var(--paper-grey-800);
          color: white;
          padding: 8px 16px;
          font-size: 14px;
          line-height: 16px;
          border-radius: 20px;
          margin: 8px;
          cursor: pointer;
          font-weight: 900;
          min-width: 200px;


          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
        }
        .no-ben {
          font-weight: 100;
          margin-left: 20px;
        }

        .ben-main {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 20px;
        }

        .ben-info-holder {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
          margin: 20px -5px;
        }

        .button-row {
          margin: 12px 24px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: left;
          flex-direction: row;
          flex: 1;
        }

      }


      </style>

      <div style="position: relative;" class="ben-info-holder">
        <div class="beneficiary-info ben_chip" person-type=${ben?.person_type} @click=${e => this.showSelectDialog()}>
        <mwc-icon>person_search</mwc-icon>
        ${ben ? html`<div class="ben-main">${ben.name}<div class="sub">dob: ${ben.dob}</div></div>` : html`<div class="no-ben">Select&hellip;</div>`}
        </div>
        <mwc-button @click=${e => this.showCreateDialog()}>New Person&hellip;</mwc-button>
      </div>

      <mwc-dialog id="select_beneficiary_dialog"
                  headerlabel="Select Beneficiary"
                  acceptlabel="Close"
                  declinelabel=""
                  @accept=${e => { this.hideSelectDialog(); }}
                  >

                  <div class="secondary" id="search_form" @component-dirty=${e => this.searchChange(e)}>
                      <mwc-textfield fullwidth label="Search" placeholder="Search" @input=${e => {
                        this.searchChange({ 
                          field: "search_text",
                          value: e?.path?.[0]?.value
                        });
                      }}></mwc-textfield>
                  </div>
                  <div class="results" style="margin-top: 2em;">
                      ${(this.search_results && this.search_results.length > 0) ?
                          html`${repeat(this.search_results, (p) => p.id, (p, index) => {
                              let person_type = find_person_type(p);
                              let name = ['title', 'first_name', 'middle_name', 'last_name', 'suffix'].map(f => p[f] && p[f].name ? p[f].name : p[f]).filter(f => f).join(" ");
                              let email = p?.email;
                              let state = p.addresses && p.addresses.length > 0 ? p.addresses.filter(a => a.preferred).sort((a, b) => a.assigned_date - b.assigned_date).concat(p.addresses.sort((a, b) => a.assigned_date - b.assigned_date))[0].state_id : '';
                              let dob = dayjs(p?.dob).format('YYYY-MM-DD');

                              return html`
                              <div
                                class="ben_chip"
                                person-type=${person_type}
                                @click=${e => { 
                                  console.log("setting beneficiary id to", p.id, p.first_name, p.last_name);
                                  this._beneficiary = p;
                                  this.valueChange(p.id);
                                  this.hideSelectDialog(); }
                                }
                              >
                              <div>${name}</div>
                              <div class="sub">dob: ${dob}</div>
                              </div>`;
                            }
                          )}` : html`<div id="no_results">NO RESULTS</div>`}
                  </div>



                  <div slot="primaryAction" class="button-row">
                    <mwc-button @click=${e => this.hideSelectDialog()}>Close</mwc-button>
                  </div>
      </mwc-dialog>
      <mwc-dialog id="create_beneficiary_dialog"
                  headerlabel="New Person"
                  >
                  <edit-person id="add_person_form"
                               noredirect
                               @person-saved=${e => {
        //this.beneficiary.beneficiary_id = e.detail.person.id;
                    //<mwc-button @click=${e => this.showCreateDialog()}>Create New</mwc-button>
        this._beneficiary = e.detail.person;
        this.valueChange(e.detail.person.id);
        //this.handleComponentDirty({elem: this.create_dialog, dirty: true, field: 'beneficiary_id', value: e.detail.person.id})
      }}
                               >
                  </edit-person>
                  <div slot="primaryAction" class="button-row">
                    <mwc-button @click=${e => { this.hideCreateDialog(); this.add_form.reset() }}>Close</mwc-button>
                    <mwc-button raised @click=${e => { this.add_form.save(); this.hideCreateDialog(); }}>Add</mwc-button>
                  </div>
      </mwc-dialog>
    `
  }
  //
  //value=${this._value ? this._value : ''}
  //@value-changed=${(e) => this.valueChange(e.detail.value)}
  //.value=${this.beneficiary ? ['title', 'first_name', 'middle_name', 'last_name', 'suffix'].map(p => this.beneficiary ? this.beneficiary.beneficiary[p] : null).filter(p => p).join(" ") : null}
  static get properties() {
    return {
      search_results: { type: Array },
      _beneficiary: { type: Object },
      ...(super.properties)
    }
  }
}

window.customElements.define('beneficiary-picker', BeneficiaryPicker);





export class BeneficiaryEditor extends GenericPopupEditor {
  get form_name() { return "BeneficiaryEditor" }
  get title() { return "Beneficiary" }
  get subscription_type() { return BeneficiaryInfo; }
  get mutation_type() { return EditBeneficiaryInfo; }
  get data_property() { return this.beneficiary; }
  set data_property(p) { this.beneficiary = p; }
  get id_property() { return this.beneficiaryid; }
  get id_property_name() { return "beneficiaryid" }
  set id_property(id) { this.beneficiaryid = id; }
  get subscription_args() { return { BeneficiaryId: this.beneficiaryid ? this.beneficiaryid : null } }
  get header_class() { return "beneficiary"; }

  required(field) { return field !== 'removal_date' }
  static get properties() {
    return {
      beneficiary: { type: Object },
      beneficiaryid: { type: String },
      ...(super.properties)
    }
  }

  getForm() {
    return html`
      <style>
        .beneficiary {
          font-size: 120%;
          display: inline-block;
        }
        </style>
            <div class="group" style="margin-top: 20px; margin-bottom: 20px;">
              <beneficiary-picker
                               .label=${"Beneficiary"}
                               .beneficiary=${this.beneficiary ? this.beneficiary.beneficiary : null}
                               .value=${this.beneficiary_id}
                               .field=${"beneficiary_id"}
              ></beneficiary-picker>
              <kale-enum
                .label=${"Type"}
                .field=${'beneficiary_type_code'}
                ?required=${this.required('beneficiary_type_code')}
                .value=${this.beneficiary ? this.beneficiary.beneficiary_type.code : 'P'}
                .default=${this.beneficiary ? this.beneficiary.beneficiary_type.code : 'P'}
              ></kale-enum>
              <kale-enum
                .label=${"Relationship"}
                .field=${'beneficiary_relationship_code'}
                ?required=${this.required('beneficiary_relationship_code')}
                .value=${this.beneficiary ? this.beneficiary.relationship.code : null}
                .default=${this.beneficiary ? this.beneficiary.relationship.code : 'O'}
              ></kale-enum>
            </div>

            <div class="group">
            </div>
            <div class="group">
              <kale-date 
                .label=${"Designated"}
                .field=${'designation_date'}
                ?required=${this.required('designation_date')}
                .value=${this.beneficiary ? this.beneficiary.designation_date : dayjs().format('YYYY-MM-DD')}
                .default=${this.beneficiary ? this.beneficiary.designation_date : dayjs().format('YYYY-MM-DD')}
              ></kale-date>
              <kale-date
                .label=${"Removed"}
                .field=${'removal_date'}
                ?required=${this.required('removal_date')} 
                .value=${this.beneficiary ? this.beneficiary.removal_date : null}
              ></kale-date>
            </div>
    `
  }
}

window.customElements.define('edit-beneficiary', BeneficiaryEditor);





export class ContributionEditor extends GenericPopupEditor {
  get form_name() { return "ContributionEditor" }
  get title() { return "Contribution Record" }
  get subscription_type() { return ContributionInfo; }
  get mutation_type() { return EditContributionInfo; }
  get data_property() { return this.contribution; }
  set data_property(p) { this.contribution = p; }
  get id_property() { return this.contributionid; }
  get id_property_name() { return "contributionid" }
  set id_property(id) { this.contributionid = id; }

  get subscription_args() { return { ContributionId: this.contributionid ? this.contributionid : null } }
  get header_class() { return "contribution"; }

  static get properties() {
    return {
      contribution: { type: Object },
      contributionid: { type: String },
      ...(super.properties)
    }
  }
  getForm() {
    return html`
            <div class="group">
              <kale-date .label=${"Date"} .field=${'contribution_date'} ?required=${this.required('contribution_date')}  .value=${this.contribution ? this.contribution.contribution_date : null}></kale-date>

              <kale-filtered-enum
                .label=${"Employer"}
                .field=${'employer_code'}
                ?required=${this.required('employer_code')}
                .value=${this.contribution ? this.contribution.employer_code : null}
                .default=${this.contribution ? this.contribution.employer_code : null}>
                </kale-filtered-enum>
            </div>
            <div class="group">
              <kale-textfield  .label=${"EOY Base Salary"} .field=${'eoy_base_salary'} ?required=${this.required('eoy_base_salary')}  .value=${this.contribution ? this.contribution.eoy_base_salary : null}></kale-textfield>
              <kale-textfield  .label=${"EE Contribution"} .field=${'ee_pension_contribution'} ?required=${this.required('ee_pension_contribution')}  .value=${this.contribution ? this.contribution.ee_pension_contribution : null}></kale-textfield>
              <kale-textfield  .label=${"Pension Salary"} .field=${'pension_salary'} ?required=${this.required('pension_salary')}  .value=${this.contribution ? this.contribution.pension_salary : null}></kale-textfield>
            </div>
            <div class="group">
              <kale-toggle .label=${"Made Up"} .field=${'made_up'}  .value=${this.contribution ? this.contribution.made_up : null}></kale-toggle>
            </div>
    `
  }
}
window.customElements.define('edit-contribution', ContributionEditor);

/*
export class SalaryEditor extends GenericPopupEditor {
  get form_name() { return "SalaryEditor" }
  get title() { return "Salary Record" }
  get subscription_type() { return SalaryInfo; }
  get mutation_type() { return EditSalaryInfo; }
  get data_property() { return this.salary; }
  set data_property(p) { this.salary = p; }
  get id_property() { return this.salaryid; }
  get id_property_name() { return "salaryid" }
  set id_property(id) { this.salaryid = id; }

  get subscription_args() { return { SalaryId: this.salaryid ? this.salaryid : null } }
  get header_class() { return "contribution"; }

  static get properties() {
    return {
      salary: { type: Object },
      salaryid: { type: String },
      ...(super.properties)
    }
  }
  getForm() {
    return html`
            <div class="group">
              <kale-date .label=${"Date"} .field=${'effective_date'} ?required=${this.required('effective_date')}  .value=${this.salary ? this.salary.effective_date : null}></kale-date>
              <kale-textfield  .label=${"Salary"} .field=${'salary'} ?required=${this.required('salary')}  .value=${this.salary ? this.salary.salary : null}></kale-textfield>
            </div>
    `
  }
}
window.customElements.define('edit-contribution', SalaryEditor);
*/


function saveSelection() {
  var sel = window.getSelection();
  if (sel.getRangeAt && sel.rangeCount) {
    return sel.getRangeAt(0).cloneRange();
  }
}

function restoreSelection(range) {
  if (range) {
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }
}

export class ContributionDateCell extends KaleComponent {
  get styles() {
    return css`
        :host {
          position: relative;
        }
        span.placeholder {
          visibility: hidden;
          display: none;
          position: absolute;
          top:0;
          right: 50px;
          height: 0;
          width: 0;
          font-size: 75%;
          font-style: italic;
          text-transform: uppercase;
        }
        span.placeholder[placehold] {
          visibility: visible;
          display: inline-block;
          opacity: 0.25;
        }
        #input_elem[dirty] {
          font-style: italic;
          color: var(--paper-green-600); 
        }
        .nowrap {
          white-space: nowrap;
        }
        
        `

  }

  static get properties() {
    return {
      focused: { type: Boolean },
      ...(super.properties)
    }
  }
  render() {
    return html`<style>${this.styles}</style><span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null} >${this._field}</span><div id="input_elem" ?dirty=${this.dirty} @input=${e => this.input_change(e)} class="nowrap editable datecontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true}></div>`
  }

  set field(f) { this._field = f; }
  get field() { return `${this.contrib_id}::${this._field}` }

  firstUpdated() {
    super.firstUpdated();
    if (this.elem) this.elem.innerText = this.format(this._value);
    if (this.newitem) {
      //console.warn("NEW ITEM TRIGGERED", this._value, this._orig);
      this.newitem = false;
      let val = this._value;
      this._orig = NaN;
      this._value = NaN;
      this.value = val ;

      //this.dispatchEvent(new CustomEvent('component-dirty', { bubbles: true, composed: true, detail: { elem: this, dirty: this.dirty, valid: this.valid, field: this.field, value: this.value } }));
    }
    this.scrollIntoView();
  }
  set value(val) {
    //console.warn("SET", this, val, this._orig, val === this._orig);
    val = val === undefined ? null : val;
    val = this.transformInput(val);
    let oldVal = this._orig;
    if (val !== oldVal) {
      //console.warn("NEW VALUE", val, oldVal);
      val = val === 'actual_null_request' ? null : val;
      this._value = val;
      this._orig = val;
      this._send_events = false;
      this.updateComplete.then(() => {
        this._send_events = true;
      });
      if (this.elem) this.elem.innerText = this.format(this._value);
    } else {
      this._orig = val;
    }
  }
  format(val) {
    const na = /^\w*[nN]\/*[aA]\s*/;
    return val !== null && val !== undefined && !na.test(val) ? new EventDate(val) : null;
  };

  get value() {
    return this._value;
  }

  formatInput(val) {
    return val;
  }
  async input_change(evt) {
    let elem = evt.srcElement ?? evt.target ?? evt.path?.[0];

    console.log("input_change", evt, elem);
    console.log("elem:", elem);

    let content = this.formatInput(elem.innerText);
    this.valueChange(content);
    console.log(`${this.constructor.name}[${this.field}] input: "${content}" value=${this.value}`);
  }
}
export class ContributionAmtCell extends ContributionDateCell {
  render() {
    return html`<style>${this.styles}</style><span style="float: left; opacity: 0.8;">$</span><span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="nowrap editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focus()} ></div>`
  }
  //format(val) { return val ? (Math.round(val*100)/100).toLocaleString([], {style: 'currency', currency: 'USD'}) : val });
  format(val) {
    return val ? (Math.round(val * 100) / 100) === 0 ? null : (Math.round(val * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '–';
  }
  focus() {
    this.focused = true;
    window.setTimeout(() => {
      var sel, range;
      if (window.getSelection && document.createRange) {
        range = document.createRange();
        range.selectNodeContents(this.elem);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(this.elem);
        range.select();
      }
    }, 1);
  }

  formatInput(val) {
    return typeof(val) === 'string' ? val.replace(/,/, '') : '';
  }
}

export class ContributionNumberCell extends ContributionDateCell {
  render() {
    return html`<style>${this.styles}</style><span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="nowrap editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true} ></div>`
  }
  format(val) { return val };
}


export class ContributionTextCell extends ContributionDateCell {
  render() {
    return html`<style>${this.styles}</style><span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true} ></div>`
  }
  format(val) { return val };
}

export class ContributionEnumCell extends ContributionDateCell {
  render() {
    return html`<style>${this.styles}</style><span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true} ></div>`
  }
  format(val) { return val };
}

export class ContributionSSNCell extends ContributionDateCell {
  render() {
    return html`<style>${this.styles}</style><span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="nowrap editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true} ></div>`
  }

  format(val) {
    if (typeof(val) === 'number') val = String(val);
    const digits_only = /\D/g
    if (val === null || val === undefined || !typeof (val) === 'string' || !val.replace) { return val }
    let digits = val.replace(digits_only, '');
    let out = digits;
    if (digits.length > 5) {
      out = digits.slice(0, 3) + '‑' + digits.slice(3, 5) + '‑' + digits.slice(5);
    } else if (digits.length > 3) {
      out = digits.slice(0, 3) + '‑' + digits.slice(3);
    }
    return out;
  }
}


export class ContributionBoolCell extends ContributionDateCell {
  render() {
    return html`
      <style>${this.styles}</style>
      <mwc-checkbox .value=${this.value} .checked=${this.value} @check-change=${e => this.valueChange(e.detail.checked)}></mwc-checkbox>
      `
  }
  format(val) { return val };
}


window.customElements.define('contrib-textcell', ContributionTextCell);
window.customElements.define('contrib-enumcell', ContributionEnumCell);
window.customElements.define('contrib-ssncell', ContributionSSNCell);
window.customElements.define('contrib-boolcell', ContributionBoolCell);
window.customElements.define('contrib-datecell', ContributionDateCell);
window.customElements.define('contrib-numbercell', ContributionNumberCell);
window.customElements.define('contrib-amtcell', ContributionAmtCell);


/*
class KaleDate extends KaleComponent {
  render() {
    return html`
            <mwc-textfield
            id="input_elem"
            .required=${this.required}
            label=${this.label} 
            value=${this.value ? this.value : ''}
            @value-changed=${(e) => this.valueChange(e.detail.value !== '' ? e.detail.value : null)}
          ></mwc-textfield>`

  }
  get valid() {
    console.log("checking date validity", !this.elem, this.elem ? this.elem.valid : 'no elem' , "returning: ", !this.elem || this.elem.valid);
    return this.elem !== undefined && this.elem !== null && (this.required ? this.value !== null : true) && this.elem.valid;
  }
}*/




class SeriesEditor extends GenericPopupEditor {
  get series_data() { }
  set series_data(s) { }

  //FIXME: this is ugly
  get show_delete() { return false; }
  get show_new() { return true }

  get subscription_args() { return null }

  get column_names() { return []; }
  render_row(r) { return html`` }

  constructor() {
    super();
    this._next_id = 0;
    this.blacklist = new Set();
  }

  getForm() {
    console.log("GETTING FORM", this.form_name);
    console.log("TABLE DATA", this.series_data.slice())
    return html`
      <style>
        .table-container { position: relative; /*padding-top: 37px;*/}
        .table-scroller { overflow-y: overlay; max-height: 50vh; }
        table { border-collapse: collapse; width: 100%;}
        td,th { border: none; padding: 10px 28px;}
        th {
          text-transform: uppercase;
          border: none;
          white-space: nowrap;
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 1;
        }
        /*
          height: 0;
          line-height: 0;
          color: transparent;
        th > div {
          position: absolute;
          background: transparent;
          padding: 9px 25px;
          top: 0;
          margin-left: -25px;
          color: black;
          line-height: normal;
        }*/
        tr { border: none}
        tr:nth-child(even) { background: #CCC;}
        tr[new] { background: var(--paper-orange-200)}

        td.remove { padding: 10px 0px;}
        td.date { 
        }

        td.money, th.money{
          text-align: right;
        }

        td.parenthetical {
          font-weight: 100;
          font-size: 90%;
          font-style: italic;
          opacity: 0.95;
        }

        span.currency {
          float: left;
        }
        contrib-datecell[changed], contrib-amtcell[changed] {
        font-style: italic;
        color: var(--paper-green-600); 
        }

        th span {
        }


      </style>
      <div class="table-container">
        <div class="table-scroller">
          <table>
            <thead>
              <tr>
                ${this.column_names.map(c => html`
                  <th>${c}</th>
                `)}
                <th></th>
              </tr>
            </thead>

            <tbody>
            ${this.series_data ? this.series_data.map(d => html`
              <tr ?new=${d.temp_id} >
                ${this.render_row(d)}
                <td class="remove"><mwc-icon-button @click=${e => this.deleteItem(d)} icon="delete"></mwc-icon-button></td>
              </tr>
            `) : html``}
            </tbody>

          </table>
        </div>
      </div>
    `
    /*               
    return i === 0 ?
                  html`<td class="date" >
                  <div 
                  @input=${e => this.input_change(e, c, f)}
                  class="editable datecontent" contenteditable>${c[f]}</div></td>` :
                  html`<td class="money"><span class="currency">$</span>
                  <div 
                  @input=${e => this.input_change(e, c, f)}
                  class="editable datecontent" contenteditable>${c[f].toFixed(2)}</div></td>` 
                })}


                  @keyup=${(e) => this.handleKeys(e)}
                  @keypress=${(e) => this.handleKeys(e)}
                  @paste=${(e) => this.handlePaste(e)}
                  @cut=${(e) => this.handleCut(e)}
                ${['contribution_date', 'salary', 'amount', 'interest', 'balance'].map((f,i) => {
                i === 0 ? html`<td contenteditable>${c[f]}</td>` : html`<td contenteditable>$${c[f].toFixed(2)}</td>` 
                })}
    */
  }

  deleteItem(item) {
    console.log("deleting", item);
    if (item.temp_id) {
      this.series_data = [...this.series_data.filter(c => c.temp_id === undefined || c.temp_id !== item.temp_id)];
    } else {
      let mutation = new this.mutation_type(
        p => {
          this.series_data = [...this.series_data.filter(c => c.id !== item.id)];
        },
        { changeMap: null },  //initial variables
        p => { // finalizing function
          this.series_data = [...this.series_data.filter(c => c.id !== item.id)];
        },
        (e, msgs) => { // error handler
          this.error = { data: this.data_property, error: e, msgs: this.error ? [...this.error.msgs, ...msgs] : msgs };
          this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
        });
      this.blacklist.add(item.id);
      mutation.delete(item);
      this.series_data = [...this.series_data.filter(c => c.id !== item.id)];
    }
  }

  save_impl(data) {
    console.log(`${this.form_name}.save_impl()...`, data, this.personid)
    let items = new Map();
    Object.entries(data).forEach(([fake_field, value]) => {
      let [nominal_id, field] = fake_field.split("::");
      let existing = items.get(nominal_id);
      if (existing) {
        existing.data[field] = value;
      } else {
        let info = { nominal_id: nominal_id, data: {} }
        info.data[field] = value;
        let id = nominal_id;
        let item = this.series_data.find(c => c.id === id);
        if (!item) {
          item = this.series_data.find(c => c.temp_id === nominal_id);
          id = null;
        }
        console.log(nominal_id, item, this.series_data);
        if (!item.person_id) item.person_id = this.personid;
        info.item = item;
        if (id) info.id = id;
        //id = id.slice(0,5) === 'TEMP|' ? null : id; 
        items.set(nominal_id, info);
      }
    });
    console.log("ITEMS", items);
    /*
    this.deleting = null;
    if (!data.person_id) data.person_id = this.personid;
    this.mutation.save(data, this.data_property);
    */
    this.needs_saving = new Set(items.keys());
    [...items.entries()].forEach(([key, update]) => {
      console.log("spawning a mutation for", key, update);
      let mutation = new this.mutation_type(
        p => this.series_data = [...this.series_data.filter(c => c.temp_id ? c.temp_id !== key : c.id !== p.id), p],  // data update function
        { changeMap: null },  //initial variables
        p => { // finalizing function
          this.needs_saving.delete(key);
          if (this.needs_saving.size === 0) {
            this.dispatchEvent(new CustomEvent('saved', { detail: this }));
            this.dispatchEvent(new CustomEvent('close', { detail: this }));
            this.setToDefault();
            this.saving = false;
          }
        },
        (e, msgs) => { // error handler
          this.error = { data: this.data_property, error: e, msgs: this.error ? [...this.error.msgs, ...msgs] : msgs };
          this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
        });
      mutation = this.mutation_redirect ? this.mutation_redirect.make(
        mutation,
        p => this.series_data = [...this.series_data.filter(c => c.temp_id ? c.temp_id !== key : c.id !== p.id), p],  // data update function
        { changeMap: null },  //initial variables
        p => { // finalizing function
          this.needs_saving.delete(key);
          if (this.needs_saving.size === 0) {
            this.dispatchEvent(new CustomEvent('saved', { detail: this }));
            this.dispatchEvent(new CustomEvent('close', { detail: this }));
            this.setToDefault();
            this.saving = false;
          }
        },
        (e, msgs) => { // error handler
          this.error = { data: this.data_property, error: e, msgs: this.error ? [...this.error.msgs, ...msgs] : msgs };
          this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
        }, this) : mutation; 
      mutation.save(update.data, update.item, { success: `${mutation.friendly_name}${items.size > 1 ? 's' : ''} saved`, failure: `Failed to save contribution${items.size > 1 ? 's' : ''}` });
    })
  }


  /*
  
  
  */
  input_change(evt, ctrb, field) {
    let elem = evt.path[0];
    console.log(`${ctrb.id}[${field}]`, evt);
    console.log(evt.inputType, evt.path[0].innerText);

    //this.dispatchEvent(new CustomEvent('component-dirty', {bubbles: true, composed: true, detail: {elem: this, dirty: this.dirty, valid: this.valid, field: this.field, value: this.value}})); 


  }

  suggestNextItem() {
    return {}
  }
  insertNew() {
    let item = this.suggestNextItem();
    item.temp_id = "TEMP|" + this._next_id++;
    this.series_data = [...this.series_data, item];
    console.log("got next", item, this.series_data);
    this.requestUpdate('series_data')
  }
  handleCut(e) {
    console.log("cut", e);
    return;
    let { selectionStart, selectionEnd, selectionDirection } = this._input;
    this.updateValidity(selectionStart, selectionEnd, selectionDirection);
    return;
    //this.mergeValue('');
  }

  handlePaste(e) {
    console.log("paste", e);
    return;
    e.preventDefault();
    let paste = (e.clipboardData || window.clipboardData).getData('text');
    this.mergeValue(paste);
  }

  handleKeys(e) {
    console.log("key event", e);
    return;
    var text, code = 0, evt = e ? e : event;
    if (evt.charCode != null) code = evt.charCode;
    else if (evt.which != null) code = evt.which;
    else if (evt.keyCode != null) code = evt.keyCode;

    if (code == 0 || e.ctrlKey || e.altKey) {
      let { selectionStart, selectionEnd, selectionDirection } = this._input;
      //this.updateValidity(selectionStart, selectionEnd, selectionDirection);
      this.mergeValue();
      return;
    } else {
      if (evt.preventDefault) evt.preventDefault();
      text = String.fromCharCode(code);
      this.mergeValue(text);
      return;
    }

    //Digits, special keys & backspace [\b] work as usual:
    if (text === null) return true;
    if (evt.altKey || evt.ctrlKey || code < 28) return true;

    //Any other input? Prevent the default response:
  }

  mergeValue(input) {
    let { value, selectionStart, selectionEnd, selectionDirection } = this._input;
    let start_length = value.length;
    let val = value;
    if (input) val = value.slice(0, selectionStart) + input + value.slice(selectionEnd);
    if (this.filter) { val = this.filter(val) }
    let filtered_length = val.length;
    let diff = filtered_length - start_length;
    this._input.value = val;
    this.updateValidity(selectionStart + diff, diff > 0 ? selectionStart + diff : selectionEnd, selectionDirection);
    this.dispatchEvent(new CustomEvent('value-changed', { detail: { value: this._input.value } }));
  }

  updateValidity(selectionStart, selectionEnd, selectionDirection) {
    //let {selectionStart, selectionEnd, selectionDirection} = this._input;
    let v = this._input.checkValidity();
    if (v !== this._oldValidity) {
      this._input.blur();
      this._input.focus();
      this._input.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
    }
  }



}
export class EditContributionSeries extends SeriesEditor {
  get form_name() { return "EditContributionSeries" }
  get title() { return "Contributions" }
  get subscription_type() { return ContributionInfo; }
  get mutation_type() { return EditContributionInfo; }
  get data_property() { return this.contribution; }
  set data_property(p) { this.contribution = p; }
  get id_property() { return this.contributionid; }
  get id_property_name() { return "contributionid" }
  set id_property(id) { this.contributionid = id; }
  get series_data() { return this.contributions }
  set series_data(s) { this.contributions = s }
  get subscription_args() { return { ContributionId: this.contributionid ? this.contributionid : null } }
  get header_class() { return "contribution"; }
  get column_names() { return ['Date*', 'Employer', 'EE Ctrb', html`<span style="font-weight: 100; font-size: 85%; text-transform: none; font-style: italic;">interest</span>`, html`<span style="font-weight: 100;font-size: 85%;  text-transform: none; font-style: italic;">balance</span>`, 'Base Salary', 'Pension Salary', 'Made Up']; }
  get contributions() { return this._contributions; }
  set contributions(c) {
    this._contributions = c ? c.slice() : [];
    this._contributions = this._contributions.filter(con => !this.blacklist.has(con.id));
    this._contributions.sort((a, b) => a.date - b.date);
    this.requestUpdate("contributions");
  }
  suggestNextItem() {
    let last = this.series_data[this.series_data.length - 1];
    let d = new EventDate(last.contribution_date, 1, 'year');
    return { person_id: this.personid, date: d, contribution_date: d.str }
  }
  render_row(c) {
    const date = new EventDate(c.contribution_date);
    const recorded = this.state.state && this.state.state.contrib_history ? this.state.state.contrib_history.find(r => (new EventDate(r.date)).equals(date) && Math.round(r.amt) == Math.round(c.ee_pension_contribution)) : null;

    return html`
    <td class="date"><contrib-datecell  .value=${date} .field=${'contribution_date'} .newitem=${c.temp_id} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-datecell></td> 
    <td class="text"><contrib-enumcell  .value=${c.employer_code} .field=${'employer_code'}  .newitem=${c.temp_id} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-textcell></td> 
    <td class="money"><contrib-amtcell .value=${c.ee_pension_contribution} .field=${'ee_pension_contribution'}  .newitem=${c.temp_id} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    ${recorded && recorded.canceled > 0 && recorded.balance === 0 ?
        html`
    <td class="parenthetical" style="color: red; text-align: center;" colspan="2">(withdrawn)</td>
    `
        : html`
    <td class="money parenthetical">${recorded ? MONEY(recorded.interest) : ''}</td>
    <td class="money parenthetical">${recorded ? MONEY(recorded.balance) : ''}</td>
    `
      }
    <td class="money"><contrib-amtcell .value=${c.eoy_base_salary} .field=${'eoy_base_salary'}  .newitem=${c.temp_id} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.pension_salary} .field=${'pension_salary'}  .newitem=${c.temp_id} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="bool"><contrib-boolcell .value=${c.made_up} .field=${"made_up"}  .newitem=${c.temp_id} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-boolcell></td>
    `
  }
}
window.customElements.define('contribution-series', EditContributionSeries);



export class EditSalaryHistory extends GenericPopupEditor {
  get form_name() { return "SalaryHistoryEditor" }
  get title() { return "Salary History" }
  get subscription_type() { return SalaryHistoryInfo; }
  get mutation_type() { return EditSalaryHistoryInfo; }
  get data_property() { return this.salary; }
  set data_property(p) { this.salary = p; }
  get id_property() { return this.salaryid; }
  get id_property_name() { return "salaryid" }
  set id_property(id) { this.salaryid = id; }

  get subscription_args() { return { SalaryHistoryId: this.salaryid ? this.salaryid : null } }
  get header_class() { return "salary"; }

  static get properties() {
    return {
      salary: { type: Object },
      salaryid: { type: String },
      ...(super.properties)
    }
  }
  getForm() {
    return html`
            <div class="group">
              <kale-date .label=${"Effective Date"} .field=${'effective_date'} ?required=${this.required('effective_date')} .value=${this?.salary?.effective_date}></kale-date>
              <kale-textfield .label=${"Salary"} .field=${'salary'} ?required=${this.required('salary')} .value=${this?.salary?.salary}></kale-textfield>
            </div>
    `
  }
}
window.customElements.define('edit-salary', EditSalaryHistory);

export class EditSalarySeries extends SeriesEditor {
  get form_name() { return "EditSalarySeries" }
  get title() { return "Salary History" }
  get subscription_type() { return SalaryHistoryInfo; }
  get mutation_type() { return EditSalaryHistoryInfo; }
  get data_property() { return this.salary; }
  set data_property(p) { this.salary = p; }
  get id_property() { return this.salaryid; }
  get id_property_name() { return "salaryid" }
  set id_property(id) { this.salaryid = id; }
  get series_data() { return this.salaries }
  set series_data(s) { this.salaries = s }
  get subscription_args() { return { SalaryHistoryId: this?.salaryid } }
  get header_class() { return "salary_history"; }
  get column_names() { return ['Effective Date*', 'Salary' ]; }
  get salaries() { return this._salaries; }
  set salaries(c) {
    this._salaries = c ? c.slice() : [];
    this._salaries = this._salaries.filter(con => !this.blacklist.has(con.id));
    this._salaries.sort((a, b) => a.date - b.date);
    this.requestUpdate("salaries");
  }
  suggestNextItem() {
    let last = this.series_data[this.series_data.length - 1];
    let d = new EventDate(last.effective_date, 1, 'year');
    console.log("RETURNING", { person_id: this.personid, effective_date: d, salary: last?.salary })
    return { person_id: this.personid, effective_date: d, salary: last?.salary }
  }

  render_row(s) {
    const date = new EventDate(s.effective_date);
    return html`
    <td class="date"><contrib-datecell  .value=${s.effective_date} .field=${'effective_date'} .contrib_id=${s.id ? s.id : s.temp_id} ></contrib-datecell></td> 
    <td class="money"><contrib-amtcell .value=${s.salary} .field=${'salary'} .contrib_id=${s.id ? s.id : s.temp_id} ></contrib-amtcell></td>
    `
  }
}
window.customElements.define('salary-series', EditSalarySeries);


export class EditBenefitPaymentSeries extends SeriesEditor {
  get form_name() { return "EditBenefitPaymentSeries" }
  get title() { return "Payments" }
  get subscription_type() { return BenefitPaymentInfo; }
  get mutation_type() { return EditBenefitPaymentInfo; }
  get data_property() { return this.payment; }
  set data_property(p) { this.payment = p; }
  get id_property() { return this.paymentid; }
  get id_property_name() { return "paymentid" }
  set id_property(id) { this.paymentid = id; }
  get series_data() { return this.payments }
  set series_data(s) { this.payments = s }
  get subscription_args() { return { benefitPaymentId: this.paymentid ? this.paymentid : null } }
  get header_class() { return "payment"; }
  get column_names() { return ['Date*', 'Payee', 'Lump', 'Amount', 'Taxable', 'FIT', 'SIT', 'QDRO', 'Health', 'PEOPLE', 'CU', 'MAL', 'net']; }
  get contributions() { return this._payments; }
  set contributions(c) {
    this._payments = c ? c.slice() : [];
    this._payments = this._payments.filter(con => !this.blacklist.has(con.id));
    this._payments.sort((a, b) => a.payment_date - b.payment_date);
    this.requestUpdate("payments");
  }
  suggestNextItem() {
    let last = this.series_data[this.series_data.length - 1];
    let d = new EventDate(last.contribution_date, 1, 'month');
    return { person_id: this.personid, date: d, payment_date: d.str }
  }
  render_row(c) {
    return html`
    <td class="date"><contrib-datecell  .value=${c.payment_date} .field=${'payment_date'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-datecell></td> 
    <td class="text"><contrib-textcell  .value=${c.payee_id} .field=${'payee_id'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-textcell></td> 
    <td class="bool"><contrib-boolcell .value=${c.lump} .field=${"lump"} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-boolcell></td>
    <td class="money"><contrib-amtcell .value=${c.amount} .field=${'amount'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.taxable_amount} .field=${'taxable_amount'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.fit_withheld} .field=${'fit_withheld'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.sit_withheld} .field=${'sit_withheld'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.qdro} .field=${'qdro'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.health} .field=${'health'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.people} .field=${'people'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.credit_union} .field=${'credit_union'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.mal} .field=${'mal'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>
    <td class="money"><contrib-amtcell .value=${c.net_amount} .field=${'net_amount'} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-amtcell></td>

    `
  }
}
window.customElements.define('payment-series', EditBenefitPaymentSeries);


export class BenefitPaymentEditor extends GenericPopupEditor {
  get form_name() { return "BenefitPaymentEditor" }
  get title() { return "Benefit Payment" }
  get subscription_type() { return BenefitPaymentInfo; }
  get mutation_type() { return EditBenefitPaymentInfo; }
  get data_property() { return this.payment; }
  set data_property(p) { this.payment = p; }
  get id_property() { return this.paymentid; }
  get id_property_name() { return "paymentid" }
  set id_property(id) { this.paymentid = id; }

  get subscription_args() { return { benefitPaymentId: this.paymentid ? this.paymentid : null } }
  get header_class() { return "payment"; }

  static get properties() {
    return {
      payment: { type: Object },
      paymentid: { type: String },
      ...(super.properties)
    }
  }
  getForm() {
    // TODO: restrict the adjustable fields based on the adjustment type
    return html`
            <div class="group">
              <kale-date .label=${"Date"} .field=${'payment_date'} ?required=${this.required('payment_date')}  .value=${this.payment ? this.payment.payment_date : null}></kale-date>
              <kale-textfield  .label=${"Payee ID"} .field=${'payee_id'} ?required=${this.required('payee_id')}  .value=${this.payment ? this.payment.people : null}></kale-textfield>
            </div>
            <div class="group">
              <kale-textfield  .label=${"Amount"} .field=${'amount'} ?required=${this.required('amount')}  .value=${this.payment ? this.payment.amount : null}></kale-textfield>
              <kale-textfield  .label=${"Taxable"} .field=${'taxable_amount'} ?required=${this.required('taxable_amount')}  .value=${this.payment ? this.payment.taxable_amount : null}></kale-textfield>
              <kale-textfield  .label=${"FIT withheld"} .field=${'fit_withheld'} ?required=${this.required('fit_withheld')}  .value=${this.payment ? this.payment.fit_withheld : null}></kale-textfield>
              <kale-textfield  .label=${"SIT withheld"} .field=${'sit_withheld'} ?required=${this.required('sit_withheld')}  .value=${this.payment ? this.payment.sit_withheld : null}></kale-textfield>
              <kale-textfield  .label=${"QDRO"} .field=${'qdro'} ?required=${this.required('qdro')}  .value=${this.payment ? this.payment.qdro : null}></kale-textfield>
              <kale-textfield  .label=${"Health"} .field=${'health'} ?required=${this.required('health')}  .value=${this.payment ? this.payment.health : null}></kale-textfield>
              <kale-textfield  .label=${"PEOPLE"} .field=${'people'} ?required=${this.required('people')}  .value=${this.payment ? this.payment.people : null}></kale-textfield>
              <kale-textfield  .label=${"Credit Union"} .field=${'credit_union'} ?required=${this.required('credit_union')}  .value=${this.payment ? this.payment.credit_union : null}></kale-textfield>
              <kale-textfield  .label=${"MAL"} .field=${'mal'} ?required=${this.required('mal')}  .value=${this.payment ? this.payment.mal : null}></kale-textfield>
            </div>
            <div class="group">
              <kale-textfield  .label=${"Net Amount"} .field=${'net_amount'} ?required=${this.required('net_amount')}  .value=${this.payment ? this.payment.net_amount : null}></kale-textfield>
            </div>
    `
  }
}
window.customElements.define('edit-payment', BenefitPaymentEditor);
/*
              <kale-enum
                .label=${"Adjustment Type"}
                .field=${'adjustment_type_code'}
                ?required=${this.required('adjustment_type_code')}
                .value=${this.adjustment ? this.adjustment.adjustment_type.code : null}
                .default=${this.adjustment ? this.adjustment.adjustment_type.code : null}
              ></kale-enum>
    id
    person_id
    payee_id
    payee {
      id
      first_name
      last_name
    }
    payment_date
    lump
    amount
    taxable_amount
    fit_withheld
    sit_withheld
    qdro
    health
    people
    credit_union
    mal
    net_amount
  */


export class EditAdjustmentSeries extends SeriesEditor {
  get form_name() { return "EditAdjustmentSeries" }
  get title() { return "Part-Time Hour Credit" }
  get subscription_type() { return ServiceAdjustmentInfo; }
  get mutation_type() { return EditServiceAdjustmentInfo; }
  get data_property() { return this.adjustment; }
  set data_property(p) { this.adjustment = p; }
  get id_property() { return this.adjustmentid; }
  get id_property_name() { return "adjustmentid" }
  set id_property(id) { this.adjustmentid = id; }
  get series_data() { return this.adjustments }
  set series_data(s) { this.adjustments = s }
  get subscription_args() { return { AdjustmentId: this.adjustmentid ? this.adjustmentid : null } }
  get header_class() { return "part_time_hours"; }
  get column_names() { return ['Date*', 'Hours']; }
  get contributions() { return this._adjustments; }
  set contributions(c) {
    this._adjustments = c ? c.slice() : [];
    this._adjustments = this._adjustments.filter(con => !this.blacklist.has(con.id));
    this._adjustments.sort((a, b) => a.date - b.date);
    this.requestUpdate("adjustments");
    this.requestUpdate("series_data");
  }
  suggestNextItem() {
    let next = { ...this.series_data[this.series_data.length - 1], id: undefined };
    next.adjustment_date = new EventDate(next.adjustment_date, 1, 'year');
    console.log("suggesting a new credit", next);
    return next; // { person_id: this.personid, date: d, adjustment_date: d.str }
  }
  render_row(c) {
    return html`
    ${['adjustment_date', 'service_hours'].map((f, i) => {
      return i === 0 ?
        html`<td class="date"><contrib-datecell  .value=${c[f]} .field=${f} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-datecell></td>` :
        html`<td class="text"><contrib-numbercell .value=${c[f]} .field=${f} .contrib_id=${c.id ? c.id : c.temp_id} ></contrib-numbercell></td>`
    })}
    `
  }
}
window.customElements.define('adjustment-series', EditAdjustmentSeries);



const adjustment_not_required = new Set(['service_hours', 'service_years', 'credited_service_years', 'notes']);

export class AdjustmentEditor extends GenericPopupEditor {
  get form_name() { return "AdjustmentEditor" }
  get title() { return "Manual Service Adjustment" }
  get subscription_type() { return ServiceAdjustmentInfo; }
  get mutation_type() { return EditServiceAdjustmentInfo; }
  get data_property() { return this.adjustment; }
  set data_property(p) { this.adjustment = p; }
  get id_property() { return this.adjustmentid; }
  get id_property_name() { return "adjustmentid" }
  set id_property(id) { this.adjustmentid = id; }

  get subscription_args() { return { AdjustmentId: this.adjustmentid ? this.adjustmentid : null } }
  get header_class() { return "manual_adjustment"; }

  required(field) { return !adjustment_not_required.has(field) }
  static get properties() {
    return {
      adjustment: { type: Object },
      adjustmentid: { type: String },
      ...(super.properties)
    }
  }
  getForm() {
    // TODO: restrict the adjustable fields based on the adjustment type
    return html`
            <div class="group">
              <kale-enum
                .label=${"Adjustment Type"}
                .field=${'adjustment_type_code'}
                ?required=${this.required('adjustment_type_code')}
                .value=${this.adjustment ? this.adjustment.adjustment_type.code : null}
                .default=${this.adjustment ? this.adjustment.adjustment_type.code : null}
              ></kale-enum>
              <kale-date .label=${"Applied"} .field=${'adjustment_date'} ?required=${this.required('adjustment_date')}  .value=${this.adjustment ? this.adjustment.adjustment_date : null}></kale-date>
            </div>
            <div class="group">
              <kale-textfield  .label=${"Service Hours"} .field=${'service_hours'} ?required=${this.required('service_hours')}  .value=${this.adjustment ? this.adjustment.service_hours : null}></kale-textfield>
              <kale-textfield  .label=${"Vesting Years"} .field=${'service_years'} ?required=${this.required('service_years')}  .value=${this.adjustment ? this.adjustment.service_years : null}></kale-textfield>
              <kale-textfield  .label=${"Credited Service Years"} .field=${'credited_service_years'} ?required=${this.required('credited_service_years')}  .value=${this.adjustment ? this.adjustment.credited_service_years : null}></kale-textfield>
            </div>
            <div class="group">
              <kale-textfield  .label=${"Notes"} .field=${'notes'} ?required=${this.required('notes')}  .value=${this.adjustment ? this.adjustment.notes : null}></kale-textfield>
            </div>
    `
  }
}
window.customElements.define('edit-adjustment', AdjustmentEditor);

const MONEY = (amt) => amt?.toLocaleString([], { style: 'currency', currency: 'USD' }) || amt;

export class WithdrawalEditor extends GenericPopupEditor {
  get form_name() { return "WithdrawalEditor" }
  get title() { return "Contribution Withdrawal" }
  get subscription_type() { return WithdrawalInfo; }
  get mutation_type() { return EditWithdrawalInfo; }
  get data_property() { return this.withdrawal; }
  set data_property(p) { this.withdrawal = p; }
  get id_property() { return this.withdrawalid; }
  get id_property_name() { return "withdrawalid" }
  set id_property(id) { this.withdrawalid = id; }
  
  get subscription_args() { return { WithdrawalId: this.withdrawalid ? this.withdrawalid : null }; }
  get header_class() { return "withdrawal"; }
  set withdrawal(w) {
    this._w = w;
    if (w && w.id) {
        w.user_entered_fields = ["interest", "total", "taxable"];
        this.fields.interest.value = w.interest;
        this.fields.total.value = w.total;
        this.fields.taxable.value = w.taxable;
        this.fields.contribution.value = parseFloat(w.total - w.interest).toFixed(2);
        this.fields.nonTaxable.value = parseFloat(w.total - w.taxable).toFixed(2);
      // Set user-entered fields
      w.user_entered_fields.forEach(f => {
        this.fields[f].user = true;
      });
  
      // Recompute fields if necessary
      this.recompute_fields();
    }
    this.requestUpdate("withdrawal");
  }
  get withdrawal(){return this._w }

  required(f) {
    if (!this.fields[f]?.user && this.computed[f] !== undefined) {
      return false; // If user hasn't entered value and it's a computed field, it's not required
    }
    return true; // Otherwise, the field is required
  }
  static get properties() {
    return {
      // withdrawal: { type: Object },
      withdrawalid: { type: String },
      predicted: { type: Object },
      computed: { type: Object },
      ...super.properties,
    };
  }

  constructor() {
    super();
    this.computed = {};

    this.fields = {
      total: {
        value: null,
        user: null,
        f: (fields) => {
          if (fields.total.value !== null) return fields.total.value;
          if (fields.interest.value !== null && fields.contribution.value !== null) {
            return (parseFloat(fields.contribution.value) + parseFloat(fields.interest.value));
          }
          if (fields.taxable.value !== null && fields.nonTaxable.value !== null) {
            return (parseFloat(fields.taxable.value) + parseFloat(fields.nonTaxable.value));
          }
          return null;
        },
      },
      taxable: {
        value:  null,
        user: null,
        f: (fields) => {
          if (fields.taxable.value !== null) return fields.taxable.value;
          if (fields.nonTaxable.value !== null && fields.total.value !== null) {
            return (parseFloat(fields.total.value) - parseFloat(fields.nonTaxable.value));
          }
          return null;
        },
      },
      nonTaxable: {
        value: null,
        user: null,
        f: (fields) => {
          if (fields.nonTaxable.value !== null) return fields.nonTaxable.value;
          if (fields.taxable.value !== null && fields.total.value !== null) {
            return (parseFloat(fields.total.value) - parseFloat(fields.taxable.value));
          }
          return null;
        },
      },
      interest: {
        value:  null,
        user: null,
        f: (fields) => {
          if (fields.interest.value !== null) return fields.interest.value;
          if (fields.contribution.value !== null && fields.total.value !== null) {
            return (parseFloat(fields.total.value) - parseFloat(fields.contribution.value));
          }
          return null;
        },
      },
      contribution: {
        value: null,
        user: null,
        f: (fields) => {
          if (fields.contribution.value !== null) return fields.contribution.value;
          if (fields.interest.value !== null && fields.total.value !== null) {
            return (parseFloat(fields.total.value) - parseFloat(fields.interest.value));
          }
          return null;
        },
      }
    };
  }

  handleInputChange(event) {
    const field = event.target.field;
    const value = event.target.value !== null && event.target.value!=='' ? event.target.value : null ;
    this.fields[field].user = value !== null;
    this.fields[field].value = value;
    this.recompute_fields();
  }

  recompute_fields() {
    // Reset non-user fields to null
    Object.entries(this.fields).forEach(([key, val]) => {
      if (!val.user) {
        val.value = null;
      }
    });

    Object.entries(this.fields).forEach(([key, val]) => {
      if (val.f) {
        let computedValue = val.f(this.fields);
        if (computedValue !== null && !val.user) {
          val.value = parseFloat(computedValue).toFixed(2);
        } else {
          val.value = computedValue;
        }
      }
    });
  
    // Update computed fields
    this.computed = Object.fromEntries(
      Object.entries(this.fields)
        .filter(([key, val]) => val.value != null && !val.user)
        .map(([key, val]) => [key, val.value])
    );
   // Mark fields as dirty and valid/invalid

  Object.entries(this.fields)
  .filter(([key, val]) => (val.value != null  && !val.user)||val.value===0 )
  .forEach(([key, val]) => {
    this.dirty_map.set(key, {
      dirty: true,
      elem: key ? key : this.renderRoot.getElementById(key),
      field: key,
      value: val.value
    });
    this.invalid_map.delete(key);
  });
  console.log("this.dirty_map",this.dirty_map);
  console.log("this.invalid_map",this.invalid_map);
    this.dispatchDirty(this.dirty_map.size > 0, this.invalid_map.size === 0, this.dirty_map);
    }
  
  isDisabled(field) {
    return !this.fields[field].user && this.computed[field] !== undefined;
  }

  isTVProjectWithdrawal() {
    return this.withdrawal?.withdrawal_type_code === 'TV';
  }
  
  handleWithdrawalTypeChange(e) {
    const newType = e.detail.index;  // Assuming e.detail.index holds the correct value
    
    // Define variables for withdrawal type code and name
    let withdrawalTypeCode = '';
    let name = '';
  
    // Determine the withdrawal type code and name based on newType
    if (newType === 0) {
      withdrawalTypeCode = 'SVC';
      name = 'In-Service Withdrawal';
    } else if (newType === 1) {
      withdrawalTypeCode = 'RET';
      name = 'Retirement Withdrawal';
    } else if (newType === 2) {
      withdrawalTypeCode = 'TV';
      name = 'TV Project Withdrawal';
      this.invalid_map.delete('interest');
      this.invalid_map.delete('contribution')
    } else if (newType === 3) {
      withdrawalTypeCode = 'TERM';
      name = 'Termed Withdrawal';
    }
  
    // Update the withdrawal object with new withdrawal type and code
    this.withdrawal = {
      ...this.withdrawal,
      withdrawal_type: { name: name, code: withdrawalTypeCode },
      withdrawal_type_code: withdrawalTypeCode
    }; 
    // Request update to reflect changes in UI or backend
    this.requestUpdate();
    if (withdrawalTypeCode !== 'TV') {
      if (!this.dirty_map.has('interest') && !this.invalid_map.has('interest')) {
        this.invalid_map.set('interest', {
          elem: this.renderRoot.getElementById('interest'),
          field: 'interest',
          value: this.fields.interest.value
        });
      }
      if (!this.dirty_map.has('contribution') && !this.invalid_map.has('contribution')) {
        this.invalid_map.set('contribution', {
          elem: this.renderRoot.getElementById('contribution'),
          field: 'contribution',
          value: this.fields.contribution.value
        });
      }
    }
  }
  getForm() {
    return html`
    <div class="group">
        <kale-date 
          .label=${"Date"} 
          .field=${'withdrawal_date'} 
          ?required=${this.required('withdrawal_date')}  
          .value=${this.withdrawal ? this.withdrawal.withdrawal_date : null}
        ></kale-date>
        <kale-enum
          .label=${"Withdrawal Type"}
          .field=${'withdrawal_type_code'}
          ?required=${this.required('withdrawal_type')}
          .default=${this.withdrawal ? this.withdrawal.withdrawal_type_code :null}
          .value=${this.withdrawal ? this.withdrawal.withdrawal_type_code : null}
          @selected=${this.handleWithdrawalTypeChange}
        ></kale-enum>
      </div>
        <div class="group">
          <kale-textfield 
          id="taxable" 
            .label=${"Taxable"} 
            .field=${'taxable'} 
            ?required=${this.required('taxable')}  
            .value=${this.fields.taxable.value ?? null}
            @input=${this.handleInputChange}
            ?disabled=${this.isDisabled('taxable')}
          ></kale-textfield>
          <kale-textfield 
            id="nonTaxable"
            .label=${"Non-Taxable"} 
            .field=${'nonTaxable'}  
            ?required=${this.required('nonTaxable')}  
            .value=${this.fields.nonTaxable.value ?? null}
            @input=${this.handleInputChange}
            ?disabled=${this.isDisabled('nonTaxable')}
          ></kale-textfield>
          <kale-textfield  
            id="total"
            .label=${"Total Amount"} 
            .field=${'total'} 
            ?required=${this.required('total')} 
            .value=${this.fields.total.value ?? null}
            @input=${this.handleInputChange}
            ?disabled=${this.isDisabled('total')}
          ></kale-textfield>
        </div>
        <div class="group">
          <kale-textfield  
            id="contribution"
            .label=${"Contribution"} 
            .field=${'contribution'} 
            ?required=${this.isTVProjectWithdrawal() ?false : this.required('contribution')}
            .value=${this.fields.contribution.value ?? null}
            @input=${this.handleInputChange}
            ?disabled=${this.isDisabled('contribution')}
              style=${this.isTVProjectWithdrawal() ? 'visibility: hidden;' : 'visibility: visible;'}
          ></kale-textfield>
          <kale-textfield 
            id="interest"  
            .label=${"Interest"}
            .field=${'interest'}
            ?required=${this.isTVProjectWithdrawal() ?false :this.required('interest')}
            .value=${this.fields.interest.value ?? null}
            @input=${this.handleInputChange}
            ?disabled=${this.isDisabled('interest')}
              style=${this.isTVProjectWithdrawal() ? 'visibility: hidden;' : 'visibility: visible;'}
          ></kale-textfield>
        </div>
    `;
  }
  save_impl(formdata) {
    let data;
    // Check if withdrawal_type_code is 'TV'
    if (formdata.withdrawal_type_code === 'TV') {
      data = {
        withdrawal_date: formdata.withdrawal_date, 
        withdrawal_type_code: 'TV',
        taxable: this.fields.taxable.value,
        interest:0,
        total: this.fields.total.value  ,
        user_entered_fields: ['nontaxable','contribution']  // Replace with your logic for nontaxable
      };
      this.deleting = null;
    } else {
      data = {
        withdrawal_date: formdata.withdrawal_date, 
        withdrawal_type_code: formdata.withdrawal_type_code,
        taxable: this.fields.taxable.value,
        total: this.fields.total.value,
        interest:this.fields.interest.value,
        user_entered_fields: Object.entries(this.fields)
          .filter(([key, val]) => val.user)
          .map(([key, val]) => key)
      };
      this.deleting = null;
    }
    // Ensure person_id is set if not already
    if (!data.person_id) {
      data.person_id = this.personid;
    }
  
    console.log(`${this.form_name}.save_impl()...`, data, this.personid);
    this.mutation.save(data, this.data_property, this.save_message, this.defaults);
  }
}
window.customElements.define('edit-withdrawal', WithdrawalEditor);

export class WithdrawalRepaymentEditor extends GenericPopupEditor {
  get form_name() { return "WithdrawalRepaymentEditor" }
  get title() { return "Withdrawal Repayment" }
  get subscription_type() { return WithdrawalRepaymentInfo; }
  get mutation_type() { return EditWithdrawalRepaymentInfo; }
  get data_property() { return this.repayment; }
  set data_property(p) { this.repayment = p; }
  get id_property() { return this.repaymentid; }
  get id_property_name() { return "repaymentid" }
  set id_property(id) { this.repaymentid = id; }

  get subscription_args() { return { WithdrawalRepaymentId: this.withdrawalid ? this.withdrawalid : null } }
  get header_class() { return "withdrawal"; }

  required(f) { return true; } // all fields required

  static get properties() {
    return {
      withdrawal: { type: Object },
      withdrawalid: { type: String },
      ...(super.properties)
    }
  }
  getForm() {
    return html`
            <div class="group">
              <kale-date .label=${"Date"} .field=${'repayment_date'} ?required=${this.required('repayment_date')}  .value=${this.repayment ? this.repayment.repayment_date : null}></kale-date>
            </div>
            <div class="group">
              <kale-textfield  .label=${"Original Total Amount"} .field=${'total'} ?required=${this.required('total')}  .value=${this.repayment ? this.repayment.total : null}></kale-textfield>
            </div>
            <div class="group">
              <kale-textfield  .label=${"Additional Interest"} .field=${'interest'} ?required=${this.required('interest')}  .value=${this.repayment ? this.repayment.interest : null}></kale-textfield>
            </div>
    `
  }
}
window.customElements.define('edit-withdrawalrepayment', WithdrawalRepaymentEditor);



export class ParticipationChangeEditor extends GenericPopupEditor {
  get form_name() { return "ParticipationChangeEditor" }
  get title() { return "Participation Change" }
  get subscription_type() { return ParticipationChangeInfo; }
  get mutation_type() { return EditParticipationChangeInfo; }
  get data_property() { return this.participation_change; }
  set data_property(p) { this.participation_change = p; }
  get id_property() { return this.participation_change_id; }
  get id_property_name() { return "participation_change_id" }
  set id_property(id) { this.participation_change_id = id; }

  get subscription_args() { return { ParticipationChangeId: this.participation_change_id ? this.participation_change_id : null } }
  get header_class() { return "participation"; }

  static get properties() {
    return {
      participation_change: { type: Object },
      participation_change_id: { type: String },
      ...(super.properties)
    }
  }
  getForm() {
    return html`
            <div class="group">
              <kale-date .label=${"Form Date"} .field=${'form_date'} ?required=${this.required('form_date')}  .value=${this.participation_change ? this.participation_change.form_date : null}></kale-date>
              <kale-enum
                .label=${"Action"}
                .field=${'participation_change_type_code'}
                ?required=${this.required('participation_change_type_code')}
                .value=${this.participation_change ? this.participation_change.participation_change_type_code : null}
                .default=${this.participation_change ? this.participation_change.participation_change_type_code : null}
              ></kale-enum>
            </div>
    `
  }
}
window.customElements.define('edit-participation', ParticipationChangeEditor);


export const editors = {
  "employment": EmploymentEditor,
  "suspension": SuspensionEditor,
  "benefit": BenefitEditor,
  "beneficiary": BeneficiaryEditor,
  "contribution": ContributionEditor,
  "service_adjustment": AdjustmentEditor,
  "benefit_payment": BenefitPaymentEditor,
  "withdrawal": WithdrawalEditor,
  "withdrawal_repayment": WithdrawalRepaymentEditor,
  "participation_change": ParticipationChangeEditor,
  "salary_history": EditSalaryHistory

}

export const series_editors = {
  "benefit_payment": null,
  "contribution": EditContributionSeries,
  "benefit_payment": EditBenefitPaymentSeries,
  "salary_history": EditSalarySeries,
  "service_adjustment": EditAdjustmentSeries,
}
