import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * Email  
 */

export const email_fields = gql`
  fragment EmailFields on email {
    id
    person_id
    email_status_code
    email  
    status {
      code
      name
    }
   }`


export const email_info_query = gql`
  query email_info($EmailId: uuid!){
    email( where: {id: {_eq: $EmailId}} ){
         ...EmailFields
    }
  }
  ${email_fields}
`

export class EmailInfo extends StaticQuery {
  get required_impl() {
    return ['EmailId'];
  }
  get gql_impl() {
    return email_info_query;
  }
  data_impl({ data: { email: [d] } }) {
    return d;
  }
}


export const emails_for_person_query = gql`
  query email_info($PersonId: uuid!) {
    email (
        where: {person_id: {_eq: $PersonId}}
        order_by: {email: asc}
        ){
        ...EmailFields
    }
  }
  ${email_fields}
  `

export class PersonEmail extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return emails_for_person_query;
  }
  data_impl({ data: { email: emails } }) {
    return emails;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_email_query = gql`
  mutation update_email($emailId: uuid!, $changeMap: email_insert_input!) {
    update_email (
      where: {
          id: {_eq: $emailId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...EmailFields
      }
    }
  }
  ${email_fields}
`
export const insert_email_query = gql`
  mutation insert_email($changeMap: email_insert_input!) {
    insert_email (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...EmailFields
      }
    }
  }
  ${email_fields}
`

export const upsert_email_query = gql`
  mutation upsert_email($changeMap: email_insert_input!, $changeCols: [email_update_column!]!) {
    email:insert_email (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: email_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...EmailFields
      }
    }
  }
  ${email_fields}
`

export const delete_email_query = gql`
  mutation delete_email($deleteId: uuid!) {
    delete_email (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditEmailInfo extends SaveableQuery {
  get friendly_name() { return "Email" }
  get required_keys_impl() {
    return [
      "person_id",
      "email"
    ];
  }
  // FIXME: complains on delete because the email property is null
  data_impl({ data: { email: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_email_query;
  }
  get delete_gql_impl() {
    return delete_email_query;
  }
  get save_queries_impl() {
    return [(new PersonEmail(null, null))];
  }
}

