// synced with prod
// FIXME: sorting

import { LitElement, html, css } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { timeline_colors, transitions } from "./colors.js";
import { shadows, colors } from "../shared-components/styles.js";

const getjsdate = (d, xl, offset) => {
  let ret = null;
  if (d) {
    let jd;
    if (typeof (d) === 'object' && d.constructor.name === 'Date') {
      jd = d
      //ret = d.toLocaleDateString();
    } else {
      jd = d.match && d.match(/\+/) ? (new Date(d)) : (new Date(d + 'EST'));
    }
    if (offset !== undefined) {
      jd.setHours(jd.getHours() + offset);
    }
    ret = jd//.toLocaleDateString();
  }
  return ret;
}

const todate = (d, xl, offset) => {
  let jd = getjsdate(d, xl, offset);
  return jd?.toLocaleDateString?.();
}

const tomoney = (amt) => amt !== null && amt !== undefined && typeof (amt) === 'number' ? amt.toLocaleString([], { style: 'currency', currency: 'USD' }) : '$UNKNOWN';
// SELECT pid, pg_terminate_backend(pid) FROM pg_stat_activity WHERE state = 'active' and query not ilike '%MAGIC%';

const text_to_like = arg => `%${String(arg).toLowerCase().replace(/[^a-z0-9 ]/g, '').split(' ').join('%')}%`;
const text_to_number = arg => { let a = arg.replace(/[^0-9.]/g, ''); let n = a !== '' ? Number(a) : undefined; return n === undefined || isNaN(n) ? undefined : n; };
const text_to_date = arg => new Date(arg + 'EST').toLocaleDateString();
const date_to_text = val => val && val.getYear ? val.toLocaleDateString() : val;
const like_to_text = val => val.replace(/%/g, ' ').trim();

const coltypes = {
  person_link: { diff: false, text: true },
  date: { diff: true, number: false, ordered: true },
  number: { diff: true, number: true, ordered: true },
  int: { diff: true, number: true, ordered: true },
  money: { diff: true, number: true, ordered: true },
  decimal: { diff: true, number: true, ordered: true },
  ltext: { diff: false, text: true },
  bool: { diff: false, text: false },
  ssn: { diff: false, text: true },
  ssn9: { diff: false, text: true },
  ctext: { diff: false, text: true },
  match: { diff: false, text: false },
}


const format_ssn = ssn => {
  ssn = ssn.replace(/[^\d]/g, '');
  //return ssn.replace(/(\d\d\d)(\d\d)(\d*)/g, "$1-$2-●●●●");
  return ssn.replace(/(\d\d\d)(\d\d)(\d*)/g, "$1-$2-$3");
}

const returnFirstFiveSSN = (ssn) => {
  const regex = /(\d{3})-?(\d{2})-?\d+/
  const obfuscated =  ssn.replace(regex, (match, group1, group2) => {
    return `${group1}-${group2}-●●●●`
  });

  return obfuscated
}

export const formatters = {
  person_link: (celldata, fieldinfo, row) => html`<a href="/people/view?person=${row.id}">${celldata}</a>`,
  date: celldata => todate(celldata),
  number: celldata => celldata,
  bool: celldata => `${celldata ? 'Y' : 'N'}`,
  int: celldata => celldata,
  ssn: celldata => `●●●-●●-${celldata}`,
  ssn9: celldata => `${returnFirstFiveSSN(celldata)}`,
  money: celldata => celldata ? tomoney(celldata) : celldata,
  decimal: (celldata, fieldinfo) => celldata ? celldata.toFixed(fieldinfo.decimals) : celldata,
  text: celldata => celldata,
  ltext: celldata => celldata,
  ctext: celldata => celldata
}

const XLSX_NULL = { v: null, t: 'z' };
export const xlsx_formatters = {
  person_link: (celldata, fieldinfo, row) => {
    //console.log("person link", celldata, fieldinfo, row);
    let h = html`<a href="https://benefits.afscme.org/people/view?person=${row.id}">${celldata}</a>`
    return celldata ? { v: celldata, t: 's', h: h, l: { Target: `https://benefits.afscme.org/people/view?person=${row.id}`, Tooltip: celldata } } : XLSX_NULL;
  },
  date: celldata => {
    let d = getjsdate(celldata, true);
    //let str = d ? d.toLocaleDateString() : null;
    let converted = d ? 25569.0 + ((d.getTime() - (d.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24)) : null;
    let ret = celldata ? { v: converted, t: 'n', z: 'mm-dd-yyyy' } : XLSX_NULL;
    return ret;
  },
  ssn: celldata => {
    return celldata ? { v: `•••-••-${celldata}`, t: 's' } : XLSX_NULL;
  },
  ssn9: celldata => {
    return celldata ? { v: `${format_ssn(celldata)}`, t: 's' } : XLSX_NULL;
  },
  number: celldata => {
    return celldata ? { v: celldata, t: 'n' } : XLSX_NULL;
  },
  bool: celldata => {
    return celldata ? { v: celldata ? 'Y' : 'N', t: 's' } : XLSX_NULL;
  },
  int: celldata => {
    return celldata ? { v: celldata, t: 'n' } : XLSX_NULL;
  },
  money: celldata => {
    return celldata ? { v: celldata, t: 'n', z: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00' } : XLSX_NULL;
  },
  decimal: (celldata, fieldinfo) => {
    return celldata ? { v: celldata, t: 'n' } : XLSX_NULL;
  },
  text: celldata => {
    return celldata ? { v: celldata, t: 's' } : XLSX_NULL;
  },
  ltext: celldata => {
    return celldata ? { v: celldata, t: 's' } : XLSX_NULL;
  },
  ctext: celldata => {
    return celldata ? { v: celldata, t: 's' } : XLSX_NULL;
  }
}


const column_header_style = css`
        :host {
          ${transitions}
          ${timeline_colors}
          ${shadows}
          width: 100%;
        }

        div.dropdown-wrap {
          position: absolute;
          top: 100%;
          left: 0;
          height: fit-content;
          overflow: visible;
         /* padding: 100vw;
          margin-left: -100vw;
          */
          padding-top: 0px;
          /*
          padding-top: 5px;
          margin-top: -15px;
          background-color: rgba(0, 0, 0, 0.1);
          background-color: rgba(200, 40, 40, 0.2);
          */
          transform: translateY(-120%);
          transition: translate 0s jump-end;
          transition-delay: 0.3s;
        }
        div.dropdown-wrap[show] {
          transform: translateY(0);
          transition: translate 0s jump-end;
          transition-delay: 0s;
        }
        div.dropdown { 
          background-color: white;
          border-radius: 6px;
          opacity: 0;
          transform: translateY(-120%);
          box-shadow: var(--shadow-elevated);
          padding: 10px;
          transition: var(--transform-transition), var(--opacity-transition);
          transition-duration: 0.15s;
          text-align: left;
          white-space: normal;
          text-transform: none;
        }
        div.dropdown[show] {
          opacity: 1;
          transform: translateY(0);
          z-index: 202;
        }

        .wrap > mwc-icon {
          opacity: 0.75;
        }


        div.wrap {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row; 
          transform: translate(0,0);
          white-space: nowrap;
        }

        div.wrap[show] {
          z-index: 201;
        }
        .wrap.money, .wrap.int, .wrap.number, .wrap.decimal, .wrap.date {
          justify-content: flex-end;
        }

        .wrap.ctext {
          justify-content: center;
        }
        .wrap.ltext, .wrap.ssn {
          justify-content: flex-start;
        }

        .scrim {
          position: fixed;
          top: 100%;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: black;
          opacity: 0.4;
          z-index: 200;
        }
        h5 {
          display: block;
          white-space: nowrap;
        }

        .sort {
          font-weight: 100;
          display: inline-block;
          background-color: none;
          border: 1px solid var(--paper-grey-700);
          border-radius: 12px;
          padding: 0px 8px 6px 6px;
          margin: 4px;
          color: var(--paper-grey-700);
          white-space: nowrap;

        }
        .sort[active] {
          font-weight: 800;
          color: white;
          border: 1px solid var(--paper-green-700);
          background-color: var(--paper-green-700);
          }
        .sort > mwc-icon {
          margin-right: 6px;
          opacity: 0.5;
        }
        .sort[active] > mwc-icon {
          opacity: 1;
        }
        .sort > mwc-icon[reverse] {
          transform: scaleY(-1);
        }

        .filter {
          white-space: nowrap;
          width: 250px;
        }


        .title {
          font-weight: 400;
          color: var(--paper-grey-100);
          /*color: var(--header-color, var(--paper-grey-100));*/
        }
        .title[active] {
          font-weight: 900;
          color: var(--paper-grey-900);
        }
        span.direction {
          font-weight: 100;
          font-size: 80%;
          position: relative;
          top: -0.1em;
          margin-left: 1em;
        }
        .section {
          position: relative;
          padding: 8px;
          border: 1px solid var(--paper-grey-400);
          border-radius: 6px;
          margin: 8px;
        }

        .section > h6 {
          position: absolute;
          top: calc(-2em - 11px);
          left: 1em;
          background-color: white;
          padding: 3px;
        }

        .radio-group {
          white-space: normal;
        }

        div.actions {
          width: 100%;
          text-align: right;
        }

        .reveal {
          transition: var(--transform-transition);
        }
        .reveal[revealed] {
          transform: rotate(-180deg);
        }


        mwc-menu {
          position: fixed;
          top: 1em;
          left: 0;
        }
`;

export class ColumnHeader extends LitElement {
  static styles = column_header_style
  constructor() {
    super();
    this.filter_temp = new Map();
    this.sort_temp = new Map();
  }
  static get properties() {
    return {
      def: { type: Object },
      show: { type: Boolean },
      sort: { type: Array },
      filter: { type: Array }
    };
  }

  set def(d) {
    this.__def = d;
    this.__cols = d.compare ? ['sys', 'ref', 'diff', 'match'].map(c => `${d.c}_${c}`) : [d.c];
    this.__col_set = new Set(this.__cols);
    this.requestUpdate('def');
  }
  get def() { return this.__def; }

  set sort(s) {
    this.__sort = s;
    this.__sort_active = s ? this.__col_set && s.some(s => this.__col_set.has(s.col)) : [];
    this.requestUpdate('sort');
  }
  get sort() { return this.__sort; }

  set filter(f) {
    this.__filter = f;
    this.__filter_active = f ? this.__col_set && f.some(f => this.__col_set.has(f.col)) : [];
    this.requestUpdate('filter');
  }
  get filter() { return this.__filter; }

  get active() { return this.__filter_active || this.__sort_active; }

  firstUpdated() {
    //window.addEventListener('toggle-header', e => { console.log("got toggle", e.detail, this.def.c); if (e.detail !== this.def.c) this.show = false });
    this.addEventListener('click', e => this.toggleShow(e));
  }


  renderMenu() {

  }

  renderPage() {
    return html``
  }

  showMenu() {
    const menu = this.renderRoot.getElementById('menu');
    menu.anchor = this.renderRoot.getElementById('anchor');
    console.log("SHOW", menu, menu.anchor);
    menu.show();
  }
  hideMenu() {
    const menu = this.renderRoot.getElementById('menu');
    console.log("HIDE", menu);
    menu.close();
  }

  toggleShow(e) {
    e.stopPropagation();
    this.show = !this.show;

    if (this.show) {
      console.log("showing")
      this.showMenu();
    } else {
      console.log("hiding")
      this.hideMenu();
    }

    this.filter_temp = new Map();
    this.sort_temp = new Map(this.sort.filter(s => this.__col_set.has(s.col)).map(s => [s.col, { col: s.col, reverse: s.reverse }]));
    //sort: [{ col: 'group', reverse: false }, { col: 'name', reverse: false }, { col: 'person.id', reverse: false }],
    // if (this.show) this.dispatchEvent(new CustomEvent('show-header', { detail: { col: this.def.c, hide: (c) => { if (c !== this.def.c) this.hideMenu(); } } }));
  }
  /*
          return html`<mwc-list-item @request-selected=${action} graphic="icon">${icon ? html`<mwc-icon slot="graphic">${icon}</mwc-icon>` : ''}${name}</mwc-list-item>`

    <div class="dropdown-wrap" ?show=${this.show}>
      <div class="dropdown" ?show=${this.show} @click=${e => e.stopPropagation()}>
      <div class="section">
        <h6>sort by</h6>
        ${sorts.map(s => this.renderSort(s))}
      </div>
      <div class="section">
        <h6>filter</h6>
        ${filters.map(f => this.renderFilter(f))}
      </div>
      <div class="actions">
        <mwc-button @click=${e => this.applyOpts()}>apply</mwc-button>
      </div>
    </div>


<div class="section">
        <h6>sort by</h6>
        ${sorts.map(s => this.renderSort(s))}
      </div>
      <div class="section">
        <h6>filter</h6>
        ${filters.map(f => this.renderFilter(f))}
      </div>
      <div class="actions">
        <mwc-button @click=${e => this.applyOpts()}>apply</mwc-button>
      </div>
  */

  render() {
    let sorts = this.show ? this.getSorts() : [];
    let filters = this.show ? this.getFilters() : [];
    return html`
    <div class=${`wrap ${this.def.t}`} ?compare=${this.def.compare} ?show=${this.show}>
      <span id="anchor" class=${`title ${this.def.t}`} ?active=${this.active}>${this.def.a ? this.def.a : this.def.c}</span>
      <mwc-icon class="reveal" ?revealed=${this.show}>arrow_drop_down</mwc-icon>
    </div>
    <mwc-menu absolute corner="BOTTOM_START" id="menu" @close=${e=> console.log("closed")}>
      ${sorts.map(s => html`
      <mwc-list-item graphic="icon">
        <mwc-icon slot="graphic">check</mwc-icon>
        <span>Sort</span>
      </mwc-list-item>
      `)}
      <li divider role="separator"></li>
      ${filters.map(f => html`
      <mwc-list-item graphic="icon">
        <mwc-icon slot="graphic">check</mwc-icon>
        <span>Filter</span>
      </mwc-list-item>
      `)}
    
    </mwc-menu>
    `
  }
  //<h5><mwc-icon>filter_list</mwc-icon>filter</h5>
  //${this.show ? html`<div class="scrim" @click=${e => this.show = false}></div>` : html``}
  setSort(e, col) {
    e.stopPropagation();
    //let actives = this.getSorts.filter(s => s.col !== col)
    let s = this.sort_temp.get(col);
    s = s ? s.reverse : undefined;
    let rev;
    switch (s) {
      case undefined:
        rev = false;
        break;
      case false:
        rev = true;
        break;
      case true:
        rev = undefined;
        break;
    }
    this.sort_temp.set(col, { col: col, reverse: rev });
    //this.dispatchEvent(new CustomEvent('add-sort', { detail: col }));
    this.requestUpdate("sort");
  }
  setFilter(col, op, val) {
    console.log("SETTING FILTER", col, op, val);
    //filter: [{ col: 'benefit_start_match', op: '_eq', val: false }],
    this.filter_temp.set(`${col}:::${op}`, { col: col, op: op, val: val });
  }
  applyOpts() {
    //this.dispatchEvent(new CustomEvent('add-sort', { detail: col }));
    this.dispatchEvent(new CustomEvent('col-opts', { composed: true, detail: { sort: Array.from(this.sort_temp.values()), filter: Array.from(this.filter_temp.values()) } }));
    this.filter_temp = new Map();
    this.sort_temp = new Map();
    this.show = false;
  }

  getColFilters({ col, title, coltype }, use = true) {
    if (!use) return [];
    let active = this.filter ? this.filter.filter(f => f.col === col) : [];
    let ret = [
      {
        col: col,
        coltype: coltype,
        title: title,
        op: '_eq',
        op_title: 'matches',
        arg: arg => arg,
        control: '3check',
        icon: 'check',
        use: coltype === 'match'
      },
      {
        col: col,
        coltype: coltype,
        title: title,
        op: '_ilike',
        op_title: 'contains',
        arg: text_to_like,
        rarg: like_to_text,
        control: 'text',
        icon: 'search',
        use: coltypes[coltype].text
      },
      {
        col: col,
        coltype: coltype,
        title: title,
        op: '_lt',
        op_title: 'less than',
        arg: coltype === 'date' ? text_to_date : text_to_number,
        rarg: coltype === 'date' ? date_to_text : undefined,
        control: 'text',
        icon: 'keyboard_arrow_left',
        use: coltypes[coltype].ordered
      },
      {
        col: col,
        coltype: coltype,
        title: title,
        op: '_gt',
        op_title: 'at least',
        arg: coltype === 'date' ? text_to_date : text_to_number,
        rarg: coltype === 'date' ? date_to_text : undefined,
        control: 'text',
        icon: 'keyboard_arrow_right',
        use: coltypes[coltype].ordered
      },

    ].filter(f => f.use);
    ret.forEach(r => {
      let val = active.find(a => a.op === r.op);
      if (val) {
        r.val = r.rarg ? r.rarg(val.val) : val.val;
      }

    });
    return ret;
  }
  renderFilter({ col, coltype, title, op, arg, op_title, control, icon, val }) {
    return html`
    <div class="filter">
      ${control === 'text' ? html`
      <mwc-icon>${icon}</mwc-icon>
      <mwc-textfield label=${title + ' ' + op_title} .value=${val !==undefined ? val : '' } @value-changed=${e=>
        this.setFilter(col, op, arg(e.detail.value))}
        ></mwc-textfield>` : html``}
      ${control === '3check' ? html`
      <div class="radio-group">
        <mwc-formfield label="matches">
          <mwc-radio @change=${e=> this.setFilter(col, op, true)} .checked=${val === true}></mwc-radio>
        </mwc-formfield>
        <mwc-formfield label="non-matches">
          <mwc-radio @change=${e=> this.setFilter(col, op, false)} .checked=${val === false}></mwc-radio>
        </mwc-formfield>
        <mwc-formfield label="either">
          <mwc-radio @change=${e=> this.setFilter(col, op, undefined)} .checked=${val === undefined}></mwc-radio>
        </mwc-formfield>
      </div>
      ` : html``}
    
    </div>`;
    /*
              ?checked=${this.value}
              @checked-changed=${(e) => this.toggle(e)}
 
            <mwc-checkbox .indeterminate=${true} @check-change=${e => console.log("check change", col, e.detail.checked, e.detail.indeterminate)} ></mwc-checkbox>
 
            value=${this.value !== null && this.value !== undefined ? this.value : ''}
            ?fullwidth=${this.fullwidth}
            .filter=${this.transformInput}
            @value-changed=${(e) => this.valueChange(e.detail.value.replace(/[^0-9]/g, ''))}
            */
    //${active ? (reverse ? ' desc' : ' asc') : ''}

  }
  renderSort({ col, coltype, title }) {
    let s = this.sort_temp.get(col);
    let active = s ? s.reverse !== undefined : false;
    let reverse = s ? s.reverse === true : false;

    return html`<div class="sort" @click=${e=> this.setSort(e, col)} ?active=${active}>
  <mwc-icon ?reverse=${reverse}>sort</mwc-icon>
  <span class="sort-title">${title}</span>
  ${active ? html`<span class="direction">${reverse ? 'desc' : 'asc'}</span>` : html``}
</div>`;
    //${active ? (reverse ? ' desc' : ' asc') : ''}
  }

  getFilters() {

    if (this.def.compare) {
      return [
        ...this.getColFilters({ col: this.def.c + '_match', coltype: 'match', title: 'match' }),
        ...this.getColFilters({ col: this.def.c + '_sys', coltype: this.def.t, title: 'new' }),
        ...this.getColFilters({ col: this.def.c + '_ref', coltype: this.def.t, title: 'old' }),
        ...this.getColFilters({ col: this.def.c + '_diff', coltype: this.def.t, title: 'gap' }, coltypes[this.def.t].diff),
      ];
    } else {
      return [...this.getColFilters({ col: this.def.c, coltype: this.def.t, title: this.def.c })];
    }
  }
  getSorts() {
    if (this.def.compare) {
      let [sys, ref, diff] = [
        this.sort.find(s => s.col === this.def.c + '_sys'),
        this.sort.find(s => s.col === this.def.c + '_ref'),
        this.sort.find(s => s.col === this.def.c + '_diff')
      ]
      return [
        //sort: [{ col: 'group_sys', reverse: false }, { col: 'name', reverse: false }, { col: 'id', reverse: false }],
        { col: this.def.c + '_sys', active: sys, coltype: this.def.t, title: 'new', reverse: sys && sys.reverse, use: true },
        { col: this.def.c + '_ref', active: ref, coltype: this.def.t, title: 'old', reverse: ref && ref.reverse, use: true },
        { col: this.def.c + '_diff', active: diff, coltype: this.def.t, title: 'gap', reverse: diff && diff.reverse, use: coltypes[this.def.t].diff }
      ].filter(s => s.use);
    } else {
      let s = this.sort.find(s => s.col === this.def.c);
      return [{ col: this.def.c, active: s, coltype: this.def.t, title: this.def.c, reverse: s && s.reverse }];
    }
  }

}
//this.dispatchEvent(new CustomEvent('add-sort', { detail: col }));
window.customElements.define('column-header', ColumnHeader);


const report_table_style = css`
        .table-scroller {
          /*overflow-y: overlay; 
          height: calc(100vh - 64px);*/
          /*height: fit-content;
          width: 100%;
          min-width: 100%;*/
          height: 100%;
          width: 100%;
          background-color: white; /*rgb(221, 221, 221)*/ ;
          overflow: overlay;
          position: relative;
        }
        table { 
          white-space: nowrap; 
          border-collapse: collapse;  
          font-size: 0.8em; 
          min-width: 100%; 
          padding-bottom: 1em;
        }

        td,th { 
          border: none; 
          padding: 0px;
        }

        td[firstcol],th:first-of-type  { padding-left: calc(10px + 1.5em);}
        /*td:first-of-type,th:first-of-type  { padding-left: calc(10px + 1.5em);}*/
        td[lastcol],th:last-of-type  { padding-right: calc(10px + 1.5em);}
        th {
          text-align: left;
          text-transform: uppercase;
          z-index: 1;
          position: sticky;
          top: 0;
          background-color: white;
          cursor: pointer;
          color: var(--header-color, var(--paper-grey-100));
          background-color: var(--header-bg, var(--paper-blue-700));
          padding: 10px;
          font-size: 1.1em;
        }
        
        tr { 
          border: none;
          text-align: left;
        }
        tr:nth-child(4n+1), tr:nth-child(4n+2) { background: #CCC;}

        /*
        td[comparei] {
          padding-right: 2.5em;
          padding-left: 2.5em;
        }
        */

        td {
          padding: 10px;
        }

        td.compare_label { 
          padding-right: 0px;
        }
        td[mismatch] {
          font-weight: bold;
        }

        td.sys_value {
          padding-bottom: 0px;
        }
        td.ref_value {
          padding-top: 0px;
        }


        td.remove { padding: 10px 0px;}
        td.date { 
        }
        td.null {
          font-size: 8px;
          color: var(--paper-grey-400);
        }

        td.money, td.int, td.number, td.decimal, th.money, th.int, th.number, th.decimal, td.date, th.date {
          //text-align: right;
        }
        td.money, td.int, td.number, td.decimal, td.date {
          //font-family: 'Courier New', Courier, monospace;
          
          
        }


        td.ltext, td.ssn { 
          text-align: left;
        }
        td.ctext { 
          text-align: center;
        }

        span.currency {
          float: left;
        }
        span.alert {color: var(--alert-color); font-weight: 100;}
        span.alert {color: var(--alert-color)}
        span.migration {color: var(--migration-color)}
        span.error {color: var(--error-color); font-weight: 900;}


        #watcher-holder {
          height: 0;
          overflow: visible;
          position: relative;
          visibility: hidden;
        }

        .container[show] #watcher-holder {
          visibility: visible;
        }
        #watcher {
          height: 100vh;
          position: relative;
          bottom: 100vh;
          z-index: -10;
          /*
          bottom: 100vh;
          margin-bottom: -100vh;
          z-index: -5;
          */
        }

        .money-container {
          position: relative;
          display: inline-block;
          width: calc(100% - 1em);
          max-width: 7em;
          /*
          display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row; 
        padding-left: 5em;*/
        }
        .currency-symbol {
          font-size: 60%;
          opacity: 0.8;
          position: absolute;
          top: 0;
          left: -1em;
          /*
          flex: 1 1;
          width: 100%;
          display: inline-block;
          text-align: left;*/
        }
        .status_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: rgba(64,64,64,0.4);
          align-items: center;
          justify-content: center;
          flex-direction: column; 
          display: none;
          z-index: -10;

        }
        .status_overlay[show] {
          display: flex;
          z-index: 10;
        }

        .status_overlay > span {
          color: white;
            font-size: 130%;
            font-weight: bold;
            padding: 5px 10px;
            margin-top: 40px;
            display: block;
        }
        .processing_status {
         position: fixed;
          bottom: 20px;
          right: 40px;
          z-index: 20;
          font-size: 120%;
          background-color: rgba(100,100,100,0.7);
          color: white;
          padding: 5px 10px;
          border-radius: 10px;
          opacity: 0;
        }

        

        td[mismatch] {
        }
        .comparison {
          font-weight: 700;
          white-space: nowrap;
          display: inline-block;
          width: 100%;
        }
        .comparison > * {
          position: relative;
        }


        .comparison > *::after {
          font-stretch: ultra-condensed;
          font-weight: 100;
          font-size: 80%;
          opacity: 0.4;
          position: absolute;
          left: -2.5em;
          top: 0;
          width: 2.5em;
          display: block;
          text-align: left;
        }
        .date > .comparison > *::after {
          right: -1.5em;
        }

        .compare_label { 
          font-stretch: ultra-condensed;
          font-weight: 100;
          font-size: 80%;
          opacity: 0.4;
          text-align: right;
        }
        .sys_value {
          color: var(--paper-purple-800);
        }
        .ref_value {
          color: var(--paper-cyan-800);
          /*font-style: oblique;*/
        }
        .comparison > .sys_value::after {
          content: "new";
        }
        .comparison > .ref_value::after {
          content: "old";
        }

        .processing_status[show] {
          opacity: 1;
        }
        column-header {
          position: relative;
          z-index: 1;
        }
        tr[hl] {
          background-color: pink;
        }
        #header-shadow-container {
          position: sticky;
          left: 0px;
          top: 0;
          height: 0;
          z-index: 10;
          width: 100%;
          box-sizing: border-box;
          /*
          width: calc(100vw - 16px);
          opacity: 0.5;
          */
        }
        #header-shadow {
          box-sizing: border-box;
          box-shadow: none;
          transition: box-shadow 200ms linear 0s;
        }

        #header-shadow[scrolled] {
          transition: box-shadow 200ms linear 0s;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }


        /*
        #header-shadow {
          box-shadow: none;
          transition: box-shadow 200ms linear 0s;
        }
        #header-shadow[scrolled]{
          height: 10px;
    position: relative;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          transition: box-shadow 200ms linear 0s;
    top: -9px;
}
        }*/
`;

export class ReportTable extends LitElement {
  static styles = report_table_style
  constructor() {
    super();
    this.render_limit = 200;
    this.shadow_y = -200;
  }
  static get properties() {
    return {
      report: { type: Object },
      column_edit: { type: String },
      render_limit: { type: Number },
      scrolled: { type: Boolean },
      shadow_y: { type: Number }
    };
  }
  firstUpdated() {
    this.scroller = this.renderRoot.querySelector('.table-scroller');
    //console.log("set scroller ", this.scroller);
    //this.dispatchEvent(new CustomEvent('scroller', { detail: this.scroller }));
    this.shadow = this.renderRoot.getElementById('header-shadow-container');
    this.shadow_anchor = this.renderRoot.getElementById('shadow-anchor');
    this.shadow_y = this.shadow_anchor ? this.shadow_anchor.getBoundingClientRect().bottom : this.shadow_y;



    let options = {
      root: this.scroller,
      rootMargin: '0px',
      threshold: 0.1
    }

    this.observer = new IntersectionObserver((entries, obs) => {
      console.log("intersect", entries);
      if (entries.some(entry => entry.isIntersecting)) this.intersectHandler();
    }, options);

    this.trigger = this.renderRoot.getElementById('watcher');
    this.observer.observe(this.trigger);
  }

  // emulate scrollTop function so this element can be used as app-bar scrollTarget
  get scrollTop() {
    this.scroller = this.scroller ? this.scroller : this.renderRoot.querySelector('.table-scroller');
    return this.scroller ? this.scroller.scrollTop : null;
  }

  set report(r) {
    this._report = r;
    this.show_progress = false;
    //this.render_limit = r && r.limit ? r.limit : 500;
    this.requestUpdate('report');
  }
  get report() { return this._report }

  addSort(col) {
    if (this.report) {
      this.dispatchEvent(new CustomEvent('add-sort', { detail: col }));
    }
  }
  async intersectHandler() {
    console.log("handle intersect");
    this.show_progress = !this.report || !this.report.data || (this.report && this.report.data && this.render_limit < this.report.data.length);
    this.requestUpdate('show_progress');
    await this.updateComplete;
    console.log("done");
    window.requestIdleCallback(() => this.moreData());
  }
  async moreData() {
    console.log(`intersect: lim: ${this.render_limit}, data: ${this.report && this.report.data && this.report.data.length}`);
    let limit_inc, more_data;
    if (this.report && this.report.data && this.report.data.length >= this.render_limit) {
      limit_inc = true;
      this.render_limit += this.report.limit;
    }
    if (this.report && !this.report.end) {
      this.dispatchEvent(new CustomEvent('more-data', { detail: this }));
      more_data = true;
    }
    if (limit_inc && !more_data) {
      console.log("manually resetting progress ind")
      await this.updateComplete;
      console.log("...now");
      this.show_progress = false;
      this.requestUpdate('show_progress');
    }
  }

  render() {
    /*
    if (this.report && this.report.columns) {
      console.log("FILTER OPTS");
      this.report.columns.forEach(col => {
        console.log("col", col.t, col.compare);
      })
 
    }*/
    return html`
          <div class="table-scroller" id="scroller" @scroll=${e=> { this.scrolled = this.scrollTop && this.scrollTop > 0;/*refire
            a synthetic scroll event to outside shadow dom so we can be used as a scrollTarget*/ this.dispatchEvent(new
            CustomEvent("scroll", { ...e })) }}
            >
            <div id="header-shadow-container">
              <div id="header-shadow" ?scrolled=${this.scrolled} style=xxxxxxxxxxxxxxxxxxxxxxxxxxx> </div>
            </div>
            <table>
              <thead>
                <tr id="shadow-anchor">
                  ${this.report && this.report.columns ? this.report.columns.map(c => html`
                  ${c.compare ? html`<th></th>` : ''}
                  <th class=${c.t}>
                    <column-header .def=${c} .filter=${this.report.filter} .sort=${this.report.sort} @show-header=${({ detail: {
                      col, hide } })=> this.handleColShow(col, hide)}></column-header>
          </div>
          </th>
          `) : html``}
          </tr>
          </thead>
          
          <tbody>
            ${this.report && this.report.data && this.report.columns ? repeat(this.report.data.slice(0, this.render_limit), d =>
            d.id, (d, i) => {
            let hl = this.report && this.report.highlight && this.report.highlight(d);
            return html`
            <tr ?hl=${hl}> ${this.render_row(d)}</tr>
            <tr ?hl=${hl}> ${this.render_row_extra(d)} </tr>
            `
            }) : html``}
          </tbody>
          </table>
          <div id="watcher-holder">
            <div id="watcher"></div>
          </div>
          </div>
          <div class="status_overlay" ?show=${this.show_progress || (this.report && this.report.status !=='ready' )}>
            <progress-circle style="--progress-color: white; --progress-bg: var(--paper-grey-700); --progress-size: 128;"
              .status=${this.getTableStatus(this.report)} .icon_incomplete=${""} .icon_complete=${""}></progress-circle>
            <span>${this.report && this.report.remaining ? `${this.report.remaining}...` : ''}</span>
          </div>
    `
  }

  headerShadowStyle() {
    if (!this.shadow_anchor) return '';

    const { top, height } = this.shadow_anchor.getBoundingClientRect();
    const scroll = this.scrollTop;
    return `top: ${0}px; height: ${height}px;`
  }
  /*
 
            <table>
              <thead>
                <tr>
                  ${this.report && this.report.columns ? this.report.columns.map(c => html`
                  ${c.compare ? html`<th></th>` : ''}
                  <th class=${c.t} >
                      <column-header .def=${c} .filter=${this.report.filter} .sort=${this.report.sort} @show-header=${({ detail: { col, hide } }) => this.handleColShow(col, hide)}></column-header>
                    </div>
                  </th>
                  `) : html``}
                  <th class="end"></th>
                </tr>
              </thead>
 
              <tbody>
              ${this.report && this.report.data && this.report.columns ? repeat(this.report.data.slice(0, this.render_limit), d => d.id, (d, i) => {
      let hl = this.report && this.report.highlight && this.report.highlight(d);
      return html`
                <tr ?hl=${hl}> ${this.render_row(d)}</tr>
                <tr ?hl=${hl}> ${this.render_row_extra(d)} </tr>
                `
    }) : html``}
              </tbody>
            </table>
 
 
 
 
                  <mwc-icon>${this.getSortIcon(c.c)}</mwc-icon>
                  <th @click=${e => this.addSort(c.c)} class=${c.t}>
                <tr id="watcher"></tr>
                    <th>${c}<div><span>${c}</span></div></th>
 <mwc-dialog id="dialog"
                    suppressDefaults
                    ?opened=${this.show} 
                    ></mwc-dialog>
    </mwc-dialog>*/

  handleColShow(col, hide) {
    if (this.col_hide) this.col_hide(col);
    this.col_hide = hide;
  }
  filterActive(c) {
    return true;
  }
  sortActive(c) {
    return false;
  }
  showOpts(e, c) {
    this.column_edit = c === this.column_edit ? null : c;
  }
  getSortIcon(c) {
    let s = this.report && this.report.sort && this.report.sort.find(s => s.col === c);
    if (s && s.reverse) return 'arrow_drop_up';
    if (s) return 'arrow_drop_down';
    return '';
  }
  getTableStatus(report) {
    console.log("get table status", this.show_progress, report && report.status);
    if (this.show_progress) {
      return 'animate';
    }
    if (report && report.status === 'ready') {
      return 'complete';
    }
    if (report && report.status === 'processing') {
      return 'animate';
    }
    if (report && report.status === 'requested') {
      return 'animate';
    }
    return 'incomplete'
  }
  render_row(d) {
    let last = this.report.columns.length - 1;
    return html`
    ${this.report.columns.map((f, i) => {
    if (f.compare) {
    //let ref = d[f.c + '_ref'];
    let sys = d[f.c + '_sys'];
    let match = d[f.c + '_match'];
    return html`
    <td class=${`compare_label ${match ? '' : 'sys_value' }`} compare ?firstcol=${i===0} ?lastcol=${i===last}
      rowspan=${match ? 2 : 1}>${match ? '' : 'new'}</td>
    <td class=${f.t + (match ? '' : ' sys_value' )} compare ?mismatch=${!match} rowspan=${match ? 2 : 1}>
      ${formatters[f.t](sys, f, d)}</td>`
    } else {
    if (d[f.c] === null || d[f.c] === undefined) return html`<td class="null" ?firstcol=${i===0} rowspan="2">null</td>`
    return html`<td class=${f.t} ?firstcol=${i===0} ?lastcol=${i===last} rowspan="2">${formatters[f.t](d[f.c], f, d)}</td>`
    }
    })}`
  }


  render_row_extra(d) {
    let last = this.report.columns.length - 1;
    return html`
    ${this.report.columns.map((f, i) => {
    if (f.compare) {
    let ref = d[f.c + '_ref'];
    //let sys = d[f.c + '_sys'];
    let match = d[f.c + '_match'];
    return match ? '' : html`
    <td class=${`compare_label ${match ? '' : 'ref_value' }`} compare ?firstcol=${i===0} ?lastcol=${i===last}>old</td>
    <td class=${f.t + (match ? '' : ' ref_value' )} compare ?mismatch=${!match}>${formatters[f.t](ref, f, d)}</td>`
    }
    })}
    `
  }

  close() {
    this.show = false;
    this.dispatchEvent(new CustomEvent('close', { detail: this }));
  }
}

window.customElements.define('report-table', ReportTable);