
      import worker_url from './service-worker.js?url';
      let next_worker;
      let current_release;
      window.is_local_server = () => {
        return customElements.get("vite-error-overlay")
          || (/localhost/.test(window.location))
          || (/127.0.0.1/.test(window.location));
      }
      const updates_channel = new BroadcastChannel('update-info');
      window.report_version = () => {
        updates_channel.postMessage({ action: 'report-version' });
      }

      if ('serviceWorker' in navigator && !window.is_local_server()) {
        const reg = await navigator.serviceWorker
          .register(worker_url)
          .then(async reg => {
            // Registration was successful
            console.log('IDX: ServiceWorker registration successful with scope: ', reg.scope);

            reg.addEventListener('updatefound', () => {
              // new service worker will be in reg.installing:
              next_worker = reg.installing;
              next_worker.addEventListener('statechange', async () => {
                // Has state changed?
                switch (next_worker.state) {
                  case 'installed':
                    if (navigator.serviceWorker.controller) {
                      console.log("IDX: new ServiceWorker incoming");
                    }
                    break;
                }
              });
            });


          })
          .catch(function (err) {
            // registration failed :(
            console.error('ServiceWorker registration failed: ', err);
          });

        let refreshing;
        const doRefresh = () => {
          if (refreshing) return;
          console.warn("RELOADING PAGE");
          window.location.reload();
          refreshing = true;
        }

        let next_update;

        const handle_update_message = (event) => {
          // event is a MessageEvent object
          const msg = event.data;
          switch (msg.event) {
            case "current-version":
              current_release = msg.release;
              window.dispatchEvent(new CustomEvent('current-version', {
                detail: {
                  release: msg.release
                }
              }));
              break;
            case "update-available":
              if (next_update?.commit !== msg.target?.commit) {
                console.warn("IDX: heard about new version", JSON.stringify(msg.target));
                next_update = msg.target;
              }
              window.dispatchEvent(new CustomEvent('update-available', {
                detail: {
                  new_release: msg.target,
                  old_release: msg.current,
                  do_update: () => {
                    updates_channel.postMessage({ action: 'do-upgrade', target: msg.target });
                    navigator.serviceWorker.controller.postMessage({ action: 'do-upgrade', target: msg.target });
                    let _upgrade_completion_watchdog;
                    if (_upgrade_completion_watchdog) {
                      clearInterval(_upgrade_completion_watchdog);
                      _upgrade_completion_watchdog = null;
                    }
                    _upgrade_completion_watchdog = setInterval(() => {
                      updates_channel.postMessage({ action: 'do-upgrade', target: msg.target });
                      navigator.serviceWorker.controller.postMessage({ action: 'do-upgrade', target: msg.target });
                    }, 2 * 1000);
                  }
                }
              }));
              break;

            case "update-complete":
              doRefresh();
              break;
          }
        };
        navigator.serviceWorker.addEventListener("message", (event) => { handle_update_message(event); });

        // open channel to handle update information
        updates_channel.onmessage = (event) => { handle_update_message(event); }

        try {
          const _version_check_watchdog_timer = setInterval(async () => {
            updates_channel.postMessage({ action: 'report-version' });
          }, 1000 * 300); // 5 minutes
        } catch {
          console.warn("IDX: failed to set version watchdog", e);
        }
      } 
    