import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

export const benefit_payment_fields = gql`
  fragment BenefitPaymentFields on benefit_payment {
    id
    person_id
    payee_id
    payee {
      id
      first_name
      last_name
    }
    payment_date
    lump
    amount
    taxable_amount
    fit_withheld
    sit_withheld
    qdro
    health
    people
    credit_union
    mal
    net_amount
  }`

export const benefit_payment_info_query = gql`
  query benefit_payment_info($BenefitPaymentId: uuid!){
    benefit_payment( where: {id: {_eq: $BenefitPaymentId}} ){
         ...BenefitPaymentFields
    }
  }
  ${benefit_payment_fields}
`

export class BenefitPaymentInfo extends StaticQuery {
  get required_impl() {
    return ['BenefitPaymentId'];
  }
  get gql_impl() {
    return benefit_payment_info_query;
  }
  data_impl({ data: { benefit_payment: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const benefit_payments_for_person_query = gql`
  query benefit_payment_info($PersonId: uuid!) {
    benefit_payment (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...BenefitPaymentFields
    }
  }
  ${benefit_payment_fields}
  `

export class PersonBenefitPayments extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return benefit_payments_for_person_query;
  }
  data_impl({ data: { benefit_payment: benefit_payments } }) {
    return benefit_payments;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_benefit_payment_query = gql`
  mutation update_benefit_payment($benefitPaymentId: uuid!, $changeMap: benefit_payment_set_input!) {
    update_benefit_payment (
      where: {
          id: {_eq: $benefitPaymentId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...BenefitPaymentFields
      }
    }
  }
  ${benefit_payment_fields}
`
export const insert_benefit_payment_query = gql`
  mutation insert_benefit_payment($changeMap: benefit_payment_insert_input!) {
    insert_benefit_payment (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...BenefitPaymentFields
      }
    }
  }
  ${benefit_payment_fields}
`

export const upsert_benefit_payment_query = gql`
  mutation upsert_benefit_payment($changeMap: benefit_payment_insert_input!, $changeCols: [benefit_payment_update_column!]!) {
    benefit_payment:insert_benefit_payment (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: benefit_payment_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...BenefitPaymentFields
      }
    }
  }
  ${benefit_payment_fields}
`

export const delete_benefit_payment_query = gql`
  mutation delete_benefit_payment($deleteId: uuid!) {
    delete_benefit_payment (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditBenefitPaymentInfo extends SaveableQuery {
  get friendly_name() { return "Benefit payment" }
  get required_keys_impl() {
    return [
      "id",
      "person_id",
      "payee_id",
      "payment_date",
      "lump",
      "amount",
      "taxable_amount",
      "fit_withheld",
      "sit_withheld",
      "qdro",
      "health",
      "people",
      "credit_union",
      "mal",
      "net_amount"
    ]
  }

  data_impl({ data: { benefit_payment: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_benefit_payment_query;
  }
  get delete_gql_impl() {
    return delete_benefit_payment_query;
  }
  get save_queries_impl() {
    return [(new PersonBenefitPayments(null, null))];
  }
}

