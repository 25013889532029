import { LitElement, html, css } from 'lit';
import '@material/mwc-icon';
import '@material/mwc-button';
//import 'lit-html/directives/class-map';

import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';

const card_style = css`
        :host {
          box-sizing: border-box;
          font-family: 'Roboto', 'Noto', sans-serif;
          --shadow-transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), filter 0.28s cubic-bezier(0.4, 0, 0.2, 1);
          --bg-transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
          --border-transition: border 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }
        :host(.eelevated) {
          --shadow: var(--shadow-elevation-8dp_-_box-shadow);
        } 

        :host([elevated]) {
          --shadow: var(--shadow-elevation-8dp_-_box-shadow);
        } 

        ::slotted(*) {
          box-sizing: border-box;
          margin: 0;
          padding: var(--card-padding, 20px) var(--card-padding, 20px) 0px var(--card-padding, 20px) ;

          width: 100%;
        }

        ::slotted(:last-child) {
        padding: var(--card-padding, 20px) var(--card-padding, 20px) var(--card-padding, 20px) var(--card-padding, 20px) ;
        }
        .card {
          width: inherit;
          overflow: hidden;
          box-sizing: border-box;
          background-color: var(--card-bg, white);
          border: 1px solid var(--card-border, --paper-grey-400);
          padding: inherit;
          /*padding-bottom: 16px;*/
          border-radius: var(--card-radius, 8px);
          transition: var(--shadow-transition_-_transition), var(--bg-transition), var(--border-transition);
          cursor: text;
        }

      .column {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: stretch;
        flex-direction: column; 

        padding: 0;
        height: 100%;
      }
      .column > * {
        box-sizing: border-box;
        margin: 0;
        padding: 20px;
        width: 100%;
      }
        .card {
          /*box-shadow: var(--shadow-elevation-8dp_-_box-shadow); */
          box-shadow: var(--shadow);

        }
        .card:hover, :host[elevated] .card {
          /*box-shadow: var(--shadow-elevation-8dp_-_box-shadow); */
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);

        }
`

class KaleCard extends KaleForm {
  static styles = card_style
  get form_name() { return "Card" }
  constructor() {
    super();
  }
  render() {
    return html`
        <div class="card column">
          <slot></slot>
        </div>

      `;
    /*<div>${this.valid ? "valid" : "invalid"}</div>
            <ul>
            ${[...this.invalid_map.entries()].map(([name, e]) => {
             console.log('entry:', e);
            return html`
              <li>${e.field ? 'field' : 'form'}: ${e.name ? e.name : e.field} : ${e.dirty ? 'dirty' : 'clean'} : ${e.valid ? 'valid' : 'invalid'} </li>`
            })}
            </ul>


                  headerlabel="New Person"
                  acceptlabel="Create"
                  declinelabel="Cancel"
                  @accept=${e => {this.add_person_error=null; this.save();}}}
                  @cancel=${e => {this.add_person = false; this.setToDefault(); this.add_person_error=null;}}
    */
  }

  static get properties() {
    return {
      ...(super.properties),
      elevated: { type: Boolean, reflect: true },
    };
  }
}

window.customElements.define('kale-card', KaleCard);
export { KaleCard }
