import { html, css, LitElement, nothing } from "lit";
import "@material/mwc-button";
import "@material/mwc-textfield";
import "@material/mwc-checkbox";

import { GenericPopupEditor } from "./editors.js";
import {
	DataCheckpointInfo,
	EditDataCheckpointInfo,
} from "../queries/data_checkpoint.js";
import { EventDate } from "../benefits-app/eventdate.js";
import { colors } from "../shared-components/styles.js";

const checkpoint_style = css`
	div.checkpoint_wrap {
		position: relative;
		display: block;
		width: 24px;
		height: 24px;
	}
	

	div.checkpoint_checks {
		position: absolute;
		color: var(--checkpoint-text-color, white);
		font-size: 10px;
		opacity: var(--checkpoint-icon-opacity, 0.2);
		--mdc-icon-size: var(--checkpoint-icon-size);
		top: calc(var(--checkpoint-icon-offset));
		left: calc(var(--checkpoint-icon-offset));
		padding: 2px;
		cursor: pointer;
	}

	div.checkpoint_checks:hover {
	}

	div.checkpoint_checks[active] {
		opacity: 1;
		background-color: white;
		border-radius: 100%;
	}
	div.checkpoint_checks[active][valid] {
		color: var(--paper-green-800);
		background-color: white;
	}
	div.checkpoint_checks[active]:not([valid]) {
		color: var(--paper-red-800);
	}

	div.checkpoint_checks mwc-icon {
		position: relative;
		top: 1px;
	}
`;
class DataCheckpoint extends LitElement {
	static styles = checkpoint_style;
	static get properties() {
		return {
			value: { type: Object },
		};
	}
	constructor() {
		super();
		this.active = Math.random() > 0.5;
		this.valid = Math.random() > 0.5;
	}

	render() {
		let { status, correction } = this?.value ?? {};
		let active = status;
		let valid = status === "good";
		let title = correction ? `should be ${correction}` : status ?? "add report";
		return html`
		<div class="checkpoint_wrap" title=${title}>
			<div class="checkpoint_checks" ?active=${active} ?valid=${valid}>
				<mwc-icon>${active ? (valid ? "verified" : "report") : "add_task"}</mwc-icon>
			</div>
		</div>
	
		`;
	}
}

window.customElements.define("data-checkpoint", DataCheckpoint);
export { DataCheckpoint };

const tristate_style = css`
:host {
	cursor: pointer;
	--mdc-icon-font: "Material Symbols Outlined";
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;

	display: flex;
	flex-flow: row nowrap;
}
mwc-icon[filled]{
  font-variation-settings: 'FILL' 1;
}

mwc-icon[unfilled] {
  font-variation-settings: 'FILL' 0;
}

.tristate-bar {
	display: flex;
	flex-flow: row nowrap;
	border-radius: 28px;
	height: 28px;
	background-color: var(--paper-grey-500);
	--mdc-icon-size: 42px;
	color: white;
	margin: 12px;
	position: relative;
}

.icon-box {
	display: block;
	overflow: hidden;
	height: 22px;
	width: 22px;
	margin-left: 6px; 
	margin-right: 6px;
	border-radius: 28px;
}

mwc-icon {
	position: relative;
	top: -8px;
	left: -10px;
	z-index: 1;
}
.icon-box[active][good] {
	color: var(--paper-green-700);
}
.icon-box[active][neutral] {
	color: var(--paper-grey-700);
}
.icon-box[active][bad] {
	color: var(--paper-red-700);
}

.icon-box[active] {
	overflow: visible;
  font-variation-settings: 'FILL' 1;
	--mdc-icon-size: 48px;
	pointer-events: none;
}
.icon-box[active] > mwc-icon {
	top: -10px;
	left: -12px;
}

.icon-box[active] mwc-icon:before {
	content: " ";
	display: block;
	height: 32px;
	width: 32px;
	border-radius: 32px;
	background-color: white; 
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: -1;
}

.icon-box[active] mwc-icon[circle] {
	/* color: var(--paper-grey-500); */
	color: var(--paper-grey-100);
}
.icon-box[active] mwc-icon[circle]:before {
	content: " ";
	display: block;
	height: 32px;
	width: 32px;
	border-radius: 48px;
	background-color: var(--paper-grey-500);
	border: 3px solid var(--paper-grey-500);
	position: absolute;
	top: 6px;
	left: 6px;
	z-index: -1;
}

.icon-box:after {
	content: " ";
	display: block;
	height: 48px;
	width: 48px;
	border-radius: 48px;
	background-color: var(--paper-pink-500);
	position: absolute;
	top: -10px;
	margin-left: -10px;
	opacity: 0;
	z-index: 2;
}


  .correction {
		margin-left: 12px;
	}
`;

class TriState extends LitElement {
	static styles = tristate_style;
	static get properties() {
		return {
			key: { type: String },
			value: { type: String },
			inputtype: { type: String },
			precision: { type: Number },
			checkpoint: { type: Object },
			original: { type: Object },
			calculated: { type: Object },
		};
	}
	setToDefault() {}

	layout() {
		this.renderRoot.getElementById("correction")?.layout?.();
	}
	focus() {
		this.renderRoot.getElementById("correction")?.focus?.();
	}

	set _status(s) {
		this._value = {
			...(this.value ?? {}),
			status: s,
		};
	}
	set _correction(c) {
		let cleaned = c?.trim?.();
		let is_corrected = cleaned && cleaned !== null;
		this._value = {
			...(this.value ?? {}),
			status: is_corrected ? "bad" : this?.value?.status,
			correction: is_corrected ? cleaned : null,
		};
	}
	set _value(v) {
		let old_values = JSON.stringify(this.original);
		this.value = v;
		this.checkpoint = {
			...(this.checkpoint ?? {}),
			values: {
				...(this.checkpoint?.values ?? {}),
				[this.key]: {
					...(this.checkpoint?.values?.[this.key] ?? {}),
					...v,
				},
			},
		};
		let new_values = JSON.stringify(this.checkpoint?.values);
		let dirty = old_values !== new_values;
		let valid = true;
		let field = "values"; //`values.${this.key}`;
		let value = this.checkpoint.values;

		this.dispatchEvent(
			new CustomEvent("value-changed", { detail: { value: v } }),
		);
		this.dispatchEvent(
			new CustomEvent("component-dirty", {
				bubbles: true,
				composed: true,
				detail: {
					elem: this,
					dirty,
					valid,
					field,
					value,
				},
			}),
		);
	}
	render() {
		let { calculated, value, inputtype, precision } = this;
		let { status, correction } = value;

		calculated =
			inputtype === "date"
				? calculated?.toISOString?.()?.split?.("T")?.[0]
				: calculated;
		this.updateComplete.then(() => this.layout());
		return html`
		<div class="tristate-bar">
			<span class="icon-box" good title="Mark as verified" ?active=${
				status === "good"
			} @click=${(e) => (this._status = "good")}>
				<mwc-icon>verified</mwc-icon>
			</span>
			<span class="icon-box" neutral title="Mark as unverified" ?active=${
				status === null || status === undefined
			} @click=${(e) => (this._status = null)}>
				<mwc-icon circle>check_indeterminate_small</mwc-icon>
			</span>
			<span class="icon-box" bad title="Mark as incorrect" ?active=${
				status === "bad"
			} @click=${(e) => (this._status = "bad")}>
				<mwc-icon >report</mwc-icon>
			</span>
		</div>
		<div class="correction">
	   <mwc-textfield
            id="correction"
						@click=${(e) => {
							this._status = "bad";
							this.updateComplete.then(() => this.focus());
						}}
						.disabled=${this.value?.status !== "bad"}
            .label=${"Should be"}
            .value=${correction ?? calculated}
            @input=${(e) => (this._correction = e.target.value)}
            outlined
            type=${inputtype}
            step=${precision}
          ></mwc-textfield>
		</div>
		`;
	}
}
window.customElements.define("tri-state", TriState);
export { TriState };

const checkpoint_dlg_style = css`
	:host {
		${colors}

		--mdc-dialog-max-width: 90vw;
		--mdc-dialog-min-width: min(600px, 90vw);
		--mdc-dialog-max-height: 80vh;
	}

	#dialog {
		--timeline-text-color: white;
		--timeline-primary-color: var(--paper-amber-800);
		--mdc-dialog-max-height: 80vh;
		--mdc-dialog-max-width: 90vw;
		--mdc-dialog-min-width: min(600px, 90vw);

		--popup-max-height: calc(var(--mdc-dialog-max-height) - 212px);
    --popup-max-height: 50vh;
	}

	.propertyvalue {
		display: flex;
		flex-direction: row nowrap;
	}

	.propertyvalue > span {
		color: white;
		border-radius: 10px;
		width: 100%;
		flex: 1 1 0%;
	}
	span.key {
		background-color: var(--paper-teal-700);
	}
	span.val {
		background-color: var(--paper-pink-700);
	}


	table { 
		white-space: nowrap; 
		border-collapse: collapse; 
		font-size: 16px; 
		min-width: 100%; 
		padding-bottom: 1em;
	}
	th { text-align: left; }
	td,th { border: none; padding: 12px 8px; }

	td.key {
		font-weight: 900;

	}
	td.val {
		font-weight: 100;
	}

	h4 {
		font-weight: 100;
	}

	h4 .info {
		background-color: var(--paper-pink-600);
		border-radius: 8px;
		padding: 0;
		display: inline-flex;
		flex-flow: row nowrap;
		overflow: hidden;
		font-size: 12px;
	}
	h4 .label {
		display: block;
		padding: 4px 8px;
	}
	h4 .data {
		display: block;
		font-weight: 900;
		background-color: var(--paper-cyan-600);
		padding: 4px 8px;
	}
  .notes {
    position: sticky;
    top: -12px;
    background-color: white;
    width: 100%;
    z-index: 100;
    margin-left: -12px;
    margin-right: -12px;
    padding: 12px;
    margin-top: -12px;
  }

`;

export class CheckpointEditor extends GenericPopupEditor {
	static styles = [...GenericPopupEditor.styles, checkpoint_dlg_style];
	get form_name() {
		return "CheckpointEditor";
	}
	get title() {
		return "Calculation Checkpoint";
	}
	get subscription_type() {
		return DataCheckpointInfo;
	}
	get mutation_type() {
		return EditDataCheckpointInfo;
	}
	async save_impl(data) {
		data.user = window.authmgr.current_login.user;
		return super.save_impl(data);
	}
	get data_property() {
		return this.checkpoint;
	}
	get default() {
		return {};
	}
	set data_property(p) {
		this.checkpoint = p;
	}
	get id_property() {
		return this.checkpointid;
	}
	get id_property_name() {
		return "checkpointid";
	}
	set id_property(id) {
		this.checkpointid = id;
	}
	get subscription_args() {
		return { DataCheckpointId: this.checkpointid ? this.checkpointid : null };
	}
	get header_class() {
		return "checkpoint";
	}
	get header_extra() {
		let { projection_id, target_date } = this.checkpoint ?? {};
		let { name, change } = this.person;
		let date = new EventDate(target_date);
		let now = new EventDate();
		console.log("POPUP", date, now, date > now);
		return html`
		<h4>
			<span class="info name"><span class="data name">${name}</span></span>
		
			<span class="info target">
				<span class="label target_info">${
					projection_id || date > now ? "projected to" : "computed on"
				} </span> 
				<span class="data target_date">${date.str}</span> 
			</span> 
			<span class="info change" title=${change?.table}>
				<span class="label last_change">last change</span>
				<span class="data change_date">${new EventDate(change?.timestamp).str}</span>
			</span>
		</h4>
		`;
	}

	required(field) {
		return field !== "end_date";
	}

	get extraPrimary() {
		return html`
    `;
	}

	get original() {
		return this._original ?? this.checkpoint?.values;
	}
	static get properties() {
		return {
			person: { type: Object },
			report: { type: Object },
			checkpoint: { type: Object },
			checkpointid: { type: String },
			...super.properties,
		};
	}

	firstUpdated() {
		this.dialog = this;
		window.router.registerDialog(this.dialog);
		this.addEventListener("keyup", (e) => {
			if (e.keyCode === 13) {
				// enter
			}
		});
	}

	handleComponentDirty(d) {
		if (!this._original) this._original = this.checkpoint.values;
		super.handleComponentDirty(d);
		if (d.field.split(".")?.[0] === "values") {
			this.checkpoint = {
				...this.checkpoint,
				values: d.value,
			};
		}
	}

	close() {
		this.cancel(); // needed to reset some parent state
	}

	getForm() {
		const { report, checkpoint } = this;

		return html`
      <div class="notes">
        <kale-textfield wide outlined .label=${"notes"} .field=${"notes"} .value=${
			checkpoint?.notes
		}></kale-textfield>
      </div>
			<table>
				<tbody>

					${report
						?.filter?.(({ checkpoint }) => checkpoint !== false)
						?.map?.(({ key, val, qty, amt, bal, date, precision }) => {
							let value = checkpoint?.values?.[key] ?? {};

							let val_to_str;
							let val_type;
							switch (val?.constructor?.name) {
								case "Date":
									val_type = "date";
									val_to_str = new EventDate(val).str;
									break;
								case "String":
									val_type = "string";
									val_to_str = val;
									break;
								case "Number":
									val_type = amt ? "money" : "number";
									val_to_str = amt
										? amt.toLocaleString([], {
												style: "currency",
												currency: "USD",
										  })
										: val.toLocaleString();
									break;
								default:
									val_type = "unk";
									val_to_str = `${val}`;
									break;
							}

							return html`
						<tr>
							<td class="key">${key}</td>
							<td class="val">${val_to_str}</td>
							<td class="status">
								<tri-state 
                .inputtype=${val_type} 
                .precision=${precision}
                .original=${this.original} 
                .calculated=${val} 
                .checkpoint=${checkpoint} 
                .key=${key} .value=${value} ></tri-state>
							</td>
						</tr>`;
						})}
				</tbody>
			</table>
    `;
	}
}
window.customElements.define("edit-checkpoint", CheckpointEditor);
