const editor_type_info = [
  {
    class: "employment",
    icon: "work",
    color: "var(--employment-color)",
    dialog_label: "Edit Employment",
    label: "Start of Employment"
  },
  {
    class: "suspension",
    icon: "work_off",
    color: "var(--suspension-color)",
    dialog_label: "Suspension",
    label: "Suspension",
  },
  {
    class: "benefit",
    icon: "beach_access",
    color: "var(--benefit-color)",
    dialog_label: "Retirement Benefit",
    label: "Retirement",
    extra_args: { benefit_type: { code: 'RET' } },
    matches: d => d.benefit_type && d.benefit_type.code === 'RET'
  },
  {
    class: "benefit",
    icon: "accessible",
    color: "var(--benefit-color)",
    label: "Disability Benefit",
    dialog_label: "Disability Benefit",
    extra_args: { benefit_type: { code: 'DIS' } },
    matches: d => d.benefit_type && d.benefit_type.code === 'DIS'
  },
  {
    class: "benefit",
    icon: "hourglass_empty",
    color: "var(--benefit-color)",
    label: "Death Benefit",
    dialog_label: "Death Benefit",
    extra_args: { benefit_type: { code: 'DEATH' } },
    matches: d => d.benefit_type && d.benefit_type.code === 'DEATH'
  },
  {
    class: "beneficiary",
    icon: "supervisor_account",
    color: "var(--beneficiary-color)",
    dialog_label: "Beneficiary",
    label: "Beneficiary Designated",
  },
  {
    class: "contribution",
    icon: "attach_money",
    color: "var(--contribution-color)",
    dialog_label: "Contribution",
    label: "Add Contribution",
  },
  {
    class: "benefit_payment",
    icon: "monetization_on",
    color: "var(--payment-color)",
    dialog_label: "Benefit Payment",
    label: "Benefit Payment",
  },
  {
    class: "service_adjustment",
    icon: "tune",
    color: "var(--adjustment-color)",
    dialog_label: "Service Adjustment",
    label: "Service Adjustment",
  },
  {
    class: "participation_change",
    icon: "ballot",
    color: "var(--participation-color)",
    dialog_label: "Participation Option",
    label: "Participation Option",
  },
  {
    class: "withdrawal",
    icon: "account_balance",
    color: "var(--withdrawal-color)",
    dialog_label: "Withdrawal",
    label: "Withdrawal",
  },
  {
    class: "withdrawal_repayment",
    icon: "account_balance",
    color: "var(--withdrawal-color)",
    dialog_label: "Withdrawal Repayment",
    label: "Withdrawal Repayment",
  },
  {
    class: "salary_history",
    icon: "monetization_on",
    color: "var(--employment-color)",
    dialog_label: "Salary History",
    label: "Salary History",
  },
];

const table_icons = {
  ...Object.fromEntries(editor_type_info.map(e => [e.class, e.icon])),
  person: 'person',
  person_note: 'sticky_note_2',
  projection: 'trending_up',
  address: 'location_on',
  phone: 'phone',
  email: 'email',
}
export { editor_type_info, table_icons };
