import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';
//import { simple_person_fields } from './person.js';
import { person_tag_fields } from './tag.js';

const simple_person_fields_beneficiary = gql`
  fragment SimplePersonFieldsBeneficiary on person {
    id
    first_name
    middle_name
    last_name
    ssn
    birth_date
    deceased_date
    person_suffix_code
    person_title_code
    person_marital_status_code
    person_gender_code
    tags { ...PersonTagFields }
  }
  ${person_tag_fields}

`
/*
 * Beneficiary
 */

export const beneficiary_fields = gql`
  fragment BeneficiaryFields on beneficiary {
    id
    person_id
    designation_date
    removal_date
    beneficiary_id 
    beneficiary {
      ...SimplePersonFieldsBeneficiary
    }
    benefactor {
      ...SimplePersonFieldsBeneficiary
    }
    beneficiary_relationship_code
    relationship{
      code
      name
    }
    beneficiary_type_code 
    beneficiary_type {
      code
      name
    }
   }
   ${simple_person_fields_beneficiary}
`



export const beneficiary_info_query = gql`
  query beneficiary_info($BeneficiaryId: uuid!){
    beneficiary( where: {id: {_eq: $BeneficiaryId}} ){
         ...BeneficiaryFields
    }
  }
  ${beneficiary_fields}
`

export class BeneficiaryInfo extends StaticQuery {
  get required_impl() {
    return ['BeneficiaryId'];
  }
  get gql_impl() {
    return beneficiary_info_query;
  }
  data_impl({ data: { beneficiary: [d] } }) {
    return d;
  }
}


export const beneficiaries_for_person_query = gql`
  query beneficiary_info($PersonId: uuid!) {
    beneficiary (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...BeneficiaryFields
    }
  }
  ${beneficiary_fields}
  `

export class PersonBeneficiaries extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return beneficiaries_for_person_query;
  }
  data_impl({ data: { beneficiary: beneficiaries } }) {
    return beneficiaries;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_beneficiary_query = gql`
  mutation update_beneficiary($beneficiaryId: uuid!, $changeMap: beneficiary_set_input!) {
    update_beneficiary (
      where: {
          id: {_eq: $beneficiaryId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...BeneficiaryFields
      }
    }
  }
  ${beneficiary_fields}
`
export const insert_beneficiary_query = gql`
  mutation insert_beneficiary($changeMap: beneficiary_insert_input!) {
    insert_beneficiary (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...BeneficiaryFields
      }
    }
  }
  ${beneficiary_fields}
`

export const upsert_beneficiary_query = gql`
  mutation upsert_beneficiary($changeMap: beneficiary_insert_input!, $changeCols: [beneficiary_update_column!]!) {
    beneficiary:insert_beneficiary (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: beneficiary_pkey,
        update_columns: $changeCols

      }
    ) {
      returning {
        ...BeneficiaryFields
      }
    }
  }
  ${beneficiary_fields}
`

export const delete_beneficiary_query = gql`
  mutation delete_beneficiary($deleteId: uuid!) {
    delete_beneficiary (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditBeneficiaryInfo extends SaveableQuery {
  get friendly_name() { return "Beneficiary" }
  get required_keys_impl() {
    return ["id", "person_id", "beneficiary_id", "beneficiary_type_code", "beneficiary_relationship_code", "designation_date"];
  }
  data_impl({ data: { beneficiary: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_beneficiary_query;
  }
  get delete_gql_impl() {
    return delete_beneficiary_query;
  }
  get save_queries_impl() {
    return [(new PersonBeneficiaries(null, null))];
  }
}

