import { LitElement, html, css } from 'lit';
import { KalePage } from '../shared-components/page.js';
import '../shared-components/progress-circle.js';
// SELECT pid, pg_terminate_backend(pid) FROM pg_stat_activity WHERE state = 'active' and query not ilike '%MAGIC%';

import { reports } from '../benefits-app/report-defs.js';
import { ViewReport } from './report.js';
import { client } from '../queries/queries.js';
import { enum_spec} from '../queries/enums.js';

const reports_page_styles = css`

:host {
	--mdc-icon-font: "Material Symbols Outlined";
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}
      .column {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: center;
        align-items: stretch;
        flex-direction: column; 
        width: 100%;
        padding: 0;
        height: fit-content;
        padding-bottom: 24px;
      }

      .column > * {
        margin: 0;
        margin-top: 12px;
        margin-left: 12px;
        min-width: 300px;
        width: 100%;
        flex: 1 1;
        max-width: 600px;
      }

        .scroller {
          overflow: overlay;
          height: calc(100vh - 64px);
          width: 100%;
        }

        .content-area {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;

          height: calc(100vh - 64px);
        }

        mwc-fab {
          position: fixed;
          right: 50px;
          bottom: 50px;
        }

        .primary {
          font-weight: 500;
          font-size: 20px;
          }
        .secondary {
          font-weight: 200;
          font-size: 16px;
          margin-top: 8px;
          }


        mwc-top-app-bar {
          --mdc-theme-primary: var(--paper-green-700);
          background-color: var(--paper-green-700);
        }
        mwc-top-app-bar[report] {
          --mdc-theme-primary: var(--paper-purple-900);
          --app-bar-text: white;
        }

        .table-scroller[height_computed] {
          height: fit-content;
        }
              
        .top-app-bar-adjust {
          margin-top: 64px;
          }

        kale-card {
          width: 100%;
          margin-top: 24px;
          --card-border: var(--paper-grey-400);
          --card-bg: white;/*var(--paper-grey-700);*/
          width: fit-content;

        }


        div.card-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row; 
          margin-bottom: 0;
          padding: 8px;
          width: 100%;
          /*()
          padding-right: 18px;
          padding-bottom: 16px;
          */
        }

        div.card-header {
          padding: 18px;
          padding-top: 8px;
          padding-right: 32px;
          font-size: 1.3em;
          font-weight: 500;
          background-color: var(--paper-kale-800);
          color: white;
          width: 100%;
        }
        .card-header > mwc-icon {
          margin-right: 12px;
          position: relative;
          font-size: 150%;
          top: 8px;
          margin-top: -8px;
          width: 100%;
        }

        div.card-content {
          padding: 18px;
          flex: 1 1;
        }

        .card-content > h2 {
          margin-top: 0px;
        }


        report-table {
          display: none;
        }
        report-table[show] {
          display: block;
          position: fixed;
          left: 0;
          top: 64px;
          width: 100%;
          height: 100%;
        }


        mwc-textfield {
          width: 6em;
  
        }

        mwc-select{
          width: 14.5em;
        }

    .report-choice {
      margin-top: 18px;
      border: 1px solid var(--paper-grey-400);
      background-color: white;
      border-radius: 8px;
      padding: 24px;
      cursor: pointer;
          transition: var(--shadow-transition);
          box-shadow: none; /* var(--shadow-level-one);*/
        }

        .report-choice:hover {
          /*box-shadow: var(--shadow-elevation-8dp_-_box-shadow); */
          box-shadow: var(--shadow-elevated);

        }
               .report-choice-header {
                 display: flex;
                 flex-direction: row;
                 width: 100%;
                 padding-bottom: 16px;
               }
               .report-choice-header > * {
                 padding: 4px;
               }
               .report-choice-icon{
                 margin-top: -12px;

               }
               .report-choice-name{
                 flex: 1;
                 font-size: 120%;
                 font-weight: bold;

               }
               .report-choice-arguments{

               }
               .report-choice-buttons{

               }
                .report-choice-desc{
                  font-weight: 200;
                }

`;


class ReportsPage extends KalePage {
  static styles = [ super.styles, reports_page_styles]
  static icon = "table_chart"
  static default_title = "Reports"

  constructor() {
    super();
    this.args = new Map();
    this.codes = [];
  };

  connectedCallback(){
    super.connectedCallback();
    this.fetchWithDrawalCodes();
  }

  fetchWithDrawalCodes = () => {
    client.query({
      fetchPolicy: 'cache-first',
      query: enum_spec.withdrawal_type_code.query
    }).then((resp) => {
      let codes = resp.data.values.map(c => ({
        code: c.code,
        name: c.name
      }));
      codes.unshift({code: 'ALL', name: 'All'})
      this.codes = [...codes];
      this.requestUpdate();
    }).catch((err) => {
      console.error(`Couldn't retrieve the list of withdrawal type codes due to: ${err}`)
    })
  }

  renderPage() {
    return html`
            <div class="column">
            ${reports.map(report => html`
                <div class="report-choice" @click=${e => this.openReport(report)}>
                <div class="report-choice-header">
                <span class="report-choice-icon">
                  <progress-circle style="--progress-color: var(--paper-purple-600); --progress-size: 32; position: relative; top: 8px;" .icon_incomplete=${report.icon} .icon_complete=${report.icon}></progress-circle>
                  </span>
                <span class="report-choice-name">
                  ${report.name}
                  </span>
                <span class="report-choice-arguments" @click=${e => e.stopPropagation()}>
                ${report.args.year ? html`<mwc-textfield outlined label="Year" id=${`year-control-${report.name}`} .value=${report.args.year} @input=${(e)=>this.yearChange(report, this.renderRoot.getElementById(`year-control-${report.name}`).value)}></mwc-textfield>` : html``}
                ${report.active_vested ? html`<mwc-formfield label="Active"><mwc-checkbox ?indeterminate=${report.args.active===null} ?checked=${report.args.active===true} @change=${(e)=> this.triChange(e, 'active', report)}></mwc-checkbox></mwc-formfield>` : html``}
                ${report.active_vested ? html`<mwc-formfield label="Vested"><mwc-checkbox ?indeterminate=${report.args.vested===null} ?checked=${report.args.vested===true} @change=${(e)=> this.triChange(e, 'vested', report)}></mwc-checkbox></mwc-formfield>` : html``}
                ${report.active_vested ? html`<mwc-formfield label="Termed"><mwc-checkbox ?indeterminate=${report.args.termed===null} ?checked=${report.args.termed===true} @change=${(e)=> this.triChange(e, 'termed', report)}></mwc-checkbox></mwc-formfield>` : html``}  

                ${this._withdrawalSelector(report.name)}
                
              </span>
                <span class="report-choice-buttons">
                      <mwc-button icon="view_list" @click=${e => this.openReport(report)}> view</mwc-button>
                  </span>
                      </div>

                  <div class="report-choice-desc">${report.desc}</div>
                </div>

      `)
      }
            </div>
  `;
  }

  withdrawalReportType(reportName, reportType){
    this.args.set(reportName, {withdrawal_type_code: reportType})
  }

  yearChange(report, year) {
    console.log("change year", year);
    let existing = this.args.get(report.name);
    this.args.set(report.name, { ...(existing ? existing : {}), year: Number(year) } );
  }
  triChange(e, arg, report) {
    console.log("TRICHANGE:", e, arg, report);
    let existing = this.args.get(report.name) ?? {};
    //let t = this.reports.get(report.name);
    //t = t ? t : { ...report };
    let cur_state = existing?.[arg];
    let next_state = cur_state === true ? false : (cur_state === false ? null : true);
    let elem = e.srcElement ?? e.target ?? e.path?.[0];
    if (next_state) {
      elem.setAttribute('checked', true);
      elem.removeAttribute('indeterminate');
    } else if (next_state === 'false') {
      elem.setAttribute('checked', false);
      elem.removeAttribute('indeterminate');
    } else {
      elem.setAttribute('indeterminate', true);
      elem.removeAttribute('checked');
    }
    console.log("toggle tristate", arg, `"${cur_state}"=>"${next_state}"`, e);
    //this.reports.set(report.name, { ...t, status: undefined, offset: undefined, args: { ...t.args, [arg]: next_state } });
    existing[arg] = next_state;
    this.args.set(report.name, existing)
  }

  openReport(report) {
    
    let args = this.args.get(report.name);
    let url = ViewReport.constructAddress(window.location, { report: report.report_id, ...args });
    console.log("OPENREPORT ARGS", JSON.stringify(args, null, 2));
    console.log("OPENREPORT CONSTRUCT URL", url);
    window.router.navigate(url);
  }

  _withdrawalSelector = (reportName) => {
      switch(reportName){
        case 'Withdrawals':
          let args = this.args.get('Withdrawals');
          let withdrawal_type_code = args ? args.withdrawal_type_code : undefined
          if(withdrawal_type_code === undefined){
            this.args.set('Withdrawals', {withdrawal_type_code: 'ALL'})
          }

          return html`
            <mwc-select outlined label="Withdrawal Type">
              ${this.codes.map(opt => {
                  return html`
                    <mwc-list-item value=${opt.code} ?selected=${opt.code === 'ALL'}  @click=${(e) => this.withdrawalReportType(reportName, e.target.value)}>${opt.name}</mwc-list-item>
                  `
              })}
            </mwc-select>
          `
      }
  }
}


window.customElements.define('reports-page', ReportsPage);
export { ReportsPage }
