
import { html, css } from 'lit';
import { KalePage } from '../shared-components/page.js';
import XLSX from 'xlsx';
import '../shared-components/progress-circle.js';
import { client } from '../queries/queries.js';
import { xlsx_formatters } from '../components/report-components.js';
import { reports } from '../benefits-app/report-defs.js';

const report_view_styles = css`
        :host {
          --top-bar-color: var(--paper-purple-900);
          --top-bar-text: white;
        }

        report-table {
          opacity: 0;
        }

        report-table[show] {
          opacity: 1;
        }
        
        #main_title {
          font-weight: 900;

        }
        #subtitle {
          font-weight: 100;
          margin-left: 2em;
          position: relative;
          bottom: 5px;
        }

        .argument {
          background-color: var(--top-bar-text);
          color: var(--top-bar-color);
          border: 1px solid var(--top-bar-text);
          padding: 0px 12px;
          border-radius: 20px;
          display: inline-block;
          box-sizing: border-box;
          font-size: 10px;
          font-family: monospace;
          font-weight: 400;
          line-height: 2em;
          margin-left: 1em;

        }
        .argument_name {
          display: inline-block;
          box-sizing: border-box;
          text-transform: uppercase;
          padding: 0;
          margin: 0;
          font-weight: 900;
        }
        .argument_name::after {
          content: ' ='
        }
        .argument_value {
          display: inline-block;
          box-sizing: border-box;
          padding: 0;
          margin: 0;
          text-decoration: underline;
          font-style: oblique;

}
        

`;
const findMatchingReportDef = ({ matched_path, report }) => {
  if (!(matched_path || report)) return;
  let path = (matched_path ? matched_path : "").toLowerCase().replace(/[^a-z]/g, '');
  let name = (report ? report : "").toLowerCase().replace(/[^a-z]/g, '');
  const match_reports = reports.map(r => ({ match_string: r.name.toLowerCase().replace(/[^a-z]/g, ''), report: r }));
  const match = match_reports.find(r => r.match_string === path || r.match_string === name);
  if (match) return match.report;
  //return reports.find(r => r.title === matched_path || r.name.toLowerCase().replace(/[^a-z]/g, '') === matched_path || r.title === name || r.name.toLowerCase().replace(/[^a-z]/g, '') === name);
}

class ViewReport extends KalePage {
  static styles = [super.styles, report_view_styles]
  static icon = "table_chart"
  static default_title = "Report"

  constructor() {
    super();
    this.done = 0;
    this.time_per_chunk = 4;
  }
  actions = [
    {
      name: 'Close',
      icon: 'cancel',
      action: () => { window.router.closeAndNavigate('/reports') }
    },
    {
      name: 'Download',
      icon: 'file_download',
      action: () => this.downloadTable()
    },
    ...this.actions]
  static get properties() {
    return {
      ...super.properties,
      report: { type: Object }
    };
  }

  setTitle(name, args) {
    this.arguments = Object.keys(args).map(k => ({ name: k, value: args[k] }));
    this.subtitle = `${Object.keys(args).map(k => `${k}=${args[k] ?? '[any]'}`).join(", ")}`;
    this.title = `${name}`
  }

  static sanitizeArgs(def, args) {
    let valid_args = new Set(Object.keys(def.args));
    let combined_args = { ...def.args, ...args };
    let valid_keys = Object.keys(combined_args).filter(k => valid_args.has(k)).sort();
    let sanitized = Object.fromEntries(valid_keys.map(k => [k, combined_args[k]]));
    return sanitized;
  }


  static constructAddress({ protocol, host }, args) {
    let def = findMatchingReportDef(args);
    if (def) {
      let report_args = ViewReport.sanitizeArgs(def, args);
      let arg_names = Object.keys(report_args).filter(k => report_args[k]);
      let canon = `${protocol}//${host}/reports/${def.report_id}${arg_names.length > 0 ? `?${arg_names.map(k => `${k}=${report_args[k]}`).join('&')}` : ''}`
      return canon;
    }
    console.warn("no report matched", args);
    return `${protocol}//${host}/reports`
  }

  firstUpdated() {
    this.scroller = this.renderRoot.getElementById('table');
  }

  set search(args) { //matched_path should contain the 
    let def = findMatchingReportDef(args);
    if (def) {
      this.report = { ...def, args: ViewReport.sanitizeArgs(def, args) };
      this.setTitle(def.name, this.report.args);
      this.moreData();
    } else {
      console.warn("no report found for", report);
    }
  }
  renderTitle() {
    return html`
        <span id="main_title">${this.report?.name}</span>
        <span id="subtitle">${this.arguments.map(a => html`
          <span class="argument">
            <span class="argument_name">${a.name}</span>
            <span class="argument_value">${a.value ?? '[any]'}</span>
          </span>`)}
        </span>`
  }
  renderPage() {
    return html`<report-table id="table" } ?show=${this.report && true} .report=${this.report} @col-opts=${e=> this.colOpts(this.report,
    e.detail)} @add-sort=${({ detail: col }) => this.addSort(this.report, col)}
    @more-data=${e => this.moreData(this.report)}
    @scroller=${e => this.scroller = e.detail}
  ></report-table>
  `;
  }
  get ready() {
    return this.report && this.report.status === 'ready'; //TODO: expiration
  }
  get processing() {
    return this.report && this.report.status === 'processing'; //TODO: expiration
  }
  get requested() {
    return this.report && this.report.status === 'requested'; //TODO: expiration
  }

  get icon_status() {
    if (this.ready) {
      return 'complete';
    }
    if (this.processing) {
      return 'complete';
    }
    if (this.requested) {
      return 'animate';
    }
    return 'incomplete'
  }

  prepButtonText(report) {
    switch (this.icon_status) {
      case 'complete':
        return 'prepared';
      case 'animate':
        return 'preparing...';
      case 'incomplete':
      default:
        return 'prepare';
    }
  }

  prepareTable() {
    this.report = { ...this.report, data: [], status: 'requested' }
    this.moreData();
  }

  moreData(nolimit) {
    console.log("get more for ", this.report.name, this.report.args, this.report.sort, performance.now());
    this.report = { ...this.report, offset: this.report.offset !== undefined ? this.report.offset + this.report.limit : 0 };

    if (this.report.invalid) {
      this.report = { ...this.report, status: 'requested', offset: 0 };
    } else {
      this.report = { ...this.report, status: 'requested' };
    }

    client.query({
      fetchPolicy: 'network-only',
      query: this.report.query(this.report.filter, this.report.sort, nolimit ? 0 : this.report.limit, this.report.offset, this.report.args)
    }
    )
      .then(data => {
        console.log("got some data", data)
        const raw_data = data.data;
        data = data.data.report;
        this.processData(data, nolimit, raw_data);
      })
      .catch(error => console.error(error));
  }


  idleProcess(deadline, processed, remaining) {
    let time = deadline.timeRemaining();
    let start = performance.now();
    let chunk = 50;//Math.round(time / (report.time_per_row ? report.time_per_row : 4));
    while ((deadline.timeRemaining() || deadline.didTimeout) && remaining.length > 0) {
      processed = [...processed, ...remaining.slice(0, chunk).map(r => this.report.row_func(r, this.report.args, this.report.raw_data))];
      remaining = remaining.slice(chunk);
      //processed.push(report.row_func(remaining.shift()));
    }
    if (remaining.length === 0) {
      this.report = {
        ...this.report,
        status: remaining.length > 0 ? 'processing' : 'ready',
        data: [...this.report.data ? this.report.data : [], ...processed],
        remaining: remaining.length,
      };
      processed = [];
    } else {
      this.report = { ...this.report, remaining: remaining.length };
    }

    let elapsed = performance.now() - start;
    let processed_rows = this.report.processed_rows ? this.report.processed_rows + chunk : chunk;
    let total_process_time = this.report.total_process_time ? this.report.total_process_time + elapsed : elapsed;
    this.report.processed_rows = processed_rows;
    this.report.total_process_time = total_process_time;
    this.report.time_per_row = this.report.processed_rows / this.report.total_process_time;
    if (remaining.length > 0) {
      this.handle = requestIdleCallback(d => this.idleProcess(d, processed, remaining));
    } else {
      if (this.report.cb) this.report.cb();
      this.report.cb = null;
      console.log(`idle: all done!`, this.report.data)
      this.requestUpdate("report");
    }
    //console.log(`had ${time}, tried ${chunk} (=${this.time_per_chunk}*${time}), took ${elapsed} = ${elapsed / chunk}/row`);
  }
  processData(data, nolimit, raw_data) {
    console.log(`processing ${data.length} results...`, data)
    this.report = {
      ...this.report,
      status: 'processing',
      data: [...(this.report.data && !this.report.invalid ? this.report.data : [])],
      raw_data,
      end: data.length < this.report.limit || nolimit,
      invalid: false
    };

    if (this.report.row_func) {
      this.handle = requestIdleCallback(deadline => this.idleProcess(deadline, [], data));
    } else {
      this.report = {
        ...this.report,
        status: 'ready',
        data: [...this.report.data ? this.report.data : [], ...data],
        remaining: 0
      };
      if (this.report.cb) this.report.cb();
      this.report.cb = null;
      console.log(`process: all done!`, this.report.data)
      this.requestUpdate("report");
    }
  }

  colOpts({ sort, filter }) {
    let sort_cols = new Set(sort.map(s => s.col));
    this.report.sort = [...sort, ...this.report.sort.filter(s => !sort_cols.has(s.col))].filter(s => s.reverse !== undefined);

    let filters = new Set(filter.map(f => `${f.col}:::${f.op}`));
    this.report.filter = [...filter, ...this.report.filter.filter(f => !filters.has(`${f.col}:::${f.op}`))].filter(f => f.val !== undefined);

    this.report.offset = undefined;
    this.report.invalid = true;
    this.moreData();
    this.requestUpdate('report');
  }
  addSort(col) {
    let current = (this.report.sort ? this.report.sort : []); //.map((col, reverse) => ({col: col, num: reverse+0}));
    let old = current.find(s => s.col === col);
    let num = old ? old.reverse + 1 : 0;
    this.report.sort = [...[{ col: col, num: num }].filter(c => num < 2).map(({ col, num }) => ({ col: col, reverse: num > 0 })), ...current.filter(s => s.col !== col)];
    console.log(`${this.report.name}: new sort == ${col}== `);
    console.log(this.report.sort);
    this.report.offset = undefined;
    this.report.invalid = true;
    this.moreData();
    this.requestUpdate('report');
  }


  /*
  showTable(report) {
    this.setTitle(report.name);
    let t = this.reports.get(report.name);
    report = t ? t : report;
    if (this.ready)) {
      this.report = this.reports.get(report.name);
    } else {
      console.log("not ready");
      report.cb = () => this.showTable(report);
      this.prepareTable(report);
    }
  }

  // NOT YET USED
  printTable() {
    if (this.ready) {
        //FIXME: do something here?
    } else {
      report.cb = () => this.showTable(report);
      this.prepareTable(report);
    }
  }*/

  /*
  _saveFile(data, filename, type) {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      var a = document.createElement("a");
      var url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 60000);
    }
  }*/

  //const worksheet = XLSX.utils.aoa_to_sheet(arrayOfArray);


  _saveXLSX(name, fields, data) {
    name = name.replace(/[\\\/?*\][]/g, '-');
    let all_data = [[...fields], ...data];
    const fitToColumn = (arrayOfArray) => arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] !== null && a2[i] !== undefined ? (typeof (a2[i]) === 'object' && a2[i].constructor.name === 'Date' ? a2[i].toLocaleDateString() : a2[i].toString()).length : 0)) }));
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(all_data);

    let d2 = XLSX.utils.sheet_to_json(ws, { header: 1 });

    console.log("data", data);
    console.log("data", d2);

    ws['!cols'] = fitToColumn(d2);
    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, name);
    console.log("XLS WB", wb);
    XLSX.writeFile(wb, name + ".xlsx");
  }


  downloadTable() {
    if (this.ready) {
      //this.report = this.reports.get(report.name);
      if (this.report.end) {
        let colinfo = [...(this.report.download_columns ? this.report.download_columns : this.report.columns).map(f => ({ ...f }))];
        let fields, data;
        if (colinfo.some(c => c.compare)) {
          // table has some comparisons
          console.log(this.report.data)
          colinfo.forEach(col => {
            col.need_compare = col.compare && this.report.data.some(row => row.id && !row[col.c + '_match']);
          });
          fields = colinfo.reduce((cols, col) =>
            col.need_compare
              ? [...cols,
              { field: col.c, sub: 'sys', name: `${col.c} [new]` },
              { field: col.c, sub: 'ref', name: `${col.c} [old]` },
              { field: col.c, sub: 'match', name: `${col.c} [match]` }
              ]
              : [...cols,
              col.compare ? { field: col.c, sub: 'sys', name: col.c } : { field: col.c, name: col.c }
              ], []);
          data = this.report.data.map(d => fields.map(f => f.sub ? d[f.field + '_' + f.sub] : d[f.field]));
          fields = fields.map(f => f.name);
        } else {
          console.log("COLINFO", colinfo);
          fields = colinfo.map(f => f.c);
          data = this.report.data.map(d => colinfo.map(({ c, t }) => xlsx_formatters[t](d[c], { c, t }, d)));
        }
        this._saveXLSX(`${this.report.name}${this.report.subname ? ' ' + this.report.subname(this.report.args) : ''}`, fields, data);
      } else {
        this.report.cb = () => this.downloadTable();
        this.moreData(true);
      }
    } else {
      this.report.cb = () => this.downloadTable();
      this.prepareTable();
    }
  }
}

window.customElements.define('report-view', ViewReport);
export { ViewReport }
