import { LitElement, html, css } from 'lit';
import { Button } from '@material/mwc-button';

import { wait, transitionEnd, animationEnd } from './utilities/anim.js';

const snackbar_timeout = 7000;
const snackbar_styles = css`
        :host {
          font-family: 'Roboto', 'Noto', sans-serif;
          --shadow-level-one: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
         --shadow-level-onepointone: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4); 
         --shadow-level-two: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4); 
         --shadow-transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), filter 0.28s cubic-bezier(0.4, 0, 0.2, 1);

        }

        div.snack {
          position: fixed;
          bottom: 48px;
          left: 48px;
          width: 344px;
          background-color: var(--paper-grey-700);
          --mdc-theme-primary: var(--paper-pink-100);
          color: white;
          border-radius: 5px;
          z-index: 100;
          box-shadow: var(--shadow-level-two);

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          transition: opacity 0.3s ease 0s, transform 0.5s ease 0s;
          transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        }
        div.snack {
          visibility: hidden;
        }
        div.snack[active] {
          visibility: visible;
        }

        div.snack[state="closed"] {
          opacity: 0;
          transform: translateY(calc(100% + 48px));
        }
        div.snack[state="closed"][kind="upgrade"] {
          opacity: 0;
          transform: translateY(calc(-100% - 48px));
        }
        div.snack[state="open"] {
          opacity: 1;
          transform: translateY(0px);
        }

        div.snack[kind="error"] {
          background-color: var(--paper-red-700);
        }

        div.snack[kind="success"] {
          background-color: var(--paper-cyan-800);
        }
        div.snack[kind="upgrade"] {
          background-color: var(--paper-purple-800);
          --mdc-theme-primary: var(--paper-pink-100);
          height: fit-content;
          top: 48px;
          left: 48px;
        }

        div.text {
          margin: 16px;
        }
        div.action {
          margin: 8px;
        }
      `;

class KaleSnackbar extends LitElement {
  static styles = snackbar_styles

  static get properties() {
    return {
      current: { type: Object },
      active: { type: Boolean },
      state: { type: Object },
    };
  }
  constructor() {
    super();
    this.queue = [];
    this.current = null;
    this.state = "closed";
  }

  async closeCurrent() {
    this.state = "closed";
    await this.updateComplete;
    let transition = transitionEnd(this.renderRoot.getElementById("current_snack"), 700);
    await transition;
  }
  doAction(action) {
    this.current = null;
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    action();
    let t = setTimeout(async () => { await this.showNext(); }, 200); 
  }

  async showSnack(s) {

    this.timeout = setTimeout(async () => {
      await this.closeCurrent();
      this.current = null;
      let t = setTimeout(async () => { await this.showNext(); }, 200);
    }, (s.duration ? s.duration * 1000 : snackbar_timeout));

    this.state = "closed";
    this.current = s;
    await this.updateComplete;
    await transitionEnd(this.renderRoot.getElementById("current_snack"), 700);
    this.state = "open";
    await this.updateComplete;
    await transitionEnd(this.renderRoot.getElementById("current_snack"), 700);
  }
  async showNext() {
    if (this.current) await this.closeCurrent();
    let next = this.queue.shift();
    if (next) this.showSnack(next);
  }
  async queueSnack(snack) {
    this.queue.push(snack);
    await this.showNext();
    return { cancel: async () => {
      if (!this.queue.find(s => s === snack) && !this.current === snack) return false;
      this.queue = [...this.queue.filter(q => q !== snack)];
      if (this.current === snack) {
        await this.closeCurrent(); //this.current = null;
        this.current = null;
      }
      return true;;
    }}
  }
  render() {
    const snack = this.current;
    return html`
        <div 
          id="current_snack" 
          class="snack"
          ?active=${this.current}
          state=${this.state}
          kind=${snack?.kind ?? 'normal'}>
        <div class="text">${snack?.text ?? ''}</div>
        ${snack?.action 
          ? html`<div class="action">
                  <mwc-button @click=${e => this.doAction(snack.action)}>
                    ${snack.action_text}
                  </mwc-button>
                </div>` 
          : html``}
        </div>
    `;
  }

}


window.customElements.define('kale-snackbar', KaleSnackbar);
export { KaleSnackbar }
