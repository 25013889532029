import { LitElement, html, css } from 'lit';
import '@material/mwc-icon';
import '@material/mwc-button';
import { PersonInfo, EditPersonInfo, EditEmploymentInfo } from '../queries/queries.js';
import { KaleForm } from '../shared-components/form.js';

//TODO: candidate for utility library
export const isEmptyObj = (obj) => (Object.entries(obj).length === 0 && obj.constructor === Object);

const person_edit_form_style = css`
        .group {
          padding: 0 4px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: left;
          flex-direction: row;
        }
        .one-line {
          flex-wrap: nowrap;
          max-width: 100%;
          overflow: hidden;
        }
        .group > * {
          flex: 1 1;
        }

        .narrow {
        }
        .wide {
        }
        div.card-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row; 
          margin-bottom: 0;
        }


        .label {
          font-size: 70%;
          font-weight: bold;
          font-variant: small-caps;
          opacity: 0.75;
          display: inline-block;
          width: 5em;
          vertical-align: middle;
          line-height: 0.9em;
          position: relative;
          bottom: 0.15em;

        }

        kale-enum {
        }

`

class PersonEditForm extends KaleForm {
  static styles = person_edit_form_style
  get form_name() { return "PersonEditForm" }
  static get properties() {
    return {
      person: { type: Object },
      personid: { type: String },
      noredirect: { type: Boolean },
      nodeceased: { type: Boolean },
      employment: { type: Boolean },
      ...(super.properties)
    }
  }
  updated(props) {
    if (props.has('personid')) {
      this.subscription.query({ PersonId: this.personid });
    }
  }

  constructor() {
    super();
    this.employment_data = { employment_type_code: "FT" };
    this.subscription = new PersonInfo(p => { this.person = p; }, { PersonId: null });
    this.mutation = new EditPersonInfo(
      p => { this.person = p; console.log(`first: ${this.needs_redirect}, ${this.noredirect}, ${this.personid}, ${!this.noredirect && !this.personid}`); this.needs_redirect = this.needs_redirect || (!this.noredirect && !this.personid); this.personid = p.id; },  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
        if (this.employment && this.employment_data.employer_code && this.employment_data.employment_type_code && this.employment_data.start_date) {
          this.employment_mutation.save(this.employment_data, { person_id: this.person.id });
        }
        this.saveComplete(Object.keys(p));
        this.dispatchEvent(new CustomEvent('person-saved', { detail: { person: p } }));
        //if (!this.personid && this.person && !this.noredirect) { //create mode
        if (this.needs_redirect) {
          window.pushPath(`/people/edit?person=${this.person.id}`)
        }
        this.reset();
      },
      (e, msgs) => { // error handler
        //console.log("person-save error handler");
        this.dispatchEvent(new CustomEvent('save-error', { detail: { person: this.person, error: e, msgs: msgs } }));
        //console.log("dispatched event");
      });

    this.employment_mutation = new EditEmploymentInfo(
      e => this.employment_data = e,  // data update function
      { changeMap: null },  //initial variables
      e => { // finalizing function
        //console.log("employment data finalized", e);
        this.dispatchEvent(new CustomEvent('employment-saved', { detail: { employment: e } }));
      });

  }
  render() {
    return html`
            <div class="group" id="name">
              <kale-enum style="flex: 0 1 15%" .label=${"Title"} .field=${'person_title_code'} .value=${this.person && this.person.title ? this.person.title.code : null}></kale-enum>
              <kale-textfield style="flex: 1 0 20%" .label=${"First"} .field=${'first_name'} .value=${this.person ? this.person.first_name : null}></kale-textfield>
              <kale-textfield style="flex: 0 1 20%" .label=${"Middle"} .field=${'middle_name'} .value=${this.person ? this.person.middle_name : null}></kale-textfield>
              <kale-textfield style="flex: 1 0 30%" .label=${"Last"} .field=${'last_name'} .value=${this.person ? this.person.last_name : null}></kale-textfield>
              <kale-enum style="flex: 0 1 15%" .label=${"Suffix"} .field=${'person_suffix_code'} .value=${this.person && this.person.suffix ? this.person.suffix.code : null}></kale-enum>
            </div>

            <div class="group one-line">
              <kale-ssn .label=${"SSN"} .field=${'ssn'} .value=${this.person ? this.person.ssn : null}></kale-ssn>
              <kale-date required .label=${"DOB"} .field=${'birth_date'} .value=${this.person ? this.person.birth_date : null}></kale-date>
              ${!this.nodeceased ? html`<kale-date .label=${"Deceased"} .field=${'deceased_date'} .value=${this.person ? this.person.deceased_date : null}></kale-date>` : html``}
            </div>
            <div class="group" id="personal">
              <kale-enum .label=${"Marital Status"} .field=${'person_marital_status_code'} .value=${this.person && this.person.marital_status ? this.person.marital_status.code : null}></kale-enum>
              <kale-enum .label=${"Gender"} .field=${'person_gender_code'} .value=${this.person && this.person.gender ? this.person.gender.code : null}></kale-enum>
            </div>

            <kale-contact .title=${'Participant Contact Information'}  .contacts=${this.person ? [...this.person.addresses, ...this.person.phones, ...this.person.emails] : []} .personid=${this.personid}></kale-contact>

            ${this.employment ? html`

            <div class="group" id="employment">
              <kale-enum .label=${"Initial Employer"} .field=${'employer_code'}></kale-enum>
              <kale-enum .label=${"Type"} .field=${'employment_type_code'} .value=${"FT"} .default=${"FT"}></kale-enum>
              <kale-date .label=${"Start Date"} .field=${'start_date'}></kale-date>
            </div>
            ` : html``}

    `;

    /*
    <kale-emails .title=${'Email'} .emails=${this.person ? this.person.emails : []} .personid=${this.person && this.person.id ? this.person.id : null}></kale-emails>
    <kale-phones .title=${'Phone'} .phones=${this.person ? this.person.phones : []} .personid=${this.person && this.person.id ? this.person.id : null}></kale-phones>
    <kale-addresses .title=${'Addresses'} .addresses=${this.person ? this.person.addresses : []} .personid=${this.person && this.person.id ? this.person.id : null}></kale-addresses>
    */
  }

  async save_impl(data) {
    this.employment_data = Object.assign(this.employment_data, { employer_code: data.employer_code, employment_type_code: data.employment_type_code ? data.employment_type_code : "FT", start_date: data.start_date });
    // Filter out the employment fields from the person fields:
    console.log("edit person save impl", { ...data }, Object.keys(data))
    if (isEmptyObj(data)) {
      return {};
    }
    data = Object.assign(...Object.keys(data).filter(k => !this.employment_data[k]).map(k => ({ [k]: data[k] })));
    console.log("saving person and empl", { ...data }, { ...this.employment_data });
    let ret = await this.mutation.save(data, this.person);

    console.log("got ret", this.personid, this.person, this.noredirect);
    if (!this.personid && this.person && !this.noredirect) { //create mode
      //console.warn("ALT PUSHING PATH");
      //window.pushPath(`/people/edit?person=${this.person.id}`)
    }
    return ret;
  }
}


window.customElements.define('edit-person', PersonEditForm);
export { PersonEditForm }
