import { LitElement, html, css, nothing } from 'lit';
import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-menu';
import '@material/mwc-drawer';
import '@material/mwc-textfield';
import '@material/mwc-top-app-bar-fixed';
import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import '../components/edit-person.js';

const page_styles = css`
        :host {
          font-family: 'Roboto', 'Noto', sans-serif;
          width: 100%;
          height: 100%;
          overflow: hidden;

          --top-bar-color: var(--paper-green-700);
          --top-bar-text: white;
          --default-theme-primary: #6200ee;
          --default-theme-on-primary: #ffffff;
          --app-bar-count: 1;
          --app-bar-height: 64px;
          --app-bar-offset: calc(var(--app-bar-count) * var(--app-bar-height));
        }

        .default-padding {
            padding: 24px;
        }

        .flex-vertical {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
        }
        .flex-horizontal {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
        }

        #content {
          width: 100%;
          /*height: calc(100% - calc(var(--app-bar-count) * var(--app-bar-height)));*/
          height: calc(100vh - var(--app-bar-offset));
          overflow: overlay;
        }
        .menu_container {
            position: relative;
            width: 100%;
            text-align: right;

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: center;

            --mdc-theme-primary: var(--top-bar-text, white);
        }
        [slot=actionItems] {
            --mdc-theme-primary: var(--top-bar-text, white);

        }

        #kebab_menu {
            position: relative;
            top: -18px;
        }
    
        #title {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: center;
        }
        #title > *:last-child {
            margin-right: 18px;
        }

        a.parent-title {
            font-weight: 100;
            color: var(--top-bar-text, white);
        }
        #main_title {

        }
        #subtitle {
        }

        
        [slot=actionItems][toggled] {
            color: --mdc-theme-on-primary;
            background-color: red;
            border-radius: 100%;
        }



        mwc-tab-bar {
          background-color: var(--paper-teal-200);
          --mdc-theme-primary: var(--paper-purple-800); /* active text color */
          --mdc-theme-secondary: var(--paper-purple-800); /* indicator icon color */
          max-width: margin-right: 48px;
          overflow-x: overlay;
        }

        .tab-bar-back {
          background-color: var(--paper-teal-200);
          box-shadow: var(--mdc-top-app-bar-fixed-box-shadow, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12));
          position: fixed;
          height: 48px;
          z-index: 3;
          width: 100%;
          max-width: 100vw;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          --mdc-theme-primary: var(--paper-purple-800); /* active text color */
          --mdc-theme-secondary: var(--paper-purple-800); /* indicator icon color */
        }
        .tab-bar-back > mwc-icon-button {
          color: var(--paper-purple-700);
        }
        div.tab-bar {
          transform: translateY( calc(-48px + var(--proj-tab-pos) ) );
          transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
          height: 48px;
          width: 100%;
        }

        @media print {
            * {
                display: none !important;
            }

            mwc-top-app-bar-fixed, #content, #left-col, projection-report {
                display: inline !important;
            }

            #content {
                overflow: visible;
            }

            
        }


`;

class KalePage extends LitElement {
    static styles = page_styles
    static default_title = "Template"
    static icon = "title"
    actions = [
        {
            name: 'Preferences',
            icon: 'account_circle',
            //link: '/prefs'
            action: () => window.location.href = '/prefs',
            kebab_only: true
        },
        {
            name: 'Sign out',
            icon: 'exit_to_app',
            action: () => this.dispatchEvent(new CustomEvent('signout', { bubbles: true, composed: true, detail: null })),
            kebab_only: true
        },
    ]
    constructor() {
      super();
      this.title = this.constructor.default_title
    }
    /*

            <div class="column">
              <div class="card">
              prefs
              </div>
            </div>

    */
    static constructAddress(location, args) {
        return location.href;
    }
   
    get extraToolbar() { return false }

    renderMenu() {

    }

    renderPage() {
        return html``
    }
    renderTitle() {
        return html`<span id="main_title">${this.title}</span>`
    }

    showKebabMenu() {
        const button = this.renderRoot.getElementById('kebab_button');
        const menu = this.renderRoot.getElementById('kebab_menu');
        menu.anchor = button;
        menu.show();
    }
    //<a href="/" slot="navigationIcon" style="color: white"><mwc-icon-button title="Home" icon="arrow_back" ></mwc-icon-button></a>

    openNav(e) {
        this.dispatchEvent(new CustomEvent('nav-drawer', { bubbles: true, composed: true, detail: null }))
    } 
    openHelp(e) {
        this.dispatchEvent(new CustomEvent('help-panel', { bubbles: true, composed: true, detail: null }))
    }
    evalItem(item) {
        const { link, action, name, icon, full, kebab_only } = item;
        return {
            action,
            link: typeof(link) === 'function' ? link() : link,
            name: typeof(name) === 'function' ? name() : name,
            icon: typeof(icon) === 'function' ? icon() : icon,
            full: typeof(full) === 'function' ? full() : full,
            kebab_only: typeof(kebab_only) === 'function' ? kebab_only() : kebab_only,
        }
    }
    renderActionItem(item) {
        const { link, action, name, icon, full, kebab_only, toggled } = item;
        if (kebab_only) return '';
        if (action) {
            return full ? html`<mwc-button ?toggled=${toggled} slot="actionItems" title=${name} icon=${icon} @click=${action}>${name}</mwc-button>` : html`<mwc-icon-button ?toggled=${toggled} slot="actionItems" title=${name} icon=${icon} @click=${action}></mwc-icon-button>`;
        }

    }
    renderMenuItem(item) {
        const { link, action, name, icon } = item;
        if (action) {
            return html`<mwc-list-item @request-selected=${action} graphic="icon">${icon ? html`<mwc-icon slot="graphic">${icon}</mwc-icon>` : ''}${name}</mwc-list-item>`
        }
    }
    renderTopContent() {
        return nothing
    }
    renderActionItems(actions){
        actions = actions.map(k => this.evalItem(k));
        const top_bar_actions = actions.filter(k => !k.kebab_only);
        const kebab_actions = actions.filter(k => k.kebab_only);
        return html`
            ${top_bar_actions.map(k => this.renderActionItem(k))}
            <mwc-icon-button slot="actionItems" title="More" id="kebab_button" icon="more_vert" @click=${this.showKebabMenu}>
            <mwc-menu absolute corner="BOTTOM_START" id="kebab_menu">${kebab_actions.map(k => this.renderMenuItem(k))}</mwc-menu>
            </mwc-icon-button>
            `;
    }
    get top_content_height() {
        return css`64px`;
    }

                            //--app-bar-count: ${1 + (extraToolbar ? 1 : 0) + (this.topcontent !== nothing ? 1 : 0)};
                            //--app-bar-offset: calc(var(--app-bar-count) * var(--app-bar-height));

    render() {
        const { icon } = this.constructor;
        const { title, subtitle, actions, scroller, extraToolbar, parentpage: { path=null, elem=null } } = this;
        let { topcontent } = this.renderTopContent();
        return html`
     
                <mwc-top-app-bar-fixed 
                        id="top_bar" 
                        class="scrolltarget"
                        style=${css`
                            --app-bar-offset: ${this.top_content_height};
                            --mdc-theme-primary: var(--top-bar-color);
                            --mdc-theme-on-primary: var(--top-bar-text);
                            position: relative;
                            `}
                        >
                    <mwc-icon-button slot="navigationIcon" title="Navigation" id="nav_drawer_button" icon="menu" @click=${e => this.openNav(e)}></mwc-icon-button>
                    <div slot="title" id="title">
                        ${elem ? html`<a href=${path} class="parent-title">${elem?.title}</a><mwc-icon>navigate_next</mwc-icon>` : nothing}
                        ${icon && false ? html`<mwc-icon >${icon}</mwc-icon>` : nothing }${this.renderTitle()}
                    </div>
                    <mwc-icon-button slot="actionItems" title="Help" id="help_button" icon="help" @click=${e => this.openHelp(e)}></mwc-icon-button>
                    ${this.renderActionItems(actions)}
                    ${this.renderTopContent()}
                    <div id="content" 
                            @scroll=${e => this.forwardScrollEvents(e, this.renderRoot.getElementById("content"))}
                            style="--mdc-theme-primary: var(--default-theme-primary); --mdc-theme-on-primary: var(--default-theme-on-primary);"
                    >
                        ${this.renderPage()}
                    </div>
                </mwc-top-app-bar-fixed>


        `;
                    /*
                    ${this?.extraToolbar ? html` <mwc-top-app-bar-fixed class=${this.extraToolbar ? "scrolltarget" : ""} id="second_bar"> ${this.extraToolbarContent()} </mwc-top-app-bar-fixed > ` : nothing }
                        class=${this.extraToolbar ? "" : "scrolltarget"}
                    */
        /*<div style="position: relative; top: -4px">
                    <div class="menu_container" slot="actionItems" >
                    </div>

style="font-size: 150%; position: relative; top: 8px; margin-right: 12px;"
        */
    }


    /*

    get scroller() { 
        console.warn("get SCROLLER:", this._scroller);
        return this._scroller;
    }

    set scroller(target) {
        this._scroller = target;
        console.warn("set SCROLLER:", this._scroller);
        this.requestUpdate('scroller');
        const bar = this.renderRoot.querySelector('.scrolltarget');
        //this.scroller = this.renderRoot.getElementById('content');
        console.warn("FOUND SCROLL TARGET:", bar, bar?.scrollTarget === target);
        console.warn(bar?.scrollTarget);
        console.warn(target);
        //bar.scrollTarget = target;
    }
    */

    firstUpdated() {
        //this.scroller = this.renderRoot.getElementById('content');
    }
    forwardScrollEvents(e, s) {
        const bars = this.renderRoot.querySelectorAll('.scrolltarget');
        const scroller = s ?? e?.path?.[0];
        for (let bar of bars) {
            if (scroller && bar.scrollTarget !== scroller) bar.scrollTarget = scroller;
            bar.handleTargetScroll(e)
        }
    }


    activateRoute() {
    }

    static get properties() {
        return {
            search_results: { type: Array },
            title: {type: String },
            subtitle: {type: String },
            parentpage: {type: Object },
        };
    }
}

window.customElements.define('kale-page', KalePage);
export { KalePage }

