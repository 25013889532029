import gql from 'graphql-tag';
import { 
  person_fields,
  client,
  timeline_cache_fragment,
  formatQueryError,
  get_pension_factors
} from '../queries/queries.js';
import * as Comlink from 'comlink';

const BGPS = 'background: #ffc627; color: #43495a; font-size: smaller; padding: 3px; border-radius: 3px;';

const BASE_BATCH = 100;
const BASE_WAIT = 100;
const MAX_BATCH = 300;
const MIN_BATCH = 100;
const MIN_WAIT = 50;
const MAX_WAIT = 200;
const GOAL_COMPLETE_DURATON = 1000 * 60 * 60 * 0.5; // 1/2 hour

const random_todo_list = gql`
  query todos($block_size: Int!) {
    view_needs_recompute_randomized(limit: $block_size){
      id
      computed_date
      expires_date
      remaining_count
      person {
        ...PersonFields
      }
    }
  }
  ${person_fields}
`
const upsert_timeline_cache_query = gql`
  mutation process_updates($timelines: [timeline_cache_insert_input!]!, $timeline_cols: [timeline_cache_update_column!]!) {
    timeline_cache:insert_timeline_cache (
      objects: $timelines,
      on_conflict: {
        constraint: timeline_cache_pkey,
        update_columns: $timeline_cols
      })
      {
      returning {
        ...CacheFields
      }
    }
  }
  ${timeline_cache_fragment}
`

const global_worker = new Worker(new URL('/src/benefits-app/pension-timeline-worker.js', import.meta.url), { type: 'module' });

class ProcessPending {
  constructor(notify_cb = () => null) {
    //super();
    console.log("%cProcessor booting...", BGPS);
    this.notify_cb = notify_cb;
    this.pending_data = [];
    this.errors = [];
    this.in_process = null;
    this.processed_and_uploaded = 0;
    this.worker = Comlink.wrap(global_worker);

    this.initial_count = 0;
    this.start_time = (new Date()*1); 
    this.eta = this.start_time + GOAL_COMPLETE_DURATON;
    this.last_batch_start = this.start_time;
    this.last_batch_initial_count = 0;
    this.base_wait = BASE_WAIT;
    this.batch_size = BASE_BATCH;

    //this.show_logs = true;
    this.continue();
  }

  get timeout() {
    let timeout = 100;
    return timeout;
  }

  continue(last_op) {
    let timeout = this.base_wait;
    if (last_op === 'upload' && this.pending_data.length === 0 ) {
      // just finished a batch, pause and do some calculation and
      // aim to download all batches within reasonable timespan
      const now = new Date()*1;
      const elapsed = now - this.start_time;
      const last_batch_duration = now - this.last_batch_start;
      this.last_batch_start = now;
      const last_batch_size = this.processed_and_uploaded - this.last_batch_initial_count;
      this.last_batch_initial_count = this.processed_and_uploaded;

      const rate = last_batch_duration / last_batch_size;
      const eta = rate * this.remaining_count;
      const est_total_time = elapsed + eta;
      this.eta = this.start_time + est_total_time;
      if (this.show_logs) console.log(`%ceta: ${eta/1000/60}m, total:${est_total_time/1000/60}m, goal: ${GOAL_COMPLETE_DURATON/1000/60}m`, BGPS);
      if (est_total_time > GOAL_COMPLETE_DURATON) { // too slow
        this.base_wait = this.base_wait/2;
        this.base_wait = this.base_wait < MIN_WAIT ? MIN_WAIT : this.base_wait;
        this.batch_size = Math.ceil(this.batch_size * 1.2);
        this.batch_size = this.batch_size > MAX_BATCH ? MAX_BATCH : this.batch_size;
        if (this.show_logs) console.log(`%c reducing baseline delay to ${timeout/1000}s, upping batch size to ${this.batch_size}`, BGPS);
      } 
      if (est_total_time < GOAL_COMPLETE_DURATON*0.8) { // too fast
        this.base_wait = this.base_wait * 1.2;
        this.base_wait = this.base_wait > MAX_WAIT ? MAX_WAIT : this.base_wait;
        this.batch_size = Math.ceil(this.batch_size * 0.8);
        this.batch_size = this.batch_size < MIN_BATCH ? MIN_BATCH : this.batch_size;
        if (this.show_logs) console.log(`%c increasing baseline delay to ${timeout/1000}s, dropping batch size to ${this.batch_size}`, BGPS);
      } 
      // wait at least 30s between batches to allow GC to clean up
      timeout = 30*1000 + this.base_wait * 10;
      if (this.show_logs) console.log(`%cinterstage timeout = ${timeout / 1000}s`, BGPS);
    }
    if (this.no_work) {
      timeout = 1000*60*10;  // 10m
    }
    this.operation_pending = false;
    window.setTimeout(() => {
      window.requestIdleCallback(() => this.run_process());
    }, timeout)
  }

  stop() {
    this.stopped = true;
  }

  run_process() {
    if (this.stopped || this.operation_pending) {
      return;
    }
    this.operation_pending = true;
    if (this.pending_data.length === 0 && !this.in_process) {
      this.fetch_work();
      return;
    }
    if (this.in_process?.done) {
      this.upload_work();
      return;
    }
    if (!this.in_process) {
      this.begin_work().then(() => this.continue("begin"));
      return;
    }
    this.continue();
  }
  async fetch_work() {
    client.query({
      fetchPolicy: 'no-cache',
      query: random_todo_list,
      variables: { block_size: this.batch_size },
    }).then(data => {
      const inner_data = data.data.view_needs_recompute_randomized;
      if (inner_data.length > 0) {
        this.no_work = false;
        this.remaining_count = inner_data?.[0].remaining_count;
        if (this.remaining_count >= this.initial_count) {
          this.initial_count = this.remaining_count;
        }
        this.last_batch_initial_count = this.processed_and_uploaded;
        if (this.show_logs) console.log(`%cfetch work: found ${inner_data.length}/${this.remaining_count}/${this.processed_and_uploaded}/${this.initial_count}`, BGPS);
        this.pending_data = inner_data.map(d => d.person);
        this.notify_cb({
          eta: this.eta,
          total: this.initial_count,
          complete: (this.initial_count - this.remaining_count),
          remaining: (this.remaining_count)
        });
      } else {
        console.log("%cfetch work: no more work to fetch", BGPS);
        this.no_work = true;
      }
      this.continue("fetch");
    })
      .catch(error => {
        console.error(`%cfetch work: error ${error}`, BGPS);
        this.continue("fetch");
      });
  }
  async upload_work() {
    if (this.show_logs) console.log("%cupload work", BGPS, this.in_process.processed_data.person_id);
    let now = new Date();
    let timeline = this.in_process.processed_data;
    let data = {
      nodes: timeline.nodes.map(n => ({ ...n, edit_data: n.edit_data ? true : n.edit_data, edit_series_data: n.edit_series_data ? true : n.edit_series_data })),
      expires_date: timeline.expires ? timeline.expires : new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
      person_id: this.in_process.raw_data.id,
      state: timeline.state,
      report: timeline.report,
      next_year: timeline.next_year,
      errors: timeline.errors,
      years: timeline.years,
      computed_date: now
    };
    client.mutate({
      fetchPolicy: 'no-cache',
      mutation: upsert_timeline_cache_query,
      variables: {
        timelines: [data],
        timeline_cols: ['computed_date', 'expires_date', 'state', 'next_year', 'report', 'errors', 'nodes', 'years']
      },
    }).then(async data => {
      let result = data.data.timeline_cache.returning;
      this.in_process = null;
      client.queryManager.mutationStore.store = {};
      this.processed_and_uploaded += 1;
      this.notify_cb({
        eta: this.eta,
        total: this.initial_count,
        complete: (this.initial_count - this.remaining_count) + (this.processed_and_uploaded - this.last_batch_initial_count),
        remaining: (this.remaining_count) - (this.processed_and_uploaded - this.last_batch_initial_count),
      });
      this.continue("upload");
    })
      .catch(error => {
        formatQueryError(error);
        console.error(`%cfetch work: error ${error}`, BGPS);
        this.in_process.error = error;
        this.errors.push(this.in_process);
        this.in_process = null;
        this.continue("upload");
      })
  }
  async begin_work() {
    this.in_process = {
      raw_data: this.pending_data.pop(),
      processed_data: null,
      done: false
    }
    let item = this.in_process.raw_data;
    if (!item) {return;}
    let build = Number(window.localStorage.getItem('benefits_build_number'));
    const factors = await get_pension_factors();
    let runner = await (new this.worker(this.in_process.raw_data, null, build, factors));
    try {
      await runner.run(null, null, null);
      let final = await runner.result();
      this.in_process.processed_data = final;
      this.in_process.done = true;
    } catch (e) {
      this.in_process.error = e;
      this.errors.push(this.in_process);
      this.in_process = null;
    }
  }
}

export { ProcessPending }
