import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';


export const participation_change_fields = gql`
  fragment ParticipationChangeFields on participation_change {
    id
    person_id
    form_date
    participation_change_type_code
    change_type {
      code
      name
    }
   }`

export const participation_change_info_query = gql`
  query participation_change_info($ParticipationChangeId: uuid!){
    participation_change( where: {id: {_eq: $ParticipationChangeId}} ){
         ...ParticipationChangeFields
    }
  }
  ${participation_change_fields}
`

export class ParticipationChangeInfo extends StaticQuery {
  get required_impl() {
    return ['ParticipationChangeId'];
  }
  get gql_impl() {
    return participation_change_info_query;
  }
  data_impl({ data: { participation_change: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const participation_changes_for_person_query = gql`
  query person_participation_changes($PersonId: uuid!) {
    participation_change (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...ParticipationChangeFields
    }
  }
  ${participation_change_fields}
  `

export class ParticipationChange extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return participation_changes_for_person_query;
  }
  data_impl({ data: { participation_change: participation_changes } }) {
    return participation_changes;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_participation_change_query = gql`
  mutation update_participation_change($participation_changeId: uuid!, $changeMap: participation_change_set_input!) {
    update_participation_change (
      where: {
          id: {_eq: $participation_changeId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...ParticipationChangeFields
      }
    }
  }
  ${participation_change_fields}
`
export const insert_participation_change_query = gql`
  mutation insert_participation_change($changeMap: participation_change_insert_input!) {
    insert_participation_change (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...ParticipationChangeFields
      }
    }
  }
  ${participation_change_fields}
`

export const upsert_participation_change_query = gql`
  mutation upsert_participation_change($changeMap: participation_change_insert_input!, $changeCols: [participation_change_update_column!]!) {
    participation_change:insert_participation_change (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: participation_change_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...ParticipationChangeFields
      }
    }
  }
  ${participation_change_fields}
`

export const delete_participation_change_query = gql`
  mutation delete_participation_change($deleteId: uuid!) {
    delete_participation_change (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditParticipationChangeInfo extends SaveableQuery {
  get friendly_name() { return "Participation" }
  get required_keys_impl() {
    return ["id", "person_id", "form_date", "participation_change_type_code"];
  }
  data_impl({ data: { participation_change: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_participation_change_query;
  }
  get delete_gql_impl() {
    return delete_participation_change_query;
  }
  get save_queries_impl() {
    return [(new ParticipationChange(null, null))];
  }
}

