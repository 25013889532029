import { LitElement, html, css, unsafeCSS } from 'lit';
import { Icon } from '@material/mwc-icon';
import { Button } from '@material/mwc-button';
import { TextField } from '@material/mwc-textfield';

import { KaleCard } from './card.js';
import { wait, transitionEnd, animationEnd } from './utilities/anim.js';
import './utilities/authmgr.js';
import { fromEntries } from './utilities/fromentries.js';
import { bgURL, logoURL } from '../benefits-app/images.js';

const login_box_style = css`
        :host {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;

          --afscme-blue: #005daa;
          --afscme-green: #00a94f;
          --mdc-theme-primary: var(--afscme-blue);
        }
        host[state='LOGGED IN'] {
          display: none;
        }

        #bg { 
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
        }

        /*
        #bg[transition_out] {
          top: -100vh;
        }*/

        .cover {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-size: cover;

        }
        #bgimage {
          background-color: blue;
          /*background-image: url(/src/img/retirement-cocktail-moz-tiny.jpg); *//* FIXME: use a variable with fallback  */

          /*background-image: url(/src/img/beautiful-tropical-beach-sea.webp);*/ /* FIXME: use a variable with fallback  */
          background-image: url(${unsafeCSS(bgURL)});

          /* <a href="https://www.freepik.com/free-photo/beautiful-tropical-beach-sea_4123380.htm#query=beach%20bed&position=0&from_view=keyword">Image by lifeforstock</a> on Freepik<a href="https://www.freepik.com></a> */
          filter: blur(1px) contrast(50%) saturate(120%) ;
          filter: blur(8px) contrast(70%) saturate(30%) brightness(120%) ;
                  }

        #bgscrim {
          background-color: var(--afscme-green);
          background-color: var(--paper-grey-900);
          filter: saturate(50%) ;
          opacity: 0.0;
        }

        #header-logo {
          background-image: url(${unsafeCSS(logoURL)});
          width: 100%;
          height: 28px;
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
        }

        #page {
          background-color: none;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row; 
          overflow: hidden;
        }

        #page[hidden] {
         display: none;
        }

        .wide {
          width: 250px;
        }

        kale-card {
          /*@apply(--layout-flex);*/
          position: relative;
          width: fit-content;
          height: fit-content;

          /*min-width: 20vw;
          left: 40vw;
          top: 30vh;
          left: 100px;
          
          */
          --paper-card-header: {
              background: #e9437a;
          };

        }
        div.card-content {
        }

        mwc-textfield {
          width: auto;
          display: block;
        }

        mwc-textfield + mwc-textfield {
          margin-top: 22px;
        }

        div.card-actions {
          padding-top: 1em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row; 
          width: 100%;
        }
        div.login-content {
          display: flex;
          flex-direction: row;
        }

        div.login-content > * {
          padding-left: 1em;
        }
        div.login-content > *:first-child {
          padding-left: 0em;
        }

        div.single-signon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: var(--paper-grey-600);
          margin-top: -1.2em;
        }

        div.single-signon mwc-button {
          --mdc-theme-primary: var(--paper-green-800);
        }

        div.sign-on-message {
          max-width: 10em;
          padding-bottom: 1em;
          width: fit-content;
          text-align: center;
        }

        div.or-divider {
          border-right: 1px solid var(--paper-grey-600);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          left: -0.5em;

          margin-right: 1em;
          margin-left: 1em;
        }

        div.or-divider:after {
          content: "or";
          position: relative;
          left: 0.5em;
          background-color: white;
          color: var(--paper-grey-600);
        }

        form > * + * {
          margin-top: 22px;
        }

        div.header {
          background-color: var(--mdc-theme-primary, #6200ee);
          color: white;
          font-size: 140%;
          font-weight: 50;
          padding: 12px 20px 12px 20px;
          text-transform: uppercase;
          width: 100%;
          white-space: nowrap;
          
        }



        @keyframes shake {
          10%, 90% {
            transform: translate3d(-1px, 0, 0);
          }

          20%, 80% {
            transform: translate3d(2px, 0, 0);
          }

          30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
          }

          40%, 60% {
            transform: translate3d(4px, 0, 0);
          }
        }


        @keyframes fade_error {
          0% { opacity: 1; }
          80% { opacity: 1; }
          100% { opacity: 0; }
        }

        

        #bg {
          transition: all 0.3s ease 0s;
          transition-timing-function: cubic-bezier(.4, 0, .2, 1);
          transform: translateY(0);
        }
        #bg[offscreen] {
          transform: translateY(-100vh);
        }

        #logincard {
          transition: transform 0.3s ease 0s;
          transition-timing-function: cubic-bezier(.4, 0, .2, 1);
          transform: translateY(0);
        }
        #logincard[offscreen] {
          transform: translateY(100vh);
        }
        
        #logincard[shake]{
          animation: shake 0.6s cubic-bezier(.36,.07,.19,.97) both;
          transform: translate3d(0, 0, 0);
          backface-visibility: hidden;
          perspective: 1000px;
        }

        #scrim {
          background-color: black;
          opacity: 0.50;
          transition: opacity 0.3s ease 0s;
          transition-timing-function: cubic-bezier(.4, 0, .2, 1);
          opacity: 0.5;
        }
        #scrim[hidden] {
          opacity: 0;
        }

        .error {
          font-weight: bold;
          color: var(--paper-red-800);
          margin-left: 10px;
          flex: 1;
          opacity: 1;
        }
        .error[fade]{
          opacity: 0;
          animation: fade_error 3s cubic-bezier(.36,.07,.19,.97) both;
        }
        .welcome {
          color: var(--paper-grey-700);
          font-weight: 100;
          margin-bottom: 22px;
        }
`

class LoginBox extends LitElement {
  static styles = login_box_style

  constructor() {
    super();
    this.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        this.attemptLogin();
      }
    }, { passive: true });

    this.bootstrap();

    /*
    let search;
    if (window.location.search && window.location.search !== "") { search = fromEntries(new URLSearchParams(window.location.search).entries()); }
    this.initial_registration = window.location.pathname === '/register' && search && search.u && search.c ? { email: search.u, pw: search.c } : null;
    if (this.initial_registration) {
      this.doRegistrationLogin(this.initial_registration);
    } else {
      //this.state = "LOGGED OUT";
      this.attemptRehydrate();
    }
    */
  }

  get state() { return this.__state; }
  set state(new_state) {
    if (new_state === undefined) new_state = this.__state;
    const transition = `[${this.__state}] => [${new_state}]`;
    //console.log("LOGIN:", transition);
    if (this.__state === new_state) return;

    this.requestUpdate('state');//, this.__state, new_state);
    this.requestUpdate();//, this.__state, new_state);
    this.__state = new_state;
    // this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: message && message.success ? message.success : `${this.friendly_name ? this.friendly_name : this.constructor.name} ${create ? 'created' : 'saved'}` } })); // TODO: undo

    switch (transition) {
      case '[undefined] => [LOGGED OUT]':
      case '[undefined] => [SET PASSWORD]':
      case '[undefined] => [PW RECOVER]':
      case '[undefined] => [RECOVERY REQUEST]':
      case '[undefined] => [MFA CHALLENGE]':
      case '[MFA CHALLENGE] => [LOGGED OUT]':
      case '[SET PASSWORD] => [LOGGED OUT]':
      case '[LOGGED OUT] => [PW RECOVER]':
      case '[LOGGED OUT] => [SET PASSWORD]':
      case '[LOGGED OUT] => [RECOVERY REQUEST]':
      case '[RECOVERY REQUEST] => [PW RECOVER]':
      case '[RECOVERY REQUEST] => [LOGGED OUT]':
      case '[PW RECOVER] => [LOGGED OUT]':
        break;

      case '[LOGGED IN] => [LOGGED OUT]':
        this.show();
        break;

      case '[undefined] => [LOGGED IN]':
        this.hide(false);
        break;
      case '[LOGGED OUT] => [LOGGED IN]':
      case '[MFA CHALLENGE] => [LOGGED IN]':
      case '[SET PASSWORD] => [LOGGED IN]':
        this.tried_saved = false;
        this.hide();
        break;

      case '[LOGGED OUT] => [MFA CHALLENGE]':
      case '[LOGGED OUT] => [SET PASSWORD]':
      case '[MFA CHALLENGE] => [SET PASSWORD]':
      case '[LOGGED IN] => [MFA CHALLENGE]':
      case '[LOGGED IN] => [SET PASSWORD]':
      case '[SET PASSWORD] => [MFA CHALLENGE]':
      default:
        console.error("ILLEGAL/UNEXPECTED TRANSITION", transition);
        break;
    }

    return this.__state;
  }

  async awaitStateChange() {
    const { state, login, error } = await window.authmgr.stateChange();
    this.state = state;
    this.login = login;
    this.handleError(error);
  }

  get is_animating() {
    return this._animating;
  }
  set is_animating(a) {
    let old = this._animating;
    if (old != a) {
      this._animating = a;
      this.requestUpdate("is_animating", old);
    }
  }

  email_input(email) {
    if (!this._internal_input) {
      this._internal_input = true;
      this.__login_email_text = email;
      this._internal_input = false;
    }
  }
  password_input(pw) {
    if (!this._internal_input) {
      this._internal_input = true;
      this.__login_password_text = pw;
      this._internal_input = false;
    }
  }
  recovery_code_input(code) {
    if (!this._internal_input) {
      this._internal_input = true;
      this.__recovery_code_text = code;
      this._internal_input = false;
    }
  }
  mfa_code_input(code) {
    if (!this._internal_input) {
      this._internal_input = true;
      this.__mfa_code_text = code;
      this._internal_input = false;
    }
  }
  check_errors(pw1, pw2) {
    let empty1 = !pw1 || pw1 === '';
    let empty2 = !pw2 || pw2 === '';
    if (empty1 && empty2) return null;
    if (!empty1 && pw1.length < 12) return 'too short';
    if (!empty2 && pw2 !== pw1) return 'passwords don\'t match';
    return null;
  }

  renderRegistrationForm() {
    return html`
              <div class="card-content">
                ${this.initial_registration ? html`<div class="welcome">Welcome, ${this.login.name}. Please choose a password.</div>` : html``}
                  <mwc-textfield outlined type="password" id="pass" label="password" helper=${"12 characters minimum"} .pattern=${".{12,}"} @input=${e => { this.pw1 = e.target.value; }}></mwc-textfield>
                  <mwc-textfield outlined type="password" id="pass2" label="re-enter" .pattern=${".{12,}"} .invalid=${this.pw1 !== this.pw2} @input=${e => { this.pw2 = e.target.value; }}></mwc-textfield>
              </div>
              <div class="card-actions">
                <div style="flex: 1 1"></div>
                <mwc-button ?disabled=${this.pw1 !== this.pw2 && this.pw1.length >= 12} login_button unelevated @click="${async (e) => this.completePasswordSetup(e)}">${this.initial_registration ? "Register" : "Set"}</mwc-button>
              </div>`;

  }
  renderRecoveryRequestForm() {
    return html`
            <div class="card-content">
              <mwc-textfield outlined id="email" name="email" autocomplete="email" label="user email" icontrailing="email" .value=${ (() => this.__login_email_text ? this.__login_email_text : '')()} @input=${e => this.email_input(e.target.value)}></mwc-textfield>
            </div>
            <div class="card-actions">
              <mwc-button left @click=${e => { this.state = "LOGGED OUT" }}>cancel</mwc-button>
              <mwc-button login_button unelevated @click=${e => { this.makeRecoveryRequest() }}>send code</mwc-button>
            </div>
            `;
  }

  renderRecoveryForm() {
    return html`
            <div class="card-content">
                <mwc-textfield outlined id="recovery_code" helper="check your email for the recovery code" name="recovery_code" label="code"  @input=${e => this.recovery_code_input(e.target.value)}></mwc-textfield>
                <mwc-textfield outlined type="password" id="pass" label="new password" helper=${"12 characters minimum"} .pattern=${".{12,}"} @input=${e => { this.pw1 = e.target.value; }}></mwc-textfield>
                <mwc-textfield outlined type="password" id="pass2" label="re-enter" .pattern=${".{12,}"} .invalid=${this.pw1 !== this.pw2} @input=${e => { this.pw2 = e.target.value; }}></mwc-textfield>
            </div>
            <div class="card-actions">
              <mwc-button left @click=${e => { this.state = "LOGGED OUT" }}>cancel</mwc-button>
              <mwc-button login_button unelevated @click=${e => { this.completeRecovery() }}>confirm</mwc-button>
            </div>
            `;
  }

  renderMFAForm() {
    return html`
            <div class="card-content">
                <mwc-textfield outlined id="mfa_code" name="mfa_code" label="code"  @input=${e => this.mfa_code_input(e.target.value)}></mwc-textfield>
            </div>
            <div class="card-actions">
              <mwc-button left @click=${e => { this.state = "LOGGED OUT" }}>cancel</mwc-button>
              <mwc-button login_button unelevated @click=${e => { this.completeMFA() }}>login</mwc-button>
            </div>
            `;
  }

  renderLoginForm() {
    return html`
      <div class="login-content">
        <div class="single-signon">
          <div class="sign-on-message">Sign in with AFSCME.org credentials:</div>
          <div>
              <mwc-button login_button unelevated @click=${e => window?.authmgr?.attemptAFSCMESSO()}>AFSCME STAFF</mwc-button>
          </div>
        </div>
        <div class="or-divider"></div>
        <div class="manual-signon">
          <div class="card-content">
              <mwc-textfield outlined id="email" name="email" autocomplete="email" label="email" icontrailing="email" @focus=${e => this.trySavedCredentials()}  @input=${e => this.email_input(e.target.value)}></mwc-textfield>
              <mwc-textfield  outlined type="password" id="pass" name="password" label="password" icontrailing="visibility_off" autocomplete="current-password" @focus=${e => this.trySavedCredentials()} @input=${e => this.password_input(e.target.value)}></mwc-textfield>
          </div>
          <div class="card-actions">
            <mwc-button dense @click=${e => { this.state = "RECOVERY REQUEST" }}>recover password&hellip;</mwc-button>
            <mwc-button login_button unelevated @click=${e => { this.attemptLogin() }}>login</mwc-button>
          </div>
        </div>
      </div>
    `;
  }

  renderForm() {
    switch (this.state) {
      case 'SET PASSWORD':
        return this.renderRegistrationForm();
      case 'RECOVERY REQUEST':
        return this.renderRecoveryRequestForm();
      case 'PW RECOVER':
        return this.renderRecoveryForm();
      case undefined:
        return html``;
      default:
      case 'LOGGED OUT':
        return this.renderLoginForm();
    }
  }

  renderHeader() {
    switch (this.state) {
      case 'SET PASSWORD':
        return "new user setup"
      case 'RECOVERY REQUEST':
      case 'PW RECOVER':
        return "recover password"
      default:
      case 'LOGGED OUT':
        return `${this.header}`;
    }
  }
  /*${this.error_message ? html`<div class="error" id="errorbox">${this.error_message}</div>` : html``}*/
  render() {
    return html`
        <header class="container cover" id='page' ?hidden=${!this.is_animating && this._hidden}>
          <div class="cover" id='scrim' ?hidden=${this._hidden}></div>
          <div id="bg" ?offscreen=${this._hidden}>
            <div class="cover" id='bgimage'></div>
            <div class="cover" id='bgscrim'></div>
          </div>
          <kale-card  id='logincard' ?offscreen=${this._hidden || this.state === undefined}>
            <div class='header'>
              <span id="header-logo"></span>
            ${this.renderHeader()}</div>
            ${ this.renderForm()}
          </kale-card>
        </header>
  `;
  }

  async doRegistrationLogin(info) {
    const { state, login, error } = await window.authmgr.login({ id: info.email, password: info.pw });
    this.state = state;
    this.login = login;
    if (error) {
      this.handleError("Bad registration link");
      this.state = "LOGGED OUT";
    }
  }

  handleError(message) {
    if (message) {
      this.shake();
      this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: message, kind: 'error' } }));
      console.warn("LOGIN BOX ERROR: ", message);
    }
  }
  successMessage(message) {
    if (message) {
      this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: message, kind: 'success', duration: 2.5 } }));
    }
  }

  async bootstrap() {
    await this.attemptSSOCompletion() 
    || await this.attemptRegistrationLogin()
    || await this.attemptRehydrate();
  }

  async attemptRegistrationLogin() {
    let search;
    if (window.location.search && window.location.search !== "") { search = fromEntries(new URLSearchParams(window.location.search).entries()); }
    this.initial_registration = window.location.pathname === '/register' && search && search.u && search.c ? { email: search.u, pw: search.c } : null;

    if (this.initial_registration) {
      this.doRegistrationLogin(this.initial_registration);
      return true;
    } 
    return false;
  }

  async attemptSSOCompletion() {
    const { state, login, error } = await window.authmgr.completeSSO(location);
    this.handleError(error);
    this.state = state;
    this.login = login;
    return state;
  }

  async attemptRehydrate() {
    const { state, login, error } = await window.authmgr.rehydrate();
    this.state = state;
    this.login = login;
    this.handleError(error);
    return state;
  }

  async attemptLogin(e) {
    const { state, login, error } = await window.authmgr.login({ id: this.__login_email_text, password: this.__login_password_text });
    this.state = state;
    this.login = login;
    this.handleError(error);
    return state;
  }

  async trySavedCredentials() {
    if (this.tried_saved) return;
    this.tried_saved = true;
    const email = this.renderRoot.getElementById('email');
    const pw = this.renderRoot.getElementById('pass');
    const cred = await window.authmgr.getSavedCredentials();

    if (cred && cred.id) {
      email.value = cred.id;
      this.email_input(cred.id);
      if (!cred.password) pw.focus();
    }

    if (cred && cred.password) {
      pw.value = "XXXXXXXXXXXXXXXX"; // simulate pw entry 
      const { state, login, error } = await window.authmgr.login(cred);
      this.state = state;
      this.login = login;
      this.handleError(error);
      if (!error) {
        await this.updateComplete;
      }
    }
  }

  async makeRecoveryRequest() {
    if (this.__login_email_text) {
      const { state, login, error } = await window.authmgr.forgot_password(this.__login_email_text);
      this.state = state;
      this.login = login;
      this.handleError(error);
    }
    else {
      this.handleError("no email specified");
    }
  }

  async completeRecovery() {
    if (!this.__recovery_code_text || this.__recovery_code_text === '') {
      await this.shake();
      this.handleError('no code entered');
      return;
    }
    const ok = await this.checkNewPassword();
    if (ok) {
      const { state, login, error } = await window.authmgr.complete_forgotten_password(this.__recovery_code_text, this.pw1);
      this.state = state;
      this.login = login;
      this.handleError(error);
      if (!error) {
        await this.updateComplete;
        this.successMessage("password change succeeded");
      }
    }
  }

  async completeMFA() {
    if (!this.__mfa_code_text || this.__mfa_code_text === '') {
      await this.shake();
      this.handleError('no code entered');
      return;
    }
    const { state, login, error } = await window.authmgr.complete_mfa_challenge(this.__mfa_code_text);
    this.state = state;
    this.login = login;
    this.handleError(error);
  }

  async checkNewPassword() {
    if (this.pw1 !== this.pw2 || !this.pw1 || this.pw1 === '' || this.pw1.length < 12) {
      await this.shake();
      if (!this.pw1 || this.pw1 === '') {
        this.handleError("no password entered!")
      } else if (this.pw1.length < 12) {
        this.handleError("not enough characters!")
      } else if (this.pw1 !== this.pw2) {
        this.handleError("passwords don't match!")
      }
      return false;
    } else {
      return true;
    }
  }

  async completePasswordSetup(e) {
    const ok = await this.checkNewPassword();
    if (ok) {
      const { state, login, error } = await window.authmgr.complete_password_setup(this.pw1);
      this.state = state;
      this.login = login;
      this.handleError(error);
      if (!error) {
        await this.updateComplete;
        this.successMessage("registration complete");
      }
    }
  }

  async shake() {
    const card = this.renderRoot.getElementById('logincard');
    card.setAttribute('shake', true);
    await animationEnd(card);
    card.removeAttribute('shake');
  }

  async show() {
    this.dispatchEvent(new CustomEvent('login', { bubbles: true, composed: true, detail: { state: this.__state, login: { user: this.login ? this.login.user : '' } } }));
    const card = this.renderRoot.getElementById('logincard');
    const bg = this.renderRoot.getElementById('bg');
    const scrim = this.renderRoot.getElementById('scrim');
    const pw = this.renderRoot.getElementById('pass');
    if (pw) pw.value = "";
    this.is_animating = true;
    await this.updateComplete;
    this._hidden = false;
    let transitions = Promise.all([transitionEnd(card), transitionEnd(bg), transitionEnd(scrim)]);
    await transitions;
    this.is_animating = false;
  }

  async hide(animate = true) {
    if (animate) {
      const card = this.renderRoot.getElementById('logincard');
      const bg = this.renderRoot.getElementById('bg');
      const scrim = this.renderRoot.getElementById('scrim');
      let transitions = Promise.all([transitionEnd(card), transitionEnd(bg), transitionEnd(scrim)]);
      this.is_animating = true;
      this._hidden = true;
      await transitions;
    } else {
      this.is_animating = false;
      this._hidden = true;
    }
    this.is_animating = false;
    this.dispatchEvent(new CustomEvent('login', { bubbles: true, composed: true, detail: { state: this.__state, login: { user: this.login ? this.login.user : '' } } }));
    this.awaitStateChange();
  }

  static get properties() {
    return {
      pw1: { type: String },
      pw2: { type: String },
      header: { type: String }
    };
  }
}

window.customElements.define('login-box', LoginBox);
export { LoginBox }
