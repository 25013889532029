import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

export const contribution_fields = gql`
  fragment ContributionFields on contribution {
    person_id
    id
    contribution_date
    eoy_base_salary
    ytd_base_pay
    ee_pension_contribution
    pension_salary
    made_up
    employer_code
   }`

export const contribution_info_query = gql`
  query contribution_info($ContributionId: uuid!){
    contribution( where: {id: {_eq: $ContributionId}} ){
         ...ContributionFields
    }
  }
  ${contribution_fields}
`

export class ContributionInfo extends StaticQuery {
  get required_impl() {
    return ['ContributionId'];
  }
  get gql_impl() {
    return contribution_info_query;
  }
  data_impl({ data: { contribution: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const contributions_for_person_query = gql`
  query contribution_info($PersonId: uuid!) {
    contribution (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...ContributionFields
    }
  }
  ${contribution_fields}
  `

export class PersonContributions extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return contributions_for_person_query;
  }
  data_impl({ data: { contribution: contributions } }) {
    return contributions;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_contribution_query = gql`
  mutation update_contribution($contributionId: uuid!, $changeMap: contribution_set_input!) {
    update_contribution (
      where: {
          id: {_eq: $contributionId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...ContributionFields
      }
    }
  }
  ${contribution_fields}
`
export const insert_contribution_query = gql`
  mutation insert_contribution($changeMap: contribution_insert_input!) {
    insert_contribution (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...ContributionFields
      }
    }
  }
  ${contribution_fields}
`

export const upsert_contribution_query = gql`
  mutation upsert_contribution($changeMap: contribution_insert_input!, $changeCols: [contribution_update_column!]!) {
    contribution:insert_contribution (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: contribution_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...ContributionFields
      }
    }
  }
  ${contribution_fields}
`

export const delete_contribution_query = gql`
  mutation delete_contribution($deleteId: uuid!) {
    delete_contribution (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditContributionInfo extends SaveableQuery {
  get friendly_name() { return "Contribution" }
  get required_keys_impl() {
    return ["id", "person_id", "contribution_date", "eoy_base_salary", "ee_pension_contribution", "pension_salary"];
  }
  data_impl({ data: { contribution: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_contribution_query;
  }
  get delete_gql_impl() {
    return delete_contribution_query;
  }
  get save_queries_impl() {
    return [(new PersonContributions(null, null))];
  }
}

