import { LitElement, html, css, nothing } from 'lit';
import { KalePage } from '../shared-components/page.js';
import { KaleForm } from '../shared-components/form.js';
import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-textfield';

import { client, formatQueryError } from '../queries/queries.js';
import gql from 'graphql-tag';

import '../shared-components/vertical-slider.js';
import '../components/edit-person.js';
import { GenericPopupEditor } from '../components/editors.js';

const enum_configs = [
  {
    name: "Employers",
    dialog: "Employer Code",
    table: 'employer_codes',
    pkey: "employer_codes_pkey",
    required: ['code', 'name'],
    fields: [
      {
        name: 'code',
        default: 'CODE',
        type: 'text'
      },
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'deprecated',
        display: 'hide',
        type: 'bool'
      },
      {
        name: 'type',
        default: 'user',
        type: 'enum',
        values: [
          'user',
          'admin',
          'viewer']
      }]
  },
  {
    name: "Adjustment Types",
    dialog: "Adjustment Type",
    table: 'service_adjustment_type_codes',
    pkey: "service_adjustment_type_codes_pkey",
    required: ['code', 'name'],
    fields: [
      {
        name: 'code',
        default: 'CODE',
        type: 'text'
      },
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'deprecated',
        display: 'hide',
        type: 'bool'
      }]
  },
  {
    name: "States",
    dialog: "State Code",
    table: 'address_state_codes',
    pkey: "address_state_codes_pkey",
    required: ['code', 'name'],
    fields: [
      {
        name: 'code',
        default: 'CODE',
        type: 'text'
      },
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'deprecated',
        display: 'hide',
        type: 'bool'
      }]
  },
  {
    name: "Address Statuses",
    dialog: "Address Status",
    table: 'address_status_codes',
    pkey: "address_status_codes_pkey",
    required: ['code', 'name'],
    fields: [
      {
        name: 'code',
        default: 'CODE',
        type: 'text'
      },
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'deprecated',
        display: 'hide',
        type: 'bool'
      }]
  },
  {
    name: "Phone Statuses",
    dialog: "Phone Status",
    table: 'phone_status_codes',
    pkey: "phone_status_codes_pkey",
    required: ['code', 'name'],
    fields: [
      {
        name: 'code',
        default: 'CODE',
        type: 'text'
      },
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'deprecated',
        display: 'hide',
        type: 'bool'
      }]
  },
  {
    name: "Email Statuses",
    dialog: "Email Status",
    table: 'email_status_codes',
    pkey: "email_status_codes_pkey",
    required: ['code', 'name'],
    fields: [
      {
        name: 'code',
        default: 'CODE',
        type: 'text'
      },
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'deprecated',
        display: 'hide',
        type: 'bool'
      }]
  },
  {
    name: "Employment Types",
    dialog: "Employment Type",
    table: 'employment_type_codes',
    pkey: "employment_type_codes_pkey",
    required: ['code', 'name'],
    fields: [
      {
        name: 'code',
        default: 'CODE',
        type: 'text'
      },
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'eligible_prior_to_sep1_1995',
        type: 'bool'
      },
      {
        name: 'eligible_after_sep1_1995',
        type: 'bool'
      },
      {
        name: 'full_time',
        type: 'bool'
      },
      {
        name: 'deprecated',
        display: 'hide',
        type: 'bool'
      }]
  },
  {
    name: "Suspension Types",
    dialog: "Suspension Type",
    table: 'suspension_type_codes',
    pkey: "suspension_type_codes_pkey",
    required: ['code', 'name'],
    fields: [
      {
        name: 'code',
        default: 'CODE',
        type: 'text'
      },
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'short_name',
        type: 'text',
        default: 'short'
      },
      {
        name: 'limit_service_break',
        type: 'bool'
      },
      {
        name: 'paid',
        type: 'bool'
      },
      {
        name: 'unlimited',
        type: 'bool'
      },
      {
        name: 'deprecated',
        display: 'hide',
        type: 'bool'
      }]
  },
  {
    name: "Beneficiary Relationships",
    dialog: "Beneficiary Relationship",
    table: 'beneficiary_relationship_codes',
    pkey: "beneficiary_relationship_codes_pkey",
    required: ['code', 'name'],
    fields: [
      {
        name: 'code',
        default: 'CODE',
        type: 'text'
      },
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'from_beneficiary_perspective',
        display: 'member is their:',
        type: 'text'
      },
      {
        name: 'deprecated',
        display: 'hide',
        type: 'bool'
      }]
  },
  {
    name: "Users",
    dialog: "User",
    id: 'email',
    table: 'users',
    deletable: true,
    pkey: "users_pkey",
    required: ['name', 'email', 'type'],
    fields: [
      {
        name: 'name',
        default: 'enter name',
        type: 'text'
      },
      {
        name: 'email',
        default: 'enter email',
        type: 'text'
      },
      {
        name: 'type',
        default: 'user',
        type: 'enum',
        values: [
          'user',
          'admin',
          'viewer']
      }]
  },
];

const enum_config_styles = css`
        /* TABLE STYLES */
        :host {
          display: flex;
          flex-direction: column;
          width: fit-content;
          overflow: hidden;
        }
        :host > * {
          margin-top: 24px;
        }

        .table-container { 
          flex: 1 1;
          box-sizing: border-box;
          height: 100%;
          margin-bottom: 12px;
          position: relative;
          /*FIXME: this isn't optimal... */ 
          overflow-y: hidden;
          display: flex;
          flex-direction: column;
          justify-content: end;
          overflow-x: hidden;
          overflow: hidden;
        }
        .table-scroller {
          min-width: fit-content;
          flex: 1
          overflow-x: hidden;
          overflow-y: hidden;
         }
        table { 
          box-sizing: border-box;
          border-collapse: collapse;
          min-width: 40vw;
          overflow-x: visible;
          overflow: hidden;
        }
        td,th {
          border: none;
          padding: 28px 28px;
          text-align: left;
        }
        th {
          text-align: left;
          text-transform: uppercase;
          border: none;
          font-size: 90%;
          opacity: 0.7;
        }

        /*
        tr { border: none}
        tr:nth-child(even) { background: #CCC;}
        */

        tr { border: none; border-bottom: 1px solid var(--paper-grey-400);}
        tbody > tr:hover {background-color: var(--paper-yellow-50); cursor: pointer;}

        #navigation {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          padding: 12px;
          padding-bottom: 0px;
        }

        #filter-icon {
          padding-left: 18px;
          }
        #filter-text {
          flex: 1 1;
          padding-left: 6px;
          --mdc-text-field-fill-color: white;
          --mdc-text-field-outlined-idle-border-color: red;
          --mdc-text-field-outlined-hover-border-color: green;
          --mdc-theme-primary: teal;
          margin-left: -10px;
        }

        #navigation-info {
          margin-right: 12px;
        }
        #navigation:last-child { margin-right : 12px }

        #add {
          color: var(--paper-pink-a200);
        }



        .editor {
          position: relative;
          top: -28px;
          left: -28px;
          width: calc(100% + 56px);
          height: 0;
          box-sizing: border-box;
          overflow: visible;
        }
        .hidden_escape {
          overflow: visible;
          position: fixed;
        }

        .inner-editor {
          box-sizing: border-box;
          position: absolute;
          left: 0;
          width: fit-content;
          padding: 12px;
          background-color: white;
          border: 1px solid var(--paper-grey-400);
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
          z-index: 2;
        }
        .inner-editor mwc-textfield {
          width: 100%;
        }

        .scrim {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: 1;
          background-color: black;
          opacity: 0.5;
        }

        .bool_column {
          text-align: center;
          max-width: 43px;
          padding: 10px 28px;
        }

        tr.new_row {
          background-color: pink;
        }
        td.default_val {
          font-style: italic;
        }



.card {
          box-sizing: border-box;
          background-color: white;
          border: 1px solid var(--paper-grey-400);
          border-radius: 8px;
          transition: var(--shadow-transition);
          font-size: 12px;
          overflow: hidden;

          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column; 

        }
        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }

        .filterbar {
          flex-direction: row; 
          align-items: center;
          /*padding: 12px;*/
          width: 100%;
        }

        .tablecard {
          overflow-y: hidden;
          overflow-x: visible;
          max-height: 100%;
          margin-bottom: 24px;
          width: fit-content;
        }
`;


const enum_dlg_styles = css``;
/*
{
  name: "Employers",
  table: 'employer_codes',
  pkey: "employer_codes_pkey",
  fields: [
    {
      name: 'code',
      default: 'CODE',
      type: 'text'
    },
    {
      name: 'name',
      default: 'enter name',
      type: 'text'
    },
    {
      name: 'deprecated',
      display: 'hide',
      type: 'bool'
    }]
},*/
export class EnumDialog extends GenericPopupEditor {
  get form_name() { return "EnumEditor" }
  get title() { return this.config.name }
  get mutation_type() { return Object; }
  /*
  get data_property() { return this.employment; }
  get default() { return { employment_type_code: 'FT' } }
  set data_property(p) { this.employment = p; }
  get id_property() { return this.employmentid; }
  get id_property_name() { return "employmentid" }
  set id_property(id) { this.employmentid = id; }
  get subscription_args() { return { EmploymentId: this.employmentid ? this.employmentid : null } }
  get header_class() { return "employment"; }
  */
  constructor() {
    super();

  }

  required(field) { return false; } //field !== 'end_date' }

  static get properties() {
    return {
      config: { type: Object },
      ...(super.properties)
    }
  }

          //.value=${f.default ?? null}>
  getForm() {
    return html`

      ${this.config.fields.filter(f => f.type !== 'hidden').map(f => {
        console.log("rendering", f);
        switch (f.type) {
          case 'text': {
            return html`
      <div class="group">
        <kale-textfield
          .label=${f.display ?? f.name}
          .field=${f.name}
          ?required=${this.config.required.some(r => r === f.name)} 
        ></kale-textfield>
      </div>
            `;
          }
            /*
            return html`<td class=${unsaved && f.default && f.default === d[f.name] ? 'default_val' : ''} @click=${e => this.showEditor(e, f.name, d)}>
                                          ${this.editor && this.editor[0] === d && this.editor[1] === f.name
                ? html`
                          <div class="editor">
                            <div class="scrim" @click=${e => { e.stopPropagation(); this.editor = null }}></div>
                            <div class="inner-editor">
                              <mwc-textfield class="editor-field" 
                              code=${d.code} .fullWidth=${true} 
                              .value=${d[f.name]}
                              @cancel-edit=${e => this.editor = null}
                              @value-entered=${e => this.enterValue(d, f.name, e.detail.value)}
                              @keyup=${e => this.textFieldKey(d, f, e)}
                              >
                              </mwc-textfield>
                            </div>
                          </div>`
                : html``}
                                          ${((d, f) => { return d[f.name] })(d, f)}
                                        </td>`
                                        */
          case 'enum': {
            return html`
              <kale-enum
                .label=${f.display ?? f.name}
                .field=${f.name}
                ?required=${this.config.required.some(r => r === f.name)} 
                .values=${f.values}
                >
                </kale-enum>
`
          }
            /*
            return html`
            <td
              class=${unsaved && f.default && f.default === d[f.name] ? 'default_val' : ''}
              @click=${e => this.showEditor(e, f.name, d)}
            >
                ${this.editor && this.editor[0] === d && this.editor[1] === f.name
                ? html`
                          <div class="editor">
                            <div class="hidden_escape">
                            <div class="scrim" @click=${e => { e.stopPropagation(); this.editor = null }}></div>
                              <div class="inner-editor">
                                <mwc-select class="editor-selector">
                                ${f.values.map(v => html`
                                  <mwc-list-item
                                  value=${v}
                                  ?selected=${v === d[f.name]}
                                  @selected=${e => this.enterValue(d, f.name, v)}>${v}</mwc-list-item>
                                `)}
                                </mwc-select>
                              </div>
                            </div>
                          </div>`
                : html``}
                ${((d, f) => { return d[f.name] })(d, f)}
              </td>`
              */
          case 'bool': {
            return html`
    <div class="group">
                  <kale-toggle .label=${f.display ?? f.name} .field=${f.code}  .value=${this?.data?.[f.code]}></kale-toggle>
        </div>
                `
              }
                /*
                return html`<td class="bool_column"><mwc-checkbox .checked=${d[f.name]} @click=${e => this.enterValue(d, f.name, !d[f.name])}></mwc-checkbox></td>`
                */                  
            }})}
        </div>
    `
  }
}
window.customElements.define('enum-dialog', EnumDialog);

/*

        <div class="group">
              <kale-filtered-enum
                .label=${"Employer"}
                .field=${'employer_code'}
                ?required=${this.required('employer_code')}
                .value=${this.employment ? this.employment.employer.code : null}
                .default=${this.employment ? this.employment.employer.code : null}>
                </kale-filtered-enum>
              <kale-enum
                .label=${"Employment Type"}
                .field=${'employment_type_code'}
                ?required=${this.required('employment_type_code')}
                .default=${this.employment ? this.employment.employment_type.code : this.default.employment_type_code}
                .value=${this.employment ? this.employment.employment_type.code : null}
                >
                </kale-enum>
            </div>
            <div class="group">
              <kale-date .label=${"Start"} .field=${'start_date'} ?required=${this.required('start_date')}  .value=${this.employment ? this.employment.start_date : null}></kale-date>
              <kale-date .label=${"End"} .field=${'end_date'} ?required=${this.required('end_date')}  .value=${this.employment ? this.employment.end_date : null}></kale-date>
            </div>

            */



class EnumConfig extends KaleForm {
  static styles = enum_config_styles
  get form_name() { return "Enum Config" }

  render() {
    console.log(this.config.table, "rendering");
    console.log('rendering:', this.filtered ? this.filtered.slice(this.start_index, this.start_index + (this.items_per_page ? this.items_per_page : 100)) : 'no filter')
    return html`
      ${this.config ?

            //<mwc-icon id="filter-icon">search</mwc-icon>
        html`
        <enum-dialog .config=${this.config} .opened=${true} ></enum-dialog>
        <div class="card filterbar">
            <mwc-textfield
            icon="search"
            id="filter-text"
            @input=${e => this.filter = e.target.value}
            label=${`filter ${this.config?.name?.toLowerCase()}`}></mwc-textfield>
        </div>
        <div class="card tablecard">
      <div class="table-container">
        <div class="table-scroller">
          <table>
            <thead>
              <tr>
                ${this.config.fields.filter(f => f.type !== 'hidden').map(f => html`<th class=${`${f.type}_column`}>${f.display ? f.display : f.name.replace(/_/g, ' ', 0)}</th>`)} 
              </tr>
            </thead>
            <tbody>
            ${this.filtered ? this.filtered.slice(this.start_index, this.start_index + (this.items_per_page ? this.items_per_page : 100)).map(d => {

          //let has_defaults = this.config.fields.filter(f => f.default).some(f => f.default === d[f.name]);
          let unsaved = d.__typename === undefined;
          return html`
              <tr class=${ unsaved ? "new_row" : "normal_row"}>
                  ${this.config.fields.filter(f => f.type !== 'hidden').map(f => {
            switch (f.type) {
              case 'text':
                return html`<td class=${unsaved && f.default && f.default === d[f.name] ? 'default_val' : ''} @click=${e => this.showEditor(e, f.name, d)}>
                                              ${this.editor && this.editor[0] === d && this.editor[1] === f.name
                    ? html`
                              <div class="editor">
                                <div class="scrim" @click=${e => { e.stopPropagation(); this.editor = null }}></div>
                                <div class="inner-editor">
                                  <mwc-textfield class="editor-field" 
                                  code=${d.code} .fullWidth=${true} 
                                  .value=${d[f.name]}
                                  @cancel-edit=${e => this.editor = null}
                                  @value-entered=${e => this.enterValue(d, f.name, e.detail.value)}
                                  @keyup=${e => this.textFieldKey(d, f, e)}
                                  >
                                  </mwc-textfield>
                                </div>
                              </div>`
                    : html``}
                                              ${((d, f) => { return d[f.name] })(d, f)}
                                            </td>`
              case 'enum':
                return html`
                <td
                  class=${unsaved && f.default && f.default === d[f.name] ? 'default_val' : ''}
                  @click=${e => this.showEditor(e, f.name, d)}
                >
                    ${this.editor && this.editor[0] === d && this.editor[1] === f.name
                    ? html`
                              <div class="editor">
                                <div class="hidden_escape">
                                <div class="scrim" @click=${e => { e.stopPropagation(); this.editor = null }}></div>
                                  <div class="inner-editor">
                                    <mwc-select class="editor-selector">
                                    ${f.values.map(v => html`
                                      <mwc-list-item
                                      value=${v}
                                      ?selected=${v === d[f.name]}
                                      @selected=${e => this.enterValue(d, f.name, v)}>${v}</mwc-list-item>
                                    `)}
                                    </mwc-select>
                                  </div>
                                </div>
                              </div>`
                    : html``}
                    ${((d, f) => { return d[f.name] })(d, f)}
                  </td>`
              case 'bool':
                return html`<td class="bool_column"><mwc-checkbox .checked=${d[f.name]} @click=${e => this.enterValue(d, f.name, !d[f.name])}></mwc-checkbox></td>`

            }

          })}
              </tr>
            `}) : html``}
            </tbody>

          </table>
        </div>
        <div id="navigation">
        ${ this.items_per_page < this?.filtered?.length
          ? html`
          <div id="navigation-info">${this.start_index + 1}-${this.lastOnPage()} of ${this.filtered && this.filtered.length ? this.filtered.length : 'unknown'}</div>
          <mwc-icon-button ?disabled=${this.start_index <= 0} icon="chevron_left" @click=${e => this.prevPage()}></mwc-icon-button>
          <mwc-icon-button ?disabled=${!this.filtered || this.lastOnPage() + 1 > this.filtered.length} icon="chevron_right" @click=${e => this.nextPage()}></mwc-icon-button>
        ` : nothing }
          <mwc-icon-button id="add" icon="add_circle" @click=${e => this.addItem()}></mwc-icon-button>
        </div>

      </div>
        </div>
      `
        : html``}
    `
    // <td class="remove"><mwc-icon-button @click=${e => this.deleteContrib(c)} icon="delete"></mwc-icon-button></td>
    //${d[f]}

  }
  textFieldKey(d, f, e) {
    switch(e.key) {
      case 'Enter':
        let value = this.renderRoot.querySelector('.editor-field')?.value;
        console.log(value, "ENTER", d, f);
        this.enterValue(d, f.name, value);

        break;
      case 'Escape':
        console.log("ESCAPE");
        this.editor = null;
        break;
    }
  }
  addItem() {
    // TODO:
    let val = {};
    this.config.fields.forEach(f => {
      if (f.default) val[f.name] = f.default;
      //val[f.name] = f.default ? f.default : null;
    });
    this.values.push(val);
    console.log("new item", this.config.table, val, this.values);
    this.filter = this.filter;
    if (this.items_per_page === this.filtered.length - 1) {
      this.items_per_page = this.filtered.length;
      this.updateComplete.then(() => {
        this.computePageSize();
        if (this.items_per_page !== this.filtered.length) this.showLastPage();
      });
    } else {
      this.showLastPage();
    }
  }
  showLastPage() {
    let len = this.filtered.length + 1;
    this.start_index = len - (len % this.items_per_page);
  }
  showEditor(e, f, d) {
    console.log("showing editor", e, f, d);
    this.editor = [d, f];
    this.updateComplete.then(() => {
      let tf = this.renderRoot.querySelector(`.editor-field`);
      if (tf) {
        tf.blur();
        tf.focus();
      }
    });
  }
  enterValue(d, f, val) {
    console.log("value entered", d, f, val);
    this.editor = null;
    if (f === 'code') {
      this.changeCode(d, val);
    } else {
      this.saveData(d, f, val);
    }
  }

  changeCode(d, new_code, showsnack = true) {
    console.log("changecode", d, new_code);
    let old_code = String(d.code);
    if (!old_code) {
      console.log("switching to saveData path", d, new_code);
      this.saveData(d, "code", new_code);
      return;
    }
    let update_mutation = gql`
    mutation update_${this.config.table} {
        outdata:update_${this.config.table} ( where: {code: {_eq: "${d.code}"}},
                                              _set: { code: "${new_code}" })
                  { returning { ${this.config.fields.map(f => f.name).join('\n')} } } }`;
    client.mutate({
      mutation: update_mutation,
      refetchQueries: [`${this.config.table}_values`],
    }).then(data => {
      let n = data.data.outdata.returning[0];
      this.values = this.values.map(v => v.code === d.code ? n : v);
      this.filter = this.filter;
      if (showsnack) this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `Code updated`, action_text: 'undo', action: () => { this.changeCode(n, old_code, false) } } }));
    })
      .catch(error => {
        formatQueryError(error);
      });
  }

  saveData(d, f, val, showsnack = true) {
    let old_value = d[f];
    console.log("saveData", d, f, val, old_value);

    let save_mutation = gql`
        mutation upsert_${this.config.table}($changeMap: ${this.config.table}_insert_input!, $changeCols: [${this.config.table}_update_column!]!) {
        outdata:insert_${this.config.table} (
        objects: [ $changeMap ],
        on_conflict: {
          constraint: ${this.config.pkey},
          update_columns: $changeCols
        }
      ) {
        returning {
          ${this.config.fields.map(f => f.name).join('\n')}
        }
      }
    }
    `

    let save_data = {};
    Object.keys(d).filter(k => k !== "__typename").forEach(k => save_data[k] = d[k])
    save_data[f] = val;

    console.log("SAVING", save_data);
    client.mutate({
      mutation: save_mutation,
      variables: { changeMap: save_data, changeCols: [f] },// Object.keys(save_data).filter(k => k!=='code')},
      refetchQueries: [`${this.config.table}_values`],
    }).then(data => {
      d = data.data.outdata.returning[0];
      console.log("GOT RETURN DATA", d);
      console.log("CUR VALS", [...this.values]);
      console.log("CUR FILT", this.filter);
      this.values = [...this.values.map(v => v[this?.config?.id ?? 'code'] === d[this?.config?.id ?? 'code'] ? d : v)];
      this.requestUpdate("values");
      console.log("NEW VALS", [...this.values]);
      //this.values = this.values.map(v => v.code === d.code ? d : v);
      this.filter = this.filter;
      if (showsnack) this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `Item updated`, action_text: 'undo', action: () => { this.saveData(d, f, old_value, false) } } }));
    })
      .catch(error => {
        formatQueryError(error);
      });



    /*


    client.query({query: gql`
          query { values:${this.config.table} {
            ${this.config.fields.join('\n')}
          }}
      `})
          .then(
              data => 
                {
                }, 
              error => console.error("ENUM CONFIG failed to load values for", this.config.name)
              );
              */
  }
  set filter(f) {
    f = f ? f.trim().toLowerCase() : null;
    this._filter = f;
    let precount = this.filtered ? this.filtered.length : 0;
    let re = new RegExp(f?.toLowerCase()?.split(' ')?.join('.*'), 'i');
    if (!re) return this.values;
    this.filtered = this.values.filter(v => this.config.fields.filter(f => f.type === 'text' || f.type === 'enum').map(f => v[f.name]).join(' ').match(re));
    this.requestUpdate('filtered');
    if (precount !== this.filtered.length) this.start_index = 0; // set start to 0 if length changed
  }
  get filter() {
    return this._filter;
  }
  lastOnPage() {
    let last = this.start_index + this.items_per_page;
    let max = this.filtered ? this.filtered.length : 100;
    return last >= max ? max : last;
  }
  nextPage() {
    this.start_index += this.items_per_page;
    this.start_index = this.start_index > this.filtered.length ? this.filtered.length - (this.filtered.length - (this.filtered.length % this.items_per_page)) : this.start_index;
    this.start_index = this.start_index === this.filtered.length ? this.filtered.length - this.items_per_page : this.start_index;
  }
  prevPage() {
    this.start_index -= this.items_per_page;
    this.start_index = this.start_index < 0 ? 0 : this.start_index;
  }
  static get properties() {
    return {
      config: { type: Object },
      //values: { type: Array},
      filtered: { type: Array },
      hovered: { type: Boolean },
      editor: { type: Object },
      start_index: { type: Number },
      items_per_page: { type: Number },
      ...(super.properties)
    };
  }
  constructor() {
    super();
    this.start_index = 0;
    this.items_per_page = null;
  }

  async computePageSize() {
    let table = this.renderRoot.querySelector(".table-scroller");
    let max_offset = table.clientHeight;
    let bottom = table.getBoundingClientRect()?.bottom;
    console.log("CLIENT HEIGHT", max_offset);
    let rows = this.renderRoot.querySelectorAll("tbody > *");
    let max_row = 0;
    for (var r of rows) {
      let b = r.getBoundingClientRect()?.bottom;
      console.log(table, r, bottom, b);
      if (b > bottom){
        break;
      }

      if (r.offsetTop + r.offsetHeight > max_offset) {
        break;
      }
      max_row++;
    }

    let repeat = this.items_per_page !== max_row;
    this.items_per_page = max_row;
    console.log("ITEMS", this.items_per_page);
    
    if (repeat) {
      await this.updateComplete;
      this.computePageSize()
    }


  }

  cache = new WeakMap();

  loadData(data) {
    this.values = data.data.values;
    this.filter = null;
    let len = this.values.length;
    let size = len <= 3 ? 1 : len <= 8 ? 2 : 3;
    this.dispatchEvent(new CustomEvent('sizing', { bubbles: true, composed: true, detail: size }));
    this.updateComplete.then(() => { this.computePageSize(); })
  }


  updated(props) {
    if (props.has("config")) {
      console.log("config updated", this.config)
      this.items_per_page = null;
      if (this.cache.has(this.config)) {
        this.loadData(this.cache.get(this.config));
      } else {
        client.query({
          query: gql`
        query ${this.config.table}_values { values:${this.config.table} {
        ${this.config.fields.map(f => f.name).join('\n')}
          }}
      `})
        .then(
          data => {
            this.cache.set(this.config, data);
            this.loadData(data);
          },
          error => console.error("ENUM CONFIG failed to load values for", this.config.name)
        );

      }

    }
  }
}

window.customElements.define('enum-configurator', EnumConfig);
export { EnumConfig }

const app_settings_page_styles = css`
        .grid {
          margin: 24px;
          display: grid;
          grid-template-columns: repeat(3, calc(33vw - 36px));
          grid-auto-rows: 400px;
          grid-gap: 24px;
          grid-auto-flow: dense;
        }

        .span2 {
          grid-row-end: span 2;
        }
        .span3 {
          grid-row-end: span 2;
        }
        .card {
          box-sizing: border-box;
          background-color: white;
          border: 1px solid var(--paper-grey-400);
          border-radius: 8px;
          transition: var(--shadow-transition);
          font-size: 12px;
          overflow: hidden;

          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column; 

          margin: 24px;
          margin-bottom: 0px;
        }
        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }
        .top-app-bar-adjust {
          margin-top: 64px;
        }
        .card > h2 {
          padding-left: 24px;
        }
        enum-configurator {
          height: 100%;
          box-sizing: border-box;
        }

        .config_container {
          box-sizing: border-box;
          height: calc(100% - 0px);
          margin: 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }


`;

class AppSettingsPage extends KalePage {
  static styles = [super.styles, app_settings_page_styles]
  static icon = "settings"
  static default_title = "Settings"
  
  size_classes = new Map()

  OLDrenderPage() {
    return html`
            <div class="grid">
              ${enum_configs.map(c =>
      html`
                  <div class=${`card ${this.getSizeClass(c.table)}`} >
                    <h2>${c.name}</h2>
                    <enum-configurator .config=${c} @sizing=${e => this.setSizeClass(c.table, e.detail)}></enum-configurator>
                  </div>
              `
    )}
            </div>
        `;
  }

  set tab_index(i) {
    console.log("tab_index =>", i, enum_configs?.[i]?.name);
    this._tab_index = i;
    this.requestUpdate("tab");
  }
  get tab_index() {
    return this._tab_index;
  }

  get tab() {
    return enum_configs?.[this._tab_index];
  }


  get top_content_height() {
    return css`calc(${super.top_content_height} + 48px)`;
  }

  renderTopContent() {
    return html`<div class="tab-bar" ?projection=${this.projecting} ?transition=${this.ptransition}>
      <div class="tab-bar-back">
        <mwc-tab-bar .activeIndex=${this.tab_index} @MDCTabBar:activated=${e => this.tab_index = e.detail.index}>
          ${enum_configs.map(c => html`
            <mwc-tab label=${c?.name} > </mwc-tab>
          `)}
        </mwc-tab-bar>
      </div>
    </div>
    `;
  }


  /*
      <div class="card">
          <h2>${c.name}</h2>
      </div>
      <div class="card">
      </div>
      <div class=${`card ${this.getSizeClass(c.table)}`} >
      </div>
      */
  renderPage() {
    let c = enum_configs[this.tab_index];
    return html`
    <div class="config_container">
        <enum-configurator .config=${c} @sizing=${e => this.setSizeClass(c.table, e.detail)}></enum-configurator>
    </div>
        `;
  }


  setSizeClass(table, size) {
    this.size_classes.set(table, size);
    this.requestUpdate("size_classes");
  }
  getSizeClass(table) {
    return this.size_classes.has(table) ? `span${this.size_classes.get(table)}` : '';
  }

  activateRoute() {
    //Poke the search query whenever the page is reshown:
    //console.log("people-page activated");
    //this.updateSearchResults();
  }
  _tab_index = 0;

  static get properties() {
    return {
      search_results: { type: Array },
      ...(super.properties)
    };
  }
}

window.customElements.define('settings-page', AppSettingsPage);
export { AppSettingsPage }
