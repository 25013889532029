import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

export const withdrawal_fields = gql`
  fragment WithdrawalFields on withdrawal {
    id
    person_id
    withdrawal_date
    total
    interest
    taxable
    ytd_contributions
    withdrawal_type_code
    user_entered_fields
    withdrawal_type
     {
      name
      code 
      deprecated
    }
   }`

export const withdrawal_info_query = gql`
  query withdrawal_info($WithdrawalId: uuid!){
    withdrawal( where: {id: {_eq: $WithdrawalId}} ){
         ...WithdrawalFields
    }
  }
  ${withdrawal_fields}
`

export class WithdrawalInfo extends StaticQuery {
  get required_impl() {
    return ['WithdrawalId'];
  }
  get gql_impl() {
    return withdrawal_info_query;
  }
  data_impl({ data: { withdrawal: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const withdrawals_for_person_query = gql`
  query withdrawal_info($PersonId: uuid!) {
    withdrawal (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...WithdrawalFields
    }
  }
  ${withdrawal_fields}
  `

export class PersonWithdrawals extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return withdrawals_for_person_query;
  }
  data_impl({ data: { withdrawal: withdrawals } }) {
    return withdrawals;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_withdrawal_query = gql`
  mutation update_withdrawal($withdrawalId: uuid!, $changeMap: withdrawal_set_input!) {
    update_withdrawal (
      where: {
          id: {_eq: $withdrawalId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...WithdrawalFields
      }
    }
  }
  ${withdrawal_fields}
`
export const insert_withdrawal_query = gql`
  mutation insert_withdrawal($changeMap: withdrawal_insert_input!) {
    insert_withdrawal (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...WithdrawalFields
      }
    }
  }
  ${withdrawal_fields}
`

export const upsert_withdrawal_query = gql`
  mutation upsert_withdrawal($changeMap: withdrawal_insert_input!, $changeCols: [withdrawal_update_column!]!) {
    withdrawal:insert_withdrawal (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: withdrawal_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...WithdrawalFields
      }
    }
  }
  ${withdrawal_fields}
`

export const delete_withdrawal_query = gql`
  mutation delete_withdrawal($deleteId: uuid!) {
    delete_withdrawal (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditWithdrawalInfo extends SaveableQuery {
  get friendly_name() { return "Withdrawal" }
  get required_keys_impl() {
    return ["id", "person_id", "withdrawal_date"];
  }
  data_impl({ data: { withdrawal: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_withdrawal_query;
  }
  get delete_gql_impl() {
    return delete_withdrawal_query;
  }
  get save_queries_impl() {
    return [(new PersonWithdrawals(null, null))];
  }
}

