import { LitElement, html, css } from 'lit';
import '@material/mwc-icon';

const kale_chip_style = css`
    :host {
        box-sizing: border-box;
        padding: 8px;
        cursor: pointer;

        background-color: var(--mdc-theme-primary, #e0e0e0);
        color: var(--mdc-theme-text-primary-on-primary, rgba(0,0,0,0.87));

        -webkit-tap-highlight-color: rgba(0,0,0,0);
        will-change: transform, opacity;
        border-radius: 16px;
        font-size: 12px;
        font-family: Roboto, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: .875rem;
        line-height: 1.25rem;
        font-weight: 400;
        letter-spacing: .01786em;
        text-decoration: inherit;
        text-transform: inherit;
        display: inline-flex;
        position: relative;
        align-items: center;
        padding: 7px 12px;
        outline: none;
        overflow: hidden;
    }
  

    mwc-icon { 
        font-size: 20px;
        width: 20px;
        height: 20px;
    }

    #text { 
        display: block;
        line-height: 17px;
        white-space: nowrap;
    }
    #text[compact] { 
        font-stretch: ultra-condensed;
        font-family: "Roboto Condensed", Roboto, sans-serif;
    }
`

/*

        kale-chip[active], mwc-chip[active] { --mdc-theme-primary: var(--paper-green-700);  --mdc-theme-text-primary-on-primary: white;  }
        kale-chip[exclude], mwc-chip[exclude] { 
          --mdc-theme-primary: var(--paper-grey-200);
          --mdc-theme-text-primary-on-primary: var(--paper-grey-500);
          text-decoration: line-through;
        }

                    leadingIcon="bookmark"
                    <kale-chip
                    label=${tag.name}
                    ?exclude=${this.__exclude_tags.has(tag.code)}
                    ?active=${  this.__exclude_tags.has(tag.code) || this.__include_tags.has(tag.code)}
                    @click=${e => this.toggleTag(tag.code)}
                    ></kale-chip>
*/

class KaleChip extends LitElement {
    static styles = kale_chip_style
    static properties = {
        label: { type: String },
        icon: { type: String },
        leadingIcon: { type: String },
        trailingIcon: { type: String },
        compact: { type: Boolean },
    }

    render() {
        return html`${this.leadingIcon ? html`<mwc-icon>${this.leadingIcon}</mwc-icon>` : ''}${this.icon ? html`<mwc-icon>${this.icon}</mwc-icon>` : ''}<span id="text" ?compact=${this.compact}>${this.label}</span>${this.trailingIcon ? html`<mwc-icon>${this.trailingIcon}</mwc-icon>` : ''}`
    }
}


window.customElements.define('kale-chip', KaleChip);
export { KaleChip as KaleChip };