import { marked } from 'marked';
import { LitElement, html, css} from 'lit';
import {until} from 'lit/directives/until.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import changelogURL from '../../changelog.md';

const changelog_styles = css``;

class ChangelogViewer extends LitElement {
    static styles = changelog_styles
    constructor() {
        super();
        this.data = fetch(changelogURL);
        this.opened = true;
    }
    async renderFile() {
        let file = await this.data;
        let data = await file?.body?.getReader?.()?.read?.();//
        let body = new TextDecoder().decode(data.value);
        console.log(body)
        return unsafeHTML(marked.parse(body?.replace?.(/BEN-\d+/g, "[$&](https://jira.afscme.org/browse/$&)")));
    }
    render() {
        return html`
            <div style="max-width: 60vw;">
            ${until(this.renderFile(), html`<span>loading...</span>`)}
            </div>
            `;
    }
}

window.customElements.define('changelog-viewer', ChangelogViewer);

export { ChangelogViewer };