import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

export const salary_history_fields = gql`
  fragment SalaryHistoryFields on salary_history {
    id
    person_id
    effective_date
    salary
  }`

export const salary_history_info_query = gql`
  query salary_history_info($SalaryHistoryId: uuid!){
    salary_history( where: {id: {_eq: $SalaryHistoryId}} ){
         ...SalaryHistoryFields
    }
  }
  ${salary_history_fields}
`

export class SalaryHistoryInfo extends StaticQuery {
  get required_impl() {
    return ['SalaryHistoryId'];
  }
  get gql_impl() {
    return salary_history_info_query;
  }
  data_impl({ data: { salary_history: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const salary_history_for_person_query = gql`
  query salary_history_info($PersonId: uuid!) {
    salary_history (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...SalaryHistoryFields
    }
  }
  ${salary_history_fields}
  `

export class PersonSalaryHistory extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return salary_history_for_person_query;
  }
  data_impl({ data: { salary_history: salary_history } }) {
    return salary_history;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_salary_history_query = gql`
  mutation update_salary_history($benefitPaymentId: uuid!, $changeMap: salary_history_set_input!) {
    update_salary_history (
      where: {
          id: {_eq: $benefitPaymentId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...SalaryHistoryFields
      }
    }
  }
  ${salary_history_fields}
`
export const insert_salary_history_query = gql`
  mutation insert_salary_history($changeMap: salary_history_insert_input!) {
    insert_salary_history (
      objects: [
        $changeMap
      ]
    ) {
      returning {Info
        ...SalaryHistoryFields
      }
    }
  }
  ${salary_history_fields}
`

export const upsert_salary_history_query = gql`
  mutation upsert_salary_history($changeMap: salary_history_insert_input!, $changeCols: [salary_history_update_column!]!) {
    salary_history:insert_salary_history (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: salary_history_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...SalaryHistoryFields
      }
    }
  }
  ${salary_history_fields}
`

export const delete_salary_history_query = gql`
  mutation delete_salary_history($deleteId: uuid!) {
    delete_salary_history (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`

export class EditSalaryHistoryInfo extends SaveableQuery {
  get typename() { return "salary_history" }
  get friendly_name() { return "Salary History" }
  get required_keys_impl() {
    return [
      "id",
      "person_id",
      "effective_date",
      "salary",
    ]
  }

  data_impl({ data: { salary_history: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_salary_history_query;
  }
  get delete_gql_impl() {
    return delete_salary_history_query;
  }
  get save_queries_impl() {
    return [(new PersonSalaryHistory(null, null))];
  }
}

