import { LitElement, html, css } from 'lit';
import '../shared-components/chip.js';


const person_tag_chips_style = css`
        :host {
          --mdc-theme-primary: var(--paper-grey-200);
        }
        #chips{
          margin-top: 8px;
        }
        kale-chip {
          margin-top: 8px;
          
        }
`;
class PersonTagChips extends LitElement {
  static styles = person_tag_chips_style
  static get properties() {
    return {
      map: { type: Object }
    };
  }
  constructor() {
    super();
    this.tags = [];
  }
  set events(evts) {
    this.map = new Map(evts);
  }

  set tags(t) {
    this._tags = [];
    if (t && t.length > 0) {
      if (typeof (t[0]) === 'string') {  // just codes
        this._tags = t.map(tag => ({ name: tag, code: tag }));
      } else if (t[0] && t[0].tag_type) { // full info 
        this._tags = t.sort((a, b) => b.tag_type.priority - a.tag_type.priority).map((tag, index) => ({ name: tag.tag_type.name, code: tag.tag_type.code }));
      }
    }
    this.requestUpdate('tags');
  }
  get tags() {
    return this._tags;
  }

  sendMessage(msg, code, e) {
    //code = Symbol.for(code);
    if (this.map && this.map.has(code)) {
      let events = this.map.get(code);
      //console.log(`sending ${msg} for`, evt, e);
      this.dispatchEvent(new CustomEvent(msg, { bubbles: true, composed: true, detail: { event_ids: events, e: e } }));
    }
  }
  render() {
    return html`
          <div id="chips">
        ${this.tags.map((tag, index) =>
      html` <kale-chip
        label=${tag.name}
        @click=${e => this.sendMessage('event-jump', tag.code, e)}
        @mouseover=${e => this.sendMessage('event-highlight', tag.code, e)}
        @mouseleave=${e => this.sendMessage('event-unhighlight', tag.code, e)}
        >
        </kale-chip>
        `)}
      </div>
    `
  }
}

window.customElements.define('person-tags', PersonTagChips);
