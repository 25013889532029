// Date class with better ergonomics for timeline processing:

//


const has_tz = /\d+T\d+/;
export class EventDate extends Date {
  constructor(d, incr, incr_type) {
    if (d === undefined) {
      super();
      return;
    }
    if (typeof (d) === 'string' && !has_tz.test(d)) {
      super(d && !isNaN(new Date(d + "T00:00:00-05:00")) ? d + "T00:00:00-05:00" : d); // add a time and TZ to avoid UTC default
    } else {
      super(d);
    }
    if (incr !== undefined) {
      switch (incr_type) {
        case undefined:
        case "month":
        case "months":
          this.setMonth(this.getMonth() + incr);
          break;
        case "day":
        case "days":
        case "date":
          this.setDate(this.getDate() + incr);
          break;
        case "year":
        case "years":
          this.setFullYear(this.getFullYear() + incr);
          break;
      }
    }
  }

  unitize() {
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);
    return this;
  }

  add(qty, unit) {
    if (qty !== undefined) {
      switch (unit) {
        case undefined:
        case "month":
        case "months":
          this.setMonth(this.getMonth() + qty);
          break;
        case "day":
        case "days":
        case "date":
          this.setDate(this.getDate() + qty);
          break;
        case "year":
        case "years":
          this.setFullYear(this.getFullYear() + qty);
          break;
      }
    }
  }

  equals(d) {
    return Math.abs(this - d) < (12 * 60 * 60 * 1000); //within 12 hours
  }

  get year() {
    let y = this.getFullYear();
    let soy = new Date(y, 0, 0);
    let eoy = new Date(y + 1, 0, 0);
    let frac = (this - soy) / (eoy - soy);
    return y + frac;
  }

  get duodecimal_year() {
    let y = this.getFullYear();
    let m = this.getMonth();
    let d = this.getDate();
    m = d >= 15 ? 1 : 0;
    return y + m / 12.0;
  }

  yearsUntil(d) {
    //let a = this >= d ? this.year : d.year;
    // let b = this >= d ? d.year : this.year;
    //return a - b;
    return Math.abs(this.year - d.year);
  }
  monthsUntil(d) {
    return this.yearsUntil(d) * 12;
  }

  yearsUntilDuodecimal(d) {
    let y1 = this.getFullYear();
    let y2 = d.getFullYear();
    let m1 = this.getMonth() + (this.getDate() >= 15 ? 1 : 0);
    let m2 = d.getMonth() + (d.getDate() >= 15 ? 1 : 0);

    return ((y2 * 12 + m2) - (y1 * 12 + m1)) / 12.0;
    //return Math.abs(this.duodecimal_year - d.duodecimal_year);
  }

  toString() {
    return this.str;
  }
  get localeStr() {
    return this.toLocaleDateString();
  }

  get str() {
    //return this.toISOString().slice(0, 10);
    return this.toLocaleDateString();
  }

  get nb_str() {
    return this.toLocaleDateString();
    //return this.toISOString().slice(0, 10).replace(/-/g, '‑');
  }

  get dayName() {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][this.getDay()]
  }
  get isWeekDay() {
    let d = this.getDay();
    return 0 < d && d < 6;
  }

  nextWorkday() {
    let d = new EventDate(this);
    while (!d.isWeekDay) {
      d.setDate(d.getDate() + 1);
    }
    return d;
  }

  /*
  previousAnniversaryOf(date) {
      let ret = new EventDate(date);
      let diff = this.year - ret.year;
      ret.setFullYear(ret.getFullYear() + Math.floor(diff));
      return ret;
      */
  previousAnniversaryOf(root) {
    let anny = new EventDate(root);
    anny.setFullYear(this.getFullYear());
    if (anny >= this) {
      anny.setFullYear(this.getFullYear() - 1);
    }
    return anny;
  }
  nextAnniversaryOf(root) {
    let anny = new EventDate(root);
    anny.setFullYear(this.getFullYear());
    if (anny <= this) {
      anny.setFullYear(this.getFullYear() + 1);
    }
    return anny;
  }
}
EventDate.september_1_1995 = new EventDate("1995-09-01"); // project staff become eligible
EventDate.january_1_2001 = new EventDate("2001-01-01"); // pension formula date A
EventDate.january_1_2014 = new EventDate("2014-01-01"); // hire date cutoff for 3 year delay in contribution requirement date, pension formula date B

EventDate.project_staff_elig_change = EventDate.september_1_1995;
EventDate.mandatory_contrib_change = EventDate.january_1_2014;

EventDate.formula_period_A = EventDate.january_1_2001;
EventDate.formula_period_B = EventDate.january_1_2014;
