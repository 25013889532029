import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * SUSPENSION
 */


export const suspension_fields = gql`
  fragment SuspensionFields on suspension {
    id
    person_id
    suspension_type_code
    suspension_type {
      code
      name
      paid
      unlimited
      limit_service_break
      incurs_service_deprecated
      breaks_service_deprecated
    }
    start_date
    end_date
   }`

export const suspension_info_query = gql`
  query suspension_info($SuspensionId: uuid!){
    suspension( where: {id: {_eq: $SuspensionId}} ){
         ...SuspensionFields
    }
  }
  ${suspension_fields}
`

export class SuspensionInfo extends StaticQuery {
  get required_impl() {
    return ['SuspensionId'];
  }
  get gql_impl() {
    return suspension_info_query;
  }
  data_impl({ data: { suspension: [d] } }) {
    return d;
  }
}


export const suspensions_for_person_query = gql`
  query suspension_info($PersonId: uuid!) {
    suspension (
        where: {person_id: {_eq: $PersonId}}
        order_by: {start_date: asc}
        ){
        ...SuspensionFields
    }
  }
  ${suspension_fields}
  `

export class PersonSuspensions extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return suspensions_for_person_query;
  }
  data_impl({ data: { suspension: suspensions } }) {
    return suspensions;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_suspension_query = gql`
  mutation update_suspension($suspensionId: uuid!, $changeMap: suspension_insert_input!) {
    update_suspension (
      where: {
          id: {_eq: $suspensionId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...SuspensionFields
      }
    }
  }
  ${suspension_fields}
`

export const delete_suspension_query = gql`
  mutation delete_suspension($deleteId: uuid!) {
    delete_suspension (
      where: {
          id: {_eq: $deleteId} 
      },
    ) {
      affected_rows
    }
  }

`

export const insert_suspension_query = gql`
  mutation insert_suspension($changeMap: suspension_insert_input!) {
    insert_suspension (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...SuspensionFields
      }
    }
  }
  ${suspension_fields}
`

export const upsert_suspension_query = gql`
  mutation upsert_suspension($changeMap: suspension_insert_input!, $changeCols: [suspension_update_column!]!) {
    suspension:insert_suspension (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: suspension_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...SuspensionFields
      }
    }
  }
  ${suspension_fields}
`


export class EditSuspensionInfo extends SaveableQuery {
  get friendly_name() { return "Tag" }
  get required_keys_impl() {
    return [
      "id",
      "person_id",
      "suspension_type_code",
      "start_date",
    ];
  }
  data_impl({ data: { suspension: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_suspension_query;
  }
  get delete_gql_impl() {
    return delete_suspension_query;
  }
  get save_queries_impl() {
    return [(new PersonSuspensions(null, null))];
  }
}

