import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * Migration information
 */


// all data (for use in the main person query)
export const person_migration_fields = gql`
  fragment MigrationFields on person_migration {
    id
    person_id
    person_pk
    ssn
    original_data
    warnings
    expectations
    fixed
    import_date
   }`


// The *Query and *Mutation classes defined here are used only by the
// migration component, which changes/gets updates only on the 'fixed'
// data. We can use this fragment internally to save bandwidth.
export const person_migration_fixed_fields = gql`
  fragment MigrationFixedFields on person_migration {
    id
    person_id
    fixed
    import_date
   }`

export const person_migration_info_query = gql`
  query person_migration_info($MigrationId: uuid!){
    person_migration( where: {id: {_eq: $MigrationId}} ){
         ...MigrationFields
    }
  }
  ${person_migration_fields}
`

export class MigrationInfo extends StaticQuery {
  get required_impl() {
    return ['migrationId'];
  }
  get gql_impl() {
    return person_migration_info_query;
  }
  data_impl({ data: { person_migration: [d] } }) {
    return d;
  }
}


export const update_person_migration_query = gql`
  mutation update_person_migration($migrationId: uuid!, $changeMap: person_migration_set_input!) {
    update_person_migration (
      where: {
          id: {_eq: $migrationId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...MigrationFields
      }
    }
  }
  ${person_migration_fields}
`
export const insert_person_migration_query = gql`
  mutation insert_person_migration($changeMap: person_migration_insert_input!) {
    insert_person_migration (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...MigrationFields
      }
    }
  }
  ${person_migration_fields}
`

export const upsert_person_migration_query = gql`
  mutation upsert_person_migration($changeMap: person_migration_insert_input!, $changeCols: [person_migration_update_column!]!) {
    person_migration:insert_person_migration (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: person_migration_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...MigrationFields
      }
    }
  }
  ${person_migration_fields}
`

export const delete_person_migration_query = gql`
  mutation delete_person_migration($deleteId: uuid!) {
    delete_person_migration (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export const person_migration_for_person_query = gql`
  query person_migration_info($PersonId: uuid!) {
    person_migration (
        where: {person_id: {_eq: $PersonId}}
        order_by: {import_date: asc}
        ){
        ...MigrationFields
    }
  }
  ${person_migration_fields}
  `

export class PersonMigration extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return person_migration_for_person_query;
  }
  data_impl({ data: { person_migration: migrations } }) {
    return migrations;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}


export class EditMigrationInfo extends SaveableQuery {
  get friendly_name() { return "Migration" }
  get required_keys_impl() {
    return [
      "id",
      "person_id",
      "fixed"
      //"person_pk",
      //"ssn"
    ];
  }
  data_impl({ data: { update_person_migration: { returning: [d] } } }) {
    return d;
  }
  variables(vars) {
    return { migrationId: vars.id, changeMap: vars }
  }

  get mutate_gql_impl() {
    return update_person_migration_query;
  }
  get delete_gql_impl() {
    return delete_person_migration_query;
  }
  get save_queries_impl() {
    return [(new PersonMigration(null, null))];
  }
}

