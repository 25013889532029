import { LitElement, html, css } from 'lit';
import '@material/mwc-fab';

import { timeline_colors } from "./colors.js";
import { editor_type_info } from "./editor_types.js";

let timeline_fab_style = css`
        :host {
         font-family: "Roboto", "Sans";
         ${timeline_colors}

        }


        .employment { --mdc-theme-secondary: var(--employment-color) }
        .suspension { --mdc-theme-secondary: var(--suspension-color) }
        .benefit { --mdc-theme-secondary: var(--benefit-color) }
        .beneficiary { --mdc-theme-secondary: var(--beneficiary-color) }
        .contribution { --mdc-theme-secondary: var(--contribution-color) }
        .service_adjustment { --mdc-theme-secondary: var(--adjustment-color) }
        .withdrawal { --mdc-theme-secondary: var(--withdrawal-color) }
        .payment { --mdc-theme-secondary: var(--payment-color) }
        .projection { --mdc-theme-secondary: var(--projection-color) }
        .participation_change { --mdc-theme-secondary: var(--participation-color) }

      .primary {
        --mdc-theme-on-primary: var(--timeline-fab-text, white);
        --mdc-theme-primary: var(--timeline-fab-color, var(--paper-pink-a200));
        --mdc-theme-on-secondary: var(--timeline-fab-text, white);
        --mdc-theme-secondary: var(--timeline-fab-color, var(--paper-pink-a200));
        }

      .button-holder {
        position: fixed;
        overflow: visible;
        margin: 0px;
        bottom: 16px;
        right: 32px;
        visibility: visible;
        }

      .button-holder[opened]{
        visibility: visible;
        z-index: 6;
        
      }
      .speed-dial {
        --mdc-theme-on-primary: var(--paper-grey-800);
        --mdc-theme-on-secondary: white;
        --mdc-theme-primary: white;
        --mdc-theme-secondary: var(--paper-purple-600);
        margin-bottom: 16px;
        display: block;
        z-index: 2;
        margin-left: 8px;
        margin-right: 8px;
        opacity: 0;
        transition: opacity 0.5s ease;
        width: 240px;
      }

      .speed-dial > * {
        display: inline-block;
        z-index: 2;
      }
      
      .speed-dial[show_menu] {
        opacity: 1;
        }


      .label {
        background-color: white;
        padding: 8px;
        border-radius: 4px;
        color: var(--paper-grey-800);
        position: relative;
        bottom: 6px;
        left: 12px;
      }

      .labela {
        color: white;
        font-weight: 800;
        position: relative;
        bottom: 6px;
        left: 12px;
      }

 #buttons {
   cursor: pointer;
   visibility: hidden;
   height: 0px;
display: flex;
      flex-direction: column;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    position: absolute;
    right: 0;
    bottom: 64px;
    width: calc(100vw - 100px);   

 }
 #buttons[opened] {
    visibility: visible;
    height: auto;
    max-height: calc(100vh - 150px);
    overflow: hidden;
 }
 
      /*.speed-dial > * { transform: scale(0.5) } */
@media only screen and (max-height: 850px) {
      .speed-dial { margin-bottom: 8px; }
}
`


class TimelineFab extends LitElement {
  static styles = timeline_fab_style
  constructor() {
    super();
    this.add_menu = false;
  }
  static get properties() {
    return {
      add_menu: { type: Boolean },
      label: {type: String }
    };
  }
  insert_editor(editor_type, extra_args) {
    console.log("inserting", editor_type, extra_args ? extra_args : '');
    this.dispatchEvent(new CustomEvent("new-event", { bubbles: true, composed: true, detail: { event_type: editor_type, extra_args: extra_args } }));
  }

  render() {
    let first_node = true;
    return html`
      <div id="button-scrim" style="z-index: 3; visibility: ${this.add_menu ? 'visible' : 'hidden'}; opacity: ${this.add_menu ? '1' : '0'}; height: 100vh; width: 100vw; position: fixed; top: 0; left: 0; background-color: black; opacity: 0.5; transition: visibility 0.1s ease, opacity 0.3s ease; cursor: pointer;" @click=${e => this.add_menu = false}></div>
      </div>
      <div class="button-holder" ?opened=${this.add_menu} >
        <div id="buttons" ?opened=${this.add_menu} @click=${e => this.add_menu = false}>
          ${editor_type_info.map((button, idx, buttons) =>
      html`
          <div class="speed-dial" style="transition-delay: ${buttons.length * 0.03 - idx * 0.03}s" ?show_menu=${this.add_menu} @click=${e => this.insert_editor(button.class, button.extra_args)}>
            <mwc-fab icon=${button.icon} class=${button.class} label=${button.label} mini></mwc-fab>
            <div class="label">${button.label}</div>
          </div>`
    )}
        </div>

        <mwc-fab class="primary" icon="add" extended label=${this.label} @click=${e => this.add_menu = !this.add_menu}></mwc-fab>

      </div>`
  }
}

window.customElements.define('timeline-fab', TimelineFab);
export { TimelineFab }
