import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * Phone  
 */

export const phone_fields = gql`
  fragment PhoneFields on phone {
    id
    person_id
    number
    extension
    phone_type_code
    type {
      code
      name
    }
    phone_status_code
    status {
      code
      name
    }
    note
   }`


export const phone_info_query = gql`
  query phone_info($PhoneId: uuid!){
    phone( where: {id: {_eq: $PhoneId}} ){
         ...PhoneFields
    }
  }
  ${phone_fields}
`

export class PhoneInfo extends StaticQuery {
  get required_impl() {
    return ['PhoneId'];
  }
  get gql_impl() {
    return phone_info_query;
  }
  data_impl({ data: { phone: [d] } }) {
    return d;
  }
}


export const phones_for_person_query = gql`
  query phone_info($PersonId: uuid!) {
    phone (
        where: {person_id: {_eq: $PersonId}}
        order_by: {number: asc}
        ){
        ...PhoneFields
    }
  }
  ${phone_fields}
  `

export class PersonPhone extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return phones_for_person_query;
  }
  data_impl({ data: { phone: phones } }) {
    return phones;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_phone_query = gql`
  mutation update_phone($phoneId: uuid!, $changeMap: phone_insert_input!) {
    update_phone (
      where: {
          id: {_eq: $phoneId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...PhoneFields
      }
    }
  }
  ${phone_fields}
`
export const insert_phone_query = gql`
  mutation insert_phone($changeMap: phone_insert_input!) {
    insert_phone (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...PhoneFields
      }
    }
  }
  ${phone_fields}
`

export const upsert_phone_query = gql`
  mutation upsert_phone($changeMap: phone_insert_input!, $changeCols: [phone_update_column!]!) {
    phone:insert_phone (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: phone_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...PhoneFields
      }
    }
  }
  ${phone_fields}
`

export const delete_phone_query = gql`
  mutation delete_phone($deleteId: uuid!) {
    delete_phone (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditPhoneInfo extends SaveableQuery {
  get friendly_name() { return "Phone" }
  get required_keys_impl() {
    return [
      "number",
      "person_id"
    ];
  }
  // FIXME: complains on delete because the phone property is null
  data_impl({ data: { phone: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_phone_query;
  }
  get delete_gql_impl() {
    return delete_phone_query;
  }
  get save_queries_impl() {
    return [(new PersonPhone(null, null))];
  }
}

