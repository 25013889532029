const client_style = 'background: #01FFFF; color: #0A0101; font-size: smaller; padding: 3px; border-radius: 3px;';
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import Observable from 'zen-observable';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

import jwt_decode from 'jwt-decode';
import { getRefreshTokenLink } from 'apollo-link-refresh-token';


//import { RetryLink } from 'apollo-link-retry/lib/retryLink';


// import DefaultClient from 'apollo-boost';
//import gql from 'graphql-tag

// suppress the dev tools suggestion message:
window.__APOLLO_DEVTOOLS_GLOBAL_HOOK__ = null;

export const formatQueryError = (error, elem = null) => {
  //window.handleJWTError(error);
  let gql_errs;
  let svr_errs;
  let other_errs;
  if (elem) elem.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `${this.constructor.name} error`, action_text: 'View', action: () => console.warn("UNIMPL") } }));
  console.error("%cHASURA: FormatQueryError(): raw error is:", client_style, error);
  console.error("%cHASURA: JSON:", client_style, JSON.stringify(error));
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    gql_errs = error.graphQLErrors.map(e => JSON.stringify(e)).join("\n\t");
    console.error(`%cHASURA: GraphQL errors:\n\t${gql_errs}`, client_style)
  }
  if (error.networkError && error.networkError.result) {
    svr_errs = error.networkError.result.errors.map(e => {
      if (e.path && e.error && e.code) {
        return `${e.code}: ${e.error} at "${e.path}"`;
      }
      return JSON.stringify(e);
    }).join("\n\t")
    console.error(`%cHASURA: Server errors:\n\t${svr_errs}`, client_style)
  }
  if (gql_errs === undefined && svr_errs === undefined) {
    other_errs = JSON.stringify(error);
    console.error(`%cHASURA: Unparsed errors:\n\t${other_errs}`, client_style)
  }

}

export const extractErrorMessages = (error) => {
  let msgs = [];
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    msgs = [...msgs, ...error.graphQLErrors.map(e => JSON.stringify(e))]
  }
  if (error.networkError && error.networkError.result) {
    msgs = [...msgs, ...error.networkError.result.errors.map(e => e.message)];
  }
  if (msgs.length === 0) {
    msgs = [JSON.stringify(error)];
  }
  return msgs;
}

const other_ids = {
  'person_bookmark': bookmark => `${bookmark.user_email}:${bookmark.person_id}`
}

const id_cache = new InMemoryCache({
  dataIdFromObject: object => {
    //let id = Object.keys(object).filter(o => o.endsWith('_id')).map(o => o.replace(id_re, "")).find(o => o === object.__typename || o === object.__typename.replace(tn_re, "")) + "_id";
    //let ret = object[id] ? `${ object.__typename } _${ object[id] } ` : null ;
    //console.log(`data_id for ${ object.__typename }: \n\tid = ${ id } \n\tret = ${ ret } `);
    //return ret;
    let gen_id = `${object.__typename}_${object.id ? object.id : object.code ? object.code : other_ids[object.__typename](object)} `
    console.log(`%cHASAURA: genid: ${object.__typename} => id: ${gen_id}`, client_style);
    return gen_id
  }
});




const dev_instance = (location.hostname === 'localhost' || location.hostname === '127.0.0.1') && location.port === '8081';
const domain = location.hostname.split('.')[0];
const domains = new Set(['benefits', 'benefits-test']);
const domain_based = !dev_instance && domains.has(domain);
const local_container = !dev_instance && !domain_based && location.hostname === 'localhost';
const dev = local_container || dev_instance;
const use_prod = true;
const hasura_port = use_prod ? '' : dev_instance ? '8080' : local_container ? '9000' : location.port;
const hasura_host = dev_instance && !use_prod ? 'localhost' : dev ? 'benefits-test.afscme.org' : location.hostname;
const hasura_path = '/v1/graphql';

const secure = !dev_instance || use_prod;
const hasura_uri = `http${secure ? 's' : ''}://${hasura_host}:${hasura_port}${hasura_path}`;
const hasura_ws_uri = `ws${secure ? 's' : ''}://${hasura_host}:${hasura_port}${hasura_path}`;

console.log("%cHASURA: using server @", client_style, hasura_uri);
console.log("%cHASURA: websocket @", client_style, hasura_ws_uri);

/*

//uri: 'http://localhost:8080/v1alpha1/graphql'
//uri: 'http://'+location.hostname+':'/graphql'
//uri: `${ location.protocol }//${location.hostname}:${location.port ? location.port : '80'}/graphql`
const dev_instance = location.hostname === 'localhost' && location.port === '8081';
const domain = location.hostname.split('.')[0];
const domains = new Set(['benefits', 'benefits-test']);
const domain_based = !dev_instance && domains.has(domain);
const local_container = !dev_instance && !domain_based && location.hostname === 'localhost';
const hasura_port = dev_instance ? '8080' : local_container ? '9000' : location.port;
const hasura_uri = `${location.protocol}//${location.hostname}${hasura_port !== '' ? `:${hasura_port}` : ''}/${dev_instance || domain_based ? 'v1/' : ''}graphql${local_container ? '_local' : ''}`;
const hasura_ws_uri = `ws://${location.hostname}${hasura_port !== '' ? `:${hasura_port}` : ''}/${dev_instance || domain_based ? 'v1/' : ''}graphql${local_container ? '_local' : ''}`;

console.log("using hasura @", hasura_uri);
//console.log(`domain=${domain}, dev_instance=${dev_instance}, domain_based=${domain_based}, local_container=${local_container}, hasura_port=${hasura_port}`);
*/
const known_errors = new Set(["GraphQL error: Malformed Authorization header", "GraphQL error: Could not verify JWT: JWTExpired"]);

/*
const retry_link = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, _operation) => known_errors.has(error.message) && window.attemptReauthorize()
  }
});
*/

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: hasura_ws_uri,
  options: {
    reconnect: true,
    /*
    connectionParams: () => {
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : "Bearer XYZ",
        }
      }
    }*/
    connectionParams: async () => {
      const token = await window.authmgr.refresh();
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        }
      };
    }
  }
});


/*
const subscriptionMiddleware = {
  applyMiddleware: async (options, next) => {
    const token = await window.authmgr.refresh();
    console.warn("WS MIDDLEWARE()", JSON.parse(JSON.stringify(options)));
    options.headers = {
      ...options.headers,
      authorization: token ? `Bearer ${token}` : ""
    }
    next()
  },
}

// add the middleware to the web socket link via the Subscription Transport client
wsLink.subscriptionClient.use([subscriptionMiddleware])
*/



const httpLink = createHttpLink({
  uri: hasura_uri,
});

const http_and_ws_link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);


const isTokenValid = token => {
  const decodedToken = jwt_decode(token);

  if (!decodedToken) {
    return false;
  }
  console.log("%cHASURA: TOK VALID: DECODED", client_style, decodedToken);

  const now = new Date();
  return now.getTime() < decodedToken.exp * 1000;
};

const refreshTokenLink = getRefreshTokenLink({
  authorizationHeaderKey: 'authorization',
  fetchNewAccessToken: async (refreshToken) => { console.warn("%cHASURA: refreshTokenLink attempted:", client_style, refreshToken); return window.attemptRefresh(refreshToken) },
  getAccessToken: () => localStorage.getItem('id_token'),
  getRefreshToken: () => localStorage.getItem('refresh_token'),
  isAccessTokenValid: accessToken => isTokenValid(accessToken),
  isUnauthenticatedError: graphQLError => {
    const { extensions } = graphQLError;
    if (
      extensions &&
      extensions.code &&
      extensions.code === 'UNAUTHENTICATED'
    ) {
      return true;
    }
    return false;
  },
});


const errorLink = onError((args) => {
  const { operation, response, graphQLErrors, networkError, forward } = args;
  /*
  console.log("OP:", operation);
  console.log("RESP:", response);
  console.log("gql errors", graphQLErrors);
  console.log('net errors', networkError);
  console.log('forward', forward);
  */
  if (networkError) { //FIXME: make sure this is an auth error
    const definition = getMainDefinition(operation.query);
    return new Observable(observer => {
      async function run() {
        const oldHeaders = operation.getContext().headers;
        const token = await window.authmgr.refresh();

        //FIXME: hack to update wslink auth headers
        wsLink.subscriptionClient.close(true, true);
        wsLink.subscriptionClient.connect();

        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: token ? `Bearer ${token}` : "",
          },
        });
        forward(operation).subscribe({
          next: n => observer.next(n),
          error: e => observer.error(e),
          complete: e => observer.complete()
        });
      }
      run();//.then(() => observer.complete(), e => observer.error(e));
    });
  }
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
        case 'invalid-headers':
        case 'invalid-jwt':
          console.warn("%cHASURA: ERROR LINK CAUGHT A FISH", client_style, err.extensions.code, operation);
          return new Observable(observer => {
            async function run() {
              const oldHeaders = operation.getContext().headers;
              const token = await window.authmgr.refresh();
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: token ? `Bearer ${token}` : "",
                },
              });
              forward(operation).subscribe({
                next: n => observer.next(n),
                error: e => observer.error(e),
                complete: e => observer.complete()
              });
            }
            run();//.then(() => observer.complete(), e => observer.error(e));
          });
        default:
          console.warn(`%cHASURA: UNHANDLED ERROR CODE "${err.extensions.code}"`, client_style);
          console.log(`%cHASURA: json=`, client_style, JSON.stringify(args, null, 2));
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.warn(`%cHASURA: [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, client_style)
          });
          if (networkError) console.warn(`%cHASURA: [Network error]: ${networkError}`, client_style);
      }
    }
  }
  /*
  if (jwt_errors) {
    console.log("there are jwt errrors");
    // let fixed = await window.authmgr.refresh();
    let fixed;
    console.log("fixed jwt error?", fixed);
    //if (fixed) return forward(operation);
  }*/
});


const authLink = setContext(async ({ query }, other) => {
  // get the authentication token from local storage if it exists
  //const token = window.localStorage.getItem('id_token');
  //console.log('authlink token', token, headers);
  //const token = "ABC";
  const def = getMainDefinition(query);
  const is_sub = def.kind === 'OperationDefinition' && def.operation === 'subscription'
  const token = await window.authmgr.refresh();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...other.headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});


export const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    refreshTokenLink,
    errorLink,
    //httpLink
    http_and_ws_link,
  ]),
  cache: new InMemoryCache()
});
//console.log("CLIENT:", client);

/*
export const no_cache_client = () => (new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    httpLink
    //http_and_ws_link,
  ]),
  cache: {}
}));*/
/*
export const client = new DefaultClient({
  uri: hasura_uri
  //, cache: id_cache
});*/

//console.log("HASURA CLIENT", client);

export class StaticQuery {
  get typename() {
    return null;
  }
  get required_impl() {
    return [];
  }
  get gql_impl() {
    return null;
  }
  constructor(result, vars) {
    this.__result_func = result;
    this.query(vars);

  }
  data_impl(data) {
    return data;
  }
  vars_from_impl(data) {
    return null;
  }
  query(vars) {
    //console.log("PersonInfo query");
    //console.log(this.required_impl);
    //console.log(this.gql_impl);
    //console.log(vars);
    if (vars && this.required_impl.every(r => (r in vars && vars[r] !== null))) {
      this.__last_vars = vars;
      //console.log("variables in place, running");
      if (this.__subscription && window.active_subs.find(s => s.sub === this.__subscription)) {
        //console.log("refetching", this.constructor.name, vars);
        this.__subscription.refetch(vars);
      } else {
        //console.log("^^ subscribing", this.constructor.name, vars);
        this.__subscription = client.watchQuery({
          variables: vars || undefined,
          query: this.gql_impl
        });

        let sub = this.__subscription.subscribe(
          {
            next: r => {
              console.log(`%cHASURA: recvd watched data for "${this.constructor.name}"`, client_style, { args: vars, result: this.data_impl(r)});
              this.__result_func(this.data_impl(r));
            },
            error: (e) => {
              console.error("%cHASURA: !! watchquery errored out", client_style, this.constructor.name);
              console.error(e, e.length, JSON.stringify(e));
              //if (e && e.length > 0) formatQueryError(e)
            },
            complete: () => console.warn("%cHASURA: || watchquery subscription complete", client_style, this.constructor.name)
          });
        window.active_subs.push({ sub: sub, vars: vars, name: this.constructor.name });
      }
    }
    /* else {
      console.warn("not running query yet: variables missing", this.constructor.name, vars, this.required_impl);
    }*/
  }
}


export class SaveableQuery extends StaticQuery {
  get required_impl() {
    return ["changeMap"];
  }

  get mutate_gql_impl() {
    return null;
  }
  get save_queries_impl() {
    return [];
  }
  get required_keys_impl() {
    return [];
  }
  add_keys_impl(data) {
    return data;
  }
  get friendly_name() {
    return null;
  }
  constructor(result, vars, finally_func, error_func, elem = null) {
    super(result, vars);
    this.__finally_func = finally_func;
    this.__error_func = error_func;
    this.__elem = elem;
  }
  update_impl(proxy, data) {
    //console.warn("___________proxy update_impl_________________");
    if (proxy) {
      const inner_data = this.data_impl(data);
      this.__result_func(inner_data);

      //console.log("UPDATING SAVE QUERIES", this.save_queries_impl)
      this.save_queries_impl.forEach(async query => {
        console.log("trying to update", query, this.data_impl(data));
        const vars = query.vars_from_impl(this.data_impl(data));
        console.log("vars", vars);
        console.log("attempting to fetch", { query: query.gql_impl, variables: vars });
        //const query_data = proxy.readQuery({ query: query.gql_impl, variables: vars});
        let query_data = (await client.query({ query: query.gql_impl, variables: vars })).data;
        console.log("query_data", query_data);
        console.log("query", query);
        const query_array = query.data_impl({ data: query_data });
        //console.log("query_array", query_array.map(q=>JSON.stringify(q)).join("\n"));
        if (query_array && query_array.every(d => d.id !== inner_data.id)) { // id is not in existing list
          query_array.push(inner_data);
          //console.log("query_array NOW", query_array.map(q=>JSON.stringify(q)).join("\n"));
          proxy.writeQuery({ query: query.gql_impl, variables: vars, data: query_data });
        }
      });
    } else {
      this.__result_func(this.data_impl(data));
    }
    //console.log("proxy update finished");
  }
  get refetch_queries() { return ['person_info', 'person_ext_info', 'search_people'] }
  //get refetch_queries() { return [{ query: ext_person_info_query, variables: { PersonId: window.person_id } }] }
  save(vars, obj, message, defaults) {
    let create = !obj || !obj.id;
    console.log("saving query with", vars, obj, this.required_keys_impl, defaults);
    vars = this.add_keys_impl(vars);

    // Load defaults for new objects
    if (create && defaults) {
      vars = {...defaults, ...vars};
    }
    // ...Attempt to patch up required fields on existing objects
    if (obj) {
      this.required_keys_impl.forEach(k => {
        // FIXME: could be smarter about mapping "xxx_type_code" to "xxx.code", but schema might still not be consistent enough for that...
        if (vars[k] === undefined) {
          if (obj[k] !== undefined) {
            vars[k] = obj[k]
          } else if (k.slice(-5) === "_code") {
            // try all variations of key string to see if there's a matching field
            let variations = [...new Set(k.split("_").slice(0, -1).reduce((acc, cur, idx, src) => [...acc, ...src.slice(idx).map((a, idx, arr) => arr.slice(idx).join("_")), ...src.slice(idx).map((a, idx, arr) => arr.slice(0, arr.length - idx).join("_"))], []))];
            let detail = variations.find(f => obj[f] !== undefined && obj[f].code !== undefined);
            console.warn("found a match from", k, "to", `${detail}.code`);
            if (detail) {
              vars[k] = obj[detail].code;
            }
          }
        }
        if (vars[k] !== undefined) {
          console.log(`fixed ${k} => ${vars[k]}`);
        } else {
          console.error(`unable to fix required field ${k}=${undefined}`);
        }
      })
    } 
    return new Promise((resolve, reject) => {
      client.mutate({
        mutation: this.mutate_gql_impl,
        variables: this.variables(vars),
        update: (proxy, data) => this.update_impl(proxy, data),
        refetchQueries: this.refetch_queries     //update: (dataproxy, {data: {person: {returning: [d]}}}) => this.handleMutationData(dataproxy, d)
      }).then(data => {
        //console.warn("___________final data_________________");
        //console.warn(data, this.refetch_queries, client);
        this.__result_func(this.data_impl(data));
        (this.__elem ? this.__elem : document).dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: message && message.success ? message.success : `${this.friendly_name ? this.friendly_name : this.constructor.name} ${create ? 'created' : 'saved'}` } })); // TODO: undo
        if (this.__finally_func) {
          this.__finally_func(this.data_impl(data));
        }
        resolve(data);
      })
        .catch(error => {
          (this.__elem ? this.__elem : document).dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: message && message.failure ? message.failure : `${this.friendly_name ? this.friendly_name : this.constructor.name} ${create ? 'create failed' : 'save failed'}` } })); // TODO: undo
          formatQueryError(error);
          if (this.__error_func) {
            this.__error_func(error, extractErrorMessages(error));
          }
          reject(error);
        });
    })
  }
  variables(vars) {
    return { changeMap: vars, changeCols: Object.keys(vars).filter(k => k !== 'id') }
  }

  delete(obj) {
    console.log("Delete obj", obj);
    client.mutate({
      mutation: this.delete_gql_impl,
      variables: { deleteId: obj.id },
      refetchQueries: this.refetch_queries
      //update: (dataproxy, {data: {person: {returning: [d]}}}) => this.handleMutationData(dataproxy, d)
    }).then(data => {
      console.log("delete went through");

      if (this.__elem) this.__elem.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `${this.friendly_name ? this.friendly_name : this.constructor.name} deleted` } }));  // TODO: undo

      /*
    this.deleted_result_func_impl(this.data_impl(data));
    if (this.__finally_func) {
      this.deleted_finally_func_impl(this.data_impl(data));
    }*/
    })
      .catch(error => { console.log("error", error); formatQueryError(error, this.__elem) });

  }

  fill_in(o) {
    o.id = o.id ? o.id : createGUID();
    return o;
  }
}

/*
const createGUID = () => {  
    let S4 = () => Math.floor((1+Math.random())*0x10000).toString(16).substring(1); 
    let guid = `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
    
    return guid.toLowerCase();  
 }*/


export const createGUID = () => { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export class MutationRedirect {
  make(old_mut, update, vars, final, error, obj) {
    return {
      save: (save_data, save_obj, msg) => {
        if (!save_obj) {
          save_data = old_mut.fill_in(save_data);
          save_obj = {...save_data};
          console.warn("FILLED IN =>", save_obj);
        }
        console.warn("REDIRECTING SAVE", save_data, save_obj, msg);
        let mut = new this._mut(
          p => {// data update function
            update?.(this.extract_data(old_mut?.typename, save_data, save_obj, p))
          },  
          vars,  //initial variables
          p => { // finalizing function
            final?.(this.extract_data(old_mut?.typename, save_data, save_obj, p))
          },
          (e, msgs) => { // error handler
            error?.(e, msgs);
          }, this._elem);
        let inserted_data = this.insert_data(old_mut?.typename, save_data, save_obj);
        mut.save(inserted_data, this.data);
      },
      delete: (prop) => {
        console.warn("!!! IMPL TBD", prop);
      },
    }
  }
  extract_data(typename, data, obj, new_data) {
    if (this._extract) {
      return this._extract(typename, data, obj, new_data);
    }
    console.warn("!!! EXTRACT DATA IMPL TBD");
    return {}
  }
  insert_data(typename, data, obj) {
    if (this._insert) {
      return this._insert(typename, data, obj);
    }
    console.warn("!!! INSERT DATA IMPL TBD");
    return {}
  }
  get data() {
    return this._get_data ? this._get_data() : {};
  }

  constructor(elem, mut, extract, insert, get_data) {
    this._elem = elem;
    this._mut = mut;
    this._extract = extract;
    this._insert = insert;
    this._get_data = get_data;
  }
}

/*

class DeleteQuery{
  get gql_impl() {
    return null;
  }

  get id_name_impl() {
    return null;
  }


  delete(id) {
    client.mutate({
      mutation: this.gql_impl,
      variables: Object.fromEntries([this.__id_name, id]),
    }).then( data => console.log("deleted"))

  }

}
*/

export const active_subscriptions = () => Array.from(client.queryManager.queries.entries()).filter(([k, v]) => v.listeners.size > 0).map(([k, v]) => ({ query: v.document.definitions.filter(d => d.operation === 'query').map(d => d.name.value)[0], vars: v.observableQuery && v.observableQuery.options ? v.observableQuery.options.variables : null }));
