import { LitElement, html, css, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { KalePage } from '../shared-components/page.js';
import { KaleForm } from '../shared-components/form.js';
import { debounce } from '../shared-components/utilities/debounce.js';
import '../shared-components/chip.js';

import gql from 'graphql-tag';
import { client, person_search_fragment } from '../queries/queries.js';
import { EventDate } from '../benefits-app/eventdate.js';
import '../components/edit-person.js';

let find_person_type = (p) => {
          //FIXME: this may or may not always match the calculation being done on the person page
          let tags = new Set((p?.tags ?? []).filter(t => !t.tag_type.deprecated).map(t => t.person_tag_type_code));
          if (tags.has("ERR")) { return "errors" }
          if (p.deceased_date !== null) { return "deceased" }
          if (tags.has("NVEMPL") || tags.has("NOTELIG")) { return "ineligible" }
          if (tags.has("RET") && tags.has("EMP")) { return "working_retiree" }
          if (tags.has("RET")) { return "retiree" }
          if (tags.has("EMP") || tags.has("FT") || tags.has("PT")) { return "employee" }
          if (tags.has("TERM")) { return "ex-employee" }
        };

        
const people_page_style = css`
   
      .pink {
        --mdc-theme-on-primary: white;
        --mdc-theme-primary: var(--paper-pink-a200);
        --mdc-theme-on-secondary: white;
        --mdc-theme-secondary: var(--paper-pink-a200);

        }

      .column {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column; 
        width: 100%;
        background-color: white;
        min-height: 100%;
      }
      .column > * {
        margin-top: 24px;
        width: 100%;

        margin-right: 64px;
        margin-left: 64px;

        max-width: 1200px;
      }

        .scroller {
          background-color: white;
          overflow: overlay;

          height: calc(100vh - 64px);
          width: 100%;
        }

        .content-area {
          background-color: white;
          flex: 1;
          padding-left: 24px;
          padding-right: 24px;

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
          padding-bottom: 64px;
        }


        mwc-fab {
          position: fixed;
          right: 50px;
          bottom: 50px;
        }

        .primary {
          font-weight: 500;
          font-size: 20px;
          }
        .secondary {
          font-weight: 200;
          font-size: 16px;
          margin-top: 8px;
          }


        mwc-top-app-bar {
          --mdc-theme-primary: var(--paper-green-700);
          background-color: var(--paper-green-700);
        }

      .chips{
          margin-top: 8px;
        }
        kale-chip, mwc-chip {
          margin-top: 8px;
          user-select: none;
        }
        
        kale-chip, mwc-chip[active] { --mdc-theme-primary: var(--paper-grey-200);  --mdc-theme-text-primary-on-primary: var(--paper-grey-900);  }
        kale-chip[active], mwc-chip[active] { --mdc-theme-primary: var(--paper-green-700);  --mdc-theme-text-primary-on-primary: white;  }
        kale-chip[exclude], mwc-chip[exclude] { text-decoration: line-through; --mdc-theme-primary: var(--paper-grey-200);  --mdc-theme-text-primary-on-primary: var(--paper-grey-500);  }


        .card {
        box-sizing: border-box;
          background-color: white;
          border: 1px solid var(--paper-grey-400);
          padding: 12px;
         padding-bottom: 16px;
         border-radius: 8px;
          transition: var(--shadow-transition);
          cursor: text;

        }


        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }
        #search_box {
         font-size: 22px; 
         display: flex;
         flex-direction: row;
        }

        #search_box > #search_text_editable {
          display: inline-block;
          flex: 1;
          margin-left: 8px;
          min-width: 10px;
          line-height: 2em;
        }

        #search_text_editable,#search_text_editable:active,#search_text_editable:focus   {
            border:none;
            outline:none;
         }
         
         #search_text_editable[empty]::before {
          content: "Filter";
          color: var(--paper-grey-500);
         }

        #search_text_editable:active::before,#search_text_editable:focus::before {
          content: "";
        }

        .results {
          color: var(--paper-grey-800);
          font-size: 16px;
          padding: 0;
          display: grid;
          width: 100%;
          max-width: 1200px;
          grid-template-columns: [ico-start] auto [ico-end info-start] auto [info-end extra-start] auto [extra-end tag-start] 1fr [tag-end action-start] auto [action-end];
          grid-template-rows: none;
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          justify-content: space-between;
          grid-auto-flow: dense;

        }

          /*
          */
        .result {
          /*
          padding: 4px;
          color: var(--paper-grey-800);
          grid-column-start: 1;
          grid-column-end: 5;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          */
        }
        /*
        .result > div {
          width: fit-content;
          margin: 8px;
        }*/

        .results > a {
          padding: 24px;
          border-bottom: 1px solid var(--paper-grey-300);
          text-decoration: none;
          color: var(--paper-grey-900);
        }

        .results > a[hovered] { 
          background-color: var(--paper-grey-200);
        }


        .graphic {
          grid-column-start: ico-start;
          grid-column-end: ico-end;
          --mdc-icon-size: 48px;
          color: var(--paper-grey-800);
          border-bottom: none !important;
        }

        .info {
          grid-column-start: info-start;
          grid-column-end: info-end;
          font-weight: 700;
        }
        .extrainfo {
          grid-column-start: extra-start;
          grid-column-end: extra-end;
          font-weight: 700;
          text-align: center;
        }
        .tags {
          grid-column-start: tag-start;
          grid-column-end: tag-end;
          /*background-color: var(--paper-teal-200);*/
          /*flex: 3;*/
          /*text-transform: uppercase;*/
          color: var(--paper-grey-900);
        }
        .actions {
          grid-column-start: action-start;
          grid-column-end: action-end;
          /*flex: 3;*/
          text-align: right;
        }

        .tags .tag {
          font-weight: 100;
          font-size: 12px;
          display: inline-block;
          background-color: var(--paper-grey-300);
          border-radius: 5px;
          padding: 2px 4px 2px 4px;
          margin: 2px;
        }

        .tags .tag.ALERT {
          background-color: var(--paper-yellow-200);
        }
        .tags .tag.ERR {
          background-color: var(--paper-red-200);
        }

        .tags .tag.MIGR_INCOMPL {
          background-color: var(--paper-purple-200);
        }

        .tags  .bookmark {
          font-size: 12px;
          display: inline-block;
          border-radius: 4px;
          background-color: var(--paper-grey-300);
          padding: 2px 4px 2px 4px;
        }
        .tags  .bookmark > mwc-icon {
          font-size: 12px;
          position: relative;
          top: 1px; 
        }

        .name {
          white-space: nowrap;
        }
        .age, .deceased {
          font-weight: 600;
          font-variant: small-caps;
          opacity: 0.4;
          font-size: 13px;
        }
        .address, .employer {
          font-size: 80%;
          font-weight: 400;
          white-space: nowrap;
          color: var(--paper-grey-600);
        }



        @media screen and (max-width: 750px) { 


          .results { 
            margin-left: 0;
            margin-right: 0;
            grid-template-columns: [ico-start tag-start] auto [ico-end info-start] 1fr [info-end action-start] auto [tag-end action-end];
          } 
          /*
          .tags, .actions {
            grid-row: span 2;
          }
          */
          .extrainfo {
            display: none;
          }
          .results > a {
            padding: 8px;
            padding-left: 20px;
            padding-right: 24px;
            border-bottom: none;
          }
          .graphic {
          }

          .results a.graphic, .results a.info, .results a.actions {
            border-bottom: none !important;
            padding-top: 20px !important;
          }
          .results a.tags {
            border-bottom: 1px solid var(--paper-grey-300) !important;
            padding-top: 0 !important;
            padding-bottom: 20px !important;
            overflow: hidden;
          }

          .tags .tag {
            font-size: 10px;
            margin: 0;
          }

          .column {
            width: 100vw;
            margin: 0;
            padding: 0;
          }


          .card#search_card {
            width: 100%;
            margin: 0;

          }

          .card {
              border: none;
          }
          .card:hover {
            box-shadow: none;
          }
          .content-area {
            padding: 0;
            padding-bottom: 56px;
          }
        } 

        
        @media screen and (max-width: 600px) { 
          .top-app-bar-adjust {
            margin-top: 56px;
          }
          .scroller {
            height: calc(100vh - 56px);
          }
        }

      .clear_button {
        color: var(--paper-grey-600);
      }

      .clear_button[hidden] {
        /*visibility: hidden;*/
      }
     .graphic mwc-icon{
          color: var(--paper-grey-500);
        }

/* --deceased-person-color: var(--paper-grey-900);
    --working-retiree-person-color: var(--paper-lime-700);
    --employee-person-color: var(--paper-green-700);
    --ineligible-person-color: var(--paper-grey-700);
    --ex-employee-person-color: var(--paper-blue-700);
    --error-person-color: var(--paper-red-700);
    --retiree-person-color: var(--paper-yellow-700);*/

     .graphic mwc-icon[person-type='working_retiree']{
          color: var(--working-retiree-person-color);
        }
      .graphic  mwc-icon[person-type='employee']{
          color: var(--employee-person-color);
        }
        .graphic  mwc-icon[person-type='ex-employee']{
          color: var(--ex-employee-person-color);
          }
        .graphic  mwc-icon[person-type='retiree']{
          color: var(--retiree-person-color);
        }
        .graphic  mwc-icon[person-type='deceased']{
          color: var(--deceased-person-color);
        }
        .graphic  mwc-icon[person-type='ineligible']{
          color: var(--ineligible-person-color);
        }
        .graphic  mwc-icon[person-type='errors']{
          color: var(--error-person-color);
        }

        .nomore {
          width: 100%;
          text-align: center;
          color: var(--paper-grey-400);
        }

        .save-error {
          color: red;
          padding: 12px;

        }

        .signout {
          --mdc-theme-primary: white;
        }
        .userinfo {
          color: white;
        }
        .userdetail {
          font-size: 50%;
          display: inline-block;
        }
        .userdetail > * {
          display: block;
        }`;

const addressString = (addr) => {
  const sep = "•"
  //return `${['1', '2', '3'].map(n => addr[`line${n}`]).filter(a => a).join(` ${sep} `)} ${sep} ${addr.city} ${addr.address_state_code}`
  return `${addr.city} ${addr.address_state_code}`
}


class PeoplePage extends KalePage {
  get form_name() { return "People Page" }
  static styles = [super.styles, people_page_style]
  static icon = "people"
  static default_title = "People"

  constructor() {
    super();
    this.search_results = [];
    this.tags = [];
    this.__search_text = null;
    this.__employer = null;
    this.__include_tags = new Set();
    this.__exclude_tags = new Set();
    this.__bookmarked = "";
    this.__limit = 15;
    this.__fetching = false;
    this.__records_offset = 0;
    this.__sequence = 0;
    this.records_exhausted = false;
    this.blur_debounce = debounce(this.blur_actual.bind(this), 150);
    this._updateSearchResultsLong = debounce(this.updateSearchResultsDebounced.bind(this), 300);
    this._updateSearchResultsShort = debounce(this.updateSearchResultsDebounced.bind(this), 50);
    //this.updateSearchResults = debounce(this.oldSearch.bind(this), 100);
  }


  /* 


        <mwc-top-app-bar .type=${"fixed"} .bgcolor=${"var(--paper-green-700)"} >
            <div slot="title" id="title"><div style="position: relative; top: -4px"><mwc-icon style="font-size: 150%; position: relative; top: 8px; margin-right: 12px;">people</mwc-icon>People</div></div>
            <a href="/reports" slot="actionItems" style="color: white"><mwc-icon-button title="Reports" icon="assignment" ></mwc-icon-button></mwc-icon-button></a>
            <a href="/files" slot="actionItems" style="color: white"><mwc-icon-button title="Import" icon="cloud_upload" ></mwc-icon-button></mwc-icon-button></a>
            <a href="/settings" slot="actionItems" style="color: white"><mwc-icon-button title="Benefits App Settings" icon="settings" ></mwc-icon-button></a>
            <div class="userinfo" slot="actionItems">
              <a href="/prefs" style="color: white"><mwc-icon-button title=${this.login ? `${this.login.user} [${this.login.roles}]` : 'no login!'} icon="account_circle"></mwc-icon-button></a>
            </div>
            <mwc-button slot="actionItems" class="signout" @click=${e => this.dispatchEvent(new CustomEvent('signout', { bubbles: true, composed: true, detail: null }))}>sign out</mwc-button>
        </mwc-top-app-bar>

  */
  renderPage() {
    return html`
            <div class="column">
              <div id="search_card" class="card">
                <div id="search_box">
                    <mwc-icon style="position: relative; top: 12px;">search</mwc-icon>
                    <span id="search_text_editable" ?focused=${this.focused} @focus=${e => this.focused = true} @blur=${e => this.focused = false} contenteditable ?empty=${this.search_text === null || this.search_text === ""} @keypress=${e => this.searchFilterEnter(e)} @keydown=${e => this.searchFilterEnter(e)} @keyup=${e => this.searchFilterEnter(e)} @input=${e => this.searchInput(e)} ></span>
                    <mwc-icon-button class="clear_button" icon="clear" style="font-size: 10px" @click=${e => { this.resetSearch() }} ></mwc-icon-button>
                </div>
                <div class="chips">
                  ${this.tags && this.tags.length > 0 ? this.tags.map((tag, index) => html`
                    <kale-chip
                    label=${tag.name}
                    ?exclude=${this.__exclude_tags.has(tag.code)}
                    ?active=${  this.__exclude_tags.has(tag.code) || this.__include_tags.has(tag.code)}
                    @click=${e => this.toggleTag(tag.code)}
                    ></kale-chip>
                    `
    ) : html``}
                </div>
                <kale-chip
                    label="Bookmarked"
                    leadingIcon="bookmark"
                    style="position: relative; top: 4px;"
                    ?exclude=${this.__bookmarked === "exclude"}
                    ?active=${this.__bookmarked !== ""}
                    @click=${e => this.bookmarked = this.__bookmarked === "" ? "include" : this.__bookmarked === "include" ? "exclude" : ""}
                    ></kale-chip>
                <div id="employer" style="margin-top: 20px; width: 100%; position: relative; transform: translate(0,0);">
                  <kale-filtered-enum .label=${"Employer"} .field=${'employer_code'} .value=${this.__employer} @component-dirty=${e => { this.employer = e.detail.value; }}></kale-filtered-enum>
                </div>


                ${
      html``
      }


              </div>


              <div class="results" >
              ${this.search_results && this.search_results.length > 0 ? html`
                ${repeat(this.search_results, (p) => p.id, (p, index) => {
        let person_type = find_person_type(p) /*(() => {
          //FIXME: this may or may not always match the calculation being done on the person page
          let tags = new Set(p.tags.filter(t => !t.tag_type.deprecated).map(t => t.person_tag_type_code));
          if (tags.has("ERR")) { return "errors" }
          if (p.deceased_date !== null) { return "deceased" }
          if (tags.has("NVEMPL") || tags.has("NOTELIG")) { return "ineligible" }
          if (tags.has("RET") && tags.has("EMP")) { return "working_retiree" }
          if (tags.has("RET")) { return "retiree" }
          if (tags.has("EMP") || tags.has("FT") || tags.has("PT")) { return "employee" }
          if (tags.has("TERM")) { return "ex-employee" }
        })()*/
        return html`
                 <a href="/people/view?person=${p.id}"
class="graphic"
?hovered=${this.hovered === p.id}
@mouseover=${e => this.hovered = p.id}
@mouseleave=${e => this.hovered = null}>
<div class="graphic-content">
<mwc-icon person-type=${person_type} >${person_type === 'ineligible' ? 'person_outline' : 'person'}</mwc-icon></div></a>
                  <a href="/people/view?person=${p.id}" class="info" ?hovered=${this.hovered === p.id} @mouseover=${e => this.hovered = p.id} @mouseleave=${e => this.hovered = null}><div class="info-content">
                    <div class="name"> ${['title', 'first_name', 'middle_name', 'last_name', 'suffix'].map(f => p[f] && p[f].name ? p[f].name : p[f]).filter(f => f).join(" ")}</div>
                    <div class="employer">
                      ${p.employments && p.employments.length > 0 ? html`${p.employments.sort((b, a) => (new Date(a.start_date)) - (new Date(b.start_date)))[0].employer.name}` : html``}
                    </div>
                    <div class="address">
                      ${p.addresses && p.addresses.length > 0 ? html`${addressString(p.addresses.sort((b, a) => {
          return ((a.preferred ? 2 : 0) + (a.address_status_code === "GOOD" ? 1 : 0)) - ((b.preferred ? 2 : 0) + (b.address_status_code === "GOOD" ? 1 : 0));
        })[0])}` : html``}
                    </div>
                  </div></a>
                 <a href="/people/view?person=${p.id}" class="extrainfo" ?hovered=${this.hovered === p.id} @mouseover=${e => this.hovered = p.id} @mouseleave=${e => this.hovered = null}>
                 <div>${p.birth_date && !p.deceased_date ? html`<span class="age">age ${Math.floor((new EventDate(p.birth_date)).yearsUntil(new EventDate()))}</span>` : p.deceased_date ? html`<span class="deceased">deceased</span>` : html``}</div>
                 </div>
                 <a href="/people/view?person=${p.id}" class="tags" ?hovered=${this.hovered === p.id} @mouseover=${e => this.hovered = p.id} @mouseleave=${e => this.hovered = null}><div class="tags-content">
                     ${p.tags.sort((a, b) => b.tag_type.priority - a.tag_type.priority).map(t => html`<span class=${`tag ${t.person_tag_type_code}`}>${t.tag_type.name}</span> `)} 
                     ${p.bookmarks.filter(b => b.user_email === window.authmgr.current_login.email).length > 0 ? html`<span class="bookmark"><mwc-icon>bookmark</mwc-icon></span>` : html``}
                  </div></a>
                  <a href="/people/view?person=${p.id}" class="actions" ?hovered=${this.hovered === p.id} @mouseover=${e => this.hovered = p.id} @mouseleave=${e => this.hovered = null}><div class="actions-content">
                    <mwc-button>View</mwc-button>
                  </div></a>
                `})}
              ` : html``}
              </div>

              ${this.records_exhausted ? html`<div class="nomore">${this.search_results.length === 0 ? "no matches found" : this.search_results.length > 1 ? `${this.search_results.length} matches found` : '1 match found'}</div>` : html``}
              <div id="watcher" @click=${e => this.requestSearch()} ></div>
            </div>
        <mwc-fab class="pink" icon="person_add" extended label="New" @click=${e => { 
            this.add_person = new AddPersonDialog();
            this.add_person.addEventListener('form-saved', e => {
              console.warn("ADD PERSON FORM SAVED", e);
              this.add_person = null;
            })
          }}></mwc-fab>

        ${this.add_person ? this.add_person : nothing}
      `;
    /*<div>${this.valid ? "valid" : "invalid"}</div>
            <ul>
            ${[...this.invalid_map.entries()].map(([name, e]) => {
             console.log('entry:', e);
            return html`
              <li>${e.field ? 'field' : 'form'}: ${e.name ? e.name : e.field} : ${e.dirty ? 'dirty' : 'clean'} : ${e.valid ? 'valid' : 'invalid'} </li>`
            })}
            </ul>


                  headerlabel="New Person"
                  acceptlabel="Create"
                  declinelabel="Cancel"
                  @accept=${e => {this.add_person_error=null; this.save();}}}
                  @cancel=${e => {this.add_person = false; this.setToDefault(); this.add_person_error=null;}}
    */
  }

  searchFilterEnter(e) {
    if (e.keyCode && e.keyCode === 13) {
      e.preventDefault();
      this.editor.dispatchEvent(new KeyboardEvent(e.type, { ...e, key: ' ', code: 'Space', charCode: 0, keyCode: 32 }));
    }
  }
  searchInput(e) {
    const search = this.editor.innerHTML.trim();
    this.search_text = search; // search.length > 0 ? search + ' ' : ''; //this.editor.innerHTML;
    //this.placeholder = search; //this.editor.innerHTML;
  }

  resetSearch() {
    this.search_text = null;
    this.editor.innerHTML = "";
    this.clearTags();
    this.employer = null;
  }

  get focused() {
    return this._focused;
  }
  set focused(focus) {
    let old = this._focused;
    if (focus) {
      this.blur_debounce.clear();
      this._focused = true;
      if (focus != old) { this.requestUpdate("focused", old); }
    } else {
      this.blur_debounce();
    }
  }

  blur_actual() {
    let old = this._focused;
    this._focused = false;
    if (old != this._focused) {
      this.requestUpdate("focused", old);
    }
  }

  input_change() {
    let text = this.editor.innerHTML;
  }
  clearTags() {
    let oldi = new Set(this.__include_tags);
    let olde = new Set(this.__exclude_tags);
    this.__include_tags = new Set();
    this.__exclude_tags = new Set();
    this.requestUpdate("__include_tags", oldi);
    this.requestUpdate("__exclude_tags", olde);
    this.updateSearchResultsShort();
  }
  unsetTag(tag) {
    //console.log(`unset ${tag}`);
    let oldi = new Set(this.__include_tags);
    let olde = new Set(this.__exclude_tags);
    this.__include_tags.delete(tag);
    this.__exclude_tags.delete(tag);
    this.requestUpdate("__include_tags", oldi);
    this.requestUpdate("__exclude_tags", olde);
    this.updateSearchResultsShort();
  }

  setIncluded(tag) {
    //console.log(`set incl ${tag}`);
    let oldi = new Set(this.__include_tags);
    let olde = new Set(this.__exclude_tags);
    this.__include_tags.add(tag);
    this.__exclude_tags.delete(tag);
    this.requestUpdate("__include_tags", oldi);
    this.requestUpdate("__exclude_tags", olde);
    this.updateSearchResultsShort();
  }
  setExcluded(tag) {
    //console.log(`set excl ${tag}`);
    let oldi = new Set(this.__include_tags);
    let olde = new Set(this.__exclude_tags);
    this.__include_tags.delete(tag);
    this.__exclude_tags.add(tag);
    this.requestUpdate("__include_tags", oldi);
    this.requestUpdate("__exclude_tags", olde);
    this.updateSearchResultsShort();
  }

  toggleTag(tag) {
    let excluded = this.__exclude_tags.has(tag);
    let included = this.__include_tags.has(tag);

    if (excluded) {
      this.unsetTag(tag);
    }
    else if (included) {
      this.setExcluded(tag);
    }
    else {
      this.setIncluded(tag);
    }
  }

  updateSearchResultsLong() {
    this._updateSearchResultsLong();
  }
  updateSearchResultsShort() {
    this._updateSearchResultsShort();
  }

  updateSearchResultsDebounced() {
    //let bookmarked = this.__bookmarked === true ? "include" : this.__bookmarked === null ? "" : "exclude";
    let search = `text: "${this.search_text}" employer: "${this.__employer ? this.__employer : ''}" +tags: [${[...this.__include_tags.values()]}] -tags: [${Array.from(this.__exclude_tags.values())}] bookmarks: <${this.__bookmarked}>`
    if (search == this.__last_search_hash) {
      //console.log(`skipping search`);
      return;
    }

    // Search parameters have changed, reset and rerun
    //console.log(`SEARCH: (last= <<${this.__last_search_hash}>> => new= <<${search}>>)`);
    this.__last_search_hash = search;
    this.records_exhausted = false;
    this.__records_offset = 0;

    //   this.search_results = [];

    this.__fetching = false;
    this.requestSearch();
  }

  requestSearch() {
    this.doSearch();
    //window.requestIdleCallback(() => this.doSearch())
  }

  doSearch() {
    if (this.records_exhausted) {
      //console.log(`Search complete: exhausted=${this.records_exhausted}, fetching=${this.__fetching}`);
      return;
    }
    //if (this.__fetching) { console.log(`requeuing search request: exhausted=${this.records_exhausted}, fetching=${this.__fetching}`); this.requestSearch(); return;}
    if (this.__fetching) {
      //console.log(`requeuing search request: exhausted=${this.records_exhausted}, fetching=${this.__fetching}`);
      return;
    }
    this.__fetching = true;

    let q = gql`
      query search_people($useremail: String!, $recs: Int!, $rec_offset: Int!, $search_string: String!, $bookmarked: String!,  $employer: String!, $include_tags: _text!, $exclude_tags: _text!) {
        results:people_search_all(
        args: {
            useremail: $useremail,
            recs: $recs,
            rec_offset: $rec_offset,
            search_string: $search_string,
            bookmarked: $bookmarked,
            employer: $employer,
            include_tags: $include_tags,
            exclude_tags: $exclude_tags
            }
          ){
          ...PersonSearchFields
        }
      }
      ${person_search_fragment}
    `;

    console.log("EMAIL IS", window.authmgr.current_login.email);
    let args = {
      useremail: window.authmgr.current_login.email,
      recs: this.__limit,
      rec_offset: this.__records_offset,
      search_string: this.search_text ? this.search_text : "",
      bookmarked: this.__bookmarked,
      employer: this.__employer ? this.__employer : "",
      include_tags: `{${[...this.__include_tags.values()].join(',')}}`,
      exclude_tags: `{${[...this.__exclude_tags.values()].join(',')}}`
    };
    console.log("SENDING PEOPLE QUERY", args);
    this.__sequence += 1;
    const seq = this.__sequence;
    //console.log(`"${args.search_string}"`, seq, this.__sequence);
    client.query({
      fetchPolicy: 'network-only',
      query: q,
      variables: args
    }
    ).then(data => {
      //console.log("PEOPLE QUERY DATA CAME BACK", data);
      if (this.__sequence !== seq) {
        //console.warn('out of seq results', seq, this.__sequence);
        return;
      }
      //console.log("search results", seq, this.__sequence);

      if (this.__records_offset === 0) this.search_results = [];
      this.newData(data);
    }
    )
      .catch(error => {
        console.error('PEOPLE QUERY GOT ERROR', error);
        window.authmgr.refresh();
      });
  }

  set bookmarked(val) {
    //console.log(`bookmarked ${this.__bookmarked} => ${val}`);
    let old = this.__bookmarked;
    this.__bookmarked = val;
    //console.log(this.__bookmarked);
    if (old !== val) {
      this.requestUpdate("bookmarked", old);
      this.updateSearchResultsShort();
    }
  }
  set search_text(text) {
    //console.log(`search_text ${this.__search_text} => ${text}`);
    let old = this.__search_text;
    this.__search_text = text;
    if (old !== text) {
      this.updateSearchResultsLong(); // longer search req debounce for typed text
    }
  }
  get search_text() { return this.__search_text }


  set employer(code) {
    //console.log(`employer ${this.__employer} => ${code}`);
    let old = this.__employer;
    //console.log("search_text -> ", text);
    if (old != code) {
      this.__employer = code;
      this.requestUpdate("employer", old);
      this.updateSearchResultsShort();
    }
  }
  get emmployer() { return this.__emmployer }


  newData(d) {
    this.__fetching = false;
    //console.log("got search results", d);
    if (d.data.results) {
      let count = d.data.results.length;
      this.__records_offset += count;
      if (count < this.__limit) {
        this.records_exhausted = true;
      }
      //console.log("new results", d.data.results);
      this.search_results = [...this.search_results, ...d.data.results];

      if (this.__result_trigger_exposed && !this.records_exhausted) this.requestSearch();
    } else {
      //console.warn("no people search results")
      this.search_results = [];
    }
  }



  firstUpdated() {
    super.firstUpdated();
    this.editor = this.renderRoot.getElementById('search_text_editable');
    this.search_box = this.renderRoot.getElementById('search_box');
    this.search_box.addEventListener("click", e => this.editor.focus());

    //const form = this.renderRoot.getElementById('form');
    this.form = this.renderRoot.getElementById('form');
    this.search_box = this.renderRoot.getElementById('search_box');
    // this.updateSearchResults();

    let options = {
      root: document.querySelector('.scroller'),
      rootMargin: '0px',
      threshold: 0.5
    }

    this.observer = new IntersectionObserver((entries, obs) => {
      entries.forEach(entry => {
        //console.log("intersection observed:", entry.isIntersecting, entry.intersectionRatio);
        this.__result_trigger_exposed = entry.isIntersecting;
        if (entry.isIntersecting) {
          this.requestSearch();
        }
      })
    }, options);

    this.trigger = this.renderRoot.getElementById('watcher');
    this.observer.observe(this.trigger)


    client.query({
      query: gql`
        query {
          person_tag_type_codes 
          (order_by: {priority: desc})
          {
            code
            name
            is_system
            priority
            deprecated
          }
        }

      `})
      .then(data => this.tags = data.data.person_tag_type_codes.filter(t => !t.deprecated))
      .catch(error => {
        console.error("PEOPLE TAG QUERY", error)
        window.handleJWTError(error);
      });

    client.query({
      query: gql`
        query {
          employer_codes
          {
            code
            name
          }
        }

      `})
      .then(data => this.employers = data.data.employer_codes)
      .catch(error => {
        console.error("PEOPLE EMPLOYER CODES QUERY", error)
        window.handleJWTError(error);
      });

  }


  activateRoute() {
    //Poke the search query whenever the page is reshown:
    //console.log("people-page activated");
    this.__last_search_hash = "";
    this.updateSearchResultsShort();
  }

  static get properties() {
    return {
      records_exhausted: { type: Boolean },
      tags: { type: Array },
      employers: { type: Array },
      search_results: { type: Array },
      add_person: { type: Boolean },
      add_person_error: { type: Object },
      hovered: { type: String },
      active_user: { type: String },
      login: { type: Object },
      title: { type: String },
      ...(super.properties)
    };
  }
}

window.customElements.define('people-page', PeoplePage);
export { PeoplePage }



class AddPersonDialog extends KaleForm {
  get form_name() { return "AddPersonDialog" }
  static get properties() {
    return {
      add_person: { type: Boolean },
      add_person_error: { type: Object }
    }
  }
  constructor() {
    super();
    this.add_person = true;
  }
  render() {
    return html`
        <mwc-dialog id="dialog" ?open=${this.add_person}>
          <div class="header" ?errors=${this.add_person_error}
            style="margin: -24px; width: calc(100% + 48px); height: 48px; padding-bottom: 16px; padding-left: 24px; color: var(--header-text-color); background-color: var(--header-color);">
            <h2>Add New Person</h2>
          </div>
        
          ${this.add_person_error ? html`<div class="save-error">
            <h2>SAVE FAILED</h2>
            ${this.add_person_error.msgs.map(m => html`<div>${m}</div>`)}
          </div>` : html``}
          <edit-person @person-saved=${e=> { this.add_person = false; this.setToDefault(); }}
            @save-error=${err => { this.add_person_error = err.detail; console.error("PERSON-SAVE-ERROR", err); }}
            nodeceased employment></edit-person>
          <div slot="primaryAction" class="footer_buttons"
            style="width: 100%; display: flex; align-items: center; justify-content: flex-end; flex-direction: row;">
            <mwc-button @click=${e=> { this.add_person = false; this.setToDefault(); this.add_person_error = null; }}
              >${this.dirty ? "cancel" : "close"}</mwc-button>
            <mwc-button icony="content_create" ?unelevated=${this.dirty && this.valid} ?disabled=${!this.dirty || !this.valid}
              @click=${(e)=> { this.add_person_error = null; this.save(); }}
              >${this.dirty && !this.valid ? "incomplete" : (this.dirty ? (this.saving ? "saving..." : "save") : "save")}
            </mwc-button>
          </div>
        </mwc-dialog>
  `
  }
}

window.customElements.define('add-person', AddPersonDialog);
export { AddPersonDialog, find_person_type }