import { LitElement, html, css, svg, nothing } from 'lit';
import { KalePage } from '../shared-components/page.js';
import { client, insert_pension_factors, insert_tax_factors } from '../queries/queries.js';
import { readFactorFile, load_file } from '../components/importer.js';
import { get_pension_factors, reset_pension_factors_cache } from '../queries/queries.js' 
import '../components/edit-person.js';

const import_export_page_styles = css`
       
      .pink {
        --mdc-theme-on-primary: white;
        --mdc-theme-primary: var(--paper-pink-a200);
        --mdc-theme-on-secondary: white;
        --mdc-theme-secondary: var(--paper-pink-a200);

        }

      .column {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column; 
        width: 100%;
        padding: 24px;
        padding-top: 0;
        height: 100%;
      }
      .column > * {
        margin-top: 24px;
      }
      .load_message {
          padding-bottom: 18px;

        }

      .drag_placeholder {
          text-align: center;
          opacity: 0.4;
          box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; 
        width: 100%;
        height: 100%;

        }

        .scroller {
          overflow: overlay;
          height: calc(100vh - 64px);
          width: 100%;
        }

        .content-area {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;

          height: calc(100vh - 64px);
        }

        mwc-fab {
          /*
          position: fixed;
          right: 50px;
          bottom: 50px;
          */
        }
        #fab-holder {
          width: 100%;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: flex-end;
          flex-direction: row;
        }

        .primary {
          font-weight: 500;
          font-size: 20px;
          }
        .secondary {
          font-weight: 200;
          font-size: 16px;
          margin-top: 8px;
          }


        mwc-top-app-bar {
          --mdc-theme-primary: var(--paper-green-700);
          background-color: var(--paper-green-700);
        }

        .card {
          box-sizing: border-box;
          background-color: white;
          border: 1px solid var(--paper-grey-400);
          border-radius: 8px;
          transition: var(--shadow-transition);
          height: 100%;
          width: 100%;
          font-size: 12px;
          overflow: hidden;

          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column; 

        }
        .card > h2 {
          margin: 24px;
        }

        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }

        .card[height_computed] {
          height: fit-content;
        }

        .table-scroller[height_computed] {
          height: fit-content;
        }
              
        .top-app-bar-adjust {
          margin-top: 64px;
          }


        /* TABLE STYLES */

        .table-container { flex: 1 1; box-sizing: border-box; width: 100%; position: relative; /*FIXME: this isn't optimal... */ overflow-y: hidden; overflow-x: overlay}
        .table-scroller { overflow-y: hidden; height: 100%; width: 100%; min-width: fit-content; }
        table { box-sizing: border-box; border-collapse: collapse; width: 100%;}
        td,th { border: none; padding: 10px 28px; max-width: calc(100% / 14)}
        th {
          text-align: left;
          text-transform: uppercase;
          border: none;
          font-size: 90%;
          opacity: 0.5;
          max-width: calc(100vw / 20);
        }
        th[type="number"], th[type="amt"] {
          text-align: right;
        }
        th[type="match"] {
          text-align: center;
        }
        th > div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row; 
          position: relative;
        }
        
        th[type="number"] > div, th[type="amt"] > div {
          justify-content: flex-end;
        }
        th[type="number"] span, th[type="amt"] span {
          order: 99;
        }


        th mwc-icon {
          visibility: hidden;
          margin-right: 4px;
        }
        th mwc-icon[sort]{
          visibility: visible;
        }
        th:hover mwc-icon:not([sort]) {
          visibility: visible;
          opacity: 0.45;
        }
        th span {
        }

        th:hover {
          cursor: pointer;
        }

        th.control {
          opacity: 1;
        }



        /*
        tr { border: none}
        tr:nth-child(even) { background: #CCC;}
        */

        tr { border: none; border-bottom: 1px solid var(--paper-grey-400);}
        tbody > tr:hover {background-color: var(--paper-yellow-50); cursor: pointer;}
        tr[selected] {background-color: var(--paper-teal-50); cursor: pointer;}
        tr[imported] {opacity: 0.5}


        td.amtcell, td.datecell, td.textcell, td.numbercell > *
        {
          cursor: auto;
        }

        td.remove { padding: 10px 0px;}
        td.type { 
          text-transform: uppercase;
          font-size: 75%;
          font-weight: bold;
        }

        td.amtcell, td.numbercell {
          text-align: right;
        }
        td.boolcell { 
          text-align: center;
        }
        
        td[match="good"] {
          color: var(--paper-green-900);
        }
        td[match="poor"] {
          color: var(--paper-yellow-900);
          font-weight: 500;
        }
        td[match="bad"] {
          color: var(--paper-red-900);
          font-weight: 800;
        }

        td.match {
          font-weight: 900;
        }


        span.currency {
          float: left;
        }
        contrib-datecell[changed], contrib-amtcell[changed] {
        font-style: italic;
        color: var(--paper-green-600); 
        }

        .table_controls {
          box-sizing: border-box;

          font-size: 16px;
          padding: 18px 36px 0px 36px;
          width: 100%;
          height: 96px;

          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;

          --mdc-theme-primary: var(--paper-teal-700);
        }
        .table_controls[selection] {
          background-color: var(--paper-teal-100);
          color: var(--paper-teal-900);
        }

        .table_controls > * {
        }

        .table_controls > .info {
          margin-left: 64px;
        }
        div.info > span {
        }
        span.info-label {
          font-weight: 100;
          font-size: 90%;
        }
        span.info-data { 
          margin-left: 0.3em;
          font-weight: 900;
          color: var(--paper-purple-700);
          font-size: 130%;
        }

        .table_controls .buttons {
          flex: 1 1;

          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
        }
        .buttons > * {
          margin-right: 12px;
          margin-left: 12px;
        }

        div.gauge {
          position: relative;
          width: 48px;
          height: 48px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          font-size: 120%;
          font-weight: bold;
        }

        div.gauge > span {
          white-space: nowrap;
          text-transform: uppercase;
        }
       
        #navigation {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          padding: 12px;
        }

        #navigation-info {
          margin-right: 12px;
        }
        #navigation:last-child { margin-right : 12px }

        mwc-checkbox[disabled] {
          opacity: 0.5;
        }

 
        svg { overflow: visible; }
        circle { opacity: 0.25 }
        path { fill: none; stroke-width: 10px;}
        circle { fill: none; stroke-width: 5px; opacity: 0.3}
        svg[match="good"] { stroke: var(--paper-green-900)}
        svg[match="poor"] { stroke: var(--paper-yellow-900)}
        svg[match="bad"] { stroke: var(--paper-red-900)}
`;


class ImportFactorsPage extends KalePage {
    static styles = [super.styles, import_export_page_styles]
    static icon = "cloud_upload"
    static default_title = "Import Factor Data"

    get form_name() { return "Import" }
    constructor() {
        super();
        this.title = "Import";
        this.files = [];
        this.update_factor_metadata();
    }

    async update_factor_metadata () {
      this.factor_data = await get_pension_factors();
    }

    renderPage() {

        return html`
              <div class="column" ?dragging=${this.dragging} ?data=${this.data && !this.dragging} id="drop-target" @drop=${e => { e.preventDefault(); this.dragDrop(e) }} @dragenter=${e => this.dragEnter(e)} @dragover=${e => this.dragEnter(e)} @dragleave=${e => this.dragLeave(e)} >
              <div id="fab-holder">
              <mwc-fab class="pink" id="upload_button" icon="file_upload" extended label="Open File" @click=${e => { this.renderRoot.getElementById('file_chooser').click(); this.renderRoot.getElementById('upload_button').blur(); }}></mwc-fab>
              </div>
              <table id = "fatcor_info_table">
                <tr> 
                    <th></th>
                    <th>Last uploaded filename</th>
                    <th>Last uploaded date</th>
                </tr>
                <tr>
                  <th>Factors</th>
                  <th>           
                  ${this.factor_data?.regular_factors_filename}
                  </th>
                  <th>
                  ${this.factor_data?.regular_factors_date}
                  </th>
                </tr>
                <tr>
                <th>Tax Rates</th>
                  <th>        
                  ${this.factor_data?.tax_factors_filename}
                  </th>
                  <th>
                  ${this.factor_data?.tax_factors_date}
                  </th>
                </tr>
              </table>
      <input
          id="file_chooser"
          type="file"
          multiple="true"
          @change=${e => { for (const f of e.target.files) { this.addFile(f) } }}
          hidden>
          `;
    }

    dragEnter(e) {
        e.preventDefault();
        this.dragging = true;
    }
    dragLeave(e) {
        e.preventDefault();
        this.dragging = false;
    }

    dragDrop(e) {
        e.preventDefault();
        this.dragging = false;

        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < e.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (e.dataTransfer.items[i].kind === 'file') {
                    var file = e.dataTransfer.items[i].getAsFile();

                    window.requestIdleCallback(() => this.addFile(file));

                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < e.dataTransfer.files.length; i++) {
                window.requestIdleCallback(() => this.addFile(e.dataTransfer.files[i]));
            }
        }
        // Pass event to removeDragData for cleanup
        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to remove the drag data
            e.dataTransfer.items.clear();
        } else {
            // Use DataTransfer interface to remove the drag data
            e.dataTransfer.clearData();
        }
    }

    async addFile(f) {
            switch (f.type) { 
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  let {factors,factor_type} = await readFactorFile(f);
                  console.log("data", factors)
                  console.log("factor type", factor_type)
                  client.mutate({
                    //mutation: insert_tax_factors,
                    mutation: factor_type === 'tax' ? insert_tax_factors : insert_pension_factors, 
                    variables: {"newFactors": factors}
                    //{
                    //  "newFactors": {   
                    //    filename: "tax_factors_filename",
                    //    mortalitytable_90cm: [],
                    //    tax_rates_120pct_midterm: {}}
                    //}
                  }).then(factors => {
                    console.log("returned data", factors);
                    reset_pension_factors_cache();
                    this.update_factor_metadata(); 
                    this.dispatchEvent(new CustomEvent('snackbar', {
                      bubbles: true,
                      composed: true,
                      detail: {
                        text: `${factor_type === 'pension' ? 'Pension factors' : 'Tax factors'} "${f.name}" saved`
                      }
                    }))
                  })
                    break;
                case "text/tab-separated-values":
                case "text/csv":
                    this.data = { 'type': 'error', message: `Only .xlsx files are currently recognized` };
                    break;
                default:
                    console.error("invalid file type:", f.type);
                    this.data = { 'type': 'error', message: `Found [${f.type}] file, but only .xlsx files are currently recognized.` };
            }
    }

    static get properties() {
        return {
            files: { type: Array },
            dragging: { type: Boolean },
            active_user: { type: String },
            factor_data: {type: Object},
            ...(super.properties)
        };
    }
}

window.customElements.define('import-factors-page', ImportFactorsPage);
export { ImportFactorsPage }