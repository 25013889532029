import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * Address
 */

export const address_fields = gql`
  fragment AddressFields on address {
    id
    person_id
    assigned_date

    address_status_code
    status {
      code
      name
    }

    preferred  

    line1  
    line2  
    line3  
    city  
    address_state_code
    state {
      code
      name
    }
    zip  
    google_id
    coordinates
    note
   }`


export const address_info_query = gql`
  query address_info($AddressId: uuid!){
    address( where: {id: {_eq: $AddressId}} ){
         ...AddressFields
    }
  }
  ${address_fields}
`

export class AddressInfo extends StaticQuery {
  get required_impl() {
    return ['AddressId'];
  }
  get gql_impl() {
    return address_info_query;
  }
  data_impl({ data: { address: [d] } }) {
    return d;
  }
}


export const addresses_for_person_query = gql`
  query address_info($PersonId: uuid!) {
    address (
        where: {person_id: {_eq: $PersonId}}
        order_by: {assigned_date: asc}
        ){
        ...AddressFields
    }
  }
  ${address_fields}
  `

export class PersonAddress extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return addresses_for_person_query;
  }
  data_impl({ data: { address: addresses } }) {
    return addresses;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_address_query = gql`
  mutation update_address($leaveId: uuid!, $changeMap: address_insert_input!) {
    update_address (
      where: {
          id: {_eq: $addressId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...AddressFields
      }
    }
  }
  ${address_fields}
`
export const insert_address_query = gql`
  mutation insert_address($changeMap: address_insert_input!) {
    insert_address (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...AddressFields
      }
    }
  }
  ${address_fields}
`

export const upsert_address_query = gql`
  mutation upsert_address($changeMap: address_insert_input!, $changeCols: [address_update_column!]!) {
    address:insert_address (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: address_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...AddressFields
      }
    }
  }
  ${address_fields}
`

export const delete_address_query = gql`
  mutation delete_address($deleteId: uuid!) {
    delete_address (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditAddressInfo extends SaveableQuery {
  get friendly_name() { return "Address" }
  get required_keys_impl() {
    return [
      "person_id"
    ];
  }
  // FIXME: complains on delete because the address property is null
  data_impl({ data: { address: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_address_query;
  }
  get delete_gql_impl() {
    return delete_address_query;
  }
  get save_queries_impl() {
    return [(new PersonAddress(null, null))];
  }
}

