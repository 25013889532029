import { LitElement, html, css, nothing } from 'lit';
import '@material/mwc-button';
import '@material/mwc-switch';
import '@material/mwc-select';
import '@material/mwc-dialog';
import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-textfield';
import '@material/mwc-formfield';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);

//import * as recursiveDiff from 'recursive-diff';
import {getDiff} from 'recursive-diff';

import '../shared-components/card.js';
import '../shared-components/chip.js';


const history_style = css`
    kale-card {
        width: 100%;
    }

    kale-card > .card-content, kale-card > h3 {
        --mdc-theme-primary: var(--paper-teal-900);
        --mdc-theme-on-primary: white;
        --mdc-text-field-fill-color: white;
        background-color: var(--paper-teal-200);
        color: var(--paper-teal-900);
    }
    kale-card > h3 {
    }
    kale-card, kale-card > .card-content {
        overflow: visible;
        position: relative;
    }

    .sec {
        margin-right: 1em;
    }

    code {
        font-size: small
    }

    mwc-list-item[action="DELETE"] {
        --change-action-color-primary: var(--paper-red-800);
    }
    mwc-list-item[action="INSERT"] {
        --change-action-color-primary: var(--paper-blue-800);
    }
    mwc-list-item[action="UPDATE"] {
        --change-action-color-primary: var(--paper-purple-800);
    }
    mwc-list-item {
        color: var(--change-action-color-primary);
    }
    mwc-list-item mwc-icon {
        color: var(--change-action-color-primary);
    }
    .card-heading {
        cursor: pointer;
        padding: 1em;
        margin-bottom: 0;
        width: 100%;
        background-color: var(--paper-teal-700);
        color: white;
    }
    .card-heading h3 {
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .reveal {
        display: block;
        flex: 1;
        text-align: right;
    }
    .card-title {
        flex: 1;
        margin-left: 12px;
    }
    .header {
        text-transform: lowercase;
    }

    .detail-container {
        position: relative;
    }
    .detail {
        position: fixed;
        left: calc(100% + 1em); 
        width: fit-content;
        margin-right: 2em;
        box-sizing: border-box;
    }

    .data-block {
        background-color: var(--paper-grey-100);
        border-radius: 6px;
    }

    .detail > kale-card > div{
        padding: 2em;
        max-height: 50vh;
        overflow: auto;
        font-size: 90%;
        box-sizing: border-box;
    }

    .change-detail-box {
        box-sizing: border-box;
       padding: 20px;
       border-radius: 10px;
    }

    .change-detail-header {
        font-size: 110%;
        font-weight: bold;
        color: var(--paper-grey-900);
        margin-bottom: 6px;
    }
    .change-detail-header > span {
        background-color: var(--paper-grey-100);
        padding: 2px 4px;
        border-radius: 5px;
        font-weight: 100;
    }
    .change-detail-header > span.table {
        font-weight: 900;

    }

    .prop-container {
        font-family: 'monospace';
        width: fit-content;
        color: var(--paper-grey-900);
        white-space: nowrap;
    }
    .prop-container[has-change] {
        font-weight: 900;
    }
    .prop-container[kind="delete"] {
        font-weight: 900;
        color: red;
        text-decoration: line-through;
    }
    .prop-container[kind="insert"] .prop-val {
        font-weight: 900;
        color: var(--paper-blue-700);
    }
    .prop-name {
        font-weight: 100;
        opacity: 0.76;
    }
    .prop-container[has-change] .prop-name {
        font-weight: 900;
        opacity: 1;
    }
    
    .prop-val {
        font-weight: 300;
        background-color: var(--paper-grey-100);
        padding: 2px 4px;
        border-radius: 5px;
    }
    .prop-val[isold] {
        background-color: var(--paper-grey-800);
        color: white;
    }
    .prop-val[isnew] {
        background-color: var(--paper-purple-800);
        color: white;
    }
    .prop-obj {

    }
    .prop-arrow {
        position: relative;
        display: inline-block;
        width: 15px;
        top: -5px;
    }

    .prop-arrow:before {
        content: "";
        width: 10px;
        height: 3px;
        background: var(--paper-purple-800);
        position: absolute;
        top: 50%;
        margin-top: -1px;
        right: 9px;
    }

    .prop-arrow:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent var(--paper-purple-800);
        position: absolute;
        top: 50%;
        margin-top: -5px;
        right: 5px;
    }
`;

const renderProp = (path, val, isObj, diff, transform=(v) => v, kind) => {
    if (path.length === 0) { return html`${val}`}
    return html`<div class="prop-container" kind=${kind} ?has-change=${diff} style=${`padding-left: ${path.length*5}px`}>
        <span class="prop-name">${path.at(-1)}:</span>
        ${isObj ? val : html`<span class="prop-val" ?isold=${diff}>${transform(val)}</span>`}
        ${diff ? html`<span class="prop-arrow"></span><span class="prop-val" ?isnew=${diff}>${transform(diff.val)}</span>` : nothing}
    </div>`
}
const typeOf = (obj) => {
    return {}.toString.call(obj).split(' ')[1].slice(0, -1).toLowerCase();
}

const doProp = (path, val, kind, diffs) => {
    const diff = diffs.get(path.join(':'));
    let transform = (v) => v;
    switch (typeOf(val)) {
        case 'object':
        case 'array':
            return renderProp(path, html`
            ${path.length > 0 ? '{' : nothing}
            ${Object.entries(val).map(([nprop, nval]) => doProp([...path, nprop], nval, kind, diffs))}
            ${path.length > 0 ? '}' : nothing}
            `, true);
        case 'string':
            transform = v => `"${v}"`;
        case 'number':
            break;
        case 'date':
            break;
        case 'null':
            val = "null";
    }
    return renderProp(path, val, false, diff, transform, kind);
}

class ChangeHistory extends LitElement {
  constructor() {
    super();
    console.warn("change hist");
  }
  static styles = history_style;
  static get properties() {
    return {
      changes: { type: Array },
      selected: { type: String },
      open: { type: Boolean },
    }
  }

  reconstructChangeObjects() {
    // apply successive change deltas object_id-wise to 
    // reconstruct and insert a complete 'data' object 
    // with the full entity as it was at the start of each
    // change record 
    const raw = this.changes?.flatMap?.(c => c.changes);
    let changes = raw.reverse();
    let current_objs = {};
    let output = changes?.map(c => {
        let {action, object_id, old, new:delta} = c;
        switch (action) {
            case "INSERT": {
                current_objs[object_id] = delta;
                c.data = delta;
                break;
            }
            case "UPDATE": {
                let o = current_objs?.[object_id];
                c.data = o;
                let n = {...o, ...delta};
                c.ndata = n;
                current_objs[object_id] = n;
                break;
            }
            case "DELETE": {
                let o = current_objs?.[object_id];
                c.data = o;
                current_objs[object_id] = {};
                break;
            }
        }
        return c;
    });
    // apply the enriched change objects to the original list
    const lookup = Object.fromEntries(output.map(c => [c.id, c]))
    return this.changes?.map?.(c => ({...c, changes: c.changes.map(cc => lookup[cc.id])}));
  }

  renderOrigData(d, label) {
    if (!d) { return nothing; }
    let str = JSON.stringify(d, null, 2).slice(1, -1).trim();
    return html`
    <div class="data-block">
    <pre><code>  ${str}</code></pre>
    </div>
    `;
  }
  renderInsert(chg) {
    return html`<div class="change insert">${doProp([], chg.data, "insert", new Map())}</div>`
  }
  renderUpdate(chg) {
    const {old, new:chged, data, ndata} = chg;
    let diff = new Map(getDiff(data, ndata).map(d => [d.path.join(':'), d]));
    return html`<div class="change update">${doProp([], chg.data, "update", diff)}</div>`
  }
  renderDelete(chg) {
    return html`<div class="change delete">${doProp([], chg.data, "delete", new Map())}</div>`
  }

  renderChange(chg) {
    switch (chg.action) {
        case 'INSERT':
            return this.renderInsert(chg);
        case 'UPDATE':
            return this.renderUpdate(chg);
        case 'DELETE':
            return this.renderDelete(chg);
    }
  }

  render() {
    const c = this.reconstructChangeObjects();
    console.log("CHANGES", c);
    return html`
        <div style="margin-top: 16px; margin-bottom: 0px; height: fit-content;">
            <kale-card elevation="1" style="--card-padding: 0px;">
                <div class="card-heading" @click=${e => this.open = !this.open}>
                    <mwc-ripple></mwc-ripple>
                    <h3>
                        <mwc-icon>receipt</mwc-icon>
                        <span class="card-title">Data History</span>
                        <span class='reveal'>
                            <mwc-icon>${this.open ? 'arrow_drop_up' : 'arrow_drop_down'}</mwc-icon>
                        </span >
                    </h3 >
                </div>
                ${!this.open ? nothing : html`
                <mwc-list @action=${e => console.log(e)}>
                ${this?.changes.map((c,i) => html`
                    ${this.selected !== c.id || !this.open ? nothing : html`
                    <div class="detail-container" >
                        <div class="detail" >
                            <kale-card elevated>
                                <div>
                                    ${c.changes.map(d => html`
                                    <div class="change-detail-box">
                                        <div class="change-detail-header"><span class="table">${c.table}</span> <span class="verb">${c.verb}</span> <span class="date">${c.date}</span></div>
                                        <div class="change-detail-data">
                                            ${this.renderChange(d)}
                                        </div>
                                    </div>
                                    `)}
                                </div>
                            </kale-card>
                        </div>
                    </div>
                    ` }
                    <mwc-list-item graphic="icon" twoline hasmeta table=${c.table} action=${c.action} @request-selected=${e => this.selected = this.selected === c.id ? null : c.id}>
                        <span class="header">${c.alt_label} ${c.verb}</span>
                        <span class="sec user" slot="secondary">${c.user}</span>
                        <span class="sec date" slot="secondary" title=${c.date}>${c.reldate}</span>
                        <mwc-icon slot="graphic" >${c.icon}</mwc-icon>
                    </mwc-list-item>
                    ${i !== this.changes.length - 1 ? html` <li divider role="separator"></li>` : nothing}
                `)}
                </mwc-list>
                `}
            </kale-card>
        </div>
    `
  }
}
window.customElements.define('change-history', ChangeHistory);
export { ChangeHistory }