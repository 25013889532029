import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

export const service_adjustment_fields = gql`
  fragment ServiceAdjustmentFields on service_adjustment {
    id
    person_id
    adjustment_date
    adjustment_type_code
    adjustment_type {
      code
      name
    }
    service_hours
    service_years
    credited_service_years
    notes
   }`

export const service_adjustment_info_query = gql`
  query service_adjustment_info($ServiceAdjustmentId: uuid!){
    service_adjustment( where: {id: {_eq: $ServiceAdjustmentId}} ){
         ...ServiceAdjustmentFields
    }
  }
  ${service_adjustment_fields}
`

export class ServiceAdjustmentInfo extends StaticQuery {
  get required_impl() {
    return ['ServiceAdjustmentId'];
  }
  get gql_impl() {
    return service_adjustment_info_query;
  }
  data_impl({ data: { service_adjustment: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const service_adjustments_for_person_query = gql`
  query service_adjustment_info($PersonId: uuid!) {
    service_adjustment (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...ServiceAdjustmentFields
    }
  }
  ${service_adjustment_fields}
  `

export class PersonServiceAdjustments extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return service_adjustments_for_person_query;
  }
  data_impl({ data: { service_adjustment: service_adjustments } }) {
    return service_adjustments;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_service_adjustment_query = gql`
  mutation update_service_adjustment($AdjustmentId: uuid!, $changeMap: service_adjustment_set_input!) {
    update_service_adjustment (
      where: {
          id: {_eq: $AdjustmentId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...ServiceAdjustmentFields
      }
    }
  }
  ${service_adjustment_fields}
`
export const insert_service_adjustment_query = gql`
  mutation insert_service_adjustment($changeMap: service_adjustment_insert_input!) {
    insert_service_adjustment (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...ServiceAdjustmentFields
      }
    }
  }
  ${service_adjustment_fields}
`

export const upsert_service_adjustment_query = gql`
  mutation upsert_service_adjustment($changeMap: service_adjustment_insert_input!, $changeCols: [service_adjustment_update_column!]!) {
    service_adjustment:insert_service_adjustment (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: service_adjustment_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...ServiceAdjustmentFields
      }
    }
  }
  ${service_adjustment_fields}
`

export const delete_service_adjustment_query = gql`
  mutation delete_service_adjustment($deleteId: uuid!) {
    delete_service_adjustment (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditServiceAdjustmentInfo extends SaveableQuery {
  get friendly_name() { return "Adjustment" }
  get required_keys_impl() {
    return ["id", "person_id", "adjustment_type_code"];
  }
  data_impl({ data: { service_adjustment: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_service_adjustment_query;
  }
  get delete_gql_impl() {
    return delete_service_adjustment_query;
  }
  get save_queries_impl() {
    return [(new PersonServiceAdjustments(null, null))];
  }
}

