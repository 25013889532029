import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';


export const withdrawal_repayment_fields = gql`
  fragment WithdrawalRepaymentFields on withdrawal_repayment {
    id
    person_id
    repayment_date
    total
    interest
   }`

export const withdrawal_repayment_info_query = gql`
  query withdrawal_repayment_info($WithdrawalRepaymentId: uuid!){
    withdrawal_repayment( where: {id: {_eq: $WithdrawalRepaymentId}} ){
         ...WithdrawalRepaymentFields
    }
  }
  ${withdrawal_repayment_fields}
`

export class WithdrawalRepaymentInfo extends StaticQuery {
  get required_impl() {
    return ['WithdrawalRepaymentId'];
  }
  get gql_impl() {
    return withdrawal_repayment_info_query;
  }
  data_impl({ data: { withdrawal_repayment: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const withdrawal_repayments_for_person_query = gql`
  query withdrawal_repayment_info($PersonId: uuid!) {
    withdrawal_repayment (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...WithdrawalRepaymentFields
    }
  }
  ${withdrawal_repayment_fields}
  `

export class PersonWithdrawalRepayments extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return withdrawal_repayments_for_person_query;
  }
  data_impl({ data: { withdrawal_repayment: withdrawal_repayments } }) {
    return withdrawal_repayments;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_withdrawal_repayment_query = gql`
  mutation update_withdrawal_repayment($WithdrawalRepaymentId: uuid!, $changeMap: withdrawal_repayment_set_input!) {
    update_withdrawal_repayment (
      where: {
          id: {_eq: $WithdrawalRepaymentId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...WithdrawalRepaymentFields
      }
    }
  }
  ${withdrawal_repayment_fields}
`
export const insert_withdrawal_repayment_query = gql`
  mutation insert_withdrawal_repayment($changeMap: withdrawal_repayment_insert_input!) {
    insert_withdrawal_repayment (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...WithdrawalRepaymentFields
      }
    }
  }
  ${withdrawal_repayment_fields}
`

export const upsert_withdrawal_repayment_query = gql`
  mutation upsert_withdrawal_repayment($changeMap: withdrawal_repayment_insert_input!, $changeCols: [withdrawal_repayment_update_column!]!) {
    withdrawal_repayment:insert_withdrawal_repayment (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: withdrawal_repayment_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...WithdrawalRepaymentFields
      }
    }
  }
  ${withdrawal_repayment_fields}
`

export const delete_withdrawal_repayment_query = gql`
  mutation delete_withdrawal_repayment($deleteId: uuid!) {
    delete_withdrawal_repayment (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditWithdrawalRepaymentInfo extends SaveableQuery {
  get friendly_name() { return "WithdrawalRepayment" }
  get required_keys_impl() {
    return ["id", "person_id", "repayment_date", "total", "interest"];
  }
  data_impl({ data: { withdrawal_repayment: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_withdrawal_repayment_query;
  }
  get delete_gql_impl() {
    return delete_withdrawal_repayment_query;
  }
  get save_queries_impl() {
    return [(new PersonWithdrawalRepayments(null, null))];
  }
}

