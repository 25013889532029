import { LitElement, html, css } from 'lit';
import '@material/mwc-icon';
import '@material/mwc-button';
import '../shared-components/card.js';
import { KaleForm } from '../shared-components/form.js';
import { timeline_colors } from '../components/colors.js';
import { EventDate } from '../benefits-app/eventdate.js';
import { EditMigrationInfo } from '../queries/queries.js';

const exclude_re = /.*_fk/;
const pk_re = /.*_pk/;

const needs_date = { 'has_tag': true, 'does_not_have_tag': true };
const priority = [
  'benefit',
  'pension_monthly_Benefit',
  'pension_group',
  'pension_retiree',
  'pension',
  'pension_service',
  'pension_contribution',
  'person_employment',
  'first_name',
  'middle_name',
  'last_name',
  'title',
  'suffix',
  'group',
  'status',
  'transferred_date',
  'service_year',
  'service_hours',
  'benefit_year',
  'benefit_month',
  'reduced_service_hours',
  'fullparttimestatus',
  'pension_year',
  'pension_month',
  'salary',
  'amount',
  'interest',
  'balance',
  'sex',
  'birth_date',
  'deceased_date',
  'ssn',
  'maritalstatus',
  'phone_number',
  'address1',
  'address2',
  'address3',
  'city',
  'state',
  'zip_code',
  'zip_plus',
  'hired_date',
  'member_date',
  'start_date',
  'end_date',
  'exit_date',
  'employmentstatus',
  'memberstatus',
  'benefitname',
  'benefitstatus',
  'annuitantcode',
  'monthly_amount',
  'taxable_amount',
  'non_taxable_amount',
  'fit_withheld',
  'sit_withheld',
  'qdro',
  'health',
  'people',
  'credit_union',
  'mal',
  'net_pension_amount',
  'retired_date',
  'annuityoption',
  'annuityterminationcode',
  'annual_salary_at_retirement',
  'final_average_salary',
  'monthly_annyity_amount',
  'monthly_annyity_amount_taxable',
  'monthly_annyity_amount_nontaxable',
  'comment',
  'last_modified_date',
  'last_modified_by',
  'created_date',
  'created_by'
]
let scores = {}
priority.forEach((p, i) => scores[p] = i + 1);

const score_func = key => {
  if (!isNaN(key)) {
    return key
  }
  if (key in scores) {
    return scores[key];
  }
  if (pk_re.test(key)) {
    return 101;
  }
  return 20;
}

const color_fade = (level) => {
  let pct = 0.15 * level;
  return `rgb(${50 + pct * 105}, ${70 + pct * (155 - 70)}, ${255 - pct * 100})`
}

const json_node_style = css`
        :host {
          ${timeline_colors}
        }
        .handle {
          cursor: pointer;
          background-color: white;
          color: var(--paper-grey-900);
          margin-top: 1em;
          padding: 0.5em;
          border-radius: 5px;
        }
        .node {
        }
        .node_name {
          font-weight: bold;
        }
        .pk {
          font-weight: 100;
          margin-left: 2em;
          font-size: 0.8em;
        }
        .node_control {
          color: white;
          background-color: var(--paper-grey-700);
          padding: 0 0.4em 0 0.4em;
          border-radius: 5px;
          font-weight: bold;
          margin-right: 0.5em;
        }
        .node_contents {
          padding-left: 1.2em;
          border-left: 1px solid white;
          position: relative;
        }
        .leaf {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: #ddd;
          padding: 5px;
          border-radius: 5px;
          margin-top: 0.5em;

        }
        .leaf_label {
          color: var(--paper-green-900);
          margin-right: 2em;
          font-weight: 120;
          font-size: 0.8em;
          font-stretch: condensed;
          display: inline-block;
        }
        .leaf_item {
          display: inline-block;
        }
        .code_desc {
          font-size: 0.6em;
          opacity: 0.7;
          margin-left: 1em;
          position: relative;
          top: -0.3em;
        }
`;

class JSONViewer extends LitElement {
  static styles = json_node_style
  constructor() {
    super();
    this.expanded = false;
  }
  //<div class="handle" @click=${e => this.expanded = !this.expanded}>${this.expanded ? '-' : '+'}${this.name}</div>
  //     ${this.expanded ?  : html``}`
  render_item(name, item_in) {
    let pk_name = name === 'Imported Data' ? 'person_pk' : name + '_pk';
    let item = item_in;
    let item_type = typeof (item_in)
    if (item_in === null || item_in === '') {
      item_type = "null"
      item = 'NONE'
    }
    else if (item_in && typeof (item_in) === 'object' && 'code' in item_in && 'desc' in item_in) {
      item = html`<span class="code" title=${item.desc}>${item.code}<span class="code_desc">(${item.desc})</span></span>`;
      item_type = "string"
    }
    else if (item_in && typeof (item_in) === 'object' && 0 in item_in && item_in.length == 1) {
      item = item_in[0];
    }
    switch (item_type) {
      case 'object':
        return html`
          <div class="handle" @click=${e => this.expanded = !this.expanded}><span class="node_control">${this.expanded ? '-' : '+'}</span><span class="node_name">${this.name}${pk_name in item ? html`<span class="pk">${pk_name}=${item[pk_name]}</span></span>` : html``}</div>
          ${this.expanded && item ? html`
            <div class="node_contents">
              ${Object.keys(item).filter(k => k !== pk_name).sort((a, b) => score_func(a) - score_func(b)).map(k => html`<json-viewer .name=${k} .data=${item[k]} .level=${this.level + 1}></json-viewer>`)}
            </div>` : html``}`;
        break;
      case 'string':
      case 'number':
      default:
        if (exclude_re.test(name)) {
          return html``;
        }
        return html`<div class="content leaf"><span class="leaf_label">${name}</span><span class="leaf_item">${item}</span></div>`;
        break;
      case 'null':
        return html``;
        break;
    }
  }

  // background-color: rgba(60, 80, 255, ${1 - 0.15*this.level});
  //background-color: #45f;
  render() {
    return html`
        <div class="node">
          ${this.render_item(this.name, this.data)}
        </div>
  `}
  static get properties() {
    return {
      name: { type: String },
      data: { type: Object },
      level: { type: Number },
      expanded: { type: Boolean }
    }
  }
}
window.customElements.define('json-viewer', JSONViewer);
export { JSONViewer }


const person_migration_box_styles = css`
        :host {
          width: 100%;
        }
        :host > * { width: 100%;}

        kale-card {
          width: 100%;
          --card-border: var(--migration-color);
          --card-bg: var(--migration-color);
          color: black;
          font-size: 12px;
        }
        kale-card[complete] {
          --card-border: var(--paper-grey-500);
          --card-bg: var(--paper-grey-500);
        }
        .card-heading-color {
            background-color: var(--migration-color);
            margin: 0;
            color: white;
            position: relative;
            top: -20px;
            padding: 16px;
            padding-bottom: 0.1em;
            margin-bottom: -20px;
          }
          .collapsed-heading {
            cursor: pointer;
            text-transform: uppercase;
            padding: 1em;
            padding-bottom: 0;
            margin-bottom: 0;
            color: var(--migration-color);
          }
        .card-heading {
            cursor: pointer;
            text-transform: uppercase;
            padding: 1em;
            margin-bottom: 0;
            color: white;
            width: 100%;
        }
        .card-heading h3 {
          margin: 0;
        }
          .card-heading-h3 {
            text-transform: uppercase;
    margin: 0;
    padding: 20px;
    padding-bottom: 12px;
    padding-left: 3px;
          }
        .label {
          font-size: 70%;
          font-weight: bold;
          font-variant: small-caps;
          opacity: 0.75;
          display: inline-block;
          width: 72px;
          vertical-align: middle;
          line-height: 0.9em;
          position: relative;
          bottom: 0.15em;
        }
        kale-card > div {
          margin-bottom: 8px;
        }
        h2 {
          font-family: 'Roboto Slab', sans-serif;
        }
        a {
          color: white;
          font-weight: 500;
          text-decoration: none;
          }
        a::after {
          content: '🔗';
          font-size: 50%;
          vertical-align: super;
        }
        div.card-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row; 
          margin-bottom: 0;
        }

        div.card-content {
          padding: 18px;
        }
        div.card-content > div{
          margin-bottom: 0;
        }

        div.ico {
          font-size: 70%;
          opacity: 0.75;
          display: inline-block;
          width: 0;
          vertical-align: middle;
          line-height: 0.9em;
          position: relative;
          left: 15px;
          top: 0.9em;
          text-decoration-line: none;
          }

        .migration_message {
          color: white;
          font-size: 1em;
          padding: 6px;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          position: relative;
        }
        .migration_message[fixed] {
          opacity: 0.6;

        }
        .migration_message:hover {
          background-color: var(--paper-deep-orange-800);
          border-radius: 5px;
          opacity: 1;
        }

        .message_label {
          background-color: var(--paper-blue-50);
          color: black;
          border-radius: 5px;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 0.7em;
          opacity: 0.8;
          display: block;
          padding: 6px 12px 6px 12px;
          margin-right: 12px;
          white-space: nowrap;
          text-align: center;
          font-stretch: extra-condensed;
        }
        .message { display: block; flex: 1 1; padding-right: 1em;}
        /*
        .message_msg { display: inline }
        .message > .message_msg + .message_msg { font-weight: 100; display: inline }*/
        .message span { display: block; }
        .message > span + span { font-weight: 100; display: inline }
        .message_date { font-weight: 100; font-size: 0.8em; display: block; whitespace: nowrap}
        /*
        ul.warning {
          margin-top:16px;
          padding:0;
          list-style-type: none
        }
        li.warning {
          color: white;
          background-color: var(--paper-deep-orange-a400);
          border-radius: 20px;
          display: inline-block;
          padding: 8px 12px 8px 12px;
          margin-right: 8px;
          margin-bottom: 8px;
        }
        */
        h3 {
          display: flex;
          flex-direction: row;
        }
        .title {
          display: block;
          flex: 1;
          position: relative;
          top: 6px;


        }
        .reveal {
          display: block;
          flex: 1;
          text-align: right;

        }
        .toggle {
          opacity: 0.9;
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% - 16px);
          height: 100%;
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          background-color: blue;
          color: white;
          align-items: center;
          z-index: 1;
          padding-left: 16px;
        }
        .toggle[fixed] {
          background-color: purple;

        }
        .check_label {
          text-align: center;
        }

        .toggle_icon, .check_label {
          display: block;
          /*
          font-size: 1.5em;
          padding: 6px 0px 6px 6px;
          */
          margin-right: 12px;
        }
`;

class PersonMigrationBox extends KaleForm {
  static styles = person_migration_box_styles
  get form_name() { return "Migration" }
  static get properties() {
    return {
      migration: { type: Object },
      timeline: { type: Object },
      hovered: { type: String },
      open: { type: Boolean },
      complete: { type: Boolean },
      ...(super.properties)
    }
  }
  constructor() {
    super();
    this.messages = [];
    this.open = false;
    this.mutation = new EditMigrationInfo(
      p => this.update_fixed(p),  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
      },
      (e, msgs) => { // error handler
        this.error = { data: this.migration, error: e, msgs: msgs };
      }, this);
  }
  updated(props) {
    if (props.has('timeline') || props.has('migration')) {
      this.update_messages();
    }
    /*
    if (props.has('hovered')) {
      //console.log("hovered", this.hovered);
      //this.dispatchEvent(new CustomEvent('warning-highlight', { bubbles: true, composed: true, detail: { uuid: this.hovered } }));
    }
    */
  }
  highlight(uuid) {
    if (uuid === this.ignore) return;
    this.dispatchEvent(new CustomEvent('event-highlight', { bubbles: true, composed: true, detail: { event_ids: new Set(this.message_map[uuid].evt ? [this.message_map[uuid].evt.id] : []) } }));
    this.ignore = null;
    this.hovered = uuid;
  }
  unhighlight(uuid) {
    this.dispatchEvent(new CustomEvent('event-unhighlight', { bubbles: true, composed: true, detail: { event_ids: new Set(this.message_map[uuid].evt ? [this.message_map[uuid].evt.id] : []) } }));
    this.ignore = null;
    this.hovered = null;
  }

  toggle_fixed(message) {
    let fixed = this.migration && this.migration.fixed ? this.migration.fixed : {};
    if (fixed[message.uuid]) {
      delete fixed[message.uuid];
      message.fixed = false;
    } else {
      message.fixed = true;
      fixed[message.uuid] = message;
    }
    this.migration.fixed = { ...fixed };
    this.ignore = message.uuid;
    this.mutation.save({ id: this.migration.id, person_id: this.migration.person_id, fixed: { ...fixed } }, this.migration);
    this.unhighlight(message.uuid);
  }

  update_messages() {
    let warnings = this.migration && this.migration.warnings ? this.migration.warnings : [];
    let expectation_map = this.migration && this.migration.expectations ? this.migration.expectations : [];
    let expectations = [];
    Object.keys(expectation_map).forEach(k => {
      let l = expectation_map[k];
      l.forEach(e => {
        e.expectation_type = k;
        expectations.push(e);
      })
    });
    let fixed = this.migration && this.migration.fixed ? this.migration.fixed : {};
    let violated = this.timeline && this.timeline.nodes ? this.timeline.nodes.filter(n => n.label_class == 'migration') : [];
    let all = {}

    warnings.forEach(w => {
      let f = fixed[w.uuid];
      all[w.uuid] = f ? f : { fixed: false, kind: 'warning', date: w.date, label: w.label, msgs: [w.message], uuid: w.uuid };
    });
    expectations.forEach(w => {
      let f = fixed[w.uuid];
      all[w.uuid] = f ? f : { fixed: false, kind: 'expectation', date: w.date, label: 'mismatch', uuid: w.uuid };
      let x = all[w.uuid];
      if (needs_date[w.expectation_type] && !x.date) {
        x.date = this.migration.import_date;
        w.date = this.migration.import_date;
      }
    });
    Object.entries(fixed).forEach(([k, w]) => {
      if (all[w.uuid]) return;
      all[w.uuid] = w; //{ fixed: true, kind: 'expectation', date: w.date, label: 'mismatch', uuid: w.uuid };
    });
    violated.forEach(v => {
      if (!v.uuid) {
        return;
      }
      if (!all[v.uuid]) {
        all[v.uuid] = {
          kind: "expectation",
          date: v.date,
          label: "mismatch",
          uuid: v.uuid,
          fixed: false
        }
      }
      all[v.uuid].evt = v.event;
      all[v.uuid].msgs = v.labels;
      all[v.uuid].first = v.show_label;
    });

    this.message_map = all;
    this.messages = Object.keys(all).map(k => all[k]).filter(m => m.kind == 'warning' || m.evt).sort((a, b) => {
      if (a.date && b.date) return b.date - a.date;
      if (a.date) return 1;
      if (b.date) return -1;
      return 0;

    });
    this.complete = this.messages.every(m => m.fixed);
    this.requestUpdate('messages');
  }
  update_fixed(migration) {
    let complete = true;
    let fixed = migration.fixed ? migration.fixed : {};
    this.messages.forEach(m => {
      m.fixed = m.uuid in fixed;
      complete = complete && m.fixed;
    });
    this.complete = complete;
    this.requestUpdate('messages');
    this.requestUpdate('complete');
  }

  render() {

    return html`
      ${this.migration ? html`
        <kale-card ?complete=${this.complete} elevation=1 >
          <div class="card-heading" @click=${e => this.open = !this.open}>
          <mwc-ripple></mwc-ripple>
            <h3>
              <span class='title'>migration ${this.complete ? 'complete' : `warnings (${this.messages.filter(m => !m.fixed).length}/${this.messages.length})`}</span>
              <span class='reveal'>
                <mwc-icon>${this.open ? 'arrow_drop_up' : 'arrow_drop_down'}</mwc-icon>
              </span >
            </h3 >
          </div >
      ${
        this.open ? html`
        
          <div class="card-content">
            <div class="warnings">${this.messages.map(m => html`
              <div class="migration_message"
                  ?fixed=${m.fixed}
                  @mouseenter=${e => this.highlight(m.uuid)}
                  @mouseleave=${e => this.unhighlight(m.uuid)}>

                  ${this.hovered && m.uuid === this.hovered ? html`
                  <div class="toggle" ?fixed=${m.fixed} @click=${e => this.toggle_fixed(m)}>
                    <div class="toggle_icon"><mwc-icon>${m.fixed ? 'block' : 'checked'}</mwc-icon></div>
                    <div class="toggle_message">MARK AS ${m.fixed ? 'IN' : ''}COMPLETE</div>
                  </div> 
                  `: html``} 
                  ${m.fixed ? html`
                  <span class="check_label"><mwc-icon>checked</mwc-icon></span>
                  ` : html`
                  <span class="message_label">${m.label ? m.label : ''}</span>
                  `}
                  <span class="message">
                    ${m.msgs && m.msgs.length > 0 ? m.msgs.map(s => html`<span>${s}</span>`) : ''}
                  </span>
                  <span class="message_date">${m.date ? new EventDate(String(m.date).split(' ')[0]) : ''}</span>
              </div>
            `)}</div> 

            <div class="original">
              <json-viewer .name=${"Imported Data"} .level=${0} .data=${this.migration.original_data}></json-viewer>
            </div>

          </div>
          ` :
          html``
        }
        </kale-card >

      ` : html``}
        
`;
    /*    
                  ${m.others && m.others.length > 0 ? html`
                    <div class="more_messages">+ ${m.others.length} more</div>
                  ` : html``}
            ${this.hovered && m.uuid === this.hovered ? html`
              `: html``} 
    ${this.hovered && m.uuid === this.hovered ? html`
                    <span class="fixed_box">
                   <mwc-switch ?checked=${m.fixed} @checked-changed=${(e) => this.toggle_fixed(m.uuid)}>FIXED</mwc-switch> 
                    </span>
                  ` : html`
                  `}
              <ul class="warning" style="">
              ${ this.timeline ? this.timeline.status_info.warnings.map(e => html`
              <li class="warning">${e.msg} (${e.count})</li>`) : html``}</div></ul>
  
                <mwc-chip label=${w.message ? w.message : w.description}></mwc-chip>
      <div class="card-actions" id="actions">
            ${this.edit ? html`
              <mwc-button @click=${e => { this.reset(); this.edit = false; }}>${this.dirty ? "cancel" : "close"}</mwc-button>
              <mwc-button ?disabled=${!this.dirty || !this.valid} @click="${(e) => this.save()}" > ${this.dirty && !this.valid ? "incomplete" : this.dirty || !this.personid ? (this.saving ? "saving..." : "save") : "saved"}</mwc-button>
            ` : html`
              <mwc-button  icon="create" @click=${e => this.edit = true}>edit</mwc-button>
          </div>
                            */
  }
  save_impl(data) {
  }
}

window.customElements.define('person-migration', PersonMigrationBox);
export { PersonMigrationBox }



