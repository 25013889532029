import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * PROJECTION DATA
 */


export const projection_fields = gql`
  fragment ProjectionFields on projection {
    id
    name
    person_id
    target_date
    assumptions
    changes
   }`

export const projection_info_query = gql`
  query projection_info($ProjectionId: uuid!){
    projection( where: {id: {_eq: $ProjectionId}} ){
         ...ProjectionFields
    }
  }
  ${projection_fields}
`

export class ProjectionInfo extends StaticQuery {
  get required_impl() {
    return ['ProjectionId'];
  }
  get gql_impl() {
    return projection_info_query;
  }
  data_impl({ data: { projection: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const projections_for_person_query = gql`
  query projection_info($PersonId: uuid!) {
    projection (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...ProjectionFields
    }
  }
  ${projection_fields}
  `

export class PersonProjections extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return projections_for_person_query;
  }
  data_impl({ data: { projection: projections } }) {
    return projections;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_projection_query = gql`
  mutation update_projection($projectionId: uuid!, $changeMap: projection_set_input!) {
    update_projection (
      where: {
          id: {_eq: $projectionId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...ProjectionFields
      }
    }
  }
  ${projection_fields}
`
export const insert_projection_query = gql`
  mutation insert_projection($changeMap: projection_insert_input!) {
    insert_projection (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...ProjectionFields
      }
    }
  }
  ${projection_fields}
`

export const upsert_projection_query = gql`
  mutation upsert_projection($changeMap: projection_insert_input!, $changeCols: [projection_update_column!]!) {
    projection:insert_projection (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: projection_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...ProjectionFields
      }
    }
  }
  ${projection_fields}
`

export const delete_projection_query = gql`
  mutation delete_projection($deleteId: uuid!) {
    delete_projection (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditProjectionInfo extends SaveableQuery {
  get friendly_name() { return "Projection" }
  get required_keys_impl() {
    return ["id", "name", "person_id", "target_date", "assumptions", "changes"];
  }
  data_impl({ data: { projection: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_projection_query;
  }
  get delete_gql_impl() {
    return delete_projection_query;
  }
  get save_queries_impl() {
    return [(new PersonProjections(null, null))];
  }
}

