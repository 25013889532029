//synced with prod
import { LitElement, html } from "lit";
import gql from "graphql-tag";
import { RetryLink } from "apollo-link-retry";

export const parse_date = (d) => {
  if (d === undefined) { return undefined }
  if (d === null) { return null }
  let p;
  switch (d.constructor.name) {
    case "Date": {
      p = d;
      break;
    }
    case "String": {
      p = new Date(d.length > 10 ? d : `${d}T00:00:00-05:00`);
      break;
    }
    case "Number": {
      p = new Date(d);
      break;
    }
    default:
      p = d;
  }
  return p;
}


export const getSort = (col, rev) => {
	let path = col.split(".");
	return path.length === 1
		? `{${col}: ${rev ? "desc_nulls_last" : "asc_nulls_first"}}`
		: `{${path[0]}: ${getSort(path.slice(1).join("."), rev)}}`;
};
//{report:{group_code: asc}}, {last_name: asc}
export const makeOrderBy = (lookup, sort) => {
	if (!sort || sort.length === 0) {
		return "";
	}
	return `order_by: [${sort
		.filter((s) => lookup[s.col] !== null)
		.map((s) => getSort(lookup[s.col] ? lookup[s.col] : s.col, s.reverse))
		.join(", ")}]`;
};
export const filterValue = (val) => {
	if (typeof val === "string") {
		return `"${val}"`;
	}
	if (val.getYear) {
		return `"${val.toLocaleDateString()}"`;
	}
	return val;
};

export const getFilt = (col, op, val) => {
	let path = col.split(".");
	return path.length === 1
		? `{${col}: {${op}: ${val}}}`
		: `{${path[0]}: ${getFilt(path.slice(1).join("."), op, val)}}`;
};

export const makeFilter = (filter, lookup) => {
	filter = filter.map((f) => ({
		...f,
		col: lookup?.[f.col] ? lookup[f.col] : f.col,
	}));
	//{ benefit_start_match: { _eq: false } }
	//filter: [{ col: 'benefit_start_match', op: '_eq', val: false }],
	//let ret = `{_and: [${filter.map(f => `{${f.col}: {${f.op}: ${filterValue(f.val)}}}`).join(', ')}]}`;
	let ret = `{_and: [${filter
		.map((f) => getFilt(f.col, f.op, filterValue(f.val)))
		.join(", ")}]}`;
	return ret;
};
export const makeCond = (sort, limit, offset, lookup) => {
	let ret = `
      ${makeOrderBy(lookup, sort)}
      ${limit > 0 ? `limit: ${limit}` : ""}
      ${offset > 0 ? `offset: ${offset}` : ""}
  `;
	return ret;
};

export const declared_reports = [
	{
		name: "Group Code Discrepancies",
		desc: "Migrated group code mismatches (Excluding mismatches where BOTH old and new group codes are one of RET00A, DE000A, DE000B, X0000B, VOUT0A)",
		icon: "compare",
		args: {},
		limit: 500,
		sort: [{ col: "Old System" }, { col: "name", reverse: false }],
		filter: [],
		query: (filter, sort, limit, offset) => {
			const lookup = {
				name: "person.last_name",
				"Old System": "old_group",
				"Migrated (Dec '19)": "new_group",
				Current: "current_group",
			};

			return gql`
      query error_detail{
        report: view_migrated_group_mismatches (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            ${makeFilter(filter, lookup)}
          ]}
        ) {
          person {
                id
                first_name
                last_name
                middle_name
              }
            date
            level
            code
            message
            message_count
            level_count
            total
            old_group
            new_group
            current_group
        }
      }`;
		},
		columns: [
			{ c: "name", t: "person_link" },
			{ c: "Old System", t: "ltext" },
			{ c: "Migrated (Dec '19)", t: "ltext" },
			{ c: "Current", t: "ltext" },
		],
		download_columns: [
			{ c: "person_id", t: "person_link" },
			{ c: "last_name", t: "ltext" },
			{ c: "first_name", t: "ltext" },
			{ c: "middle_name", t: "ltext" },
			{ c: "Old System", t: "ltext" },
			{ c: "Migrated (Dec '19)", t: "ltext" },
			{ c: "Current", t: "ltext" },
		],
		row_func: (d) => ({
			id: d.person.id,
			person_id: d.person.id,
			name: ["first_name", "middle_name", "last_name"]
				.map((n) => d.person[n])
				.filter((n) => n)
				.join(" "),
			last_name: d.person.last_name,
			first_name: d.person.first_name,
			middle_name: d.person.middle_name,
			"Old System": d.old_group,
			"Migrated (Dec '19)": d.new_group,
			Current: d.current_group,
		}),
	},

	{
		name: "Group Code",
		desc: "Computed group codes on current date.",
		//subname: args => `(${args.year})`,
		icon: "barcode",
		//args: { year: (d => { return (d.getMonth() === 11 && d.getDate() > 29) ? d.getFullYear() + 1 : d.getFullYear() })(new Date()) },
		args: {},
		limit: 2000,
		sort: [
			{ col: "name", reverse: false },
			{ col: "id", reverse: false },
		],
		filter: [],
		query: (filter, sort, limit, offset, args) => {
			const lookup = {
				name: "last_name",
				hired: "current_state.hired",
				group: "current_state.group",
			};
			console.log("FILTER", filter, makeFilter(filter, lookup));
			//{_not: {deceased_date: {}}},
			return gql`
      query untitled {
        report: person (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          id
          first_name
          last_name
          middle_name
        	ssn
          last_4 { ssn }
          
          current_state{
            in_service
            employer
            group
            basis
            vested
            hired
            status
          }
        }
      }
      `;
		},
		columns: [
			{ c: "name", t: "person_link" },
			{ c: "ssn", t: "ssn9" },
			{ c: "group", t: "ltext" },
			{ c: "hired", t: "date" },
		],
		download_columns: [
			//{ c: 'id', t: 'ctext' },
			{ c: "person_id", t: "person_link" },
			{ c: "last_name", t: "ltext" },
			{ c: "first_name", t: "ltext" },
			{ c: "middle_name", t: "ltext" },
			{ c: "ssn", t: "ssn9" },
			{ c: "group", t: "ltext" },
			{ c: "hired", t: "date" },
		],
		row_func: (d, args) => ({
			id: d.id,
			person_id: d.id,
			name: ["first_name", "middle_name", "last_name"]
				.map((n) => d[n])
				.filter((n) => n)
				.join(" "),
			//name: d.person,
			last_name: d.last_name,
			first_name: d.first_name,
			middle_name: d.middle_name,
			ssn: d.ssn,
			line3: d.preferred_address?.address
				? d.preferred_address.address.line3
				: null,
			status: d.current_state?.status ? d.current_state.status : null,
			hired: d.current_state?.hired ? d.current_state.hired : null,
			basis: d.current_state?.basis ? d.current_state.basis : null,
			group: d.current_state?.group ? d.current_state.group : null,
		}),
	},

	{
		name: "Terminated/Vested",
		desc: "Dates and data for Terminated or vested participants.",
		//subname: args => `(${args.year})`,
		icon: "star",
		args: {},
		//args: { year: (d => { return (d.getMonth() === 11 && d.getDate() > 29) ? d.getFullYear() + 1 : d.getFullYear() })(new Date()) },
		limit: 40,
		sort: [
			{ col: "name", reverse: false },
			{ col: "id", reverse: false },
		],
		filter: [],
		query: (filter, sort, limit, offset, args) => {
			const lookup = {
				name: "last_name",
				hired: "current_state.hired",
				vested: "current_state.vested",
				employer: "current_state.employer",
				"computed group": "current_state.group",
				termed: "employments.end_date",
				line1: "preferred_address.address.line1",
				line2: "preferred_address.address.line2",
				line3: "preferred_address.address.line3",
				city: "preferred_address.address.city",
				state: "preferred_address.address.address_state_code",
				zip: "preferred_address.address.zip",
				active: "current_state.in_service",
				vested: "current_state.vested",
			};

			return gql`
      query untitled {
        report: person (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {current_state: {basis: {_is_null: false}}},
            {current_state: {in_service: {_eq: false}}},					
            {current_state: {vested: {_is_null: false}}},							
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          id
          first_name
          last_name
          middle_name
        	ssn
          birth_date
          deceased_date
          last_4 { ssn }
          preferred_address{
            address {
              line1
              line2
              line3
              city
              address_state_code
              zip
            }
          }

          emails {
            email
            email_status_code
          }
          
          current_state{
            status
            in_service
            employer
            group

            contribution_balance
            contribution_interest
            ytd_contribution_interest

            hired
            basis
            participating
            contributing
            opted_in
            opted_out
            vested
          }

          benefits (order_by: {start_date: desc}) {
            start_date
            end_date
          }

          employments (order_by: {start_date: desc}) {       ##add repayment  
            employer {
              code
              name
              joined
            }
            start_date
            end_date
            employment_type_code
            employment_type {
              code
              name  
              eligible_prior_to_sep1_1995  
              eligible_after_sep1_1995
              full_time
            }
          }
          beneficiaries {
            beneficiary_relationship_code
          }
          withdrawal_repayments {
            repayment_date
            total
            interest
          }
          withdrawals (order_by: {withdrawal_date: asc}) {
            withdrawal_date
            total
            interest
            taxable
            ytd_contributions
          }
          notes {
            text
          }
          suspensions (order_by: {start_date: asc}) {
            suspension_type_code
            suspension_type {
              code
              name
              paid
              unlimited
              limit_service_break
            }
            start_date
            end_date
          }
          contributions(order_by: {contribution_date: desc}) {
            contribution_date
            eoy_base_salary
            ytd_base_pay
            ee_pension_contribution
            pension_salary
            made_up
            employer_code
          }
        }
        employer_codes {
          code
          name
        }
      }
      `;
		},
		columns: [     //columns that are displayed. add the ones on their sheet
			{ c: "ssn", t: "ssn9" },            
			{ c: "name", t: "person_link" },   //person_link is shortcut to their person page
			{ c: "affiliate", t: "ltext" }, 

			{ c: "dob", t: "date" },
			{ c: "deceased", t: "date" },
			{ c: "hired", t: "date" },
			{ c: "retired", t: "date" },
			{ c: "first day credited", t: "date" },
			{ c: "breaks in service", t: "ltext" },
			{ c: "termed", t: "date" },
			{ c: "3 year avg", t: "ltext" }, //TODO:
			{ c: "ctrb to date", t: "money" },
			{ c: "interest to date", t: "money" },
			{ c: "late applicant indicator", t: "bool" },
			{ c: "make-up ctrb indicator", t: "bool" },
			{ c: "qdro indicator", t: "bool" },
			{ c: "beneficiary indicator", t: "bool" },

      // Address
			{ c: "line1", t: "ltext" },
			{ c: "line2", t: "ltext" },
			{ c: "line3", t: "ltext" },
			{ c: "city", t: "ltext" },
			{ c: "state", t: "ltext" },
			{ c: "zip", t: "ltext" },

      // Comments
			{ c: "comments", t: "ltext" },

      // Withdrawals
			{ c: "withdrawal date", t: "date" },
			{ c: "interest withdrawal", t: "money" },
			{ c: "taxable portion", t: "money" },

      // Salary history:
      ...([1, 2, 3].flatMap(i => [
        { c: `sal${i}`, t: "money" },
        { c: `sal${i}_base`, t: "money" },
        { c: `sal${i}_months`, t: "number" },
        { c: `sal${i}_year`, t: "number" },
      ])),

      // Contrib history:
      ...((() => {
        let now = new Date().getFullYear();
        let years = []
        for (let i=1971; i<now; i++) {
           years.push({
              c: `contrib_${i}`,
              t: "money"
           });
        }
        return years;
      })()),
		],
		download_columns: [  //fields that get downloaded in excel
			{ c: "person_id", t: "person_link" },
			{ c: "ssn", t: "ssn9" },            
			{ c: "last_name", t: "ltext" },
			{ c: "first_name", t: "ltext" },
			{ c: "middle_name", t: "ltext" },
			{ c: "affiliate", t: "ltext" }, 

			{ c: "dob", t: "date" },
			{ c: "deceased", t: "date" },
			{ c: "hired", t: "date" },
			{ c: "retired", t: "date" },
			{ c: "first day credited", t: "date" },
			{ c: "breaks in service", t: "ltext" },
			{ c: "termed", t: "date" },
			{ c: "3 year avg", t: "ltext" }, //TODO:
			{ c: "ctrb to date", t: "money" },
			{ c: "interest to date", t: "money" },
			{ c: "late applicant indicator", t: "bool" },
			{ c: "make-up ctrb indicator", t: "bool" },
			{ c: "qdro indicator", t: "bool" },
			{ c: "beneficiary indicator", t: "bool" },

      // Address
			{ c: "line1", t: "ltext" },
			{ c: "line2", t: "ltext" },
			{ c: "line3", t: "ltext" },
			{ c: "city", t: "ltext" },
			{ c: "state", t: "ltext" },
			{ c: "zip", t: "ltext" },

      // Comments
			{ c: "comments", t: "ltext" },

      // Withdrawals
			{ c: "withdrawal date", t: "date" },
			{ c: "interest withdrawal", t: "money" },
			{ c: "taxable portion", t: "money" },

      // Salary history:
      ...([1, 2, 3].flatMap(i => [
        { c: `sal${i}`, t: "money" },
        { c: `sal${i}_base`, t: "money" },
        { c: `sal${i}_months`, t: "number" },
        { c: `sal${i}_year`, t: "number" },
      ])),

      // Contrib history:
      ...((() => {
        let now = new Date().getFullYear();
        let years = []
        for (let i=1971; i<now; i++) {
           years.push({
              c: `contrib_${i}`,
              t: "money"
           });
        }
        return years;
      })()),
		],
		row_func: (d, args, raw) => {

      const salary_contributions = d.contributions.filter(c => c.pension_salary);
      const service_spans = [
        ...(d.employments.map(e => ({ t: "start", d: parse_date(e.start_date)*1+1 }))),
        ...(d.employments.map(e => ({ t: "end", d: parse_date(e.end_date)*1 }))),
        ...(d.suspensions.map(e => ({ t: "end", d: parse_date(e.end_date)*1 }))),
        ...(d.suspensions.map(e => ({ t: "start", d: parse_date(e.start_date)*1+1 }))),
      ].sort((e1, e2) => e1.d - e2.d)
       .reduce((acc, e) => {
        acc.active[e.t] = parse_date(e.d);
        if (acc.active.start && acc.active.end) {
          acc.spans.push(acc.active);
          acc.active = {};
        }
        return acc;
       }, {spans: [], active: {}})
        .spans;

      return ({
        id: d.id,
        person_id: d.id,
        ssn: d.ssn,
        name: ["first_name", "middle_name", "last_name"]
          .map((n) => d[n])
          .filter((n) => n)
          .join(" "),
        //name: d.person,
        last_name: d.last_name,
        first_name: d.first_name,
        middle_name: d.middle_name,
        affiliate: d.employments?.[0]?.employer?.name,
        dob: parse_date(d.birth_date),
        deceased: parse_date(d.deceased_date),
        hired: d.current_state?.hired ? d.current_state.hired : null,
        retired: parse_date(d.benefits?.[0]?.start_date) ?? null,
        /*
        status: d.current_state?.status ? d.current_state.status : null,
        basis: d.current_state?.basis ? d.current_state.basis : null,
        "computed group": d.current_state?.group ? d.current_state.group : null,
        active: d.current_state?.in_service ? d.current_state.in_service : false,
        vested: d.current_state?.vested ? d.current_state.vested : false,
        */

        "first day credited": parse_date(d.current_state?.participating), // FIXME: confirm that particip is correct
        "breaks in service": service_spans.reduce((acc, s) => {
          if (acc.active.start) {
            const end = s.start;
            const millis = end - acc.active.start;
            const days = Math.round(millis / (1000 * 60 * 60 * 24));
            if (days > 1) {
              acc.spans.push({start: acc.active.start, end});
            }
          }
          acc.active = {start: s.end};
          return acc;
        }, {spans: [], active: {}})
          .spans
          .map(s => `${s.start.toLocaleDateString()}-${s.end.toLocaleDateString()}`)
          .join(", "),
        termed:
          !(d.current_state?.in_service ? d.current_state.in_service : false) &&
            d.employments &&
            d.employments.length > 0
            ? d.employments[0].end_date
            : null,
        "3 year avg": null, // TODO:
        "ctrb to date": (d?.current_state?.contribution_balance ?? 0) - (d?.current_state?.contribution_interest ?? 0), // TODO:
        "interest to date": (d?.current_state?.contribution_interest ?? 0) - (d?.current_state?.ytd_contribution_interest ?? 0), // TODO:
        "late applicant indicator": (() => {
          const non_particip_emp = d?.employments?.some(e => e.employment_type_code === 'INELIG');
          const basis = parse_date(d.current_state?.basis);
          const participating = parse_date(d.current_state?.participating);
          const millis = participating - basis;
          const years = millis / (1000 * 60 * 60 * 24 * 365.25);
          return Boolean(non_particip_emp || years > 1.01  || d.current_state?.opted_out || d.current_group?.opted_in)
        })(),
        "make-up ctrb indicator": (
          d.contributions.some(c => {
            const d = parse_date(c.contribution_date);
            let expected_amt;
            if (d <= parse_date("2014-01-01")) { // "for plan years beginning before 2014-01-01"
              expected_amt = 0.03 * Math.min(c.pension_salary, 3000) // 3% of first $3,000
                            + (c.pension_salary > 3000 ? 0.04*c.pension_salary : 0) // 4% of salary if > $3,000
            } else if (d <= parse_date("2015-01-01")) {
              expected_amt = 0.05 * c.pension_salary; // 5%
            } else {
              expected_amt = 0.06 * c.pension_salary; // 6%
            }
            return c.ee_pension_contribution > (expected_amt + 200);
          }) || d.contributions.some(c => c.made_up)
             || d.withdrawal_repayments.some(r => r.total > 0)
        ),
        "qdro indicator": d.beneficiaries.some(b => b.beneficiary_relationship_code === 'QDRO'),
        "beneficiary indicator": d.beneficiaries.length > 0 && d.deceased_date === null,
        
        // Address:
        line1: d.preferred_address?.address
          ? d.preferred_address.address.line1
          : null,
        line2: d.preferred_address?.address
          ? d.preferred_address.address.line2
          : null,
        line3: d.preferred_address?.address
          ? d.preferred_address.address.line3
          : null,
        city: d.preferred_address?.address
          ? d.preferred_address.address.city
          : null,
        state: d.preferred_address?.address
          ? d.preferred_address.address.address_state_code
          : null,
        zip: d.preferred_address?.address
          ? d.preferred_address.address.zip
          : null,

        // Comments:
        comments: d.notes.map(n => n.text.replace(/(<([^>]+)>)/ig, '')).join(";\n"),
        "withdrawal date": d.withdrawals.map(d => parse_date(d.withdrawal_date).toLocaleDateString()).join(", "),
        "interest withdrawal": d.withdrawals.reduce((acc, w) => acc + w.interest, 0),
        "taxable portion": d.withdrawals.reduce((acc, w) => acc + w.taxable, 0),


        // Salary history:
        ...(Object.fromEntries([0, 1, 2].flatMap(i => [
          [`sal${i + 1}`, salary_contributions?.[i]?.pension_salary ?? 0],
          [`sal${i + 1}_base`, salary_contributions?.[i]?.eoy_base_salary ?? 0],
          [`sal${i + 1}_months`, (() => {
            const ctrb = salary_contributions?.[i];
            if (!ctrb) return 0;
            const date = parse_date(salary_contributions?.[i]?.contribution_date);
            const year_start = new Date(date.getFullYear(), 0, 1)*1;
            const year_end = new Date(date.getFullYear(), 11, 31)*1;
            const spans = service_spans.filter(s => s.end > year_start && s.start < year_end);
            const milliseconds = spans.map(s => Math.min(s.end, year_end) - Math.max(s.start, year_start))
                                      .reduce((acc, ms) => acc + ms, 0);
            const months = Math.round(milliseconds / (1000 * 60 * 60 * 24 * 30.4375));

            return months;
          })()],
          [`sal${i + 1}_year`, (parse_date(salary_contributions?.[i]?.contribution_date))?.getFullYear?.() ?? null]
        ]))),

        // Contribution History:
        ...(() => {
          let now = new Date().getFullYear();
          let years = {};
          //for (let i=1971; i<now; i++) { years[`${i}`] = 0 }
          d.contributions.forEach(c => {
            const year = parse_date(c.contribution_date).getFullYear();
            years[`contrib_${year}`] = (years[`contrib_${year}`] ?? 0) + c.ee_pension_contribution;
          });
          return years;
        })(),
      });
    }
  },
  {
    name: "Active/Vested",
    desc: "Dates and data for active, vested, or termed participants.",
    //subname: args => `(${args.year})`,
    icon: "star_half",
    active_vested: true,
    //args: { year: (d => { return (d.getMonth() === 11 && d.getDate() > 29) ? d.getFullYear() + 1 : d.getFullYear() })(new Date()) },
    args: { active: null, vested: null, termed: null },
    limit: 2000,
    sort: [
      { col: "name", reverse: false },
      { col: "id", reverse: false },
    ],
    filter: [],
    query: (filter, sort, limit, offset, args) => {
      const lookup = {
        name: "last_name",
        hired: "current_state.hired",
        vested: "current_state.vested",
        employer: "current_state.employer",
        "computed group": "current_state.group",
        termed: "employments.end_date",
        line1: "preferred_address.address.line1",
        line2: "preferred_address.address.line2",
        line3: "preferred_address.address.line3",
        city: "preferred_address.address.city",
        state: "preferred_address.address.address_state_code",
        zip: "preferred_address.address.zip",
        active: "current_state.in_service",
        vested: "current_state.vested",
      };

      return gql`
      query untitled {
        report: person (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {current_state: {basis: {_is_null: false}}},
            ${args.active !== null
          ? `{current_state: {in_service: {_eq: ${args.active}}}},`
          : ""
        }
            ${args.vested !== null
          ? `{current_state: {vested: {_is_null: ${!args.vested}}}},`
          : ""
        }
          ${args.termed !== null
          ? `{current_state: {employer: {_is_null: ${!args.termed}}}},`
          : ""
        }
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          id
          first_name
          last_name
          middle_name
        	ssn
          birth_date
          last_4 { ssn }
          preferred_address{
            address {
              line1
              line2
              line3
              city
              address_state_code
              zip
            }
          }

		  emails {
			email
			email_status_code
		  }
          
          current_state{
            in_service
            employer
            group
            basis
            vested
            hired
            status
          }
          employments(order_by: {end_date: desc}, limit: 1, where: {_and: [{end_date: {_lte: "now"}}, {end_date: {_is_null: false}}]}) {
            employer_code
            end_date
          }
          
        }
        employer_codes {
          code
          name
        }
      }
      `;
    },
    columns: [
      { c: "active", t: "bool" },
      { c: "vested", t: "date" },
      { c: "name", t: "person_link" },
      { c: "dob", t: "date" },
      { c: "computed group", t: "ltext" },
      { c: "employer", t: "ltext" },
      { c: "hired", t: "date" },
      { c: "termed", t: "date" },
      { c: "ssn", t: "ssn9" },
      { c: "email", t: "ltext" },
      { c: "line1", t: "ltext" },
      { c: "line2", t: "ltext" },
      { c: "line3", t: "ltext" },
      { c: "city", t: "ltext" },
      { c: "state", t: "ltext" },
      { c: "zip", t: "ltext" },
    ],
    download_columns: [
      //{ c: 'id', t: 'ctext' },
      { c: "active", t: "bool" },
      { c: "vested", t: "date" },
      { c: "person_id", t: "person_link" },
      { c: "last_name", t: "ltext" },
      { c: "first_name", t: "ltext" },
      { c: "middle_name", t: "ltext" },
      { c: "dob", t: "date" },
      { c: "computed group", t: "ltext" },
      { c: "employer", t: "ltext" },
      { c: "hired", t: "date" },
      { c: "termed", t: "date" },
      { c: "ssn", t: "ssn9" },
      { c: "email", t: "ltext" },
      { c: "line1", t: "ltext" },
      { c: "line2", t: "ltext" },
      { c: "line3", t: "ltext" },
      { c: "city", t: "ltext" },
      { c: "state", t: "ltext" },
      { c: "zip", t: "ltext" },
    ],
    row_func: (d, args, raw) => ({
      id: d.id,
      person_id: d.id,
      name: ["first_name", "middle_name", "last_name"]
        .map((n) => d[n])
        .filter((n) => n)
        .join(" "),
      //name: d.person,
      last_name: d.last_name,
      first_name: d.first_name,
      middle_name: d.middle_name,
      ssn: d.ssn,
      email: d?.emails?.filter?.(e => e.email_status_code === 'G')?.map?.(e => e.email)?.join?.(', '),
      dob: new Date(`${d.birth_date}T00:00:00-05:00`), //FIXME: proper dates
      //emails: d.emails.map(e => e.email).join(', '),
      //phones: d.phones.map(p => `${p.number}${p.phone_type_code ? ` (${p.phone_type_code})` : ''}`).join(', '),
      line1: d.preferred_address?.address
        ? d.preferred_address.address.line1
        : null,
      line2: d.preferred_address?.address
        ? d.preferred_address.address.line2
        : null,
      line3: d.preferred_address?.address
        ? d.preferred_address.address.line3
        : null,
      city: d.preferred_address?.address
        ? d.preferred_address.address.city
        : null,
      state: d.preferred_address?.address
        ? d.preferred_address.address.address_state_code
        : null,
      zip: d.preferred_address?.address
        ? d.preferred_address.address.zip
        : null,
      status: d.current_state?.status ? d.current_state.status : null,
      hired: d.current_state?.hired ? d.current_state.hired : null,
      basis: d.current_state?.basis ? d.current_state.basis : null,
      "computed group": d.current_state?.group ? d.current_state.group : null,
      active: d.current_state?.in_service ? d.current_state.in_service : false,
      vested: d.current_state?.vested ? d.current_state.vested : false,
      employer: d.current_state?.employer ? raw.employer_codes?.find?.(c => c.code === d.current_state.employer)?.name : 
      raw.employer_codes?.find?.(c => c.code === d.employments[0]?.employer_code)?.name + ' (last)',
      termed:
        !(d.current_state?.in_service ? d.current_state.in_service : false) && //not in service
          d.employments && //worked before
          d.employments.length > 0 
          ? d.employments[0].end_date //job has ended
          : null,
      //"last employer": d.employments && d.employments.length > 0 ? d.employments.map(e => ({ ...e, start_date: e.start_date ? new Date(e.start_date) : null })).filter(e => e.start_date && e.start_date < new Date()).sort((e1, e2) => e2.start_date - e1.start_date).map(e => e.employer_code)[0] : null,
    }),
  },
  {
    name: "Deaths",
    desc: "All deceased participants and associated data.",
    icon: "deceased",
    args: {},
    limit: 2000,
    sort: [
      { col: "name", reverse: false },
      { col: "id", reverse: false },
    ],
    filter: [],
    query: (filter, sort, limit, offset, args) => {
      const lookup = {
        name: "last_name",
        hired: "current_state.hired",
        vested: "current_state.vested",
        employer: "current_state.employer",
        "computed group": "current_state.group",
        termed: "employments.end_date",
        line1: "preferred_address.address.line1",
        line2: "preferred_address.address.line2",
        line3: "preferred_address.address.line3",
        city: "preferred_address.address.city",
        state: "preferred_address.address.address_state_code",
        zip: "preferred_address.address.zip",
        active: "current_state.in_service",
        vested: "current_state.vested",
      };

      return gql`
      query untitled {
        report: person (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {deceased_date: {_is_null: false}},
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          id
          first_name
          last_name
          middle_name
        	ssn
          birth_date
          deceased_date
          last_4 { ssn }
          preferred_address{
            address {
              line1
              line2
              line3
              city
              address_state_code
              zip
            }
          }

		  emails {
			email
			email_status_code
		  }
          
          current_state{
            in_service
            employer
            group
            basis
            vested
            hired
            status
          }
          employments(order_by: {end_date: desc}, limit: 1, where: {_and: [{end_date: {_lte: "now"}}, {end_date: {_is_null: false}}]}) {
            employer_code
            end_date
          }
          
        }
      }
      `;
    },
    columns: [
      { c: "name", t: "person_link" },
      { c: "ssn", t: "ssn9" },
      { c: "dob", t: "date" },
      { c: "dod", t: "date" },
      { c: "computed group", t: "ltext" },
      { c: "hired", t: "date" },
    ],
    download_columns: [
      //{ c: 'id', t: 'ctext' },
      { c: "name", t: "person_link" },
      { c: "ssn", t: "ssn9" },
      { c: "dob", t: "date" },
      { c: "dod", t: "date" },
      { c: "computed group", t: "ltext" },
      { c: "hired", t: "date" },
    ],
    row_func: (d, args) => ({
      id: d.id,
      person_id: d.id,
      name: ["first_name", "middle_name", "last_name"]
        .map((n) => d[n])
        .filter((n) => n)
        .join(" "),
      //name: d.person,
      last_name: d.last_name,
      first_name: d.first_name,
      middle_name: d.middle_name,
      ssn: d.ssn,
      email: d?.emails?.filter?.(e => e.email_status_code === 'G')?.map?.(e => e.email)?.join?.(', '),
      dob: new Date(`${d.birth_date}T00:00:00-05:00`), //FIXME: proper dates
      dod: new Date(`${d.deceased_date}T00:00:00-05:00`), //FIXME: proper dates
      //emails: d.emails.map(e => e.email).join(', '),
      //phones: d.phones.map(p => `${p.number}${p.phone_type_code ? ` (${p.phone_type_code})` : ''}`).join(', '),
      line1: d.preferred_address?.address
        ? d.preferred_address.address.line1
        : null,
      line2: d.preferred_address?.address
        ? d.preferred_address.address.line2
        : null,
      line3: d.preferred_address?.address
        ? d.preferred_address.address.line3
        : null,
      city: d.preferred_address?.address
        ? d.preferred_address.address.city
        : null,
      state: d.preferred_address?.address
        ? d.preferred_address.address.address_state_code
        : null,
      zip: d.preferred_address?.address
        ? d.preferred_address.address.zip
        : null,
      status: d.current_state?.status ? d.current_state.status : null,
      hired: d.current_state?.hired ? d.current_state.hired : null,
      basis: d.current_state?.basis ? d.current_state.basis : null,
      "computed group": d.current_state?.group ? d.current_state.group : null,
      active: d.current_state?.in_service ? d.current_state.in_service : false,
      vested: d.current_state?.vested ? d.current_state.vested : false,
      employer: d.current_state?.employer ? d.current_state.employer : null,
      termed:
        !(d.current_state?.in_service ? d.current_state.in_service : false) &&
          d.employments &&
          d.employments.length > 0
          ? d.employments[0].end_date
          : null,
      //"last employer": d.employments && d.employments.length > 0 ? d.employments.map(e => ({ ...e, start_date: e.start_date ? new Date(e.start_date) : null })).filter(e => e.start_date && e.start_date < new Date()).sort((e1, e2) => e2.start_date - e1.start_date).map(e => e.employer_code)[0] : null,
    }),
  },


  {
    name: "Begin Deduction",
    desc: "Currently employed and reaching required contribution date.",
    subname: (args) => `(${args.year})`,
    icon: "event_upcoming",
    args: {
      year: ((d) => {
        return d.getMonth() === 11 && d.getDate() > 29
          ? d.getFullYear() + 1
          : d.getFullYear();
      })(new Date()),
    },
    limit: 2000,
    sort: [
      { col: "group", reverse: false },
      { col: "name", reverse: false },
      { col: "person.id", reverse: false },
    ],
    filter: [],
    query: (filter, sort, limit, offset, args) => {
      const lookup = {
        name: "person.last_name",
        group: "group",
        "employer code": "employer",
        "employer name": "employer",
        hired: "basis",
        contributor: "contributing",
      };
      return gql`
      query begin_deduction {
        report: report_contribution_beginning (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {contributing: {_gte: "${new Date(
        args.year,
        0,
        1,
      ).toLocaleDateString()}"}},
            {contributing: {_lte: "${new Date(
        args.year + 1,
        0,
        1,
      ).toLocaleDateString()}"}},
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          person {
            id
            first_name
            last_name
            middle_name
            last_4 { ssn }
            person_gender_code
            birth_date
            emails {
              email
              email_status_code
            }
          }
          employer_detail {
            name
            code
          }
          group
          basis
          contributing
        }
      }
      `;
    },
    columns: [
      { c: "group", t: "ltext" },
      { c: "employer code", t: "ltext" },
      { c: "employer name", t: "ltext" },
      { c: "name", t: "person_link" },
      { c: "email", t: "ltext" },
      { c: "hired", t: "date" },
      { c: "contributor", t: "date" },
    ],
    download_columns: [
      { c: "group", t: "ltext" },
      { c: "employer code", t: "ltext" },
      { c: "employer name", t: "ltext" },
      { c: "person_id", t: "person_link" },
      { c: "last_name", t: "ltext" },
      { c: "first_name", t: "ltext" },
      { c: "middle_name", t: "ltext" },
      { c: "email", t: "ltext" },
      { c: "hired", t: "date" },
      { c: "contributor", t: "date" },
    ],
    row_func: (d) => ({
      id: d.person.id,
      person_id: d.person.id,
      name: ["first_name", "middle_name", "last_name"]
        .map((n) => d.person[n])
        .filter((n) => n)
        .join(" "),
      //name: d.person,
      last_name: d.person.last_name,
      first_name: d.person.first_name,
      middle_name: d.person.middle_name,
      email: d?.person?.emails?.filter?.(e => e.email_status_code === 'G')?.map?.(e => e.email)?.join?.(', '),
      group: d.group,
      "employer code": d.employer_detail ? d.employer_detail.code : null,
      "employer name": d.employer_detail ? d.employer_detail.name : null,
      hired: d.basis,
      contributor: d.contributing,
    }),
  },

  {
    name: "Age 65",
    desc: "Reaching age 65 this year (and has employments, and not deceased).",
    subname: (args) => `(${args.year})`,
    icon: "cake",
    args: {
      year: ((d) => {
        return d.getMonth() === 11 && d.getDate() > 29
          ? d.getFullYear() + 1
          : d.getFullYear();
      })(new Date()),
    },
    limit: 2000,
    sort: [
      { col: "dob", reverse: false },
      { col: "name", reverse: false },
      { col: "id", reverse: false },
    ],
    filter: [],
    query: (filter, sort, limit, offset, args) => {
      const lookup = {
        name: "last_name",
        65: "birth_date",
        dob: "birth_date",
        ssn: "ssn",
      };
      //{_not: {deceased_date: {}}},
      return gql`
      query age65 {
        report: person (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {birth_date: {_lte: "${new Date(
        args.year - 64,
        0,
        1,
      ).toLocaleDateString()}"}},
            {birth_date: {_gte: "${new Date(
        args.year - 65,
        0,
        0,
      ).toLocaleDateString()}"}},
            {employments: {}},
            {deceased_date: {_is_null: true}},
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          id
          first_name
          last_name
          middle_name
          ssn
          person_gender_code
          birth_date
          deceased_date
          emails {
            email
          }
          phones {
            number
            phone_type_code
          }
          preferred_address {
            address {
              line1
              line2
              line3
              city
              address_state_code
              zip
            }
          }
          current_state {
              group
              employer
              status
          }
          employments {
              start_date
              end_date
              employer_code
          }
        }
      }
      `;
    },
    columns: [
      { c: "name", t: "person_link" },
      { c: "computed group", t: "ltext" },
      { c: "last employer", t: "ltext" },
      { c: "ssn", t: "ssn9" },
      { c: "dob", t: "date" },
      { c: "65", t: "date" },
      { c: "emails", t: "ltext" },
      { c: "phones", t: "ltext" },
      { c: "line1", t: "ltext" },
      { c: "line2", t: "ltext" },
      { c: "line3", t: "ltext" },
      { c: "city", t: "ltext" },
      { c: "state", t: "ltext" },
      { c: "zip", t: "ltext" },
    ],
    download_columns: [
      //{ c: 'id', t: 'ctext' },
      { c: "person_id", t: "person_link" },
      { c: "last_name", t: "ltext" },
      { c: "first_name", t: "ltext" },
      { c: "middle_name", t: "ltext" },
      { c: "computed group", t: "ltext" },
      { c: "last employer", t: "ltext" },
      { c: "ssn", t: "ssn9" },
      { c: "dob", t: "date" },
      { c: "65", t: "date" },
      { c: "emails", t: "ltext" },
      { c: "phones", t: "ltext" },
      { c: "line1", t: "ltext" },
      { c: "line2", t: "ltext" },
      { c: "line3", t: "ltext" },
      { c: "city", t: "ltext" },
      { c: "state", t: "ltext" },
      { c: "zip", t: "ltext" },
    ],
    row_func: (d, args) => ({
      id: d.id,
      person_id: d.id,
      name: ["first_name", "middle_name", "last_name"]
        .map((n) => d[n])
        .filter((n) => n)
        .join(" "),
      //name: d.person,
      last_name: d.last_name,
      first_name: d.first_name,
      middle_name: d.middle_name,
      sex: d.person_gender_code,
      dob: new Date(`${d.birth_date}T00:00:00-05:00`), //FIXME: proper dates
      deceased: d.deceased_date,
      65: ((d) => {
        d.setFullYear(d.getFullYear() + 65);
        return d;
      })(new Date(d.birth_date)),
      ssn: d.ssn,
      emails: d.emails.map((e) => e.email).join(", "),
      phones: d.phones
        .map(
          (p) =>
            `${p.number}${p.phone_type_code ? ` (${p.phone_type_code})` : ""}`,
        )
        .join(", "),
      line1: d.preferred_address?.address
        ? d.preferred_address.address.line1
        : null,
      line2: d.preferred_address?.address
        ? d.preferred_address.address.line2
        : null,
      line3: d.preferred_address?.address
        ? d.preferred_address.address.line3
        : null,
      city: d.preferred_address?.address
        ? d.preferred_address.address.city
        : null,
      state: d.preferred_address?.address
        ? d.preferred_address.address.address_state_code
        : null,
      zip: d.preferred_address?.address
        ? d.preferred_address.address.zip
        : null,
      status: d.current_state?.status ? d.current_state.status : null,
      "computed group": d.current_state?.group ? d.current_state.group : null,
      "current employer": d.current_state?.employer
        ? d.current_state.employer
        : null,
      "last employer":
        d.employments && d.employments.length > 0
          ? d.employments
            .map((e) => ({
              ...e,
              start_date: e.start_date ? new Date(e.start_date) : null,
            }))
            .filter((e) => e.start_date && e.start_date < new Date())
            .sort((e1, e2) => e2.start_date - e1.start_date)
            .map((e) => e.employer_code)[0]
          : null,
    }),
  },

  {
    name: "Withdrawals",
    desc: "Withdrawal Reporting",
    icon: "receipt",
    subname: (args) => `${args.withdrawal_type_code}`,
    args: {
      withdrawal_type_code: ((d) => {
        return `${d.withdrawal_type_code}`
      })
    },
    limit: 2000,
    filter: [],
    sort: [],
    query: (filter, sort, limit, offset, args) => {

      let whereClause;
      if(args.withdrawal_type_code === 'ALL'){
        whereClause = `{_and: [
          {},
          ${makeFilter(filter)}
        ]}`
      } else {
        whereClause = `{_and: [
          {withdrawal_type_code: {_eq: "${args.withdrawal_type_code}"}},
          ${makeFilter(filter)}
        ]}`
      }

      return gql`
        query retrieve_withdrawal{
          report: withdrawal(${makeCond(sort, limit, offset)} where: ${whereClause}){
            person {
              current_state {
                in_service
                vested
                hired
                group
                employer
              }
              last_name
              first_name
              middle_name
              birth_date
              employments(order_by: {end_date: desc}, limit: 1, where: {_and: [{end_date: {_lte: "now"}}, {end_date: {_is_null: false}}]}) {
                employer_code
                end_date
              }
              ssn
              emails {
                email
              }
              preferred_address {
                address {
                  line1
                  line2
                  line3
                  city
                  state {
                    name
                  }
                  zip
                }
              }
            }
            person_id
            interest
            taxable
            withdrawal_date
            withdrawal_type {
              code
              name
            }
            total
          }
          employer_codes {
            code
            name
        }
      }
      `
    },
    columns: [
      {c: "active", t: "bool"},
      {c: "vested", t: "date"},
      {c: "id", t: "ltext"},
      {c: "last_name", t: "ltext"},
      {c: "first_name", t: "ltext"},
      {c: "middle_name", t: "ltext"},
      {c: "dob", t: "date"},
      {c: "computed_group", t: "ltext"},
      {c: "employer", t: "ltext"},
      {c: "hired", t: "date"},
      {c: "termed", t: "date"},
      {c: "ssn", t: "ssn9"},
      {c: "email", t: "ltext"},
      {c: "line1", t: "ltext"},
      {c: "line2", t: "ltext"},
      {c: "line3", t: "ltext"},
      {c: "city", t: "ltext"},
      {c: "state", t: "ltext"},
      {c: "zip", t: "ltext"},
      {c: "withdrawal_type", t: "ltext"},
      {c: "withdrawal_date", t: "date"},
      {c: "taxable", t: "money"},
      {c: "nontaxable", t: "money"},
      {c: "interest", t: "money"},
      {c: "total amount", t: "money"}
    ],
    download_columns: [
      {c: "active", t: "bool"},
      {c: "vested", t: "date"},
      {c: "id", t: "ltext"},
      {c: "last_name", t: "ltext"},
      {c: "first_name", t: "ltext"},
      {c: "middle_name", t: "ltext"},
      {c: "dob", t: "date"},
      {c: "computed_group", t: "ltext"},
      {c: "employer", t: "ltext"},
      {c: "hired", t: "date"},
      {c: "termed", t: "date"},
      {c: "ssn", t: "ssn9"},
      {c: "email", t: "ltext"},
      {c: "line1", t: "ltext"},
      {c: "line2", t: "ltext"},
      {c: "line3", t: "ltext"},
      {c: "city", t: "ltext"},
      {c: "state", t: "ltext"},
      {c: "zip", t: "ltext"},
      {c: "withdrawal_type", t: "ltext"},
      {c: "withdrawal_date", t: "date"},
      {c: "taxable", t: "money"},
      {c: "nontaxable", t: "money"},
      {c: "interest", t: "money"},
      {c: "total amount", t: "money"}
    ],
    row_func: (withdrawal,args, raw) => ({
        active: withdrawal.person.current_state.in_service,
        vested: withdrawal.person.current_state.vested,
        id: withdrawal.person_id,
        last_name: withdrawal.person.last_name,
        first_name: withdrawal.person.first_name,
        middle_name: withdrawal.person.middle_name,
        dob: withdrawal.person.birth_date,
        computed_group: withdrawal.person.current_state?.group,
        employer: withdrawal.person.current_status?.employer ? raw.employer_codes?.find?.(c => c.code === withdrawal.person.current_status.current_state.employer)?.name :
        raw.employer_codes?.find?.(c => c.code === withdrawal.person.employments[0]?.employer_code)?.name + ' (last)',  
        hired: withdrawal.person.current_status?.hired,
        termed: !(withdrawal.current_state?.in_service ? withdrawal.current_state.in_service : false) && 
        withdrawal.employments && 
        withdrawal.employments.length > 0 
        ? withdrawal.employments[0].end_date 
        : null,
        ssn: withdrawal.person.ssn,
        email: withdrawal.person.emails?.email,
        line1: withdrawal.person.preferred_address?.address?.line1,
        line2: withdrawal.person.preferred_address?.address?.line2,
        line3: withdrawal.person.preferred_address?.address?.line3,
        city: withdrawal.person.preferred_address?.address?.city,
        state: withdrawal.person.preferred_address?.address?.state?.name,
        zip: withdrawal.person.preferred_address?.address?.zip,
        withdrawal_type: !withdrawal.withdrawal_type ? 'blank': withdrawal.withdrawal_type.name,
        withdrawal_date: withdrawal?.withdrawal_date,
        taxable: withdrawal?.taxable,
        nontaxable: withdrawal?.total - withdrawal?.taxable,
        interest: withdrawal.withdrawal_type?.code  === 
        "TV" ? "" : withdrawal.interest,
        "total amount": withdrawal?.total
    })   
  },

  {
    name: "Contributions",
    desc: "Total employee contributions by employer.",
    subname: (args) => `(${args.year})`,
    icon: "receipt",
    args: {
      year: ((d) => {
        return d.getMonth() === 11 && d.getDate() > 29
          ? d.getFullYear()
          : d.getFullYear() - 1;
      })(new Date()),
    },
    limit: 2000,
    sort: [{ col: "employer", reverse: false }],
    filter: [],
    query: (filter, sort, limit, offset, args) => {
      console.log("OFFSET", offset);
      const lookup = {
        year: "year",
        employer: "employer",
        "Total EE Contributions": "total_ee_contribution",
      };

      let ret = gql`
      query begin_deduction {
        report: report_contributions_by_employer (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {year: {_eq: ${args.year}}},
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          year
          employer
          total_ee_contribution
        }
      }
      `;
      return ret;
    },

    columns: [
      { c: "employer", t: "ltext" },
      { c: "Total EE Contributions", t: "money" },
    ],
    download_columns: [
      { c: "year", t: "number" },
      { c: "employer", t: "ltext" },
      { c: "Total EE Contributions", t: "money" },
    ],
    row_func: (d) => ({
      year: d.year,
      employer: d.employer,
      "Total EE Contributions": d.total_ee_contribution,
    }),
  },

  {
    name: "Census Reconciliation",
    desc: "Detail employee contributions by employer.",
    subname: (args) => `(${args.year})`,
    icon: "receipt_long",
    args: {
      year: ((d) => {
        return d.getMonth() === 11 && d.getDate() > 29
          ? d.getFullYear()
          : d.getFullYear() - 1;
      })(new Date()),
    },
    limit: 2000,
    sort: [
      { col: "employer", reverse: false },
      { col: "name", reverse: false },
    ],
    filter: [],
    query: (filter, sort, limit, offset, args) => {
      console.log("OFFSET", offset);
      const lookup = {
        year: "year",
        employer: "employer",
        "EE Contrib": "ee_pension_contribution",
        "Employer Total": "employer_total",
        name: "person.last_name",
        ssn: "person.ssn",
        dob: "person.birth_date",
      };

      let ret = gql`
      query begin_deduction {
        report: report_census_reconciliation_detail (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {year: {_eq: ${args.year}}},
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          person_id
          year
          person {
            first_name
            middle_name
            last_name
            ssn
            birth_date
          }
          employer
          ee_pension_contribution
          employer_total
        }
      }
      `;
      return ret;
    },
    columns: [
      { c: "year", t: "number" },
      { c: "employer", t: "ltext" },
      { c: "name", t: "person_link" },
      { c: "ssn", t: "ssn9" },
      { c: "dob", t: "date" },
      { c: "EE Contrib", t: "money" },
      { c: "Employer Total", t: "money" },
    ],
    download_columns: [
      //{ c: 'id', t: 'ctext' },
      { c: "year", t: "number" },
      { c: "employer", t: "ltext" },
      { c: "person_id", t: "person_link" },
      { c: "last_name", t: "ltext" },
      { c: "first_name", t: "ltext" },
      { c: "middle_name", t: "ltext" },
      { c: "ssn", t: "ssn9" },
      { c: "dob", t: "date" },
      { c: "EE Contrib", t: "money" },
      { c: "Employer Total", t: "money" },
    ],
    row_func: (d) => ({
      id: d.person_id,
      person_id: d.person_id,
      name: ["first_name", "middle_name", "last_name"]
        .map((n) => d.person[n])
        .filter((n) => n)
        .join(" "),
      last_name: d.person.last_name,
      first_name: d.person.first_name,
      middle_name: d.person.middle_name,
      dob: new Date(d.person.birth_date),
      ssn: d.person.ssn,

      year: d.year,
      employer: d.employer,
      "EE Contrib": d.ee_pension_contribution,
      "Employer Total": d.employer_total,
    }),
  },
  {
    name: "Summary Statements",
    icon: "print",
    //wip: true,
    args: {},
    limit: 2000,
    desc: "Pension summary statement printing data.",
    // order_by: [{report:{group_code: asc}}, {last_name: asc}]
    // ( where: {report: {basis: {_is_null:false}}})
    sort: [],
    filter: [],
    //subname: args => `(${args.year})`,
    query: (filter, sort, limit, offset, args) => {
      console.log("args=", args, "sort=", sort);
      const lookup = {
        ssn: "person.ssn",
      };

      //order_by: [{report:{group_code: asc}}, {last_name: asc}]
      //where: {basis:{}}
      return gql`
      query pension_statement {
        report:report_pension_statement_view(
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            ${makeFilter(filter)}
          ]}
        )
        {
          activegroupcode
          person_id
          last_name
          first_name
          participantfullname
          birthdate
          hireddate
          benefitstartdate
          contributionstartdate
          pensionparticipantno
          activegroupname
          address
          address2
          city
          state
          zipcode
          contribs_type
          contribs_date
          contributiondate
          interest
          contributionamount
          contribs_bal
          contribs_total_interest
          contribs_total_contribs
          accountbalance
          totalinterest
          totalcontribution
          person {
            ssn
          }
        }
      }
      `;
    },

    columns: [
      { c: "ActiveGroupCode", t: "ltext" },
      { c: "ParticipantFullName", t: "person_link" },
      { c: "SSN", t: "ssn9" },
      { c: "BirthDate", t: "date" },
      { c: "HiredDate", t: "date" },
      { c: "BenefitStartDate", t: "date" },
      { c: "ContributionStartDate", t: "date" },
      { c: "PensionParticipantNo", t: "number" },
      { c: "ActiveGroupName", t: "ltext" },
      { c: "Address", t: "ltext" },
      { c: "Address2", t: "ltext" },
      { c: "City", t: "ltext" },
      { c: "State", t: "ltext" },
      { c: "ZipCode", t: "ltext" },
      { c: "ContributionDate", t: "date" },
      { c: "Interest", t: "money" },
      { c: "ContributionAmount", t: "money" },
      { c: "AccountBalance", t: "money" },
      { c: "TotalInterest", t: "money" },
      { c: "TotalContribution", t: "money" },
    ],
    download_columns: [
      { c: "ActiveGroupCode", t: "ltext" },
      { c: "LastName", t: "ltext" },
      { c: "FirstName", t: "ltext" },
      { c: "ParticipantFullName", t: "ltext" },
      { c: "SSN", t: "ssn9" },
      { c: "BirthDate", t: "date" },
      { c: "HiredDate", t: "date" },
      { c: "BenefitStartDate", t: "date" },
      { c: "ContributionStartDate", t: "date" },
      { c: "PensionParticipantNo", t: "number" },
      { c: "ActiveGroupName", t: "ltext" },
      { c: "Address", t: "ltext" },
      { c: "Address2", t: "ltext" },
      { c: "City", t: "ltext" },
      { c: "State", t: "ltext" },
      { c: "ZipCode", t: "ltext" },
      { c: "ContributionDate", t: "date" },
      { c: "Interest", t: "money" },
      { c: "ContributionAmount", t: "money" },
      { c: "AccountBalance", t: "money" },
      { c: "TotalInterest", t: "money" },
      { c: "TotalContribution", t: "money" },
    ],
    row_func: (d) => ({
      id: d.person_id,
      person_id: d.person_id,
      ActiveGroupCode: d.activegroupcode,
      LastName: d.lastname,
      FirstName: d.firstname,
      ParticipantFullName: d.participantfullname,
      SSN: d.person.ssn,
      BirthDate: d.birthdate,
      HiredDate: d.hireddate,
      BenefitStartDate: d.benefitstartdate,
      ContributionStartDate: d.contributionstartdate,
      PensionParticipantNo: d.pensionparticipantno,
      ActiveGroupName: d.activegroupname,
      Address: d.address,
      Address2: d.address2,
      City: d.city,
      State: d.state,
      ZipCode: d.zipcode,
      ContributionDate: d.contributiondate,
      Interest: d.interest,
      ContributionAmount: d.contributionamount,
      AccountBalance: d.accountbalance,
      TotalInterest: d.totalinterest,
      TotalContribution: d.totalcontribution,
    }),
  },

  {
    name: "Actuarial Report",
    icon: "people",
    args: {
      year: ((d) => {
        return d.getMonth() === 11 && d.getDate() > 29
          ? d.getFullYear()
          : d.getFullYear() - 1;
      })(new Date()),
    },
    limit: 2000,
    desc: "Status of current participants.",
    // order_by: [{report:{group_code: asc}}, {last_name: asc}]
    // ( where: {report: {basis: {_is_null:false}}})
    sort: [
      { col: "group", reverse: false },
      { col: "name", reverse: false },
      { col: "person.id", reverse: false },
    ],
    filter: [],
    subname: (args) => `(${args.year})`,
    query: (filter, sort, limit, offset, args) => {
      console.log("args=", args, "sort=", sort);
      const lookup = {
        name: "person.last_name",
        group: "group_code",
        sex: "person.person_gender_code",
        dob: "person.birth_date",
        ssn: "person.ssn",
        hired: "hired",
        "benefit start": "basis",
        member: "participating",
        contributor: "contributing",
        "total contrib": "total_contributions",
        "total interest": "contribution_interest",
        balance: "contribution_balance",
        sal0: "sal0",
        sal1: "sal1",
        sal2: "sal2",
        service: "credited_years",
        vesting: "vesting_years",
      };

      //order_by: [{report:{group_code: asc}}, {last_name: asc}]
      //where: {basis:{}}
      return gql`
      query actuarial_report {
        report:report_yearly_data(
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {year: {_eq: ${args.year}}},
            {participating: {}},
            ${makeFilter(filter)}
          ]}
        )
        {
          person {
            id
            first_name
            last_name
            middle_name
            ssn
            last_4 { ssn }
            person_gender_code
            birth_date
          }
          year
          group_code
          basis
          age
          total_contributions
          contribution_balance
          contribution_interest
          sal0
          sal1
          sal2
          credited_years
          vesting_years
          contributing
          participating
          hired
          terminated
          ytd_contribution_interest
          ytd_credited_years
          ytd_service_hours
          ytd_vesting_years
        }
      }
      `;
    },
    columns: [
      { c: "name", t: "person_link" },
      { c: "group", t: "ctext" },
      { c: "ssn", t: "ssn9" },
      { c: "sex", t: "ctext" },
      { c: "dob", t: "date" },
      { c: "age", t: "decimal", decimals: 2 },
      { c: "hired", t: "date" },
      { c: "terminated", t: "date" },
      { c: "benefit start", t: "date" },
      { c: "member", t: "date" },
      { c: "service", t: "decimal", decimals: 3 },
      { c: "vesting", t: "number", decimals: 0 },
      { c: "contributor", t: "date" },
      { c: "total contrib", t: "money" },
      { c: "total interest", t: "money" },
      { c: "balance", t: "money" },
      { c: "sal0", t: "money" },
      { c: "sal1", t: "money" },
      { c: "sal2", t: "money" },
    ],
    download_columns: [
      { c: "person_id", t: "person_link" },
      { c: "last_name", t: "ltext" },
      { c: "first_name", t: "ltext" },
      { c: "middle_name", t: "ltext" },
      { c: "group", t: "ctext" },
      { c: "ssn", t: "ssn9" },
      { c: "sex", t: "ctext" },
      { c: "dob", t: "date" },
      { c: "age", t: "number" },
      { c: "hired", t: "date" },
      { c: "terminated", t: "date" },
      { c: "benefit start", t: "date" },
      { c: "member", t: "date" },
      { c: "service", t: "decimal", decimals: 3 },
      { c: "vesting", t: "number", decimals: 0 },
      { c: "contributor", t: "date" },
      { c: "total contrib", t: "money" },
      { c: "total interest", t: "money" },
      { c: "balance", t: "money" },
      { c: "sal0", t: "money" },
      { c: "sal1", t: "money" },
      { c: "sal2", t: "money" },
    ],
    row_func: (d) => ({
      id: d.person.id,
      person_id: d.person.id,
      name: ["first_name", "middle_name", "last_name"]
        .map((n) => d.person[n])
        .filter((n) => n)
        .join(" "),
      //name: d.person,
      last_name: d.person.last_name,
      first_name: d.person.first_name,
      middle_name: d.person.middle_name,
      group: d.group_code,
      ssn: d.person.ssn, //d.person.last_4.ssn,//d.person.ssn.slice(d.person.ssn.length - 4),
      sex: d.person.person_gender_code,
      dob: d.person.birth_date,
      age: d.age,
      "benefit start": d.basis,
      "total contrib": d.total_contributions, //tomoney(d.contribution_balance - d.contribution_interest),
      "total interest": d.contribution_interest,
      balance: d.contribution_balance,
      sal0: d.sal0,
      sal1: d.sal1,
      sal2: d.sal2,
      service: d.credited_years + d.ytd_credited_years,
      vesting: d.vesting_years + d.ytd_vesting_years,
      contributor: d.contributing,
      member: d.participating,
      hired: d.hired,
      terminated: d.terminated,
    }),
  },

  {
    name: "2018 Validation",
    icon: "done",
    args: {},
    limit: 2000,
    desc: "Compare new actuarial report results with reference 2018 results from old system.",
    // order_by: [{report:{group_code: asc}}, {last_name: asc}]
    // ( where: {report: {basis: {_is_null:false}}})
    sort: [
      { col: "group_sys", reverse: false },
      { col: "name", reverse: false },
      { col: "id", reverse: false },
    ],
    //filter: [{ col: 'benefit_start_match', op: '_eq', val: false }],
    filter: [],
    highlight: (row, field) => {
      const ssns = new Set([
      ]);
      return ssns.has(row.ssn);
    },
    query: (filter, sort, limit, offset, args) => {
      const lookup = {};

      //order_by: [{report:{group_code: asc}}, {last_name: asc}]
      //where: {basis:{}}
      //{ssn: {_in: ["612545847", "555737264", "625726938", "618389766", "024621752", "299645355", "285845320", "196589556", "219331010", "046620743", "190461576", "128721250", "549471247", "167624178", "063681616", "191608597", "653926345", "320645485", "525871679", "290706093", "169660237", "038403662", "302825289", "281740612", "342784163", "267732719", "375131411", "163526148", "592805842", "506196317", "125748846", "561871824", "595809488", "279581815", "114986174", "286927899", "348544757", "254738644", "196483867", "049687958", "411651568", "138883046", "085661937", "229551689", "216257751", "143907503", "286861010", "405375131", "132683077", "394948158", "014543065", "275682984", "268681841", "041504873", "099609575", "623463401", "461694091", "341803330", "118665872", "345881105", "441808319", "595508277", "130468204", "287702419", "553319324", "384118112", "059388957", "468985943", "589463458", "584823352", "334580081", "318620271", "202680345", "128508698", "478064924", "090724943", "069702793", "546671428", "278760569", "603328394", "575535556", "021744287", "197705065", "290821630", "319807697", "468949143", "611182784", "257418355", "077846535", "085728267", "285862284", "542139113", "606744909"]}},
      return gql`
        query validation_report {
          report: report_actuarial_validation(
            ${makeCond(sort, limit, offset, lookup)}
            where: 
            {
              _and: [
                {year: {_eq: 2018}},
                ${makeFilter(filter)}
              ]
            }
          ) {
            id
            error_count
            alert_count
            migration_count
            year
            name
            ssn
            age
            group_ref
            group_sys
            group_match
            sex_ref
            sex_sys
            sex_match
            dob_ref
            dob_sys
            dob_match
            hired_ref
            hired_sys
            hired_match
            benefit_start_ref
            benefit_start_sys
            benefit_start_match
            member_ref
            member_sys
            member_match
            service_ref
            service_sys
            service_match
            vesting_ref
            vesting_sys
            vesting_match
            contributor_ref
            contributor_sys
            contributor_match
            total_contributions_ref
            total_contributions_sys
            total_contributions_match
            total_interest_ref
            total_interest_sys
            total_interest_match
            account_balance_ref
            account_balance_sys
            account_balance_match
            sal0_ref
            sal0_sys
            sal0_match
            sal1_ref
            sal1_sys
            sal1_match
            sal2_ref
            sal2_sys
            sal2_match
          }
        }
      `;
    },
    columns: [
      { c: "name", t: "person_link" },
      { c: "error_count", a: "err", t: "int" },
      { c: "alert_count", a: "alrt", t: "int" },
      { c: "migration_count", a: "mgrt", t: "int" },
      { c: "group", t: "ctext", compare: true },
      { c: "ssn", t: "ssn" },
      { c: "sex", t: "ctext", compare: true },
      { c: "dob", t: "date", compare: true },
      { c: "age", t: "decimal", decimals: 2 },
      { c: "hired", t: "date", compare: true },
      { c: "benefit_start", t: "date", compare: true },
      { c: "member", t: "date", compare: true },
      { c: "service", t: "decimal", decimals: 3, compare: true },
      { c: "vesting", t: "number", decimals: 0, compare: true },
      { c: "contributor", t: "date", compare: true },
      { c: "total_contributions", a: "ctrbs", t: "money", compare: true },
      { c: "total_interest", a: "intr", t: "money", compare: true },
      { c: "account_balance", a: "acct bal", t: "money", compare: true },
      { c: "sal0", t: "money", compare: true },
      { c: "sal1", t: "money", compare: true },
      { c: "sal2", t: "money", compare: true },
    ],
    download_columns: [
      { c: "id", t: "person_link" },
      { c: "name", t: "ltext" },
      { c: "error_count", t: "int" },
      { c: "alert_count", t: "int" },
      { c: "migration_count", t: "int" },
      { c: "group", t: "ctext", compare: true },
      { c: "ssn", t: "ssn" },
      { c: "sex", t: "ctext", compare: true },
      { c: "dob", t: "date", compare: true },
      { c: "age", t: "decimal", decimals: 2 },
      { c: "hired", t: "date", compare: true },
      { c: "benefit_start", t: "date", compare: true },
      { c: "member", t: "date", compare: true },
      { c: "service", t: "decimal", decimals: 3, compare: true },
      { c: "vesting", t: "number", decimals: 0, compare: true },
      { c: "contributor", t: "date", compare: true },
      { c: "total_contributions", t: "money", compare: true },
      { c: "total_interest", t: "money", compare: true },
      { c: "account_balance", t: "money", compare: true },
      { c: "sal0", t: "money", compare: true },
      { c: "sal1", t: "money", compare: true },
      { c: "sal2", t: "money", compare: true },
    ],
  },
  {
    name: "Error Summary",
    desc: "Counts grouped by message type.",
    icon: "error",
    args: {},
    limit: 500,
    sort: [{ col: "affected", reverse: true }],
    filter: [],
    query: (filter, sort, limit, offset) => {
      return gql`
      query error_summary {
        report:view_cached_errors_summary(
          ${makeCond(sort, limit, offset, {})}
          where: ${makeFilter(filter)}
        ) {
          level
          code
          instances
          affected
        }
      }`;
    },
    columns: [
      { c: "level", t: "ltext" },
      { c: "code", t: "ltext" },
      { c: "instances", t: "number" },
      { c: "affected", t: "number" },
    ],
    row_func: (d) => ({
      level: html`<span class=${d.level}>${d.level}</span>`,
      code: d.code,
      instances: d.instances.toLocaleString(),
      affected: d.affected.toLocaleString(),
    }),
  },
  {
    name: "Error Detail",
    desc: "Errors, alerts and migration issues by person.",
    icon: "error",
    args: {},
    limit: 500,
    sort: [
      { col: "total", reverse: true },
      { col: "name", reverse: false },
      { col: "level" },
    ],
    filter: [],
    query: (filter, sort, limit, offset) => {
      const lookup = {
        name: "person.last_name",
      };

      return gql`
      query error_detail{
        report:cached_errors(
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            ${makeFilter(filter, lookup)}
          ]}
        ) {
          person {
                id
                first_name
                last_name
                middle_name
              }
            date
            level
            code
            message
            message_count
            level_count
            total
        }
      }`;
    },
    columns: [
      { c: "name", t: "person_link" },
      { c: "level", t: "ltext" },
      { c: "@level", t: "number" },
      // { c: 'code', t: 'ltext' },
      { c: "message", t: "ltext" },
      { c: "repeats", t: "number" },
      { c: "total", t: "number" },
    ],
    download_columns: [
      { c: "person_id", t: "person_link" },
      { c: "last_name", t: "ltext" },
      { c: "first_name", t: "ltext" },
      { c: "middle_name", t: "ltext" },
      { c: "level", t: "ltext" },
      { c: "@level", t: "number" },
      { c: "code", t: "ltext" },
      { c: "message", t: "ltext" },
      { c: "repeats", t: "number" },
      { c: "total", t: "number" },
    ],
    row_func: (d) => ({
      id: d.person.id,
      person_id: d.person.id,
      //id: `<a href="https://benefits-test.afscme.org/people/view?person=${d.person.id}">${d.person.id}</a>`,
      //name: html`<a href="/people/view?person=${d.person.id}">${['first_name', 'middle_name', 'last_name'].map(n => d.person[n]).filter(n => n).join(' ')}</a>`,
      name: ["first_name", "middle_name", "last_name"]
        .map((n) => d.person[n])
        .filter((n) => n)
        .join(" "),
      //name: d.person,
      last_name: d.person.last_name,
      first_name: d.person.first_name,
      middle_name: d.person.middle_name,
      level: html`<span class=${d.level}>${d.level}</span>`,
      code: d.code,
      message: d.message,
      "@level": d.level_count,
      repeats: d.message_count,
      total: d.total,
    }),
  },
];
const makeReportId = (r) => {
  return r.name.replace(/\//g, "-").replace(/ /g, "_").toLowerCase();
};
export const reports = declared_reports
  .filter((r) => !r.wip)
  .map((r) => ({ ...r, report_id: makeReportId(r) }));
