import { LitElement, html, css, nothing } from 'lit';
import '@material/mwc-icon';
import '@material/mwc-button';
import '../shared-components/card.js';
import { KaleForm } from '../shared-components/form.js';
import { EventDate } from '../benefits-app/eventdate.js';
import './edit-person.js';
import './migration-card.js';
import './notes.js';
import './tag-list.js';
import './checkpoint.js';

const format_ssn = ssn => {
  ssn = ssn.replace(/[^\d]/g, '');
  if (ssn.length != 9) return `[bad]`;
  // 🞲🞲🞲🞲
  // 🞵🞵🞵🞵
  // ····
  // ●●●●
  // •••• 
  // ◦◦◦◦
  // ○○○○
  // 🞵🞵🞵🞵
  const [pre, mid] = ssn.replace(/(\d\d\d)(\d\d)(\d*)/g, "$1-$2").split('-');
  return html`${pre}&#8209${mid}&#8209<span style="font-size: 80%">●●●●</span>`
}

// TODO: move to utility lib
String.prototype.toTitleCase = function () {
  var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
  var alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  var wordSeparators = /([ :–—-])/

  return this.split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' ||
          (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase()
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase()
      })
    })
    .join('')
}


const person_infobox_style = css`
        :host {
          display: flex;
          flex-direction: column;
          width: 100%;
         --transform-transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
         --height-transition: height 0.28s cubic-bezier(0.4, 0, 0.2, 1);
         --opacity-transition: opacity 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }

        :host > * { width: 100%; margin-top: 16px }
        kale-card, person-notes, person-migration {
          width: 100%;
          --card-border: var(--paper-grey-400);
        }

        kale-card[calculating] * {
          cursor: wait !important;
        }
        kale-card.color-coded {
          --card-bg: var(--paper-grey-700);
          --card-border: var(--paper-grey-700);
          color: white;
          --checkpoint-text-color: white;
        }

        kale-card.color-coded[person-type='deceased']{
          --card-bg: var(--deceased-person-color);
          --card-border: var(--deceased-person-color);
        }
        kale-card.color-coded[person-type='working_retiree']{
          --card-bg: var(--working-retiree-person-color);
          --card-border: var(--working-retiree-person-color);
          color: var(--paper-grey-200);
        }
        kale-card.color-coded[person-type='employee']{
          --card-bg: var(--employee-person-color);
          --card-border: var(--employee-person-color);
        }
        kale-card.color-coded[person-type='ex-employee']{
          --card-bg: var(--ex-employee-person-color);
          --card-border: var(--ex-employee-person-color);
            /*
          background: repeating-linear-gradient(
            -45deg,
            var(--paper-grey-700),
            var(--paper-grey-700) 10px,
            var(--paper-green-700) 10px,
            var(--paper-green-700) 20px
            );
            */
        }
        kale-card[person-type='retiree']{
          --card-bg: var(--retiree-person-color);
          --card-border: var(--retiree-person-color);
          color: black;
          --checkpoint-text-color: black;
        }

        kale-card.color-coded[person-type='ineligible']{
          --card-bg: var(--ineligible-person-color);
          --card-border: var(--inelgible-person-color);
        }
        kale-card.color-coded[person-type='errors']{
          --card-bg: var(--error-person-color);
          --card-border: var(--error-person-color);
        }


        .label {
          font-size: 70%;
          font-weight: bold;
          font-variant: small-caps;
          opacity: 0.75;
          display: inline-block;
          width: 5em;
          vertical-align: middle;
          line-height: 0.9em;
          position: relative;
          bottom: 0.15em;
        }
        .value {
          font-weight: 200;

        }
        kale-card > div {
          margin-bottom: 8px;
          cursor: pointer;
        }
        h2 {
          font-family: 'Roboto Slab', sans-serif;
        }
        a {
          color: white;
          font-weight: 500;
          text-decoration: none;
          }
        a::after {
          content: '🔗';
          font-size: 50%;
          vertical-align: super;
        }
        kale-card[person-type='retiree'] a{
          color: var(--paper-blue-900);
        }
        div.card-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row; 
          margin-bottom: 0;
          padding-right: 18px;
          padding-bottom: 16px;
          width: 100%;
        }

        div.card-content {
          padding: 18px;

          transition: var(--opacity-transition), var(--height-transition);
          opacity: 1;
          }

          div.card-content:not([loaded]) {
            opacity: 0;
            height: 300px;
          }
        div.card-content > div, div.dummy-content > div{
          margin-bottom: 12px;
          font-size: 1.2em;
          cursor: text;
        }
        div.card-content[loaded] {

          }

        .primary {
          font-weight: 500;
          font-size: 20px;
          }
        .secondary {
          font-weight: 200;
          font-size: 16px;
          margin-top: 8px;
          }
        .secondary > div {
          margin-top: 8px;
          }

        div.address {
          margin-bottom: 12px;
          font-weight: 100;
          opacity: 0.6;
          }

        div.address[preferred] {
          font-weight: normal;
          opacity: 1;
          }

        div.address[bad] > div {
          text-decoration-line: line-through;
          }

        div.ico {
          font-size: 70%;
          opacity: 0.75;
          display: inline-block;
          width: 0;
          vertical-align: middle;
          line-height: 0.9em;
          position: relative;
          left: 15px;
          top: 0.9em;
          text-decoration-line: none;
          }

          .dummy-content {
            padding: 18px;
            position: absolute;
            transition: var(--opacity-transition);
          }
          .dummy-content > div {
            height: 1.3333em;
          }
          .dummy-content .dummy {
            height: 1.3em;
            background-color: var(--paper-grey-400);
            padding-right: 2em;
            width: calc(var(--random-offset1)/5)
          }
          .dummy-content .dummyvalue {
            display: inline-block;
            margin-left: 2em;
            min-width: 100px;
            width: calc(100px + var(--random-offset2));
            height: 1.2em;
            background-color: var(--paper-grey-400);
            padding-right: 2em;
          }

          .person-edit .card-content, .person-edit .card-actions, .person-edit[loaded] .dummy-content {
            opacity: 0;
            transition: var(--opacity-transition);
          }
          .person-edit[loaded] .dummy-content {
            z-index: -100;
          }
          .person-edit[loaded] .card-content, .person-edit[loaded] .card-actions, .person-edit .dummy-content {
            opacity: 1;
          }

          div.info-item {
            --checkpoint-icon-offset: 0px;
            --checkpoint-icon-size: 16px;
            --checkpoint-icon-opacity: 0.2;
            display: flex;
            align-items: center;

            cursor: pointer;
            box-sizing: border-box;
            width: --webkit-fill-available;
          }

          div.info-item:hover {
            --checkpoint-icon-offset: -5px;
            --checkpoint-icon-size: 26px;
            --checkpoint-icon-opacity: 0.9;
          }

          div.info-item > *:nth-child(2) {
            margin-left: 4px;
          }

          div.info-item-row {
            flex: 1 1 100%;
            display: inline;
            /*
            width: 100%;
            */
            cursor: pointer;
          }


        data-checkpoint {
          /*
          visibility: hidden;
          */
          flex: 1 1;
          display: inline-block;

        }

        data-checkpoint[active] {
          visibility: visible;
        }

        .info-item:hover data-checkpoint {
          visibility: visible;
        }



          div.info-item-row > * {
            white-space: nowrap;
            display: inline-block;
            margin-right: 0.3em;
            width: fit-content;
          }

          .info-item[prediction] > div.info-item-row {
          }

          .info-item-row > .info-item-pri {
            font-weight: 900;
          }

            /*border-radius: 8px;
            border-right: 2px solid var(--paper-teal-100);
            */

          .info-item:first-child {
            font-size: 140%;
          }

        .info-item[prediction] {
        }
        span.prediction { 
          cursor: pointer;
          position: relative;
          display: inline-block;
          background-color: var(--paper-teal-100);
          color: var(--paper-teal-900);
          --mdc-icon-size: 16px;
          border-radius: 100%;
          width: 24px;
          height: 24px;
          min-width: 24px;
          margin-right: 3px;
        }
        span.prediction > mwc-icon {
          position: absolute;
          top: 4px;
          left: 4px;

        }

          /*
        @media screen and (max-width: 1200px) { 
          :host {
            flex-direction: column;
            width: 100%;
            margin-bottom: 0px;
            --card-radius: 0px;
            padding: 0;
          }
          person-migration {
            order: 4;
          }
          :host > * { margin-top: 0px }
        }


        */


        .calendar {
          position: relative;
          float: right;
          margin-left: 16px;
          margin-bottom: 0px;

          font-size: 18px;
          width: 120px !important;
          text-align: center;
          border-radius: 8px;
          grid-area: cal;
          height: fit-content;
          overflow: hidden;
          background-color: white;
        }
        .calendar > .cal_month {
          transition: var(--bg-transition);
          background-color: var(--paper-teal-900);
          color: white;
          padding: 8px;
        }
        .calendar > .cal_date {
          color: var(--paper-teal-900);
          font-size: 48px;
          font-weight: 100;
          padding: 12px;
          padding-bottom: 0;
          padding-top: 0;
        }
        .calendar > .cal_year {
          font-size: 22px;
          color: var(--paper-grey-700);
          color: var(--paper-teal-900);
          font-weight: 600;
          padding-bottom: 12px;
        }

        .calendar > .cal_icon {
          position: relative;
        }

        .calendar  mwc-icon.cal_bg {
          color: red;
          opacity: 0.2;
          --mdc-icon-size: 80px;
          position: absolute;
          top: 24.5px;
          left: 20px;
        }



        .est_chart * {
          color: black;
          font-size: 9px;
          font-weight: 100;
        }
        .est_chart > table {
          width: 100%;
          border: 1px solid white;
          border-radius: 4px;
          /*background-color: var(--paper-teal-500)*/
          background-color: white;
        }
        .est_chart th, .est_chart td:first-child {
          font-weight: 900;
        }

        .est_chart th, .est_chart td {
          text-align: center;
          padding: 2px 5px;
        }
      
        
        .est_chart th:first-child, .est_chart td:first-child {
          text-align: right;
          text-transform: uppercase;
        }
        .pcts td {
          text-transform: none !important;
          font-weight: 100 !important;
        }

        .in_plan.member td {
          border-top: 0.6px solid white;
        }
        .survivor td {
          /*border-bottom: 0.6px solid white;*/
          font-weight: 100 !important;
        }
        .in_plan.survivor td {
          border-bottom: 0.6px solid white;
        }

        @keyframes blink {
          0% { opacity: 0.1; }
          50% { opacity: 0.3; }
          100% { opacity: 0.1; }
      }

        mwc-icon.pending-data-icon {
          position: relative;
          --mdc-icon-size: 16px;
          top: -1px;
          left: 0px;
          opacity: 0.3;
          animation:blink normal 1.5s infinite ease-in-out;

        }

        .value-extra {
          border: 1px solid var(--paper-grey-400);
          color: var(--paper-grey-500);
          font-size: 70%;
          font-weight: 100;
          border-radius: 10px;
          padding: 2px 4px;
          position: relative;
          top: -2px;
          margin-left: 12px;
        }

        .bad-button {
          --mdc-theme-primary: var(--checkpoint-text-color);
          padding: 0;
          display: inline-block;
          opacity: 0.8;
        }
        .bad-button-container {
          font-size: smaller;
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: center;
          margin: 8px 0px 0px 0px !important;
        }
        .btn_bad_data:before {
          content: "";
          flex: 1;
        }
`;


class PersonInfoBox extends KaleForm {
  static styles = person_infobox_style
  get form_name() { return "PersonInfoBox" }
  static get properties() {
    return {
      person: { type: Object },
      personid: { type: Number },
      timeline: { type: Object },
      projection: { type: Object },
      open_checkpoint: { type: Object },
      model: { type: Object },
      calculating: { type: Boolean },
      edit: { type: Boolean },
      ...(super.properties)
    }
  }
  updated(props) {
    if (props.has('personid')) {
      // close edit box if we've navved to a new person
      this.edit = false;
    }
  }

  constructor() {
    super();
  }

  render() {
    //let [status, status_desc] = this.timeline ? this.timeline.status : [null,null];
//${is_future ? html`<mwc-icon>trending_up</mwc-icon>`: nothing}
/*
            <div class="cal_icon">
              <mwc-icon class="cal_bg">trending_up</mwc-icon>
            </div>
            */
    const calculating = this.calculating;
    const loaded = this.personid && this.person && this.model;
    const state = this.projection ? this?.model?.future_state : this?.model?.state;
    const report = this.projection ? this?.model?.future_report : this?.model?.report;
    const target_date = (new EventDate(this.model?.endpoint)).unitize();
    const active_checkpoint = this.model?.endpoint ? (
        this.person?.data_checkpoints?.find(d => 
            d.update_id === this.person?.changes?.[0]?.id 
          && target_date.equals((new EventDate(d.target_date)).unitize())
          && (!d.projection_id || d.projection_id === this.model?.input?.project?.id)
        ) ?? {
          person_id: this.personid,
          update_id: this.person?.changes?.[0]?.id,
          target_date: (new EventDate(this.model?.endpoint)).unitize(),
          input_data: this.model?.input,
          values: {},
          projection_id: this.model?.input?.projection?.id
        }) : null;
    
      
        /*
id - uuid, primary key, unique, default: gen_random_uuid()
person_id - uuid
update_id - uuid
target_date - timestamp with time zone
input_data - jsonb
values - jsonb
projection_id - uuid, nullable

*/
    return html`
        ${this.person && !this.projection && this.person.migrations && this.person.migrations.length > 0 ?
          this.person.migrations.map(migr => html`
            <person-migration .migration=${migr} .timeline=${this.model}></person-migration> `
          ) : html``}

        <kale-card class="color-coded" ?calculating=${calculating} elevation=1 person-type=${state?.status_info?.person_type}>
          <div class="card-content"  ?loaded=${loaded}>


          ${state && state.date && this.projection ? html`
          <div class="calendar">
            <div class="cal_month">${state.date.toLocaleString('default', { month: 'long' }).toUpperCase()}</div>
            <div class="cal_date">
            ${state.date.getDate()}</div>
            <div class="cal_year">
            ${state.date.getFullYear()}
            <mwc-icon>trending_up</mwc-icon>
            </div>
          </div>
          ` : nothing}


          ${state ? 
          (!(state?.status_info?.errors) ?
            report.map(({key, val, is_future, pri, sec, fpri, fsec, est, checkpoint}) => {
              //(this?.open_checkpoint?.id === checkpoint?.id 
                //&& this.open_checkpoint?.key === key) || ;
              return html`
            <div class="info-item" ?prediction=${is_future} @click=${ e => { this.open_checkpoint = active_checkpoint; } }>
              <div class="info-item-row" >
                <span class="info-item-pri">${is_future && fpri ? fpri : pri}</span>
                ${(is_future && fsec ? fsec : sec)?.map(f => html`
                  <span class="info-item-sec">${f}</span>
                `)}
              </div>
                ${is_future ? html`
              <span class="prediction" title="Projected Estimate"><mwc-icon>trending_up</mwc-icon></span>
                ` : nothing}
                ${ !calculating && active_checkpoint && checkpoint !== false ?  html`
              <data-checkpoint 
                .value=${active_checkpoint?.values?.[key]}
                @close-checkpoint=${e => this.open_checkpoint = null} 
                ></data-checkpoint>
                ` : nothing}
                ${ calculating && checkpoint !== false ?  html`
                <mwc-icon class="pending-data-icon">pending</mwc-icon>
             ` : nothing}
            </div>
            `;
            }
            )
        : html`
            <div class="primary" style="text-transform: uppercase">Unknown state due to errors</div>
            <ul style="margin-top:16px; padding:0; list-style-type: none">${state?.status_info?.errors?.map(e => html`<li style="border: 1px solid white; border-radius: 20px; display: inline-block; padding: 8px 12px 8px 12px; margin-right: 8px; margin-bottom: 8px;">${e.msg} (${e.count})</li>`)}</div></ul>
            `)
            : nothing}

            <div class="secondary">
              ${this.person && this.person.benefactors && this.person.benefactors.length > 0 ?
        this.person.benefactors.map(b => html`
                  <div style="margin-bottom: 20px">${b.relationship && b.relationship.id != 'U' ? b.relationship.name + " and " : ""}${b.beneficiary_type.name} Beneficiary of <a href="/people/edit?person=${b.benefactor.id}">${b.benefactor.first_name} ${b.benefactor.last_name}</a>
                    <div style="display: none; font-weight: normal; font-size: small;">(relationship: ${b.relationship.name})</div>
                  </div>`)
        : html``}
              ${this.person && this.person.beneficiaries && this.person.beneficiaries.map((b) => html`
                <div>${b.beneficiary_type.name} beneficiary: <a href="/people/edit?person=${b.beneficiary.id}">${b.beneficiary.first_name} ${b.beneficiary.last_name}</a></div>
              `)}
            </div>
            ${ !calculating && active_checkpoint ?  html`
              <div class="bad-button-container">
                <mwc-button
                class="bad-button"
                @click=${e => this.open_checkpoint = active_checkpoint}
                >are these numbers correct?
              </mwc-button>
              </div>
            ` : nothing}
            <person-tags .tags=${this.person && this.person.tags} .events=${state?.tag_events}></person-tags>
          </div>
        </kale-card>

        ${ !this.projection ? html`
        <kale-card class="person-edit" ?loaded=${loaded} elevation=1 @click=${e => { if (!this.edit) this.edit = true; }}>
        <mwc-ripple></mwc-ripple>
        <div class="dummy-content">
            <div style="--random-offset1: 3em; --random-offset2: 0em;"><span class="label dummy"></span><span class="dummyvalue"></span></div>
            <div style="--random-offset1: 3em; --random-offset2: 3em;"><span class="label dummy"></span><span class="dummyvalue"></span></div>
            <div style="--random-offset1: 5em; --random-offset2: 2em;"><span class="label dummy"></span><span class="dummyvalue"></span></div>
            <div style="--random-offset1: 7em; --random-offset2: 1em;"><span class="label dummy"></span><span class="dummyvalue"></span></div>
            <div style="--random-offset1: 4em; --random-offset2: 1em;"><span class="label dummy"></span><span class="dummyvalue"></span></div>
            <div style="--random-offset1: 9em; --random-offset2: 3em;"><span class="label dummy"></span><span class="dummyvalue"></span></div>
        </div>

        ${ this.edit ? html`
              <div class="card-content" @click=${e => { if (!this.edit) e.stopPropagation() }} ?loaded=${this.person}>
                <edit-person .personid=${this.personid} .person=${this.person} @form-saved=${e => this.edit = false} ></edit-person>
              </div> ` : html`         
        <div id="bio" class="card-content person-edit" ?loaded=${loaded}>
          <div id="ssn" @click=${e => e.stopPropagation()}>
            <span class="label">ssn</span>
            <span class="value">${this.person && this.person.ssn ? format_ssn(this.person.ssn) : ''}</span>
          </div>
          <div id="dob" @click=${e => e.stopPropagation()}>
            <span class="label">dob</span>
            <span class="value">${this.person && this.person.birth_date ? (new EventDate(this.person.birth_date)).str : ''} <span class="value-extra">${state?.age?.toFixed?.(1)} y/o</span>
          </div>
          ${ this.person && this.person.deceased_date ? html`
            <div id="deceased">
              <span class="label">deceased</span>
              <span class="value">${new EventDate(this.person.deceased_date).str}</span>
            </div>` : html``}
          <div id="gender" @click=${e => e.stopPropagation()}>
            <span class="label">gender</span>
            <span class="value">${this.person && this.person.gender ? this.person.gender.name : ''}</span>
          </div>
          <div id="marital_status" @click=${e => e.stopPropagation()}>
            <span class="label">marital status</span>
            <span class="value">${this.person && this.person.marital_status ? this.person.marital_status.name : ''}</span>
          </div>
          <div id="contact" @click=${e => e.stopPropagation()}><span class="label" style="width: 100%;">participant contact information</span><br/>
            <kale-contact noaddbox  .contacts=${this.person ? [...this.person.addresses, ...this.person.phones, ...this.person.emails] : []} .personid=${this.personid}></kale-contact>
          </div >
        </div>
      `}
          <div @click=${e => e.stopPropagation()} class="card-actions" id="actions">
            ${this.edit ? html`
              <mwc-button @click=${e => { this.reset(); this.edit = false; }}>${this.dirty ? "cancel" : "close"}</mwc-button>
              <mwc-button ?disabled=${!this.dirty || !this.valid} @click="${(e) => this.save()}" > ${this.dirty && !this.valid ? "incomplete" : this.dirty || !this.personid ? (this.saving ? "saving..." : "save") : "saved"}</mwc-button>
            ` : html`
              <mwc-button ?loaded=${loaded} icon="create" @click=${e => this.edit = true}>edit</mwc-button>
              `}
          </div>
        </kale-card>
        ` : ''}
        <person-notes .personid=${this.personid} .notes=${this.person ? this.person.notes : []}></person-notes>


	<edit-checkpoint 
			@close=${e => this.open_checkpoint = null}
      .person=${{name: `${this.person?.first_name} ${this.person?.last_name}`, change: this.person?.changes?.[0]}}
			.checkpoint=${this.open_checkpoint}
			.checkpointid=${this.open_checkpoint?.id}
			.report=${report}
			.opened=${this.open_checkpoint}>
		</edit-checkpoint>
`;
  }


  save_impl(data) {
  }
}

window.customElements.define('person-infobox', PersonInfoBox);
export { PersonInfoBox }



