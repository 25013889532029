import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * Note
 */

export const person_note_fields = gql`
  fragment NotesFields on person_note {
    id
    person_id
    complete
    text
    inserted_at
   }`


export const person_note_info_query = gql`
  query person_note_info($NotesId: uuid!){
    person_note( where: {id: {_eq: $NotesId}} ){
         ...NotesFields
    }
  }
  ${person_note_fields}
`

export class NotesInfo extends StaticQuery {
  get required_impl() {
    return ['NotesId'];
  }
  get gql_impl() {
    return person_note_info_query;
  }
  data_impl({ data: { person_note: [d] } }) {
    return d;
  }
}


export const update_person_note_query = gql`
  mutation update_person_note($leaveId: uuid!, $changeMap: person_note_insert_input!) {
    update_person_note (
      where: {
          id: {_eq: $person_noteId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...NotesFields
      }
    }
  }
  ${person_note_fields}
`
export const insert_person_note_query = gql`
  mutation insert_person_note($changeMap: person_note_insert_input!) {
    insert_person_note (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...NotesFields
      }
    }
  }
  ${person_note_fields}
`

export const upsert_person_note_query = gql`
  mutation upsert_person_note($changeMap: person_note_insert_input!, $changeCols: [person_note_update_column!]!) {
    person_note:insert_person_note (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: person_note_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...NotesFields
      }
    }
  }
  ${person_note_fields}
`

export const delete_person_note_query = gql`
  mutation delete_person_note($deleteId: uuid!) {
    delete_person_note (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export const person_note_for_person_query = gql`
  query person_note_info($PersonId: uuid!) {
    person_note (
        where: {person_id: {_eq: $PersonId}}
        order_by: {inserted_at: asc}
        ){
        ...NotesFields
    }
  }
  ${person_note_fields}
  `

export class PersonNotes extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return person_note_for_person_query;
  }
  data_impl({ data: { person_note: person_note } }) {
    return person_note;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}


export class EditNotesInfo extends SaveableQuery {
  get friendly_name() { return "Note" }
  get required_keys_impl() {
    return [
      "id",
      "person_id"
    ];
  }
  data_impl({ data: { person_note: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_person_note_query;
  }
  get delete_gql_impl() {
    return delete_person_note_query;
  }
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }
}

