import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import jwt_decode from 'jwt-decode';
import PKCE from 'js-pkce';
const debug = false;
const DO_PKCE = true;
const COGNITO_DOMAIN = 'https://afscme-benefits.auth.us-east-1.amazoncognito.com';
const COGNITO_IDENTITY_PROVIDER = "AFSCME";
const COGNITO_CB_ENDPOINT = "AFSCME_SSO";

const activityTimer = (logout) => {
    const resetMove = () => {
        document.addEventListener("mousemove", activity, { passive: true });
    };

    const LOGOUT_DELAY = 30 * 60 * 1000; //30 minutes
    const MOUSE_MOVE_INTERVAL = 5000;
    let time = setTimeout(logout, LOGOUT_DELAY);
    let movetime = setTimeout(resetMove, MOUSE_MOVE_INTERVAL);
    let canceled = false;



    const activity = (e) => {
        if (canceled) return;
        clearTimeout(time);
        time = setTimeout(logout, LOGOUT_DELAY)
        document.removeEventListener("mousemove", activity);
        clearTimeout(movetime);
        movetime = setTimeout(resetMove, MOUSE_MOVE_INTERVAL);
    };

    const cancel = () => {
        canceled = true;
        clearTimeout(time);
        clearTimeout(movetime);
        ['load', 'mousedown', 'keydown', 'touchstart', 'click', 'scroll', 'keypress', 'mousemove']
            .forEach(e => document.removeEventListener(e, activity));
    };

    ['load', 'mousedown', 'keydown', 'touchstart', 'click', 'scroll', 'keypress']
        .forEach(e => document.addEventListener(e, activity, { passive: true }));

    return { cancel };
};

export class CognitoAuthMgr {
    constructor({ login, logout, pool }) {
        this.pool = pool;
        this.user_pool = new AmazonCognitoIdentity.CognitoUserPool(pool);
        this.login_cb = login;
        this.logout_cb = logout;
        this.result_cbs = [];
    }
    get state() { return this.current_login ? this.current_login.state : undefined }
    set state(state) {
        if (state !== this.state) {
            this._state = state;
            this.current_login = { ...this.current_login ? this.current_login : {}, state };
            if (this.__state_resolve) {
                const resolve = this.__state_resolve;
                this.__state_promise = undefined;
                this.__state_resolve = undefined;
                resolve(this.current_return_val);
            }
        }
    }

    get current_return_val() { return { state: this.state, login: this.current_login, error: this.current_error ? this.current_error : null }; }

    getCognitoUser(id) {
        if (!id) {
            return;
        }
        if (this.cached_user && this.cached_user.id === id) {
            return this.cached_user.cognitoUser;
        }
        const userData = { Username: id, Pool: this.user_pool };
        this.cached_user = {
            id: id,
            cognitoUser: new AmazonCognitoIdentity.CognitoUser(userData)
        }
        return this.cached_user.cognitoUser;
    }

    doLogin({id_token, access_token, refresh_token}) {
        const id = jwt_decode(id_token);
        const access = access_token ? jwt_decode(access_token) : null;

        let id_token_expires = id.exp;
        let id_token_time = id.iat;
        let timeout = id_token_expires - id_token_time;
        let email = id.email;
        let roles = id['cognito:groups'];
        let username = id['cognito:username'];
        let name = id.given_name ?? id.name ?? email?.split?.("@")?.[0];

        const login = {
            expire_secs: timeout,
            expiration: id.exp,
            id_token: id_token,
            refresh_token: refresh_token,
            user: email,
            email: email?.toLowerCase?.(),
            name: name,
            roles: roles,
        }

        this.current_login = login;
        window.localStorage.setItem("refresh_token", login.refresh_token);
        window.localStorage.setItem("current_user", login.user);
        window.localStorage.setItem("current_name", login.name);
        window.localStorage.setItem("current_roles", JSON.stringify(login.roles));
        if (this.activity_timer) this.activity_timer.cancel();
        this.activity_timer = activityTimer(() => { this.activity_timer.cancel(); this.activity_timer = undefined; this.logout() });
        this.state = "LOGGED IN";
        return login;
    }

    get pkce() {
        if (!this.__pkce) {
            this.__pkce = new PKCE({
                client_id: this.pool.ClientId,
                redirect_uri: `${window.location.origin}/${COGNITO_CB_ENDPOINT}`,
                authorization_endpoint: `${COGNITO_DOMAIN}/oauth2/authorize`,
                token_endpoint: `${COGNITO_DOMAIN}/oauth2/token`,
                requested_scopes: 'email openid profile'
            });
        }
        return this.__pkce;
    }

    async attemptAFSCMESSO() {
            let authorize = this.pkce.authorizeUrl({identity_provider: COGNITO_IDENTITY_PROVIDER});
            console.warn("ATTEMPT SSO, URL=", authorize)
            window.location.replace(authorize);
    }

    async completeSSO(location) {
        if (this.__SSO_IN_PROGRESS) {
            return await this.__SSO_IN_PROGRESS
        }
        if (!this.__SSO_IN_PROGRESS && location.pathname === `/${COGNITO_CB_ENDPOINT}`) {
          console.warn("COMPLETE SSO", location);
            let hash_params = location.hash !== '' ? Object.fromEntries(location.hash?.slice(1)?.split("&")?.map(p => p.split("="))) : {};
            let search_params = location.search !== '' ? Object.fromEntries(location.search?.slice(1)?.split("&")?.map(p => p.split("="))) : {};
            this.__SSO_IN_PROGRESS = this.__AFSCME_SSO_finish({ ...hash_params, ...search_params });
            const result = await this.__SSO_IN_PROGRESS;
            this.__SSO_IN_PROGRESS = undefined;
            return result;
        } else {
            return this.current_return_val
        }
    }

    async __AFSCME_SSO_finish(params) {
      console.log("FINISH SSO", params);
        try {
            if (params.code) { //complete PKCE code sign in
                const url = window.location.href;
                const tokens = await this.pkce.exchangeForAccessToken(url);
              console.warn("GOT SSO TOKENS", tokens);
              const id = jwt_decode(tokens.id_token);
              console.warn("ID TOKEN IS", id)
                this.doLogin(tokens);
            } else { // implicit token
                this.doLogin(params);
            }
            this.current_login.id_token = await this.refresh();
            this.state = this.token_valid ? 'LOGGED IN' : 'LOGGED OUT';
            return this.current_return_val;
        } catch (e) {
            console.error("SSO ERROR", e);
            await this.logout();
            window.location.replace('/');
        }
    }

    async logout() {
        const cognitoUser = this.getCognitoUser(this.current_login ? this.current_login.user : null);
        if (cognitoUser) cognitoUser.signOut();
        if (this.activity_timer) this.activity_timer.cancel();
        this.activity_timer = undefined;
        this.state = "LOGGED OUT";
        this.current_login = {};
        window.localStorage.getItem("refresh_token");
        window.localStorage.removeItem("current_user");
        window.localStorage.removeItem("current_name");
        window.localStorage.removeItem("current_roles");
        if (this.logout_cb) this.logout_cb();
    }

    stateChange() {
        if (this.__state_promise) {
            return this.__state_promise;
        } else {
            this.__state_promise = new Promise((resolve, reject) => {
                this.__state_resolve = resolve;
            });
            return this.__state_promise;
        }
    }


    async login(credential) {
        if (this.attempt_in_progress) {
            console.warn("AUTHMGR.login(): Attempt already in progress");
            return this.login_promise;
        }
        this.attempt_in_progress = true;
        this.current_error = undefined;

        if (this.login_cb) this.login_cb();

        this.login_promise = new Promise((resolve_login, reject_login) => {
            const success_cb = async (result, cognitoUser) => {
                this.attempt_in_progress = false;
                this.result_cbs.push(resolve_login);
                this.handleCognitoLoginSuccess(result, credential, cognitoUser);
            }
            const error_cb = async (error) => {
                console.warn("AUTH FAILURE :-(", error);
                this.attempt_in_progress = false;
                this.result_cbs.push(resolve_login);
                this.handleCognitoLoginFailed(error.message);
            }

            const mfa_cb = async (codeDeliveryDetails) => {
                return new Promise((resolve_mfa, reject_mfa) => {
                    console.warn("MFA UNTESTED", codeDeliveryDetails);
                    this.current_login = {
                        ...(this.current_login ? this.current_login : {}),
                        mfa_cb: resolve_mfa,
                        //mfa_details: codeDeliveryDetails
                    };
                    this.state = "MFA CHALLENGE";
                    this.attempt_in_progress = false;
                    resolve_login(this.current_return_val);
                });
            }

            const newpw_cb = (attributes, required) => {
                return new Promise((resolve_newpw, reject_newpw) => {
                    console.log("AUTH SET PASSWORD REQUIRED");
                    console.log("ATTRIB:", attributes);
                    console.log("REQ:", required);
                    this.current_login = {
                        ...(this.current_login ? this.current_login : {}),
                        name: attributes.name,
                        email: attributes.email?.toLowerCase?.(),
                        password_setup_cb: resolve_newpw,
                    };
                    this.state = "SET PASSWORD";
                    this.attempt_in_progress = false;
                    resolve_login(this.current_return_val);
                });
            }
            this.__aws_authorize(credential.id.toLowerCase(), credential.password, success_cb, error_cb, mfa_cb, newpw_cb);
        });
        return this.login_promise;
    }

    async refresh() {
        if (this.token_valid) {
            return this.current_login.id_token;
        }
        if (!this.current_login || !this.current_login.user || !this.current_login.refresh_token) {
            return;
        }
        if (this.attempt_in_progress) {
            if(debug) console.warn("AUTHMGR.refresh(): Attempt already in progress");
            return this.refresh_promise;
        }
        this.attempt_in_progress = true;
        this.current_error = undefined;
        this.refresh_promise = new Promise((resolve, reject) => {
            if (this.refresh_valid) {
                const cognitoUser = this.getCognitoUser(this.current_login ? this.current_login.user : null);
                let refresh_token = this.current_login && this.current_login.refresh_token ? this.current_login.refresh_token : window.localStorage.getItem("refresh_token");
                let refreshToken = { getToken: () => refresh_token };
                cognitoUser.refreshSession(refreshToken, (err, res) => {
                    if (err) {
                        this.attempt_in_progress = false;
                        this.refresh_promise = null;
                        this.handleCognitoLoginFailed(err);
                        reject(err);
                    } else if (res) {
                        this.handleCognitoLoginSuccess(res, cognitoUser);
                        this.attempt_in_progress = false;
                        this.refresh_promise = null;
                        resolve(this.id_token());
                    }
                });
            } else {
                this.refresh_promise = null;
                this.attempt_in_progress = false;
                this.handleCognitoLoginFailed("refresh token invalid/expired");
                reject("refresh token invalid");
            }
        });
        return this.refresh_promise;
    }

    async forgot_password(email) {
        if (this.attempt_in_progress) {
            console.warn("forgot_password(): Attempt already in progress", this.attempt_in_progress);
            return this.forgotten_password_promise;
        }
        this.attempt_in_progress = true;
        this.current_error = undefined;

        this.forgotten_password_promise = new Promise((resolve_forgot, reject_forgot) => {
            const success_cb = async (result, new_credential, cognitoUser) => {
                this.attempt_in_progress = false;
                console.log("FORGOT PW SUCCESS", result, new_credential);
                this.result_cbs.push(resolve_forgot);
                this.handleCognitoRecoverySuccess(new_credential, cognitoUser);
            }
            const error_cb = async (error) => {
                console.warn("FORGOT PW FAILURE :-(", error.message);
                this.attempt_in_progress = false;
                this.result_cbs.push(resolve_forgot);
                this.handleCognitoLoginFailed(error.message);
            }

            const recover_pw_cb = () => {
                return new Promise((resolve_pwrecover, reject_pwrecover) => {
                    console.log("AUTH RECOVER PW");
                    this.current_login = {};
                    this.forgotten_password_cb = resolve_pwrecover;
                    this.state = "PW RECOVER";
                    this.attempt_in_progress = false;
                    resolve_forgot(this.current_return_val);
                });
            }
            this.__aws_forgot_password(email.toLowerCase(), success_cb, error_cb, recover_pw_cb);
        });
        return this.forgotten_password_promise;
    }

    async complete_forgotten_password(code, new_password) {
        if (this.forgotten_password_cb) {
            console.log("COMPLETING PW RECOVERY", code, new_password);
            const prom = new Promise((accept, _) => { this.result_cbs.push(accept); });
            this.forgotten_password_cb({ code, new_password });
            this.forgotten_password_cb = undefined;
            return prom;
        } else {
            console.warn("AUTHMGR: no active forgotten password challenge");
            this.current_error = 'no password recovery in progress';
            return this.current_return_val;
        }
    }

    async complete_password_setup(password) {
        console.log("COMPLETING PW SETUP", password);
        if (this.current_login && this.current_login.email && this.current_login.password_setup_cb) {
            console.log("COMPLETING PW CHANGE", password, this.current_login.user, this.current_login.password_setup_cb);
            const prom = new Promise((accept, _) => { this.result_cbs.push(accept); });
            this.current_login.password_setup_cb(password);
            this.current_login.password_setup_cb = undefined;
            return prom;
        } else {
            console.warn("AUTHMGR: no active password setup challenge", this.current_login);
            this.current_error = 'no password setup needed';
            return this.current_return_val;
        }
    }
    async complete_mfa_challenge(code) {
        if (this.current_login && this.current_login.email && this.current_login.mfa_cb) {
            const prom = new Promise((accept, _) => { this.result_cbs.push(accept); });
            this.current_login.mfa_cb(code);
            this.current_login.mfa_cb = undefined;
            return prom;
        } else {
            console.warn("AUTHMGR: no active login or mfa challenge");
            this.current_error = 'strange: no multi-factor authentication needed';
            return this.current_return_val;
        }
    }

    handleCognitoLoginFailed(message) {
        console.warn("AUTHMGR: cognito login failed", message);
        this.current_error = message;
        this.state = "LOGGED OUT";
        this.logout();
        this.result_cbs.forEach(cb => cb(this.current_return_val));
        this.result_cbs = [];
    }

    handleCognitoRecoverySuccess(new_credential, cognitoUser) {
        this.current_login = {
            user: new_credential.id,
        }

        if (window.PasswordCredential) {
            const c = new PasswordCredential(new_credential);
            const res = navigator.credentials.store(c);
        }
        this.current_error = undefined;

        window.localStorage.setItem("current_user", this.current_login.user);
        if (this.activity_timer) this.activity_timer.cancel();
        this.activity_timer = null;
        this.state = "LOGGED OUT";
        this.result_cbs.forEach(cb => cb(this.current_return_val));
        this.result_cbs = [];
    }

    async rehydrate() {
        if(debug) console.log("AUTHMGR.rehydrate()", window.localStorage.getItem("current_user"));

        if (!this.current_login || !this.current_login.refresh_token) {
            this.current_login = {
                ...(this.current_login ?? {}),
                refresh_token: window.localStorage.getItem("refresh_token"),
                user: window.localStorage.getItem("current_user"),
                name: window.localStorage.getItem("current_name"),
                roles: JSON.parse(window.localStorage.getItem("current_roles"))
            };
        }

        if (!this.current_login || !this.current_login.user) {
            this.state = "LOGGED OUT";
            return this.current_return_val;
        }
        // attempt to get a new session using a saved refresh_token
        if (this.current_login.refresh_token  
            /* don't bother if we have a good id_token: */
            && (!this.current_login.expiration || this.current_login.expiration < (new Date() / 1000))) {
            this.current_login.id_token = await this.refresh();
        }
        this.state = this.token_valid ? 'LOGGED IN' : 'LOGGED OUT';
        return this.current_return_val;
    }

    handleCognitoLoginSuccess(result, credential, cognitoUser) {
        let id_token = result.getIdToken().getJwtToken();
        let refresh_token = result.getRefreshToken().getToken();
        const { email, name } = this.doLogin({id_token, refresh_token});

        if (window.PasswordCredential && credential && credential.password) {
            const c = new PasswordCredential({ id: email, name: name, password: credential.password });
            const res = navigator.credentials.store(c);
        }
        this.current_error = undefined;
        this.result_cbs.forEach(cb => cb(this.current_return_val));
        this.result_cbs = [];
    }

    id_token() { return this.current_login ? this.current_login.id_token : null }

    async getSavedCredentials() {
        if (navigator.credentials) {
            const credentials = await navigator.credentials.get({
                password: true,
                mediation: 'required'
            });
            if (credentials && credentials.id) return credentials;
        }
        const user = window.localStorage.getItem('current_user');
        if (user) {
            return { id: user }
        }
        return undefined;
    }

    async __aws_forgot_password(email, success, failure, get_new_password) {
        const cognitoUser = this.getCognitoUser(email);
        let have_code = null;
        let credential = { id: email };
        const args = {
            onSuccess: async (result) => {
                success(result, credential, cognitoUser);
            },
            onFailure: async (err) => {
                failure(err);
            },
            inputVerificationCode: async () => {
                let { code, new_password } = await get_new_password();
                have_code = code;
                credential.password = new_password;
                cognitoUser.confirmPassword(code, new_password, args);
            }
        }
        cognitoUser.forgotPassword(args);
    }

    async __aws_authorize(email, pass, successfunc, failure, mfa, newpassword) {
        const cognitoUser = this.getCognitoUser(email);

        let authenticationData = { Username: email, Password: pass };
        let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
        let args = {
            onSuccess: async (result) => {
                successfunc(result, cognitoUser);
            },
            onFailure: async err => {
                await failure(err);
            },
            mfaRequired: async (codeDeliveryDetails) => {
                let code = await mfa(codeDeliveryDetails);
                this.current_login.mfa_cb = undefined;
                cognitoUser.sendMFACode(mfaCode, args);
            },
            newPasswordRequired: async (userAttributes, requiredAttributes) => {
                let pw = await newpassword(userAttributes, requiredAttributes);
                this.current_login.password_cb = undefined;
                cognitoUser.completeNewPasswordChallenge(pw, {}, args);
            }
        }
        cognitoUser.authenticateUser(authenticationDetails, args);
    }


    get refresh_valid() {
        let refresh_token = this.current_login && this.current_login.refresh_token ? this.current_login.refresh_token : window.localStorage.getItem("refresh_token");
        return refresh_token && refresh_token !== 0 && refresh_token !== null && refresh_token !== undefined && refresh_token !== '';
    }

    get token_valid() {
        if (this.current_login && this.current_login.id_token) {
            if (!this.cached_exp) {
                const decodedToken = jwt_decode(this.current_login.id_token);
                this.decoded = decodedToken;
                if (!decodedToken) {
                    return false;
                }
                this.cached_exp = decodedToken.exp * 1000;
            }

            const valid = (new Date()).getTime() < this.cached_exp;
            if (!valid) this.cached_exp = undefined;
            return valid;
        }
        return false;
    }
}
