import { LitElement, html, css } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { Loader } from '@googlemaps/js-api-loader';
import '@material/mwc-button';
import '@material/mwc-switch';
import '@material/mwc-select';
import '@material/mwc-dialog';
import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-textfield';
import '@material/mwc-formfield';

//import ContactParser from  'contact-parser';

import { client, EditAddressInfo, enum_spec, EditEmailInfo, EditPhoneInfo } from '../queries/queries.js';
import { debounce } from './utilities/debounce.js';

// kick off Google Map API scripts
const _loader = new Loader({
  apiKey:"AIzaSyBq-vwfQX6krInhYunIgiExYP2FSNxzaPg", 
  version: "weekly",
  libraries: ["places"],
}).load();

// Wrapper around form controls - base class
class KaleComponent extends LitElement {
  constructor() {
    super();
    this._value = null;
    this._orig = null;
    this.elem = null;
    this.default = null;
    this._send_events = true;
    this.required = false;
  }

  firstUpdated() {
    this.elem = this.renderRoot.getElementById("input_elem");
    if (this.default) {
      this.setToDefault();
    }
    if (this.required) {
      this.dispatchEvent(new CustomEvent('component-dirty',
        {
          bubbles: true,
          composed: true,
          detail:
          {
            elem: this,
            dirty: this.dirty,
            valid: this.valid,
            field: this.field,
            value: this.value ?? this.default,
            source: 'firstupdate'
          }
        }));
    }
  }

  get valid() {
    return true;
  }

  reset() {
    //console.log("RESET ON COMPONENT", this.elem);
    //et elem = this.renderRoot.getElementById("input_elem")
    if (this.elem) {
      this.elem.setAttribute("value", this._orig);
      this.elem.value = this._orig;
      //console.log(`${this.field} reset to ${this._orig}`);
    }
    if (this.required) {
      this.dispatchEvent(new CustomEvent('component-dirty',
        {
          bubbles: true,
          composed: true,
          detail: {
            elem: this,
            dirty: this.dirty,
            valid: this.valid,
            field: this.field,
            value: this.value,
            source: 'reset'
          }
        }));
    }

  }

  setToDefault() {
    if (this.debug) {
      console.warn("DEFAULT ON COMPONENT", this.elem, this.default);
    }
    if (this.elem && this.default !== undefined) {
      this.elem.setAttribute("value", this.default);
      this.elem.value = this.default;
      this.__orig = this.default;
      this.valueChange(this.default);
      if (this.debug) {
        console.warn(`${this.field} defaulted to ${this.default}`);
      }
    }
    if (this.required) {
      if (this.debug) {
        console.warn(`dispatching dirty event ${JSON.stringify({dirty: this.dirty, valid: this.valid, field: this.field, value: this.value }, null, 2)}`);
      }
      this.dispatchEvent(new CustomEvent('component-dirty',
        {
          bubbles: true,
          composed: true,
          detail: {
            elem: this,
            dirty: this.dirty,
            valid: this.valid,
            field: this.field,
            value: this.value,
            is_default: true,
            source: 'setdefault'
          }
        }));
    }
  }

  transformInput(val) { return val }

  // event handler for input events from the embedded form component
  valueChange(val) {
    //console.warn(`${this.className}(${this.field}).valueChange() ${this._value}/${this.value} => ${val} send=${this._send_events} (orig=${this._orig})`);
    val = val === 'actual_null_request' || val === undefined ? null : val;
    //val = this.transformInput(val);
    // if (tval !== val) { this.value = tval; console.log("trying to set input!")}
    // val = tval;
    let changed = val !== this._value;
    let old = this._value;
    let old_validity = this.valid;
    let new_validity = old_validity;
    this.dirty = val !== this._orig;
    this._value = val;

    if (changed) {
      new_validity = this.valid;
      if (old_validity != new_validity) {
        // this.requestUpdate("valid", old_validity);
      }
    }

    if (changed && this._send_events) {
      //console.warn(`${this.className}(${this.field}) dispatching component-dirty ${old} => ${val} (${changed ? 'changed' : 'unchanged'})  (orig=${this._orig}) (dirty=${this.dirty})`);
      this.dispatchEvent(new CustomEvent('component-dirty',
        {
          bubbles: true,
          composed: true,
          detail: {
            elem: this,
            dirty: this.dirty,
            valid: new_validity,
            field: this.field,
            value: this.value,
            source: 'change-value'
          }
        }));
    }
  }

  // setter when value is set/changed by host
  set value(val) {
    //console.warn(this.title, `${this.className}(${this.field}).set value() ${this._value} => ${val} send=${this._send_events} (orig=${this._orig}, def=${this.default}, dirty=${this.dirty})`);
    val = val === undefined ? null : val;
    val = this.transformInput(val);
    let oldVal = this._orig;
    if (val !== oldVal) { //&& (this._value === undefined || this._value === null)) {
      val = val === 'actual_null_request' ? null : val;
      this._value = val;
      this._orig = val;
      this._send_events = false;
      this.updateComplete.then(() => {
        this._send_events = true;
        /*
        console.warn(`updateComplete after ${this.className}(${this.field}).set value() ${this._value} => ${val}   send=${this._send_events} (orig=${this._orig}) (valid=${this.valid})`);
        if (!this.valid) {
          console.log("dispatching a dirty event to cover invalid setting");
          this.dispatchEvent(new CustomEvent('component-dirty', {bubbles: true, composed: true, detail: {elem: this, dirty: this.dirty, valid: this.valid, field: this.field, value: this.value}}));
        }*/
      });
      this.requestUpdate('value', oldVal);
      if (this.required) {
        this.dispatchEvent(new CustomEvent('component-dirty',
          {
            bubbles: true,
            composed: true,
            detail: {
              elem: this,
              dirty: this.dirty,
              valid: this.valid,
              field: this.field,
              value: this.value,
              source: 'set-value'
            }
          }));
      }
    } else {
      this._orig = val;
    }
  }
  get value() {
    return this._value;
  }

  static get properties() {
    return {
      label: { type: String },
      type: { type: String },
      //_value: {type: Object},
      dirty: { type: Boolean },
      required: { type: Boolean, reflect: true },
      debug: {type: Boolean}
    };
  }
}


const TextFieldStyles = css`
  mwc-textfield[wide] {
    width: 100%;
  }
`
class KaleTextField extends KaleComponent {
  static styles = TextFieldStyles;

  render() {
    return html`
      <mwc-textfield
        id="input_elem"
        label=${this.label}
        ?required=${this.required}
        placeholder=${this.fullwidth ? this.label : ''}
        value=${this.value !== null && this.value !== undefined ? this.value : ''}
        ?fullwidth=${this.fullwidth}
        ?wide=${this.wide}
        @input=${(e) => this.valueChange(e.target.value, e)}
        ?outlined=${this.outlined}
        type=${this.type}
        ?disabled=${this.disabled}
        style=${this.hundred ? 'width: 100%;' : ''}
      ></mwc-textfield>`
  }
  static get properties() {
    return {
      fullwidth: { type: Boolean },
      wide: { type: Boolean },
      outlined: { type: Boolean },
      hundred: { type: Boolean },
      disabled: { type: Boolean }, 
      ...(super.properties)
    }
  }
  get valid() {
    //console.log("TF VALID?", this.elem, !this.elem, this.elem.reportValidity());
    return !this.elem || !this.required || this.value; //this.elem.reportValidity();
  }
}

class KaleEmail extends KaleComponent {

  render() {
    return html`
            <mwc-textfield
            id="input_elem"
            label=${this.label} 
            type="email"
            icon="email"
            autocomplete="username"
            ?required=${this.required}
            placeholder=${this.fullwidth ? this.label : ''} 
            value=${this.value !== null && this.value !== undefined ? this.value : ''}
            ?fullwidth=${this.fullwidth}
            @input=${(e) => this.valueChange(e.target.value, e)}
          ></mwc-textfield>`

  }
  static get properties() {
    return {
      fullwidth: { type: Boolean },
      ...(super.properties)
    }
  }
  get valid() {
    return !this.elem || this.elem.reportValidity();
  }
}

class KalePassword extends KaleComponent {

  render() {
    return html`
            <mwc-textfield
            id="input_elem"
            label=${this.label} 
            type="password"
            autocomplete="current-password"
            ?required=${this.required}
            placeholder=${this.fullwidth ? this.label : ''} 
            value=${this.value !== null && this.value !== undefined ? this.value : ''}
            ?fullwidth=${this.fullwidth}
            @input=${(e) => this.valueChange(e.target.value, e)}
          ></mwc-textfield>`

  }
  static get properties() {
    return {
      fullwidth: { type: Boolean },
      ...(super.properties)
    }
  }
  get valid() {
    return !this.elem || this.elem.reportValidity();
  }
}




class KaleSSN extends KaleComponent {

  render() {
    /// .helperText=${"helper text"}
    //?errors=${!this.valid}
    //.invalid = ${!this.valid}
    //.helperText = ${this.valid ? '' : 'invalid SSN'}
    ///@value-changed=${(e) => this.valueChange(e.detail.value)}
    console.log("render", this.display_value);
    return html`
    <style>
      mwc-textfield[errors] {
        --mdc-theme-primary: red;
      }
      </style>
            <mwc-textfield
            style="min-width: 124px"
            id="input_elem"
            label=${this.label} 
            placeholder=${this.fullwidth ? this.label : ''} 
            ?required=${this.required}
            .pattern=${"^\\d{3}\-?\\d{2}\-?\\d{4}$"}
            ?fullwidth=${this.fullwidth}
            .filter=${this.transformInput}
            @input=${(e) => this.doInput(e.target, e)}
            value=${this.value ? this.value : ""}
          ></mwc-textfield>
          `

  }
  /*
  @keydown=${e => this.doMask(e)}
  @keypress=${e => this.doMask(e)}
  @keyup=${e => this.doMask(e)}

  */
  //@focus=${e => this.doMask(e)}
  //@blur=${e => this.doMask(e)}

  //value=${this.display_value}
  async doMask(e) {
    let evt = new KeyboardEvent(e.type, e);
    let hidden = this.renderRoot.getElementById('hidden_elem');

    console.log(e, this.value, hidden);

    hidden.dispatchEvent(evt);
    console.log(evt, hidden.value);
    //let digits = e.target.value.replace(/[^\d]/g, '').slice(0, 9);
    this.display_value = this.maskInput(e.target.value);
    e.target.value = this.display_value;
    //this.requestUpdate("display_value");
    //await this.updateComplete;
    let cursor = this.display_value.indexOf(' ');
    cursor = cursor >= 0 ? cursor : 11;
    console.log("cursor:", cursor, `"${this.display_value}"`);
    e.target.setSelectionRange(cursor, cursor);
  }
  doInput(elem, e) {
    let digits = elem.value.replace(/[^\d]/g, '').slice(0, 9);
    this.valueChange(digits);
  }
  //this.valueChange(e.target.value.replace(/[^\d]/g, ''))}
  valueChange(val, e) {
    val = typeof (val) === 'string' && val.trim() === '' ? null : val;
    this.display_value = this.maskInput(val);
    super.valueChange(val, e);
  }

  maskInput(val) {
    if (typeof val !== 'string') {
      val = "";
    }
    let digits = val.replace(/[^\d]/g, '').slice(0, 9);
    let padded = digits + " ".repeat(9 - digits.length);
    return padded.replace(/([\d ]{3})([\d ]{2})([\d ]{4})/, '$1-$2-$3');
  }

  transformInput(val) {
    //const pat = /\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?.*/g;
    //const pat = /\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?\D*(\d)?.*/g;
    const digits_only = /\D/g
    if (val === null || val === undefined || !typeof (val) === 'string') { return val }
    let digits = val.replace(digits_only, ''); //.slice(0,9);  // first 9 digits only
    let out = digits;
    if (digits.length > 5) {
      out = digits.slice(0, 3) + '-' + digits.slice(3, 5) + '-' + digits.slice(5);
    } else if (digits.length > 3) {
      out = digits.slice(0, 3) + '-' + digits.slice(3);
    }
    /*
    let out = val.replace(pat, (...args) => {
        console.log("replace args=", args);
        let digits = args.slice(1, args.length-2);
        console.log("digits=", digits);
        return digits.map((d, i) => i === 2 || i === 4 ? d+'-' : d).join('');
        //return `blah`;//${g1}${g2}${g3}-${g4}${g5}-${g6}${g7}${g8}${g9}`;
    });*/
    //console.log("transforming", `${val} => ${digits} => ${out}`);
    return out;
  }

  get regex() {
    //let strict = /^(?!\b(\d)1+\-?(\d)1+\-?(\d)1+\b)(?!123\-?45\-?6789|219\-?09\-?9999|078\-?05\-?1120)(?!666|000|9\d{2})\d{3}\-?(?!00)\d{2}\-?(?!0{4})\d{4}$/; // TODO: needs translation from python format
    let nonstrict = /^\d{3}\-?\d{2}\-?\d{4}$/;
    return nonstrict;
  }

  get valid() {
    return !this.elem || this.elem.reportValidity();
  }

  constructor() {
    super();
    this.display_value = "";
  }

  static get properties() {
    return {
      //display_value: { type: String },
      fullwidth: { type: Boolean },
      ...(super.properties)
    }
  }
}




class KaleDate extends KaleComponent {
  render() {
    /*     return html`
        <mwc-textfield @click=${e => this.opened = true} value=${this.value ? this.value : ''}></mwc-textfield>
        ${this.opened ? html`<app-datepicker id="input_elem" value=${this.value ? this.value : ''}></app-datepicker>` : html``}`; */
    return html`
    <mwc-textfield
      id="input_elem"
      ?required=${this.required}
      label=${this.label} 
      value=${this.value ? this.value : ''}
      @input=${(e) => this.valueChange(e.target.value !== '' ? e.target.value : null, e)}
      type="date"
      ?outlined=${this.outlined}
      ></mwc-textfield>`

  }
  get valid() {
    //console.log("checking date validity", this.field, this.value, !this.elem, this.elem ? this.elem.reportValidity() : 'no elem', "returning: ", !this.elem || this.elem.reportValidity(), this.elem ? this.elem.reportValidity() : 'nope');
    // FIXME: date validity
    return this.elem !== undefined && this.elem !== null && (this.required ? this.value !== null : true); // && this.elem.reportValidity();
  }
  static get properties() {
    return {
      opened: { type: Boolean },
      outlined: { type: Boolean },
      ...(super.properties)
    }
  }
}


class KaleToggle extends KaleComponent {
  toggle(e) {
    let val = this.renderRoot.getElementById('input_elem').checked;
    this.valueChange(val, e);
  }
  render() {
    return html`
      <style>
        :host {
          margin-top: 22px;
        }
        </style>
      <mwc-formfield label=${`${this.label}${this.required ? '*' : ''}`}>
            <mwc-switch
              id="input_elem"
              ?checked=${this.value}
              @checked-changed=${(e) => this.toggle(e)}
              ></mwc-switch>
          </mwc-formfield>
        `
  }
}

const kale_enum_style = css`
  mwc-select {
    position: fixed;
  }
  mwc-select[open] {
    z-index: 20;
  }

  #container {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
  }
`
class KaleEnum extends KaleComponent {
  static styles = kale_enum_style
  constructor() {
    super();
    this.width = 200;
    this.height = 56;
    this._ignore_selections = true; // ignore selections until we get focus the first time
  }
  render() {
    //this.width = this.opened ? this.width : (this.elem ? this.elem.scrollWidth : 200);
    return html`
    <div id="container" style=${`width: ${this.width}px; height: ${this.height}px`} >
     <mwc-select @focus=${this._enable} naturalWidth id="input_elem" ?open=${this.__open} @opened=${e => this.__open = true} @closed=${e => this.__open = false} ?required=${this.required} label=${this.label} @selected=${this.select}>
      ${ this.__enum_values ? html`${repeat(this.__enum_values.filter(v => !v.deprecated), (v) => v.code, (v, index) => html`
        <mwc-list-item ?selected=${v.code === this.value} value=${v.code}>${v.name}</mwc-list-item>
        `)}
        ${(this?.nullable || enum_spec[this.table ? this.table : this.field]?.nullable) ? html`<mwc-list-item ?selected=${'actual_null_request' === this.value} value='actual_null_request'><span style='font-weight: 100; opacity: 0.8; font-style: italic;'>no selection</span></mwc-list-item>` : ''}
        ` : html`
        <mwc-list-item>Loading..</mwc-list-item>
        `}
          </mwc-select>
          </div>
          `
  }

  _enable() {
    this._ignore_selections = false;
  }

  select(e) {
    if (!this._ignore_selections) {
      this.valueChange(this.elem.value, e);
    } 
  }

  static get properties() {
    return {
      __open: { type: Boolean },
      __enum_values: { type: Array },
      __manual_values: { type: Array },
      table: { type: String },
      ...(super.properties)
    }
  }
  get valid() {
    return !this.elem || !this.required || this.value;
  }

  set table(f) {
    let old_val = this._table ? this._table : this._field;
    this._table = f;
    let new_val = this._table ? this._table : this._field;
    if (new_val !== old_val) this.updateEnumSpec();
  }

  get table() { return this._table; }

  set values(v) {
    console.log("GOT MANUAL VALUES", v);
    this.__manual_values = v;
    this.__enum_values = this.__manual_values.map(v => ({ code: v, name: v }));
    console.log("assigned", this.__enum_values);
    this.requestUpdate('field');
  }

  set field(f) {
    let old_val = this._field;
    this._field = f;
    let new_val = this._table ? this._table : this._field;
    if (new_val !== old_val) this.updateEnumSpec();
  }

  get field() { return this._field; }

  update_filters() { }


  updateEnumSpec() {
    if (this.__manual_values) {  // static enum
      console.log("skipping dynamic enum fetch");
      this.__enum_values = this.__manual_values.map(v => ({code: v, name: v}));
      console.log("assigned", this.__enum_values);
      return;

    } 

    //console.warn("UPDATE ENUM SPEC", this.__enum_values);
    if (!enum_spec[this.table ? this.table : this.field]) {
      console.error("no enum spec for", this, this.table ? this.table : this.field, this.field, this.value);
      return;
    }
    this._send_events = false;
    if (!this._updating_spec) {
      this._updating_spec = true;
      client.query({ query: enum_spec[this.table ? this.table : this.field].query })
        .then(
          data => {
            this.__enum_values = data.data.values.filter(d => d.code !== null);
            this.__enum_values.sort((a, b) => { if (a.name < b.name) return -1; if (a.name > b.name) return 1; return 0; });
            this.elem.value = this._value;
            this.update_filters();
            this.updateComplete.then(() => { this._send_events = true; this._updating_spec = false; });
          },
          async error => {
            console.warn("failed to load values for", this.table ? this.table : this.field); this._updating_spec = false;
            const new_token = await window.attemptReauthorize();
            console.warn("logged in again, attempting to get Spec again");
            this._updating_spec = false;
            this.updateEnumSpec();
          }
        );
    }
  }

}
const kale_filtered_enum_style = css`
    :host {
    }
    .overlay {
      position: absolute;
      left: 16px;
      top: 22px;
      z-index: 21;
      width: fit-content;
      height: 24px;
      display: none;

      font-family: Roboto, sans-serif;
      -webkit-font-smoothing: antialiased;
      font-size: 1rem;
      line-height: 1.75rem;
      font-weight: 400;
      letter-spacing: 0.009375em;
      text-transform: inherit;
    }
    #value_hider_elem {
      background-color: var(--mdc-select-fill-color, rgb(245, 245, 245));
      color: var(--mdc-select-fill-color, rgb(245, 245, 245));
      opacity: 0;
    }
    #value_hider_elem[active] {
      opacity: 1;
    }
    #filter_elem[open], #value_hider_elem[open] {
      display: block;
    }

    #filter_elem:active, #filter_elem:focus {
      border:none;
      outline:none;

    }
    [contenteditable="true"]:active, [contenteditable="true"]:focus {
      border:none;
      outline:none;
    }

      mwc-list-item[filtered] {
        display: none;
      }
    mwc-select {
      position: fixed;
    }
    mwc-select[open] {
      z-index: 20;
    }

    #container {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
    }
`
class KaleFilteredEnum extends KaleEnum {
  static styles = kale_filtered_enum_style
  static get properties() {
    return {
      ...(super.properties)
    }
  }

  clearFilter() {
    const elem = this.renderRoot.getElementById("filter_elem")
    elem.innerHTML = '';
    this.filterChange(null);
  }
  async focusFilter() {
    const elem = this.renderRoot.getElementById("filter_elem")
    await this.updateComplete;
    console.log("FOCUSING FILTER");
    elem.focus();
  }

  filterChange(f) {
    if (this.filter !== f) {
      this.filter = f;
      this.filter_terms = this.filter && this.filter !== '' ? this.filter.toLowerCase().split(' ') : null;
      this.requestUpdate('__enum_values');
      this.requestUpdate('filter_terms');
    }
  }

  includeItem(v) {
    let inc = (v.code === this.value || !this.filter_terms || this.filter_terms.every(f => (`${v.code} ${v.name}`).toLowerCase().includes(f)));
    return inc;
  }
  render() {

    return html`
    <div id="container"  style=${`width: ${this.width}px; height: ${this.height}px`} >
      <mwc-select naturalWidth id="input_elem" @focus=${this._enable} ?open=${this.__open} ?required=${this.required} label=${this.label} @selected=${this.select} @opened=${e => { this.__open = true; this.focusFilter() }} @closed=${e => { this.__open = false; this.clearFilter(); }}>
      ${ this.__enum_values ? html`${repeat(this.__enum_values, (v) => v.code, (v, index) => html`
        <mwc-list-item ?selected=${v.code === this.value} ?filtered=${!this.includeItem(v)} value=${v.code}>${v.name}</mwc-list-item>
        `)}
        ${(this.nullable || enum_spec[this.table ? this.table : this.field].nullable) ? html`<mwc-list-item ?selected=${this.value === null || 'actual_null_request' === this.value} value='actual_null_request'>${this.value === 'actual_null_request' ? '' : html`<span style='font-weight: 100; opacity: 0.8; font-style: italic;'>no selection</span>`}</mwc-list-item>` : ''}
        ` : html`
        <mwc-list-item disabled>Loading..</mwc-list-item>
        `} </mwc-select>
        <div class="overlay" id="value_hider_elem" ?open=${this.__open} ?active=${this.value && this.filter && this.filter !== ''}>${this.__enum_values && this.value ? this.__enum_values.find(v => v.code === this.value).name : ''}</div>
        <div class="overlay" id="filter_elem" ?open=${this.__open} contenteditable @input=${e => this.filterChange(e.target.innerHTML)}></div>
    </div>
          `
  }
}




/*
class KaleEnumOld extends KaleComponent {
  constructor() {
    super();
    this._val = null;
    this._send_events = false;
    this._idstrings = new Map();
  }
  static get properties() {
    return {
      __enum_values: { type: Array },
      table: { type: String },
      nullable: { type: Boolean },
      ...(super.properties)
    }
  }
  set actualvalue(v) {
    this._actualvalue = v;
    this.requestUpdate('actualvalue');
  }
  get actualvalue() {
    return this._actualvalue;
  }

  reset() {

    //if (this.actualvalue) console.warn("RESETTING");
    //et elem = this.renderRoot.getElementById("input_elem")
    if (this.elem) {
      //console.log(`${this.label}: resetting to ${this._orig}`);
      this.elem.setAttribute("value", this.getIdString(this._orig));
      this.elem.value = this.getIdString(this._orig);
      //console.log(`${this.field} reset to ${this._orig}`);
    }
  }
  setToDefault() {
    //if (this.actualvalue) console.warn("DEFAULTING");
    if (this.elem && this.default !== undefined) {
      this.elem.setAttribute("value", this.getIdString(this.default));
      this.elem.value = this.getIdString(this.default);
      this.__orig = this.default;
      this.valueChange(this.default);
      //console.log(`${this.field} defaulted to ${this.default}=${this.getIdString(this.default)} ${this.elem.value}`);
    }
  }
  updateEnumSpec() {
    if (!enum_spec[this.table ? this.table : this.field]) {
      console.error("no enum spec for", this, this.table ? this.table : this.field, this.field, this.value);
    }
    this._send_events = false;
    if (!this._updating_spec) {
      this._updating_spec = true;
      //console.warn("fetching spec for ", this.field, enum_spec[this.table ? this.table : this.field])
      client.query({ query: enum_spec[this.table ? this.table : this.field].query })
        .then(
          data => {
            //console.log(`got some data for ${this.field}: `, data.data.values);
            this.__enum_values = data.data.values.filter(d => d.code !== null);
            this.__enum_values.sort((a, b) => { if (a.name < b.name) return -1; if (a.name > b.name) return 1; return 0; });
            if (this.nullable || enum_spec[this.table ? this.table : this.field].nullable) this.__enum_values.push({ code: "actual_null_request", name: "" });
            this.__enum_map = new Map(this.__enum_values.map(v => [this.getIdString(v.code), v.code]));
            this.elem.value = this.getIdString(this._value);
            this.updateComplete.then(() => { this._send_events = true; this._updating_spec = false; });
          },
          error => { console.error("failed to load values for", this.table ? this.table : this.field); this._updating_spec = false }
        );
    }
  }

   firstUpdated() {
    super.firstUpdated();
    //console.log(`initializing KaleEnum ${this.field}=${this.value}`, this.elem);
    //console.log("fetching the old enum spec for", this.table ? this.table : this.field );

  }
  //FIXME: all kinds of id mangling and weirdness with paper-dropdown, switch to mwc- version ASAP
  selection(e) {
    if (!this.__enum_values || e.detail.value === 'code_undefined') return;
    let val = (e.detail.value === '' ? null : this.__enum_map.get(e.detail.value));
    this.valueChange(val);
  }

  getIdString(code) {
    if (!this._idstrings.has(code)) this._idstrings.set(code, `code_${code}`);
    return this._idstrings.get(code);
  }

  render() {
    //if (this.actualvalue) console.log(`rendering enum ${this.field}: value=${this.getIdString(this._value)}(${this._val}: ${this.actualvalue}) codes=[${this.__enum_values ? this.__enum_values.map(v => `${v.code}: ${this.getIdString(v.code)}`).join(",") : "empty"}]`)
      <paper-dropdown 
             style="position: relative; top: 5px;"
            id="input_elem"
            label=${`${this.label}${this.required ? '*' : ''}`}
            .value=${this.getIdString(this.value)}
            @value-changed=${(e) => this.selection(e)}
            no-animations>
            ${
      this.__enum_values ?
        html`${repeat(this.__enum_values.filter(v => !v.deprecated), (v) => v.code, (v, index) => html`<paper-item value=${this.getIdString(v.code)}>${v.name}</paper-item>`)}`
        :
        html`<paper-item>Loading..</paper-item>`
      }
          </paper-dropdown>

    return html`

         `
  }

}
*/

class KaleForm extends LitElement {
  constructor() {
    super();
    this.dirty_map = new Map();
    this.field_map = new Map();
    this.invalid_map = new Map();
    this.dirty = false;
    this.valid = true;
  }

  installEventHandlers() {
    this.renderRoot.addEventListener('component-dirty', (e) => { e.stopPropagation(); this.handleComponentDirty(e.detail) });
    this.renderRoot.addEventListener('form-dirty', (e) => { e.stopPropagation(); this.handleFormDirty(e.detail) });
    this.renderRoot.addEventListener('form-saved', (e) => { e.stopPropagation(); this.handleFormSaved(e.detail) });
  }
  firstUpdated() {
    this.installEventHandlers();
  }
  get form_name() { return "KaleForm[Base]" }

  triggerUpdate(field) {
    return false;
  }

  dispatchDirty(dirty, valid, data) {
    this.dirty = dirty;
    this.valid = valid;
    //console.log(`KaleForm.dispatchDirty ${dirty}`, {form: this, name: this.form_name, dirty: this.dirty, save: () => this.save()});
    this.dispatchEvent(new CustomEvent('form-dirty', { bubbles: true, composed: true, detail: { form: this, name: this.form_name, dirty: this.dirty, valid: this.valid, canSave: (parent_data) => this.canSave(parent_data), save: (parent_data) => this.save(parent_data) } }));
  }

  handleComponentDirty({ elem, dirty, valid, field, value, is_default, source }) {
    if (this.debug) {
      console.warn(`${this.form_name}.handleComponentDirty(${field}) => ${value} [${dirty ? 'dirty' : 'clean'}] [${valid ? 'valid' : 'not valid'}] ${source}`);
    }
    this.field_map.set(field, { elem, dirty, valid, field, value });

    if (is_default) {
      this.defaults = {...(this.defaults ?? {}), [field]: value};
    }

    if (!dirty) {
      if(this.debug) {
        console.warn("removing from dirty map", field, value)
      }
      this.dirty_map.delete(field);
    } else {
      if(this.debug) {
        console.warn("adding to dirty map", field, value)
      }
      this.dirty_map.set(field, { elem, dirty: dirty || is_default, field, value });
    }
    if (!valid) {
      this.invalid_map.set(field, { elem: elem, dirty: dirty, valid: valid, field: field, value: value });
    } else {
      this.invalid_map.delete(field);
    }

    if (this.debug) {
      console.warn("FIELDS", this.field_map);
      console.warn("COMPS", this.form_children?.sub_components)
      console.warn("DIRTYMAP", this.dirty_map)
    }
    this.dispatchDirty(this.dirty_map.size > 0, this.invalid_map.size === 0, this.dirty_map);
    if (this.triggerUpdate(field)) { this.requestUpdate() }
  }

  handleFormDirty({ form, name, dirty, valid, save, canSave }) {
    //console.log(`${this.form_name}.handleFormDirty(${name}) => [${dirty ? 'dirty' : 'clean'}] [${valid ? 'valid' : 'invalid'}]`);
    this.field_map.set(form, { form: form, dirty: dirty });
    if (!dirty) {
      this.dirty_map.delete(form);
    } else {
      this.dirty_map.set(form, { form: form, name: name, dirty: dirty, valid: valid, save: save, canSave: canSave });
    }
    if (!valid) {
      //console.log("adding form to invalid map", form, name);
      this.invalid_map.set(form, { form: form, name: name, dirty: dirty, valid: valid });
    } else {
      this.invalid_map.delete(form);
    }
    this.dispatchDirty(this.dirty_map.size > 0, this.invalid_map.size === 0, this.dirty_map);
  }

  handleFormSaved({ form, name }) {
    this.dirty_map.delete(form);
    this.invalid_map.delete(form); // just in case
    //FIXME: should we be dispatching a dirty event here?
  }
  processParentData(pdata) {
    ['personid', 'person_id']
      .filter(f => pdata[f])
      .filter(f => this.hasOwnProperty(f))
      .forEach(f => { this[f] = pdata[f] })
  }


  get form_children() {
    let component_data = {};
    let sub_forms = [];
    this.dirty_map.forEach((val, key) => {
      if (val.form !== undefined) {
        sub_forms.push(val);
      } else if (val.elem !== undefined) {
        component_data[val.field] = val.value;
      }
    });
    return { sub_forms: sub_forms, sub_components: component_data };
  }

  canSave(parent_data) {
    if (parent_data) {
      console.warn(`${this.constructor.name}.canSave(): got some pdata`, parent_data.personid);
      this.processParentData(parent_data);
    }

    return this.can_save_impl(this.form_children.sub_components); //FIXME: does this need to check sub forms?
  }

  can_save_impl() {
    return true;
  }

  async save(parent_data) {
    if (parent_data) {
      console.warn(`${this.constructor.name}.save(): got some pdata`, parent_data.personid);
      this.processParentData(parent_data);
    }
    //console.log(`${this.form_name}.save()`);
    this.saving = true;
    let { sub_components, sub_forms } = this.form_children;
    /*
    let component_data = {};
    let sub_forms = [];
    this.dirty_map.forEach((val, key) => {
      //console.log(`${this.constructor.name}.save(): dirty has ${JSON.stringify(val)}=${key}`)
      if (val.form !== undefined) {
        sub_forms.push(val);
      } else if (val.elem !== undefined) {
        component_data[val.field] = val.value;
      }
      //this.dirty_map.delete(key);
    });*/
    let deferred_forms = [];
    sub_forms.forEach(f => {
      if (f.canSave(this)) {
        console.log("reports it can save", f);
        f.save(this);
      } else {
        console.log("deferring", f);
        deferred_forms.push(f);
      }
    });

    try {
      if (this.debug) {
        console.warn("SAVING", sub_components);
      }
      this.saved_data = await this.save_impl(sub_components);
    } catch (e) {
      console.error(`${this.constructor.name}.save_impl(${JSON.stringify({ ...sub_components })}) threw:\n${e}`);
    }
    console.log(this.constructor.name, "saved", this.saved_data);

    let unsaveable_forms = [];
    deferred_forms.forEach(f => {
      if (f.canSave(this.saved_data)) {
        f.save(this.saved_data);
      } else {
        unsaveable_forms.push(f);
      }
    });
    if (unsaveable_forms.length > 0) {
      console.error("some forms unsaveable", unsaveable_forms);
    }

    this.saving = false;
    // TODO: should collect any errors here as well
    this.dispatchDirty(this.dirty_map.size > 0, this.dirty_map);
  }

  saveError(e) {

  }
  saveComplete(keys) {
    keys.forEach(key => this.dirty_map.delete(key));
    keys.forEach(key => this.invalid_map.delete(key));
    this.dispatchDirty(this.dirty_map.size > 0, this.invalid_map.size === 0, this.dirty_map);
    this.dispatchEvent(new CustomEvent('form-saved', { bubbles: true, composed: true, detail: { form: this, name: this.form_name, dirty: this.dirty, valid: this.valid } }));
  }

  reset() {
    //console.log("RESET ON FORM", this.form_name, this.dirty_map);
    this.invalid_map = new Map();
    this.dirty_map.forEach((val, key) => {
      if (val.form !== undefined) {
        val.form.reset();
      } else if (val.elem !== undefined) {
        val.elem.reset();
      }
      this.dirty_map.delete(key);
    });
    this.dispatchDirty(this.dirty_map.size > 0, this.invalid_map.size === 0, this.dirty_map);
  }

  setToDefault() {
    //console.log("DEFAULT ON FORM", this.form_name, this.field_map);
    this.invalid_map = new Map();
    this.field_map.forEach((val, key) => {
      if (val.form !== undefined) {
        val.form.setToDefault();
      } else if (val.elem !== undefined) {
        val.elem.setToDefault();
      }
      this.dirty_map.delete(key);
    });
    this.dispatchDirty(this.dirty_map.size > 0, this.invalid_map.size === 0, this.dirty_map);
  }

  async save_impl(data) {
    // abstract impl of actual form save
    this.saveComplete(Object.keys(data));
  }

  static get properties() {
    return {
      dirty: { type: Boolean },
      valid: { type: Boolean },
      saving: { type: Boolean },
      debug: { type: Boolean }
    };
  }

}


class AddressDetail extends KaleForm {
  get form_name() { return "AddressDetail" }
  set contact(c) { this.address = c }
  static get properties() {
    return {
      address: { type: Object },
      ...(super.properties)
    }
  }
  constructor() {
    super();
  }
  render() {
    return html`

      <style>

        #container {
        }
      .group {
          padding: 0 4px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: left;
          flex-direction: row;
        }
      .horizontal {
        padding: 0 4px;
          display: flex;
          align-items: center;
          justify-content: left;
          flex-direction: row;
      }
      .horizontal > *{
          flex: 1 1 50%;
      }
        .group > * {
          flex: 1 1;
        }
        .group > *[row] {
          width: 100%;
          flex: 1 1 100%;
        }

      </style>
      <div id="container">
        <kale-toggle .label=${"Preferred"} .field=${'preferred'} .value=${this.address ? this.address.preferred : null}></kale-toggle>

        <div class="group" id="street">

          <kale-textfield .label=${"Line 1"} .field=${'line1'} .value=${this.address ? this.address.line1 : null}></kale-textfield>
          <kale-textfield .label=${"Line 2"} .field=${'line2'} .value=${this.address ? this.address.line2 : null}></kale-textfield>
          <kale-textfield .label=${"Line 3"} .field=${'line3'} .value=${this.address ? this.address.line3 : null}></kale-textfield>
        </div>
        <div class="group" id="location">
          <kale-textfield .label=${"City"} .field=${'city'} .value=${this.address ? this.address.city : null}></kale-textfield>

          <kale-filtered-enum .label=${"State"} .field=${'address_state_code'} .value=${this.address && this.address.state ? this.address.state.code : null}></kale-filtered-enum>
          <kale-textfield .label=${"Zip"} .field=${'zip'} .value=${this.address ? this.address.zip : null}></kale-textfield>
        </div>

        <div class="group" id="status">
          <kale-enum .label=${"Status"} .field=${'address_status_code'} .value=${this.address && this.address.status ? this.address.status.code : null}></kale-enum>
          <kale-textfield .label=${"Notes"} .field=${'note'} .value=${this.address ? this.address.note : null}></kale-textfield>
        </div>
      </div>
            

    `;
  }
  async save_impl(data) {
    //console.log("address detail saving", data);

    let addr_mutation = new EditAddressInfo(
      addr => {
        if (this.address && this.address.id === addr.id) {
          this.address = addr;
        }
      },  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
        this.dispatchEvent(new CustomEvent('address-detail-saved', { detail: { form: this, name: this.form_name, address: p } }));
        this.dispatchEvent(new CustomEvent('contact-detail-saved', { detail: { form: this, name: this.form_name, contact: p } }));
      });

    return addr_mutation.save(this.address.id ? { ...data, id: this.address.id } : Object.assign(this.address, data), this.address);
  }
}

window.customElements.define('address-detail', AddressDetail);

let address_sep = ' / '


// TODO: 
// augment/replace the google places calls with a client side parse like 
// https://github.com/DamonOehlman/addressit
// or https://github.com/thrustlabs/contact-parser
//
// should at least run this to provide a fallback entry if google isn't
// working or recognizing the input

class EmailDetail extends KaleForm {
  get form_name() { return "EmailDetail" }
  set contact(c) { this.email = c }
  static get properties() {
    return {
      email: { type: Object },
      ...(super.properties)
    }
  }
  constructor() {
    super();
  }
  render() {
    return html`

      <style>

        #container {
        }
      .group {
          padding: 0 4px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: left;
          flex-direction: row;
        }
      .horizontal {
        padding: 0 4px;
          display: flex;
          align-items: center;
          justify-content: left;
          flex-direction: row;
      }
      .horizontal > *{
          flex: 1 1 50%;
      }
        .group > * {
          flex: 1 1;
        }
        .group > *[row] {
          width: 100%;
          flex: 1 1 100%;
        }

      </style>
      <div id="container">
        <div class="group" id="email">
          <kale-email .label=${"Email Address"} .field=${'email'} .value=${this.email ? this.email.email : null}></kale-email>
          </div>
        <div class="group" id="status">
          <kale-enum .label=${"Status"} .field=${'email_status_code'} .value=${this.email && this.email.status ? this.email.status.code : null}></kale-enum>
          <kale-textfield .label=${"Notes"} .field=${'note'} .value=${this.email ? this.email.note : null}></kale-textfield>
          </div>
      </div>
            

    `;
  }
  async save_impl(data) {
    console.log("email detail saving", data, this.email);

    let email_mutation = new EditEmailInfo(
      email => {
        if (this.email && this.email.id === email.id) {
          this.email = email;
        }
      },  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
        this.dispatchEvent(new CustomEvent('email-detail-saved', { detail: { form: this, name: this.form_name, email: p } }));
        this.dispatchEvent(new CustomEvent('contact-detail-saved', { detail: { form: this, name: this.form_name, contact: p } }));
      });

    return email_mutation.save(this.email.id ? { ...data, id: this.email.id } : Object.assign(this.email, data), this.email);
  }
}

window.customElements.define('email-detail', EmailDetail);


class PhoneDetail extends KaleForm {
  get form_name() { return "PhoneDetail" }
  set contact(c) { this.phone = c }
  static get properties() {
    return {
      phone: { type: Object },
      ...(super.properties)
    }
  }
  constructor() {
    super();
  }
  render() {
    return html`

      <style>

        #container {
        }
      .group {
          padding: 0 4px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: left;
          flex-direction: row;
        }
      .horizontal {
        padding: 0 4px;
          display: flex;
          align-items: center;
          justify-content: left;
          flex-direction: row;
      }
      .horizontal > *{
          flex: 1 1 50%;
      }
        .group > * {
          flex: 1 1;
        }
        .group > *[row] {
          width: 100%;
          flex: 1 1 100%;
        }

      </style>
      <div id="container">
        <div class="group" id="phone">
          <kale-textfield .label=${"Phone"} .field=${'number'} .value=${this.phone ? this.phone.number : null}></kale-textfield>
          <kale-textfield .label=${"Ext"} .field=${'extension'} .value=${this.phone ? this.phone.extension : null}></kale-textfield>
          <kale-enum .label=${"Type"} .field=${'phone_type_code'} .value=${this.phone && this.phone.type ? this.phone.type.code : null}></kale-enum>
        </div>
        <div class="group" id="status">
          <kale-enum .label=${"Status"} .field=${'phone_status_code'} .value=${this.phone && this.phone.status ? this.phone.status.code : null}></kale-enum>
          <kale-textfield .label=${"Note"} .field=${'note'} .value=${this.phone ? this.phone.note : null}></kale-textfield>
        </div>
      </div>
            

    `;
  }
  async save_impl(data) {
    console.log("phone detail saving", data);
    let phone_mutation = new EditPhoneInfo(
      phone => {
        console.log("data func", phone)
        if (this.phone && this.phone.id === phone.id) {
          this.phone = phone;
        }
      },  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
        this.dispatchEvent(new CustomEvent('phone-detail-saved', { detail: { form: this, name: this.form_name, phone: p } }));
        this.dispatchEvent(new CustomEvent('contact-detail-saved', { detail: { form: this, name: this.form_name, contact: p } }));
      });

    return phone_mutation.save(this.phone.id ? { ...data, id: this.phone.id } : Object.assign(this.phone, data), this.phone);
  }
}

window.customElements.define('phone-detail', PhoneDetail);



class DummyMutation {
  constructor() {
    console.error("Dummy Mutation created");
  }
}

class Contact {
  constructor(c, result_func, final_func) { this.contact = c; this.result_func = result_func; this.final_func = final_func }
  get label() { return this.contact.__typename ? this.contact.__typename + ' ' : '' + 'unimplemented'; }
  get icon() { return 'not_interested' }
  get icon_color() { return null; }
  get title() { return 'UNKNOWN CONTACT' }
  get EditControl() { return LitElement }
  get id() { return "contact-unknown" }
  get mutation_class() { return EditPhoneInfo; }
  getMutation() {
    console.log("getMutation", this.constructor.name)
    if (this.__mutation) return this.__mutation;
    this.__mutation = new this.mutation_class(
      result => {
        if (this.result_func) this.result_func(new this.constructor(result));
      },  // data update function
      { changeMap: null },  //initial variables
      final => { // finalizing function
        if (this.final_func) this.final_func(new this.constructor(final));
      });
    return this.__mutation;
  }
  save(personid) {
    return this.getMutation().save(this.contact, { person_id: personid });
  }
  delete() {
    this.getMutation().delete(this.contact);
  }
}
class PhoneContact extends Contact {
  constructor(contact, res, fin) {
    super(contact, res, fin);
    this.parts = this.phoneParts(contact.number)
    if (this.parts.extension && !contact.extension) {
      contact.extension = this.parts.extension
    }
    this.bad = contact?.phone_status_code === 'B';
    this.preferred = contact?.preferred ?? false;
  }
  get mutation_class() { return EditPhoneInfo; }
  get title() { return 'Phone #' }
  get EditControl() { return PhoneDetail }
  get label() {
    return (this.parts.complete ? `(${this.parts.area}) ${this.parts.prefix} -${this.parts.line} ` : this.parts.all) + `${this.contact.extension ? ` x${this.contact.extension}` : ''} `;
  }
  get id() { return `phone - ${this.contact.number} x${this.contact.extension ? this.contact.extension : ''} ` }

  phoneParts(number) {
    let m = number.match(format_regex);
    m = m ? m : [number];
    if (!m[1]) m[1] = '1';
    let ret = { country: m[1], area: m[2], prefix: m[3], line: m[4], extension: m[5], all: m[0], complete: m[1] && m[3] && m[4] }
    return ret;
  }
  get icon() {
    switch (this.contact.phone_type_code) {
      case 'CELL':
        return 'smartphone';
        break;
      case 'WORK':
        return 'business';
        break;
      case 'FAX':
        return 'print';
        break;
      default:
        return "phone";
    }
  }
}

//const phone_regex = /^(1\s*?)?\s*((\([0-9]{3}\))|[0-9]{3})[\s\-.]?[\0-9]{3}[\s\-.]?[0-9]{4}$/
const phone_regex = /^[+(]*(1)?[-.\s()]*(\d{3})[-.\s()]*(\d{3})[-.\s()]*(\d{4}).*$/
const format_regex = /^[+(]*(1)?[-.\s()]*(\d{3})?[-.\s()]*(\d{1,3})?[-.\s()]*(\d{1,4})?[\s.()-]*(?:e?x\w*)?\s*(\d*)?$/i
const number_regex = /^[+(]*\d+/
const not_number_regex = /[^0-9]{5}/
PhoneContact.suggestions = (partial_text, cb, res, fin) => {
  if (number_regex.test(partial_text) && !not_number_regex.test(partial_text)) {
    cb([new PhoneContact({ number: partial_text }, res, fin)])
  } else {
    cb([])
  }
}

class EmailContact extends Contact {
  get title() { return "Email" }
  get id() { return `email - ${this.contact.email} ` }
  get label() { return this.contact.email }
  get icon() { return 'email' }
  get EditControl() { return EmailDetail }
  get mutation_class() { return EditEmailInfo; }
}

const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
EmailContact.suggestions = (partial_text, cb, res, fin) => {
  if (email_regex.test(partial_text)) {
    cb([new EmailContact({ email: partial_text }, res, fin)])
  } else {
    cb([])
  }
  // TODO: more elaborate auto-completion / suggestions:
  //let [user, server] = partial.split('@');
}

// TODO: 
// augment/replace the google places calls with a client side parse like 
// https://github.com/DamonOehlman/addressit
// or https://github.com/thrustlabs/contact-parser
//
// should at least run this to provide a fallback entry if google isn't
// working or recognizing the input

class AddressContact extends Contact {
  constructor(contact, res, fin, result, address_helper) {
    super({ ...contact, google_id: result?.place_id }, res, fin);
    this.google_result = result;
    this.address_helper = address_helper;
    this.bad = contact?.address_status_code === 'B';
    this.preferred = contact?.preferred ?? false;
  }
  get title() { return "Address" }
  get label() { return this.addressString(this.contact) }
  get id() { return `address - ${this.label.replace(/[\s,-]/g, '')} ` }
  get EditControl() { return AddressDetail }
  get mutation_class() { return EditAddressInfo; }

  finalize(cb) {
    if (this.google_result?.offline) {
      this.contact = {
        line1: this.google_result?.description,
        city: null,
        address_state_code: null,
        zip: null

        /*
        cb({ 
          coordinates: `${detail.geometry.location.lat()}, ${detail.geometry.location.lng()}`,
          google_id: addr.place_id, 
          line1: `${address_fields.street_number ? address_fields.street_number : ''} ${address_fields.route ? address_fields.route : ''}`, 
          city: address_fields.locality, 
          address_state_code: address_fields.administrative_area_level_1, 
          zip: address_fields.postal_code });*/
      }
      cb(this);
    } else if (this.address_helper && this.google_result?.place_id) {
      this.address_helper.getDetails(this.google_result, detail => {
        console.log("GOT DETAIL", detail);
        this.contact = Object.assign(this.contact, detail);
        cb(this);
      })
    } else {
      cb(this);
    }
  }

  addressString(addr) {
    const sep = "•"
    if (!addr?.line1 && this.google_result) return this.google_result.description;
    return `${['1', '2', '3'].map(n => addr[`line${n}`]).filter(a => a).join(` ${sep} `)} ${sep} ${addr.city} ${addr.state ? addr.state.code : addr.address_state_code ? addr.address_state_code : ''} ${addr.zip}`
  }

  get icon() { return this.bad ? 'location_off' : 'location_on' }
  get icon_color() { return this.preferred && !this.bad ? 'green' : null; }
}
AddressContact.suggestions = (partial_text, cb, res, fin) => {
  if (!AddressContact.helper) AddressContact.helper = new AddressHelper();
  const helper = AddressContact.helper
  helper.findAddressSuggestions(partial_text, results => {
    console.log("got results", results);
    if (results) {
      cb(results.map(r => new AddressContact(null, res, fin, r, helper)));
    } else {
      cb([]);
    }

  });
}


class AddressHelper {
  constructor() {
    //this.google = this._load_google(); 
    // FIXME: temporarily disable while cause of errors can be determined
    // mock service for now:
    this.google = {
      service: {
        getPlacePredictions: () => undefined
      } 
    };

    
  }
  async _load_google() {
    await _loader;
    return {
        service: new google.maps.places.AutocompleteService(),
        token: new google.maps.places.AutocompleteSessionToken(),
        places: new google.maps.places.PlacesService(document.getElementById("places_element"))
    }
  }
  getDetails(addr, cb) {
    this.google.then(({service, token, places}) => {
      places.getDetails({ 
        fields: ['address_component', 'geometry.location'],
        placeId: addr.place_id, sessionToken: token//this.session
      }, detail => {
        let address_fields = {};
        if (detail?.address_components) {
          detail.address_components.forEach(d => {
            d.types.forEach(t => {
              address_fields[t] = d.short_name;
            });
          });
          cb({ coordinates: `${detail.geometry.location.lat()}, ${detail.geometry.location.lng()}`, google_id: addr.place_id, line1: `${address_fields.street_number ? address_fields.street_number : ''} ${address_fields.route ? address_fields.route : ''}`, city: address_fields.locality, address_state_code: address_fields.administrative_area_level_1, zip: address_fields.postal_code });
        } else {
          // TODO: pop a toast
          console.error("NO DETAIL FOUND FOR", addr);
        }
      });
    });
  }

  async findAddressSuggestions(partial, callback) {
    const {service, token, places} = await this.google;
    if (partial && partial.length >= 5) {
      try {
        let result = await service.getPlacePredictions(
          {
            fields: 'address_component',
            input: partial,
            sessionToken: token 
        }, callback);
        if (result === undefined) {
          //something wrong
          this.places_error = true;
          callback([{
            description: partial,
            offline: true
          }]);
        }
      } catch (e) {
        console.error("error fetching suggestsions", e);
      }
    }
  }
}
const contact_map = {
  'phone': PhoneContact,
  'email': EmailContact,
  'address': AddressContact
}


const kale_contact_info_style = css`
    :host {
    }
    #chips{
      --mdc-theme-primary: var(--paper-grey-300);
    }
    .search_results {
      --mdc-theme-primary: var(--paper-grey-300);

    }
    #title{
      margin-top: 18px;
      color: rgba(0, 0, 0, 0.6);
      font-family: Roboto, sans-serif;
      line-height: 1.15rem;
      position: relative;
      left: 5px;
      font-weight: 400;
      letter-spacing: .00937rem;
    }
  .detail {
    display: none;
  }
  mwc-textfield {
    width: 100%;
  }
  #input_elem {
    margin-top: 20px;

  }
  kale-chip[bad] {
    text-decoration: line-through;
    color: var(--paper-grey-500);
  }
  kale-chip[pref] {
    --mdc-theme-primary: var(--paper-green-500);

  }
  `

class KaleContactInfo extends KaleForm {
  static styles = kale_contact_info_style
  static properties = {
    noaddbox: { type: Boolean },
    edit_detail: { type: Object },
    detail_dirty: { type: Object },
    personid: { type: String },
    title: { type: String },
    added: { type: Array },
    results: { type: Array },
    ...(super.properties)
  }
  processResult(saved) {
    let existing = this.contacts.find(c => c.id === saved.id);
    if (existing) {
      existing = saved;
    } else {
      this.__contacts.push(saved);
    }
    this.requestUpdate('contacts')
  }
  finalizeResult(final) {
    this.added = this.added.filter(c => c.id !== final.id)
    // why was this here: this.added = []
  }
  set contacts(c) {
    this.__contacts = c.map(c => c.__typename && contact_map[c.__typename] ? new contact_map[c.__typename](c, this.processResult.bind(this), this.finalizeResult.bind(this)) : new Contact(c, null, null));
    this.requestUpdate('contacts');
  }
  get contacts() { return this.__contacts };

  constructor() {
    super();
    this.contactPartial = debounce(this.contactPartialActual.bind(this), 50);
    this.added = [];
    this.results = [];
  }
  get form_name() { return "KaleContactInfo" }

  can_save_impl() {
    return this.personid;
  }
  async save_impl(data) {
    console.log("saving contacts", this.personid);
    // abstract impl of actual form save
    //this.added.forEach(contact => { contact.save(this.personid); });
    // FIXME: maybe 'let ret = await ...' then process the results further?
    return Promise.allSettled(this.added.map(contact => contact.save(this.personid)));
  }
  deselectContact(contact) {
    this.added = this.added.filter(a => a.id !== contact.id); // remove it from the temp list
    let existing = this.renderRoot.getElementById(`${contact.id} `); // get the element
    if (existing) { existing.remove(); } // nuke it for sure
    let i = this.renderRoot.querySelector("#input_elem");
    if (i) i.clear(); // clear input
    this.dispatchDirty(this.added.length > 0, this.added);
  }

  deleteContact(contact) {
    if (contact.contact.id) { // has been previously saved upstream
      contact.delete(success => {
        this.__contacts = this.__contacts.filter(c => c.id !== contact.id);
      });
    } else if (this.added) { // not previously saved, but maybe in temporary adds
      this.deselectContact(contact);
    }
  }

  addSuggestedContact(suggestion) {
    console.log("addSuggestedContact()", suggestion);
    if (suggestion.finalize) {
      console.log("finalizing...", suggestion);
      suggestion.finalize(final => this.saveAddedContact(final));
    } else {
      console.log("saving...");
      this.saveAddedContact(suggestion);
    }
  }
  saveAddedContact(suggestion) {
    this.added = [...this.added, suggestion];
    this.results = [];
    let i = this.renderRoot.querySelector("#input_elem");
    if (i) {
      // clear input
      i.value = "";
      i.blur();
    }
    this.dispatchDirty(this.added.length > 0, this.added);

    if (this.autosave) {
      this.save();
    }
  }

  contactPartialActual(partial) {
    if (!partial) {
      this.results = [];
    }
    this.partial = partial;
    //this.results = [];
    Object.values(contact_map).forEach(c => c.suggestions(partial, new_results => {
      this.results = this.results.filter(r => r.constructor.name !== c.name);
      if (this.partial === partial) {
        let unique_ids = new Set();
        let final_results = [];
        [...new_results, ...this.results].forEach(r => {
          if (!unique_ids.has(r.id)) {
            final_results.push(r);
            unique_ids.add(r.id);
          }
        });

        this.results = final_results;//[...this.results.filter(r => !new_results.some()), ...results]
      }
    }, this.processResult.bind(this), this.finalizeResult.bind(this)))
  }


  firstUpdated() {
    super.firstUpdated();
    this.dialog = this.renderRoot.getElementById("dialog");
    
    window.router.registerDialog(this.dialog);
    this.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        if (this.results && this.results.length > 0) {
          this.selectPhone(this.results[0]);
        }
      }
    });
  }

  contactLabel(contact) {
    console.log(contact);
    return contact.__typename;
  }

  contactIcon(contact) {
    return 'not_interested'
  }


  editContact(contact) {
    this.edit_detail = contact
    this.edit_control = new contact.EditControl;
    this.edit_control.contact = this.edit_detail.contact;
    this.edit_control.addEventListener('form-dirty', e => this.detail_dirty = e.detail);
    this.edit_control.addEventListener('contact-detail-saved', saved => this.deselectContact(saved.detail.contact));

    /* <phone-detail .phone=${this.edit_detail}
                  @form-dirty=${e => this.detail_dirty = e.detail}
                  @phone-detail-saved=${saved => { this.deselectPhone(saved.detail.phone) }}
                  > </phone-detail> */
  }



  render() {
    let chips = [...(this.contacts ? this.contacts : []), ...(this.added ? this.added : [])]
    console.warn("CHIPS", chips);

    return html`
    <div class="contacts_container">

  <div class="detail" id="places_element"></div>
${ this.title ? html`<div id="title">${this.title}</div>` : html``}
<div id="chips">
  ${chips && chips.length > 0 ?
        chips.map((contact, index) =>
          html`
                <kale-chip
                compact
           style='margin-top: 8px'
           ?bad=${contact.bad}
           ?pref=${contact.preferred}
           label=${contact.label}
           leadingicon=${contact.icon}
           .iconcolor = ${contact.icon_color}
           @click=${e => this.editContact(contact)}
           >
                </kale-chip>
              `)
        : html``}

</div>

${
      this.noaddbox ?
        html``
        :
        html`<mwc-textfield
            icon="contacts"
            id="input_elem"
            placeholder=${"Type to add an address, email or phone #..."}
            @input=${(e) => this.contactPartial(e.target.value)}
            ></mwc-textfield>
          <div class="search_results">
            ${(this.results && this.results.length) > 0 ?
            html`${repeat([...this.results], (suggestion) => suggestion.id, (suggestion, index) =>
              html`<kale-chip
                compact
                style='margin-top: 8px'
                id=${suggestion.id}
                label=${suggestion.label}
                thingy=${suggestion.constructor.name}
                leadingicon=${suggestion.icon}
                @click=${e => this.addSuggestedContact(suggestion)}></kale-chip>`)}`
            : html``}
          </div>
        `}

    </div>
    <mwc-dialog id="dialog"
          heading=${ this?.edit_detail?.title || ""}
          ?open=${this.edit_detail} 
        >

      ${ this.edit_control}

          <div slot="secondaryAction" style="width: 100%; flex: 1 1">
            <mwc-button icon="delete" @click=${e => { this.deleteContact(this.edit_detail); this.edit_detail = null; }}>delete</mwc-button>
          </div>
          <mwc-button slot="primaryAction" @click=${ e => { this.edit_detail = null; this.detail_dirty = undefined; }} >cancel</mwc-button>
          <mwc-button slot="primaryAction"
            ?unelevated=${this.detail_dirty} ?disabled=${!this.detail_dirty}
            @click=${ e => { if (this.detail_dirty) { console.log("SAVING\n", this.detail_dirty); this.detail_dirty.save(); this.detail_dirty = undefined; this.edit_detail = null } }} 
          >
            ${this.detail_dirty ? "save" : "saved"}
          </mwc-button>



    </mwc-dialog>
  `
    /* <phone-detail .phone=${this.edit_detail}
                  @form-dirty=${e => this.detail_dirty = e.detail}
                  @phone-detail-saved=${saved => { this.deselectPhone(saved.detail.phone) }}
                  > </phone-detail> */
  }

}

window.customElements.define('kale-component', KaleComponent);
window.customElements.define('kale-textfield', KaleTextField);
window.customElements.define('kale-email', KaleEmail);
window.customElements.define('kale-password', KalePassword);
window.customElements.define('kale-ssn', KaleSSN);
window.customElements.define('kale-date', KaleDate);
window.customElements.define('kale-toggle', KaleToggle);
window.customElements.define('kale-enum', KaleEnum);
window.customElements.define('kale-filtered-enum', KaleFilteredEnum);
window.customElements.define('kale-form', KaleForm);
//window.customElements.define('kale-addresses', KaleAddresses);
//window.customElements.define('kale-emails', KaleEmails);
//window.customElements.define('kale-phones', KalePhones);
window.customElements.define('kale-contact', KaleContactInfo);

export {
  KaleComponent as KaleComponent,
  KaleTextField as KaleTextField,
  KaleSSN as KaleSSN,
  KaleDate as KaleDate,
  KaleToggle as KaleToggle,
  KaleEnum as KaleEnum,
  KaleFilteredEnum as KaleFilteredEnum,
  KaleForm as KaleForm,
  KaleEmail as KaleEmail,
  KalePassword as KalePassword
}
