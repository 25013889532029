import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';
import { person_fields } from './person.js';

/*
 * Note
 */

export const timeline_cache_fragment = gql`
  fragment CacheFields on timeline_cache {
    person_id
    computed_date
    expires_date
    state
    report
    errors
    nodes
    years
   }`


export const timeline_cache_info_query = gql`
  query timeline_cache_info($PersonId: uuid!){
    timeline_cache( where: {person_id: {_eq: $PersonId}} ){
         ...CacheFields
    }
  }
  ${timeline_cache_fragment}
`

export class CacheInfo extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return timeline_cache_info_query;
  }
  data_impl({ data: { timeline_cache: [d] } }) {
    return d;
  }
}


export const update_timeline_cache_query = gql`
  mutation update_timeline_cache($personId: uuid!, $changeMap: timeline_cache_set_input!) {
    update_timeline_cache (
      where: {
          person_id: {_eq: $personId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...CacheFields
      }
    }
  }
  ${timeline_cache_fragment}
`
export const insert_timeline_cache_query = gql`
  mutation insert_timeline_cache($changeMap: timeline_cache_insert_input!) {
    insert_timeline_cache (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...CacheFields
      }
    }
  }
  ${timeline_cache_fragment}
`

export const upsert_timeline_cache_query = gql`
  mutation upsert_timeline_cache($changeMap: timeline_cache_insert_input!, $changeCols: [timeline_cache_update_column!]!) {
    timeline_cache:insert_timeline_cache (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: timeline_cache_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...CacheFields
      }
    }
  }
  ${timeline_cache_fragment}
`

export const upsert_timeline_cache_query_with_person = gql`
mutation upsert_timeline_cache($changeMap: timeline_cache_insert_input!, $changeCols: [timeline_cache_update_column!]!) {
  timeline_cache: insert_timeline_cache(
    objects: [$changeMap],
    on_conflict: {
    constraint: timeline_cache_pkey,
    update_columns: $changeCols
  }
  ) {
    returning {
        ...CacheFields
      person {
         ...PersonFields
      }
    }
  }
}
${ timeline_cache_fragment}
${ person_fields}
`

export const delete_timeline_cache_query = gql`
mutation delete_timeline_cache($deleteId: uuid!) {
  delete_timeline_cache(
    where: { person_id: { _eq: $deleteId } }
  ) {
    affected_rows
  }
}
`


export const timeline_cache_for_person_query = gql`
query timeline_cache_info($PersonId: uuid!) {
  timeline_cache(
    where: { person_id: { _eq: $PersonId } }
        order_by: { import_date: asc }
  ){
        ...CacheFields
  }
}
${ timeline_cache_fragment}
`

export class TimelineCache extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return timeline_cache_for_person_query;
  }
  data_impl({ data: { timeline_cache: migrations } }) {
    return migrations;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}


export class EditTimelineCache extends SaveableQuery {
  get friendly_name() { return "Timeline Cache" }
  get required_keys_impl() {
    return [
      "person_id",
      "fixed"
      //"person_pk",
      //"ssn"
    ];
  }
  data_impl({ data: { update_timeline_cache: { returning: [d] } } }) {
    return d;
  }
  variables(vars) {
    return { personId: vars.id, changeMap: vars }
  }

  get mutate_gql_impl() {
    return update_timeline_cache_query;
  }
  get delete_gql_impl() {
    return delete_timeline_cache_query;
  }
  get save_queries_impl() {
    return [(new TimelineCache(null, null))];
  }
}

