import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * BENEFIT
 */

export const benefit_fields = gql`
  fragment BenefitFields on benefit {
    id
    person_id
    start_date
    end_date
    benefit_type_code
    benefit_type {
      code
      name
    }
    benefit_annuity_type_code
    annuity_type {
      code
      name
    }
    annual_salary_at_start
    final_average_salary
    monthly_annuity_amount
    monthly_annuity_amount_taxable
    full_taxable_date
    lump_sum
   }`

export const benefit_info_query = gql`
  query benefit_info($BenefitId: uuid!){
    benefit( where: {id: {_eq: $BenefitId}} ){
         ...BenefitFields
    }
  }
  ${benefit_fields}
`


export class BenefitInfo extends StaticQuery {
  get required_impl() {
    return ['BenefitId'];
  }
  get gql_impl() {
    return benefit_info_query;
  }
  data_impl({ data: { benefit: [d] } }) {
    return d;
  }
}


export const benefits_for_person_query = gql`
  query benefit_info($PersonId: uuid!) {
    benefit (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...BenefitFields
    }
  }
  ${benefit_fields}
  `
//order_by: {start_date: asc}

export class PersonBenefits extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return benefits_for_person_query;
  }
  data_impl({ data: { benefit: benefits } }) {
    return benefits;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_benefit_query = gql`
  mutation update_benefit($benefitId: uuid!, $changeMap: benefit_set_input!) {
    update_benefit (
      where: {
          id: {_eq: $benefitId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...BenefitFields
      }
    }
  }
  ${benefit_fields}
`
export const insert_benefit_query = gql`
  mutation insert_benefit($changeMap: benefit_insert_input!) {
    insert_benefit (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...BenefitFields
      }
    }
  }
  ${benefit_fields}
`

export const upsert_benefit_query = gql`
  mutation upsert_benefit($changeMap: benefit_insert_input!, $changeCols: [benefit_update_column!]!) {
    benefit:insert_benefit (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: benefit_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...BenefitFields
      }
    }
  }
  ${benefit_fields}
`
export const delete_benefit_query = gql`
  mutation delete_benefit($deleteId: uuid!) {
    delete_benefit (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`


export class EditBenefitInfo extends SaveableQuery {
  get friendly_name() { return "Benefit" }
  get required_keys_impl() {
    return [
      "id",
      "person_id",
      "start_date",
      "benefit_annuity_type_code",
      "benefit_type_code",
      "annual_salary_at_start",
      "final_average_salary",
      "monthly_annuity_amount",
      "monthly_annuity_amount_taxable",
    ];

  }
  data_impl({ data: { benefit: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_benefit_query;
  }
  get delete_gql_impl() {
    return delete_benefit_query;
  }

  get save_queries_impl() {
    return [(new PersonBenefits(null, null))];
  }
}

