import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

/*
 * EMPLOYMENTS
 */


export const employment_fields = gql`
  fragment EmploymentFields on employment {
    id
    person_id
    employer_code
    employer {
      code
      name
      joined
    }
    start_date
    end_date
    employment_type_code
    employment_type {
      code
      name  
      eligible_prior_to_sep1_1995  
      eligible_after_sep1_1995
      full_time
    }
   }`

export const employment_info_query = gql`
  query employment_info($EmploymentId: uuid!){
    employment( where: {id: {_eq: $EmploymentId}} ){
         ...EmploymentFields
    }
  }
  ${employment_fields}
`

export class EmploymentInfo extends StaticQuery {
  get required_impl() {
    return ['EmploymentId'];
  }
  get gql_impl() {
    return employment_info_query;
  }
  data_impl({ data: { employment: [d] } }) {
    return d;
  }
}
// order_by: {start_date: asc}

export const employments_for_person_query = gql`
  query employment_info($PersonId: uuid!) {
    employment (
        where: {person_id: {_eq: $PersonId}}
        ){
        ...EmploymentFields
    }
  }
  ${employment_fields}
  `

export class PersonEmployments extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return employments_for_person_query;
  }
  data_impl({ data: { employment: employments } }) {
    return employments;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}

export const update_employment_query = gql`
  mutation update_employment($employmentId: uuid!, $changeMap: employment_set_input!) {
    update_employment (
      where: {
          id: {_eq: $employmentId} 
      },
      _set: $changeMap
    ) {
      affected_rows
      returning {
        ...EmploymentFields
      }
    }
  }
  ${employment_fields}
`
export const insert_employment_query = gql`
  mutation insert_employment($changeMap: employment_insert_input!) {
    insert_employment (
      objects: [
        $changeMap
      ]
    ) {
      returning {
        ...EmploymentFields
      }
    }
  }
  ${employment_fields}
`

export const upsert_employment_query = gql`
  mutation upsert_employment($changeMap: employment_insert_input!, $changeCols: [employment_update_column!]!) {
    employment:insert_employment (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: employment_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...EmploymentFields
      }
    }
  }
  ${employment_fields}
`

export const delete_employment_query = gql`
  mutation delete_employment($deleteId: uuid!) {
    delete_employment (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`

/*

employer:
  code: "L0052A"
  joined: null
  name: "AK LOCAL 52"

employer_code: "L0052A"

employment_type:
  code: "FT"
  eligible_after_sep1_1995: true
  eligible_prior_to_sep1_1995: true
  full_time: true
  name: "Full-time"

employment_type_code: "FT"
end_date: null
id: "f8114a55-1c35-11ea-ae5f-204747be2f5f"
person_id: "f8114a53-1c35-11ea-ae5f-204747be2f5f"
start_date: "2018-01-01"

__typename: "employment"

*/


export class EditEmploymentInfo extends SaveableQuery {
  get typename() { return "employment" }
  fill_in(e) {
    e = super.fill_in(e);
    e.employer = {
      code: "L0052A",
      joined: null,
      name: "AK LOCAL 52"
    };
    e.employment_type = {
      code: "FT",
      eligible_after_sep1_1995: true,
      eligible_prior_to_sep1_1995: true,
      full_time: true,
      name: "Full-time"
    };
    e.__typename = this.typename;
    return e;
  }
  get friendly_name() { return "Employment" }
  get required_keys_impl() {
    return ["id", "person_id", "employer_code", "employment_type_code", "start_date"];
  }
  data_impl({ data: { employment: { returning: [d] } } }) {
    return d;
  }
  get mutate_gql_impl() {
    return upsert_employment_query;
  }
  get delete_gql_impl() {
    return delete_employment_query;
  }
  get save_queries_impl() {
    return [(new PersonEmployments(null, null))];
  }
}

