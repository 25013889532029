import XLSX from 'xlsx';

const factor_sheet_names = ('10 Year Certain'
,    'Early Retirement'
,    'Joint & Survivor'
,    'Lump Sum Conversion 20+'
,    'Lump Sum Conversion 50%J&S'
,    'Lump Sum Conversion 75%J&S'
,    'Lump Sum Conversion <20'
,    'Lump Sum Conversion SLA'
)

const tax_sheet_names= ([ 'Data', 'Taxes', 'Rates', 'TaxFactors', 'Calculations', 'Programs' ])

export const readFactorFile = async (f) => {
    console.log(f.name)
    const bytes = await load_file(f);
    let wb = XLSX.read(bytes, { type: 'array' });
    if (wb.SheetNames.some(r=> factor_sheet_names.includes(r))) {
        console.log("FACTOR SUCCESS")
        var factor_type = "pension"
        var factors = {joint50:check_sheet(wb,'Joint & Survivor','joint50', 'EE Age')
        ,joint75: check_sheet(wb,'Joint & Survivor','joint75', 'EE Age')
        ,joint100: check_sheet(wb,'Joint & Survivor', 'joint100', 'EE Age')
        ,certain10: check_sheet(wb,'10 Year Certain','certain10', 'Years')
        ,early_factors: check_sheet(wb,'Early Retirement','early_factors', 'Years')
        ,lump20: check_sheet(wb,'Lump Sum Conversion <20','lump20', 'Years')
        ,lump20plus: check_sheet(wb,'Lump Sum Conversion 20+','lump20plus', 'Years')
        ,lumpsla: check_sheet(wb,'Lump Sum Conversion SLA','lumpsla', 'Years')
        ,lumpjoint50: check_sheet(wb,'Lump Sum Conversion 50%J&S','lumpjoint50', 'EE Age')
        ,lumpjoint75: check_sheet(wb,'Lump Sum Conversion 75%J&S','lumpjoint75', 'EE Age')
    }
        factors['filename'] = f.name
    } else if (wb.SheetNames.some(r=> tax_sheet_names.includes(r))) {
        console.log("TAX SUCCESS")
        var factor_type = "tax"
        var factors =  {tax_rates_120pct_midterm: check_sheet(wb,'Rates','tax_rates_120pct_midterm', 'Month'),
        member: check_sheet(wb, "TaxFactors", "member", 'Age x'),
        mortalitytable_90cm: check_sheet(wb,'TaxFactors','mortalitytable_90cm', 'Age x')}
        factors['filename'] = f.name
    } else {
        console.log("Cannot process the file provided.")
    }
    return {factor_type, factors}
}

export const load_file = (file) => {
    return new Promise((resolve, reject) => {
        var fr = new FileReader();
        fr.onload = () => {
            resolve(fr.result)
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
    }); //return {data:parsed data, factor type: string} 
}

const check_sheet = (wb, sheet, table, anchor) => {
    if (table.includes("table")) {
        var worksheet = wb.Sheets[sheet];
        var range = XLSX.utils.decode_range(worksheet['!ref']);
        var first_col = 0
        var first_row = 0
        var last_col = 0
        var last_row = 0
        for (let row = range.s.r; row <= range.e.r; row++) { //range.e.r
            for (let col = range.s.c; col <= range.e.c; col++) {
                var cell_data = worksheet[XLSX.utils.encode_cell({ c: col, r: row })]
                if (cell_data === undefined) {
                    continue
                }
                if (cell_data.v === anchor) {
                    first_row = row
                    first_col = col + 1
                } else {
                    last_row = row
                    last_col = col
                }
            }
        }
        return XLSX.utils.sheet_to_json(worksheet, { range: XLSX.utils.encode_range({ c: first_col, r: first_row }, { c: last_col, r: last_row }) })
    } else {
        return load_from_sheet(wb, sheet, table, anchor)

    }
}

const load_from_sheet = (wb, sheet, table, anchor) => {
    var worksheet = wb.Sheets[sheet];
    var range = XLSX.utils.decode_range(worksheet['!ref']);
    var table_names = {
        "JS 50%": 'joint50', "JS 75%": 'joint75', "JS 100%": 'joint100', "10 Year Certain and Life": 'certain10',
        "Early Retirement Factors": 'early_factors', "LS Conversion Factors - Less Than 20 Years of Vesting Service": 'lump20',
        "LS Conversion Factors - 20 or More Years of Vesting Service": 'lump20plus',
        "LS Conversion Factors - Immediate Single Life Annuity": 'lumpsla',
        "LS Conversion Factors - Immediate 50% Joint & Survivor Annuity": 'lumpjoint50',
        "LS Conversion Factors - Immediate 75% Joint & Survivor Annuity": 'lumpjoint75',
        "Rates": "tax_rates_120pct_midterm",
        "Member": "member"
    }
    var factor_array = Object()
    var factors = Object()
    var columns = Object()
    var anchor_flag = false
    factors['table'] = table
    for (let row = range.s.r; row <= range.e.r; row++) { //range.e.r
        for (let col = range.s.c; col <= range.e.c; col++) {
            var cell_data = worksheet[XLSX.utils.encode_cell({ c: col, r: row })]
            if (cell_data === undefined) {
                continue
            }
            // console.log(cell_data)
            if (cell_data.v in table_names) {
                if (factors['table']) {
                    factor_array[factors['table']] = factors
                    factors = new Object()
                }
                var row_name = undefined
                factors['table'] = table_names[cell_data.v]
            } else if (cell_data.v === anchor && anchor_flag === false) {
                var col_idx = col
                var row_idx = row
                anchor_flag = true
            } else if ((row === row_idx) && (!Object.values(columns).includes(cell_data.v) && (cell_data.v != anchor))) {
                columns[col] = cell_data.v
            } else if (col === col_idx) {
                factors[cell_data.v] = new Object()
                row_name = cell_data.v
            } else {
                if (row_name) {
                    if (columns[col]|| columns[col]== '0') {

                        factors[row_name][columns[col]] = cell_data.v
                    }
                }
            }
        }
    }
    factor_array[factors['table']] = factors
    //console.log(columns)
    return factor_array[table]
}
