import { html, css } from 'lit';
import { KalePage } from '../shared-components/page.js';
import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import '@material/mwc-checkbox';


import { KaleForm } from '../shared-components/form.js';
import gql from 'graphql-tag';
import { person_info_query, person_fields, client, clear_tags, insert_tags, timeline_cache_fragment, upsert_timeline_cache_query, formatQueryError } from '../queries/queries.js';

//const client = no_cache_client();

//import { SimpleTimeline } from '../simpletimeline.js';
//import { EventDate } from "../eventdate.js";
import * as Comlink from 'comlink';
/*


*/
const old_query = gql`
        query people {
          person( where: {
            _and: [
                    {tags: {person_tag_type_code: {_eq: "MGRT"}}},
                    {_not: {tags: {person_tag_type_code: {_neq: "MGRT"}}}}
                  ]
            }
          ) 
          {
            id
            first_name
            last_name
            timeline_cache {
              computed_date
            }
          }
        }
    `;


/*
 
 
              {_not: {timeline_cache:{}}},
              {_not: {timeline_cache:{computed_date: {}}}},
              {timeline_cache:{computed_date: {_lte: "${date.toLocaleDateString()}"}}},
*/
const date = new Date();
date.setDate(date.getDate() - 1);

const conditions = `
              {_not: {timeline_cache:{}}},
              {timeline_cache:{computed_date: {_is_null: true}}},
              {timeline_cache:{expires_date: {_lte: "${date.toLocaleDateString()}"}}},
              {timeline_cache:{expires_date: {_is_null: true}}}
`

const people_remaining_query = gql`
        query people {
          person( 
            where: 
             {_or: [
               ${conditions}
             ]}
             ) 
          {
            id
            timeline_cache {
              computed_date
            }
          }
        }
    `;


const people_count_query = gql`
        query people {
          person_aggregate( 
            where: 
             {_or: [
               ${conditions}
             ]}
             ) 
          {
            aggregate {
              count
            }
          }
        }
    `;



/*

*/


const old_person_block_query = gql`
        query people($block_size: Int, $have_ids: [uuid!]) {
          person(
            where: {
              _and: [
                {_or: [
                ${conditions}
                ]}
                {id: {_nin: $have_ids}}
              ]
            }
            limit: $block_size
          )  
          {
         ...PersonFields
          }
          person_aggregate(
            where: {
              _and: [
                {_not: {timeline_cache:{}}},
                {id: {_nin: $have_ids}}
              ]
            }){
              aggregate {
                count
              }
            }
        }

        ${person_fields}
    `;

const person_block_query = gql`
      query view_timeline_cache_updates($block_size: Int!) {
        view_timeline_cache_updates(limit: $block_size){
          total
          person {
            ...PersonFields
          }
        }
    }
    ${person_fields}
`


const fast_process_page_styles = css`
        :host {
          display: flex;
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
          font-family: 'Roboto', 'Noto', sans-serif;

          --shadow-level-one: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
         --shadow-level-onepointone: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4); 
         --shadow-level-two: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4); 
         --shadow-transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), filter 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }

        
      .pink {
        --mdc-theme-on-primary: white;
        --mdc-theme-primary: var(--paper-pink-a200);
        --mdc-theme-on-secondary: white;
        --mdc-theme-secondary: var(--paper-pink-a200);

        }

      .column {
        box-sizing: border-box;
        display: flex;
        align-items: left;
        justify-content: flex-start;
        flex-direction: column; 
        width: 100%;
        padding: 24px;
        padding-top: 0;
        height: 100%;
      }
      .column > * {
        margin-top: 24px;
      }

      .drag_placeholder {
          text-align: center;
          opacity: 0.4;
          box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; 
        width: 100%;
        height: 100%;

        }

        .scroller {
          overflow: overlay;
          height: calc(100vh - 64px);
          width: 100%;
          margin-top: 64px;
        }

        .content-area {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
          height: calc(100vh - 64px);
        }
        .primary {
          font-weight: 500;
          font-size: 20px;
          }
        .secondary {
          font-weight: 200;
          font-size: 16px;
          margin-top: 8px;
          }


        mwc-top-app-bar {
          --mdc-theme-primary: var(--paper-green-700);
          background-color: var(--paper-green-700);
        }

        div.process_section, div.counters {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          position: relative;
        }

        div.process_box {
          overflow: hidden;
        }

        div.process_item, div.data_item {
          padding: 4px;
          border-radius: 4px;
          background-color: var(--paper-grey-700);
          color: white;
          display: inline-block;
          margin: 4px;
          font-size: 7px;
          flex: 0;
          text-transform: lowercase;
          font-stretch: ultra-condensed;
          line-height: 0;
          width: 8em;
          height: 2em;
          line-height: 2em;
          overflow: hidden;
          text-align: center;

          white-space: nowrap;
        }

        div.data_item {
          width: 3em;
          height: 1em;
          background-color: var(--paper-cyan-500);
        }
        div.data_item[timeline] {
          background-color: var(--paper-pink-200);
        }

        div.counter {
          padding: 12px;
          border-radius: 4px;
          background-color: var(--paper-grey-700);
          color: white;
          margin: 4px;
          display: inline-block;
          width: 20vw;
          font-weight: 800;
          overflow: hidden;
        }
        div.counter span {
          z-index: 10;
        }

        div.counter .value {
          float: right;
          font-weight: 100;
        }

        div.pct_bar {
          position: relative;
          z-index: 0;
        }
        div.pct_bar div.counter {
          z-index: 2;
          background: none;
          position: absolute;
          left: 0;
          top: -5px;

        }
        div#pct_bar {
          z-index: 1;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background-color: var(--paper-green-700);
        }

        div[state='downloaded'] { background-color: var(--paper-blue-500) }
        div[state='processing'] { background-color: var(--paper-pink-300) }
        div[state='processed'] { background-color: var(--paper-green-500) }
        div[state='uploading'] { background-color: var(--paper-purple-500) }
        div[state='delete'] { opacity: 0.2 }
        div[state='process_error'] { background-color: red }
`;

let next_id = 0;
const get_id = () => next_id++;

const max_upload_size = 25;

class FastProcessPage extends KalePage {
  get form_name() { return "Fast Process Page" }

  static styles = [ super.styles, fast_process_page_styles]
  static icon = "cloud_Circle"
  static default_title = "Refresh Computed Data"
  renderPage() {

    let now = performance.now();
    let elapsed = this.process_start ? (now - this.process_start) / 1000 : 0;
    let recs_sec = Math.round(100 * this.total_processed / elapsed) / 100;
    return html`
        <mwc-top-app-bar .type=${"fixed"} .bgcolor=${"var(--paper-green-700)"} >
            <a href="/" slot="navigationIcon" style="color: white"><mwc-icon-button title="Home" icon="arrow_back" ></mwc-icon-button></a>
            <div slot="title" id="title">
              <div style="position: relative; top: -4px"><mwc-icon style="font-size: 150%; position: relative; top: 8px; margin-right: 12px;">build</mwc-icon>Process</div>
            </div>
          <a href="/settings" slot="actionItems" style="color: white"><mwc-icon-button title="Benefits App Settings" icon="settings" ></mwc-icon-button></a>
          <a href="/prefs" slot="actionItems" style="color: white"><mwc-icon-button title="Account Prefs" icon="account_circle"></mwc-icon-button></a>
        </mwc-top-app-bar>
        <div class="top-app-bar-adjust scroller">
          <div class="content-area">
            <div class="column">
              <div class="counters">
                <div class="counter"><span>initial</span><span class="value">${this.initial_server_count === null ? '...' : this.initial_server_count}</span></div>
                <div class="counter"><span>server</span><span class="value">${this.on_server === undefined ? '...' : this.on_server}</span></div>
                <div class="counter pct_bar">
                  <div id="pct_bar" style=${`width: ${this.pct_complete !== null ? this.pct_complete : 0}%`}></div>
￼                 <div class="counter"><span>%</span><span class="value">${this.pct_complete !== null ? Math.round(this.pct_complete) : '...'}</span></div>
￼               </div>

                <div class="counter"><span>recs/sec</span><span class="value">${recs_sec}</span></div>
                <div class="counter" state='requested'><span>requested</span><span class="value">${this.requested_count}</span></div>
                <div class="counter" state='downloaded'><span>downloaded</span><span class="value">${this.downloaded_count}</span></div>
                <div class="counter" state='processing'><span>processing</span><span class="value">${this.processing_count}</span></div>
                <div class="counter" state='processed'><span>processed</span><span class="value">${this.processed_count}</span></div>
                <div class="counter" state='uploading'><span>uploading</span><span class="value">${this.uploading_count}</span></div>
                <div class="counter" state='uploaded'><span>uploaded</span><span class="value">${this.upload_count}</span></div>
                <div class="counter"><span>data</span><span class="value">${this.data_map.size}</span></div>
                <div class="counter"><span>process errors</span><span class="value">${this.process_error_count}</span></div>
              </div>

              <div class="process_section">
                <div class="process_box">
                  ${this.queue.map(item => html`<div class="process_item" state=${item.state}>${item.name ? item.name : item.id}</div>`)}
                </div>
              </div>
           
              <div class="process_section">
                <div class="process_box">
                  ${Array.from(this.data_map.keys()).map(key => html`<div class="data_item" ?timeline=${key.slice(key.length - 10) === '::timeline'}>${key}</div>`)}
                </div>
              </div>
            </div>
          </div>
        </div>
        `;
  }
  // <div id="pct_bar" style=${`width: ${100.0 * (this.upload_count) / this.initial_server_count}%`}></div>
  /* 
             
  */

  get pipe_ready() {
    return this.in_process.length + this.processed.length + this.uploading.length < this.queue_size
  }

  constructor() {
    super();
    this.block_size = 100;
    this.queue_size = 50;
    this.on_server = undefined;
    this.queue = [];
    this.upload_count = 0;

    this.initial_server_count = null;

    this.data_map = new Map();
    this.total_processed = 0;

    this.download_pressure = true;


    this.workers = [];
    this.current_worker_idx = 0;
    this.target_workers = 5;


  }

  get worker() {
    while (this.workers.length < this.target_workers) {
      console.log("populating worker queue")
      this.workers.push(Comlink.wrap(new Worker(new URL('/src/benefits-app/pension-timeline-worker.js', import.meta.url), { type: 'module' })));
    }
    this.current_worker_idx = (this.current_worker_idx + 1) % this.workers.length;
    return this.workers[this.current_worker_idx];
  }

  get requested_count() { return this.queue.filter(q => q.state === 'requested').length; }
  get downloaded_count() { return this.queue.filter(q => q.state === 'downloaded').length; }
  get processing_count() { return this.queue.filter(q => q.state === 'processing').length; }
  get processed_count() { return this.queue.filter(q => q.state === 'processed').length; }
  get uploading_count() { return this.queue.filter(q => q.state === 'uploading').length; }
  get process_error_count() { return this.queue.filter(q => q.state === 'process_error').length; }

  get pct_complete() {
    let start = this.initial_server_count;
    let current = this.on_server;
    if (start === undefined || current === undefined || start === null || current === null || start === 0) return null;
    return 100.0 * (start - current) / start;
  }

  get work_to_do() { return this.on_server > 0 || this.queue.filter(q => q.state !== 'delete').length > 0 }
  get pending_count() { return this.on_server + this.downloaded_count + this.processing_count }

  async run_process() {
    if (this.halt) return;
    this.queue = this.queue.filter(q => q.state !== 'delete');
    this.requestUpdate('queue');

    if (this.on_server !== 0 && this.requested_count === 0 && this.download_pressure) {
      let block = [];
      let requests = this.on_server === undefined || this.block_size < this.on_server ? this.block_size : this.on_server;
      for (let i = 0; i < requests; i++) { block.push({ id: get_id(), state: 'requested' }) }
      this.queue = [...this.queue, ...block];
      this.fetch_block(block);
    }

    while (this.downloaded_count > 0 && this.processing_count < 20) {
      let next = this.queue.find(q => q.state === 'downloaded');
      next.state = 'processing';
      window.requestIdleCallback(() => this.process_item(next));
    }
    this.download_pressure = this.downloaded_count + this.processing_count < 30 && this.processed_count < 100; 

    if ((this.processed_count > max_upload_size || this.pending_count === 0) && this.uploading_count === 0) {
      let block = this.queue.filter(q => q.state === 'processed').slice(0, max_upload_size);
      block.forEach(b => b.state = 'uploading');
      window.requestIdleCallback(() => this.upload_block(block));
    }

    if (this.work_to_do) {
      window.setTimeout(this.run_process.bind(this), 3000);
    } else {
      console.warn("ALL DONE");
      this.workers = [];
    }
  }

  async fetch_block(request_items) {
    client.query({
      fetchPolicy: 'no-cache',
      query: person_block_query,
      variables: { block_size: this.block_size },
    }).then(data => {
      const inner_data = data.data.view_timeline_cache_updates;
      if (inner_data.length > 0) {
        const remaining = inner_data[0].total;
        this.on_server = remaining - inner_data.length;
        if (this.initial_server_count === null) this.initial_server_count = remaining;
        inner_data.forEach(({ person, total }) => {
          let req = request_items.pop();
          if (person && !req) {
            req = { id: get_id(), state: 'requested' };
            this.queue.push(req);
          }
          req.person_id = `${person.id}`;
          req.name = `${person.first_name} ${person.last_name}`
          if (this.data_map.has(req.person_id)) {
            req.state = 'delete';
          } else {
            this.data_map.set(req.person_id, { person: person });
            req.state = 'downloaded';
          }
        })
      } else {
        // no more data
        this.on_server = 0;
        this.all_fetched = true;
      }
      request_items.forEach(i => i.state = 'delete');
      this.requestUpdate('queue');
    })
      .catch(error => {
        console.error("FETCH BLOCK", error);
        request_items.forEach(i => i.state = 'delete');
        this.requestUpdate('queue');
      });
  }

  async process_item(item) {

    if (this.process_start === undefined) this.process_start = performance.now();
    let build = Number(window.localStorage.getItem('benefits_build_number'));
    try {
      //let p = this.data_map.get(item.person_id).person;
      //let tl = await new timeline(p);
      //tl.run(null, null, Comlink.proxy(async final => {
      (await new (this.worker)(this.data_map.get(item.person_id).person, build)).run(null, null, Comlink.proxy(async final => {
        //this.processed.push({ person: person, timeline: JSON.parse(JSON.stringify(final)) });
        item.timeline_id = `${item.person_id}::timeline`;
        this.data_map.set(item.timeline_id, final);
        item.state = 'processed';
        this.requestUpdate('queue');
        this.total_processed += 1;
      }));
    } catch (e) {
      item.state = 'process_error';
      item.error = e;
      console.error(item, e);
      this.requestUpdate('queue');
    }
  }

  upload_block(batch) {
    const batch_person_ids = batch.map(b => b.person_id);
    const upsert_timeline_cache_query = gql`
        mutation process_updates($timelines: [timeline_cache_insert_input!]!, $timeline_cols: [timeline_cache_update_column!]!) {
            timeline_cache:insert_timeline_cache (
              objects: $timelines,
              on_conflict: {
                constraint: timeline_cache_pkey,
                update_columns: $timeline_cols
              })
              {
              returning {
                ...CacheFields
              }
            }
          }
          ${timeline_cache_fragment}
        `

    let now = new Date();
    const change_data = batch.map(b => {
      let timeline = this.data_map.get(b.timeline_id);
      if (!timeline) console.error("NO TL DATA", b);
      return {
        nodes: timeline.nodes.map(n => ({ ...n, edit_data: n.edit_data ? true : n.edit_data, edit_series_data: n.edit_series_data ? true : n.edit_series_data })),
        expires_date: timeline.expires ? timeline.expires : new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
        person_id: b.person_id,
        state: timeline.state,
        report: timeline.report,
        next_year: timeline.next_year,
        errors: timeline.errors,
        years: timeline.years,
        computed_date: now
      };
    });


    client.mutate({
      fetchPolicy: 'no-cache',
      mutation: upsert_timeline_cache_query,
      variables: {
        timelines: change_data,
        timeline_cols: ['computed_date', 'expires_date', 'state', 'next_year', 'report', 'errors', 'nodes', 'years']
      },
      // Tags: to_add.map(t => ({ person_id: this.personid, person_tag_type_code: t })) },
      //refetchQueries: ['person_info']
    }).then(async data => {
      const completed_person_ids = new Set(data.data.timeline_cache.returning.map(r => r.person_id));
      let uploaded = this.queue.filter(q => completed_person_ids.has(q.person_id));
      uploaded.forEach(u => {
        u.state = 'delete';
        this.upload_count += 1;
        this.data_map.delete(u.person_id);
        this.data_map.delete(u.timeline_id);
      });
      this.requestUpdate('upload_count');
      if (uploaded.length != batch.length) {
        console.warn("some items not uploaded", batch.length, uploaded.length, batch_person_ids.filter(i => !completed_person_ids.has(i)));
        let unuploaded = batch.filter(b => !completed_person_ids.has(b.person_id));
        console.table(unuploaded);
        unuploaded.forEach(b => {
          b.state = 'processed';
        });
        this.requestUpdate('processed_count');
      }

      client.queryManager.mutationStore.store = {};
    })
      .catch(error => {
        formatQueryError(error);
        batch.forEach(b => {
          b.state = 'processed';
        });
        this.requestUpdate('processed_count');
      })
  }

  firstUpdated() {
    super.firstUpdated();
    window.requestIdleCallback(() => this.run_process())
  }

  activateRoute() {

  }

  updated(props) {
    if (props.has("search")) {
      this.block_size = this.search.d ? this.search.d : 50;
      this.queue_size = this.search.q ? this.search.q : 30;
    }
  }

  static get properties() {
    return {
      ...(super.properties)
    };
  }
}

window.customElements.define('process-fast', FastProcessPage);
export { FastProcessPage }
