import gql from 'graphql-tag';
import { client } from './client.js';

export const regular_factors_fields = gql`
  fragment PensionFactorFields on pension_factors {
    regular_factors_date:date
    regular_factors_id:id
    regular_factors_filename:filename
    certain10
    early_factors
    joint50
    joint75
    joint100
    lump20
    lump20plus
    lumpjoint50
    lumpjoint75
    lumpsla
   }`

export const tax_factors_fields = gql`
  fragment TaxFactorFields on factors_tax_calc {
    tax_factors_date:date
    tax_factors_id:id
    tax_factors_filename:filename
    mortalitytable_90cm
    tax_rates_120pct_midterm
    member
  }
`

export const tax_factors_list_fields = gql`
  fragment TaxFactorListFields on factors_tax_calc {
    tax_factors_date:date
    tax_factors_id:id
    tax_factors_filename:filename
  }
`

const regular_factors_query = gql`
  query {
    factors:pension_factors(limit: 1, order_by: {date: desc}) {
    ...PensionFactorFields
    }
}
${regular_factors_fields}
`

const tax_factors_query = gql`
  query {
    factors:factors_tax_calc(limit: 1, order_by: {date: desc}) {
      ...TaxFactorFields
    }
  }  
  ${tax_factors_fields}
`

const factor_filenames_query = gql`
  query {
    factors:factors_tax_calc(order_by: {date: desc}) {
      ...TaxFactorListFields
    }
  }  
  ${tax_factors_list_fields}
`

const tax_factor_by_id_query = gql`
  query tax_factor_by_id($id: uuid!) {
    factors:factors_tax_calc(where: {id: {_eq: $id}}) {
      ...TaxFactorListFields
    }
  }  
  ${tax_factors_list_fields}
`

export const insert_pension_factors = gql`
  mutation insert_pension_factors($newFactors: pension_factors_insert_input!) {
    insert_pension_factors (
      objects: [
        $newFactors
      ]
    ) {
      returning {
        ...PensionFactorFields
      }
    }
  }
  ${regular_factors_fields}
`

export const insert_tax_factors = gql`
  mutation insert_factors_tax_calc($newFactors: factors_tax_calc_insert_input!) {
    insert_factors_tax_calc (
      objects: [
        $newFactors
      ]
    ) {
      returning {
        ...TaxFactorFields
      }
    }
  }
  ${tax_factors_fields}
`

let cached_regular_factors;
const fetch_regular_factors = async () => {
  if (!cached_regular_factors) {
    let result = await client.query({query: regular_factors_query});
    cached_regular_factors = result?.data?.factors?.[0];
  }
  return cached_regular_factors ?? {};
}

let cached_tax_factors;
const fetch_tax_factors = async () => {
  if (!cached_tax_factors) {
    let result = await client.query({query: tax_factors_query});
    cached_tax_factors = result?.data?.factors?.[0];
  }
  return cached_tax_factors ?? {};
}

let cached_factor_filenames;
export const fetch_factor_filenames = async () => {
  if (!cached_factor_filenames) {
    let result = await client.query({query: factor_filenames_query});
    cached_factor_filenames = result?.data?.factors;
  }
  return cached_factor_filenames ?? {};
}

export const fetch_tax_factor_by_id = async (id) => {
  console.log(id);
  let result = await client.query({query: tax_factor_by_id_query, variables: {id} });
  const tax_factor_by_id = result?.data?.factors;
  return tax_factor_by_id ?? {};
}

export const reset_pension_factors_cache = () => {
  cached_regular_factors = null;
  cached_tax_factors = null;
  cached_factor_filenames = null;
}

export const get_pension_factors = async () => {
  let [reg, tax] = await Promise.all([fetch_regular_factors(), fetch_tax_factors()]);
  return {...reg, ...tax};
}